import { Image } from 'antd';
import { ViewItem } from 'components/view-item/view-item';
import { FC, useCallback } from 'react';
import { TAttachmentItem } from 'components/chat/types';
import { NiceRadioGroup } from 'components/commons/nice-radio-group/nice-radio-group';
import { ratingOptionsSQ } from 'modules/gdp/interested-patients/utils';
import '../sq-form.styles.less';
import { ISmileQuestionnaire } from 'services/interested-patients-api/types';

interface IProps {
  data: ISmileQuestionnaire;
  className?: string;
  labeling: any;
}

export const SQFormPreviewNew: FC<IProps> = ({ data, className, labeling }) => {
  const renderListPreview = useCallback(
    (key: keyof ISmileQuestionnaire) => (
      <ViewItem title={labeling?.[key]} className="col-span-12">
        <div className="flex flex-wrap gap-3">
          {Array.isArray(data?.[key])
            ? (data?.[key] as string[])?.map((item: string) => (
                <span className="w-max whitespace-nowrap rounded-xl bg-[#E5F0FF] p-2">{item}</span>
              ))
            : data?.[key]}
        </div>
      </ViewItem>
    ),
    [labeling, data],
  );

  return (
    <div className={`custom-slider-styling mb-6 grid grid-cols-12 gap-2 ${className}`}>
      <ViewItem title={labeling.rating} className="sq-form-container sq-preview-form col-span-12 md:col-span-8">
        <NiceRadioGroup options={ratingOptionsSQ} defaultValue={data?.rating} />
        <div className="mb-4 mr-4 mt-1 flex items-center justify-between">
          <div className="font-semibold text-black">Not Happy</div>
          <div />
          <div className="mr-3 font-semibold text-black">Not Sure</div>
          <div />
          <div className="font-semibold text-black">Love it</div>
        </div>
      </ViewItem>
      <ViewItem title={labeling?.consideredBefore} className="col-span-12">
        <span className="w-max rounded-xl bg-[#E5F0FF] p-2">{data?.consideredBefore}</span>
      </ViewItem>
      {renderListPreview('newChange')}
      {renderListPreview('annoyIssues')}
      {renderListPreview('newEventNote')}
      {renderListPreview('newConcern')}
      <ViewItem title={labeling?.note} className="col-span-12">
        <span className="rounded-xl">{data?.note ?? '-'}</span>
      </ViewItem>
      <ViewItem title={`Photos uploaded (${data?.attachments?.length ?? 0})`} className="col-span-12">
        <div className="flex flex-wrap gap-4">
          {data?.attachments?.map((attachment: TAttachmentItem) => (
            <div key={attachment?.url}>
              <Image width={180} src={attachment?.url} />
            </div>
          ))}
        </div>
      </ViewItem>
    </div>
  );
};
