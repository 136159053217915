import { FC, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { DownloadProgressItem } from './download-progress-item/download-progress-item';
import { Button, Tooltip, Collapse } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { updateDisplay } from 'redux/downloadStlsSlice';
import { ReactComponent as UpArrowSVG } from 'assets/images/up.svg';
import { ReactComponent as DownArrowSVG } from 'assets/images/down.svg';

export const DownloadProgress: FC = () => {
  const listRef = useRef<HTMLDivElement>(null);
  const progressList = useAppSelector((state) => state.downloadProgress.progressList);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(updateDisplay(false));
  };

  useEffect(() => {
    listRef?.current?.scrollTo({ top: -1000, behavior: 'smooth' });
  }, [progressList.length]);

  return (
    <div className="download-stls fixed bottom-0 z-[100] ml-2 w-80">
      <Collapse
        accordion
        expandIconPosition="end"
        defaultActiveKey={['1']}
        // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon={({ isActive }) => (
          <Button className="collapse-btn flex items-center justify-center" shape="circle" size="small">
            {isActive ? (
              <div style={{ paddingLeft: 1, paddingBottom: 2 }}>
                <UpArrowSVG />
              </div>
            ) : (
              <div style={{ paddingLeft: 1, paddingTop: 2 }}>
                <DownArrowSVG />
              </div>
            )}
          </Button>
        )}
      >
        <Collapse.Panel header="Preparing Download" forceRender key="1">
          <div ref={listRef} className="mb-3 flex max-h-[150px] flex-col-reverse overflow-auto">
            {progressList.map((item) => (
              <DownloadProgressItem
                key={item.downloadCode}
                downloadCode={item.downloadCode}
                backUrl={item.backUrl}
                payload={item.payload}
              />
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
      <div className="absolute right-2 top-3">
        <Tooltip title="Close">
          <Button
            onMouseEnter={(event) => event.stopPropagation()}
            onClick={onClose}
            type="text"
            className="h-[24px] p-1 pt-[2px]"
          >
            <CloseOutlined style={{ fontSize: '15px' }} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
