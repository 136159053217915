import { useParams } from 'react-router-dom';
import { Typography, Skeleton } from 'antd';
import { useGetRevisionRequestsQuery } from 'services/revision-requests-api/endpoints';
import { IModification } from 'services/revision-requests-api/types';
import { formatDate } from 'utils';
import { ISubmission } from 'services/patients-api/types';
import { SUBMISSION_SERVICE_TYPE } from 'config/constants';
import { FC, useMemo } from 'react';
import { RevisionRequestsForm } from './revision-requests-form/revision-requests-form';
import { TREATMENT_DESIGN_STATUS } from 'services/treatment-designs-api/types';
import { useAuth } from 'hooks/useAuth';

type TProps = {
  submissionInfo: ISubmission;
  treatmentDesignStatus?: TREATMENT_DESIGN_STATUS;
};

export const RevisionRequests: FC<TProps> = ({ submissionInfo, treatmentDesignStatus }) => {
  const { user } = useAuth();
  const params: { designId: string; submissionId: string } = useParams();
  const { data, isLoading, isFetching } = useGetRevisionRequestsQuery({
    page: 1,
    perPage: 20,
    submissionId: params.submissionId,
  });

  const showRevisionRequestsForm = useMemo(
    () =>
      (user?.role === 'SPECIALIST' &&
        submissionInfo.serviceType === SUBMISSION_SERVICE_TYPE.DUO &&
        !submissionInfo.isSubmissionApproved) ||
      (user?.role === 'DENTIST' &&
        submissionInfo.serviceType === SUBMISSION_SERVICE_TYPE.SOLO &&
        treatmentDesignStatus === TREATMENT_DESIGN_STATUS.REVIEW_DESIGN),
    [user?.role, submissionInfo.serviceType, submissionInfo.isSubmissionApproved, treatmentDesignStatus],
  );

  const checkHasFurtherInstructions = submissionInfo?.instruction?.furtherInstructions;

  return (
    <div className="mt-1 grid w-full grid-cols-12 gap-4">
      <Skeleton loading={isLoading}>
        {data?.docs && data?.docs.length > 0 ? (
          <div className="col-span-12">
            {data?.docs?.map((modification: IModification) => (
              <div
                className="requests-container mb-2 rounded-standard bg-[#F5FBFF] px-4 py-3"
                key={modification.submission}
              >
                <Typography.Title level={5} className="m-0 font-normal text-[#09263D]">
                  Design {user?.role === 'DENTIST' ? modification.dentistDesignNumber : modification.designNumber}
                </Typography.Title>
                <Typography.Text className="text-body">
                  {formatDate(modification.createdAt, 'DD/MM/YYYY - hh:mm a')}{' '}
                </Typography.Text>
                <Typography.Title
                  level={5}
                  className="mt-2 whitespace-pre-wrap font-light text-[#09263D]"
                  style={{ fontSize: 14 }}
                >
                  {modification.comment}
                </Typography.Title>
              </div>
            ))}
            {/* TODO: improve the loading */}
            <Skeleton loading={isFetching} paragraph={{ rows: 1 }}>
              <div className="hidden" />
            </Skeleton>
          </div>
        ) : !showRevisionRequestsForm ? (
          <div className="col-span-12 text-center">No data to show!</div>
        ) : null}
      </Skeleton>
      {user?.role === 'SPECIALIST' && (
        <div className="col-span-12 my-4">
          <div className="text-sm font-semibold">Specific Instructions & Guidance for this Case</div>
          {checkHasFurtherInstructions && (
            <div className="my-2 text-sm font-normal">
              {formatDate(submissionInfo?.instruction?.createdAt, 'DD/MM/YYYY - hh:mm a')}{' '}
            </div>
          )}
          <div className="mt-2 whitespace-pre-line">{submissionInfo?.instruction?.furtherInstructions || '-'}</div>
        </div>
      )}
      {showRevisionRequestsForm && (
        <div className="col-span-12">
          <RevisionRequestsForm designId={params.designId} submissionId={params.submissionId} userRole={user?.role!} />
        </div>
      )}
    </div>
  );
};
