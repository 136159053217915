import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { DatePicker } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';

const DATE_FORMAT = ['DD/MM/YYYY', 'D/M/YYYY'];

export const NiceDatePicker = forwardRef((props: PickerProps<Moment>, ref: any) => {
  const { id: idProps, onChange, format = DATE_FORMAT } = props;
  const valueRef = useRef<Moment | null>(null);

  const id = useMemo(() => idProps || new Date().toString(), []);

  useEffect(() => {
    if (id) {
      const dateInput: any = document.getElementById(id) || [];
      dateInput?.addEventListener('keyup', () => {
        if (!dateInput.value) valueRef.current = null;
        else if (moment(dateInput.value, format as string).isValid()) {
          valueRef.current = moment(dateInput.value, format as string);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    // case the value is set from outside
    if (props.value && props.value !== valueRef.current) valueRef.current = props.value;
  }, [props.value]);

  // TODO: Why need onChange when onBlur?
  const onBlur = () => {
    onChange?.(valueRef.current as any, valueRef.current?.toString()!);
  };

  return (
    <DatePicker
      format={DATE_FORMAT}
      {...props}
      id={id}
      onChange={(momentDate) => {
        if (!momentDate) {
          valueRef.current = null;
        } else if (momentDate.isValid()) {
          valueRef.current = momentDate;
        }
        onBlur();
      }}
      onSelect={(momentDate) => {
        if (momentDate && momentDate.isValid()) {
          valueRef.current = momentDate;
        }
        // onBlur();
      }}
      onBlur={onBlur}
      ref={ref}
    />
  );
});
