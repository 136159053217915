import { CarouselRef, CarouselProps } from 'antd/lib/carousel';
import { Carousel } from 'antd';
import { FC, useRef, Children } from 'react';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';

interface IProps extends CarouselProps {
  containerClassName?: string;
}

export const NiceCarousel: FC<IProps> = ({ children, containerClassName, ...restProps }) => {
  const childCount = Children.count(children);

  const carouselRef = useRef<CarouselRef>(null);
  const goNext = () => {
    carouselRef.current?.next();
  };

  const goPrev = () => {
    carouselRef.current?.prev();
  };
  return (
    <Carousel ref={carouselRef} {...restProps}>
      {Children.map(children, (child) => (
        <div className={twMerge('relative bg-[#F1F7FF]', containerClassName)}>
          {childCount > 1 && (
            <div
              className="absolute left-2 top-1/2 z-10 mt-[1px] flex h-6 w-6 -translate-y-1/2 items-center justify-center rounded-full bg-gray-200"
              onClick={(e) => {
                e.stopPropagation();
                goPrev();
              }}
            >
              <LeftCircleFilled style={{ fontSize: '22px' }} />
            </div>
          )}
          {child}
          {childCount > 1 && (
            <div
              className="absolute right-2 top-1/2 z-10 mt-[1px] flex h-6 w-6 -translate-y-1/2 items-center justify-center rounded-full bg-gray-200"
              onClick={(e) => {
                e.stopPropagation();
                goNext();
              }}
            >
              <RightCircleFilled style={{ fontSize: '22px' }} />
            </div>
          )}
        </div>
      ))}
    </Carousel>
  );
};
