import { globalStorage } from '../window';
import { api, VERSION, formatUrl } from '../api';
import pickBy from 'lodash/pickBy';
import {
  IPatientsResponse,
  IPatientsRequest,
  ISubmission,
  IArchivePatientRequest,
  IPatientJourneyResponse,
  TVerifyPatientTokenResponse,
  IGetPatientCheckingsResponse,
  ITreatmentDesignCountResponse,
  IDentistPatientSubmission,
  IDentistPatientSubmissionForm,
  IDentistRelationResponse,
  IDentistRelationPatientListPayload,
  IDentistRelationPatientListResponse,
  IUpdateSubmissionResponse,
  TSubmissionByPatientResponse,
  IPatient,
  TPreviousIprImagesResponse,
  TSharedLinkDentistResponse,
  TUpdateSharedLinkPatientSQ,
} from './types';
import { ISubmissionForm } from 'modules/gdp/case-submission/types';
import { omit } from 'lodash';
import { ISmileQuestionnaire } from 'services/interested-patients-api/types';

export const PATIENTS_API_URL = {
  SUBMISSIONS: '/submissions',
  INVITATIONS: '/cases/invitations',
  SUBMIT_CASE: '/submission-invitations',
  JOURNEY: '/submissions/patients/journey',
  PRIORITY_CASES: '/cases/priority',
  PATIENTS: '/patients',
  PATIENT_CHECKINGS: '/patient-checkings',
  PATIENT_SUBMISSIONS: '/patient-submissions',
  REQUEST_ENVELOPE: '/request/envelopes',
  DENTIST_RELATION: '/dentist-relation',
  TREATMENT_DESIGN_COUNT: '/treatment-design/count',
};

export const patientsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubmissions: build.query<IPatientsResponse, IPatientsRequest>({
      query: ({ type, ...rest }) => {
        globalStorage.patientsScreen.urlQueries = { type, ...rest };
        return formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}?${new URLSearchParams(pickBy(rest as Record<string, any>))}`);
      },
      // Provides a list of `Patients` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Posts` element was added.
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.docs.map(({ id }) => ({ type: 'Submissions', id } as const)),
              { type: 'Submissions', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Submissions', id: 'LIST' }` is invalidated
            [{ type: 'Submissions', id: 'LIST' }],
      // keepUnusedDataFor: 5,
    }),
    getSubmissionById: build.query<ISubmission, string>({
      query: (id) => formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/${id}`),
      providesTags: (result, error, id) => [{ type: 'Submissions', id }],
    }),
    searchSubmissions: build.query<IPatientsResponse, string>({
      query: (q) => formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}?page=1&perPage=20&q=${q}`),
    }),
    addSubmission: build.mutation<IUpdateSubmissionResponse, Partial<ISubmissionForm>>({
      query(body) {
        return {
          url: formatUrl(
            `${PATIENTS_API_URL.SUBMISSIONS}/${body.planType?.toLowerCase()}/${body.serviceType?.toLowerCase()}`,
          ),
          method: 'POST',
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      invalidatesTags: (result, error, { patient }) => [
        { type: 'Submissions', id: 'LIST' },
        { type: 'SubmissionsByPatient', id: patient },
        { type: 'InterestedPatients', id: 'LIST' },
      ],
    }),
    updateSubmission: build.mutation<IUpdateSubmissionResponse, Partial<ISubmissionForm> & { isAutoSave?: boolean }>({
      query(data) {
        const { id, createdAt, updatedAt, submissionId, designerStatus, dentistStatus, ...body } = data;
        return {
          url: formatUrl(
            `${PATIENTS_API_URL.SUBMISSIONS}/${body.planType?.toLowerCase()}/${body.serviceType?.toLowerCase()}/${id}`,
          ),
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to this Patients `id` only.
      // In this case, `getPatients` will be re-run. `getPatients` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id: _id, patient, isAutoSave }) => {
        if (error || isAutoSave) return [];
        return [
          { type: 'Submissions', id: result?.submission?.id },
          { type: 'SubmissionsByPatient', id: patient },
          { type: 'InterestedPatients', id: 'LIST' },
        ];
      },
    }),
    updateSubmittedSubmission: build.mutation<ISubmission, Partial<ISubmissionForm>>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/submitted/${id}`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id: _id }) => {
        if (error) return [];
        return [{ type: 'Submissions', id: result?.id }];
      },
    }),
    archiveSubmission: build.mutation<{ message: string }, IArchivePatientRequest>({
      query({ reason, id }) {
        const body = { reason };
        return {
          url: formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/${id}/archive`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        { type: 'Submissions', id: 'LIST' },
        { type: 'InterestedPatients', id: 'LIST' },
      ],
    }),
    unArchiveSubmission: build.mutation<{ message: string }, IArchivePatientRequest>({
      query({ reason, id }) {
        const body = { reason };
        return {
          url: formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/${id}/un-archive`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        { type: 'Submissions', id: 'LIST' },
        { type: 'InterestedPatients', id: 'LIST' },
      ],
    }),
    updatePatientProfile: build.mutation<ISubmission, { id: string; data: Partial<IPatient> }>({
      query({ id, data }) {
        return {
          url: formatUrl(`${PATIENTS_API_URL.PATIENTS}/${id}`),
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: 'CompletedTreatment', id }],
    }),
    getPatientsJourney: build.query<IPatientJourneyResponse, any>({
      query: () => formatUrl(`${PATIENTS_API_URL.JOURNEY}`),
      providesTags: [{ type: 'PatientJourney', id: 'LIST' }],
    }),
    getSubmissionsByPatient: build.query<TSubmissionByPatientResponse[], string>({
      query: (id) => formatUrl(`${PATIENTS_API_URL.PATIENTS}/${id}${PATIENTS_API_URL.SUBMISSIONS}`),
      providesTags: (result, error, id) => [{ type: 'SubmissionsByPatient', id }],
    }),
    submitSubmissionNotSuitable: build.mutation<ISubmission, Partial<ISubmission>>({
      query(data) {
        const { submission, ...body } = data;
        return {
          url: formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/${submission}/unsuitable`),
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to this Patients `id` only.
      // In this case, `getPatients` will be re-run. `getPatients` *might*  rerun, if this id was under its results.
      invalidatesTags: (_result, _error, { id: _id }) => [{ type: 'Submissions', id: 'LIST' }],
    }),
    getDentistPatientSubmissions: build.query<IDentistPatientSubmission[], string>({
      query: (submissionId) => formatUrl(`${PATIENTS_API_URL.PATIENT_SUBMISSIONS}?submission=${submissionId}`),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: { id: string }) => ({ type: 'PatientSubmissions', id } as const)),
              { type: 'PatientSubmissions', id: 'LIST' },
            ]
          : [{ type: 'PatientSubmissions', id: 'LIST' }],
    }),
    getDentistPatientCheckings: build.query<IGetPatientCheckingsResponse, string>({
      query: (submissionId) => ({
        url: formatUrl(`${PATIENTS_API_URL.PATIENT_CHECKINGS}?submission=${submissionId}&page=1&perPage=10`),
        method: 'GET',
      }),
    }),
    createDentistPatientCheckings: build.mutation<unknown, IDentistPatientSubmissionForm>({
      query: (data) => ({
        url: formatUrl(`${PATIENTS_API_URL.PATIENT_CHECKINGS}`),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_r, _e, { submission }) => [
        { type: 'Submissions', id: submission },
        { type: 'PatientSubmissions', id: 'LIST' },
      ],
    }),
    updateDentistPatientCheckings: build.mutation<unknown, { data: IDentistPatientSubmissionForm; id: string }>({
      query: ({ data, id }) => ({
        url: formatUrl(`${PATIENTS_API_URL.PATIENT_CHECKINGS}/${id}`),
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_r, _e, { data: { submission } }) => [
        { type: 'Submissions', id: submission },
        { type: 'PatientSubmissions', id: 'LIST' },
      ],
    }),
    markPatientStatusViewed: build.mutation<any, any>({
      query: (id) => ({
        url: formatUrl(`${PATIENTS_API_URL.PATIENT_SUBMISSIONS}/checking-histories/${id}`),
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: (_result, _error) => [{ type: 'PatientSubmissions', id: 'LIST' }],
    }),
    requestEnvelope: build.mutation<void, { practiceIds: string[] }>({
      query: ({ practiceIds }) => ({
        method: 'POST',
        url: formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}${PATIENTS_API_URL.REQUEST_ENVELOPE}`),
        body: { practiceIds },
      }),
    }),
    getSubmissionsDentistRelation: build.query<IDentistRelationResponse, string>({
      query: (id) => formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/${id}${PATIENTS_API_URL.DENTIST_RELATION}`),
    }),
    getSubmissionsDentistRelationPatientList: build.query<
      IDentistRelationPatientListResponse,
      IDentistRelationPatientListPayload
    >({
      query: ({ submission, page, perPage }) =>
        formatUrl(
          `${PATIENTS_API_URL.SUBMISSIONS}/${submission}${PATIENTS_API_URL.DENTIST_RELATION}/patient-detail?page=${page}&perPage=${perPage}`,
        ),
    }),
    getTreatmentDesignCount: build.query<ITreatmentDesignCountResponse, string>({
      query: (submissionId) =>
        formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/${submissionId}${PATIENTS_API_URL.TREATMENT_DESIGN_COUNT}`),
    }),

    // PATIENT apis
    getPatientCheckings: build.query<TVerifyPatientTokenResponse, string>({
      query: (token) => `/${VERSION}/user${PATIENTS_API_URL.PATIENT_CHECKINGS}?password=${token}`,
    }),
    submitPatientSubmission: build.mutation<any, any>({
      query: (data) => ({
        url: `/${VERSION}/user${PATIENTS_API_URL.PATIENT_SUBMISSIONS}`,
        method: 'POST',
        body: data,
      }),
    }),
    // Potential Patients apis
    getInterestedPatientSQ: build.query<IPatient, { id: string; password: string }>({
      query: ({ id, password }) => `/${VERSION}/user${PATIENTS_API_URL.PATIENTS}/${id}/sq?password=${password}`,
      providesTags: (result, error, params) => [{ type: 'InterestedPatientSQ', id: params.id }],
    }),
    updateInterestedPatientSQ: build.mutation<any, any>({
      query: (data) => ({
        url: `/${VERSION}/user${PATIENTS_API_URL.PATIENTS}/${data.id}/sq`,
        method: 'PUT',
        body: omit(data, 'id'),
      }),
      invalidatesTags: (_result, _error, data) => [
        ...(data.status === 'COMPLETED' ? [{ type: 'InterestedPatientSQ', id: data.id } as const] : []),
      ],
    }),
    getPreviousIprImagesBySubmission: build.query<TPreviousIprImagesResponse, { submissionId: string }>({
      query: ({ submissionId }) => formatUrl(`${PATIENTS_API_URL.SUBMISSIONS}/${submissionId}/previous-iprImages`),
    }),
    getSharedLinkDentistInfo: build.query<TSharedLinkDentistResponse, { sqLinkId: string }>({
      query: ({ sqLinkId }) => `/${VERSION}/user/patients/shared-link/dentist?sqLinkId=${sqLinkId}`,
    }),
    updateSharedLinkPatientSQ: build.mutation<
      ISmileQuestionnaire & { patientId: string; password: string },
      TUpdateSharedLinkPatientSQ
    >({
      query: (data) => ({
        url: `/${VERSION}/user${PATIENTS_API_URL.PATIENTS}/shared-link/sq?sqLinkId=${data.sqLinkId}`,
        method: 'POST',
        body: omit(data, 'sqLinkId'),
      }),
    }),
  }),
});

export const {
  useGetSubmissionsQuery,
  useGetSubmissionByIdQuery,
  useSearchSubmissionsQuery,
  useAddSubmissionMutation,
  useUpdateSubmissionMutation,
  useUpdateSubmittedSubmissionMutation,
  useArchiveSubmissionMutation,
  useUnArchiveSubmissionMutation,
  useGetPatientsJourneyQuery,
  useGetSubmissionsByPatientQuery,
  useSubmitSubmissionNotSuitableMutation,
  useGetDentistPatientSubmissionsQuery,
  useGetPatientCheckingsQuery,
  useLazyGetDentistPatientCheckingsQuery,
  useCreateDentistPatientCheckingsMutation,
  useUpdateDentistPatientCheckingsMutation,
  useSubmitPatientSubmissionMutation,
  useMarkPatientStatusViewedMutation,
  useRequestEnvelopeMutation,
  useUpdatePatientProfileMutation,
  useGetSubmissionsDentistRelationQuery,
  useGetSubmissionsDentistRelationPatientListQuery,
  useGetTreatmentDesignCountQuery,
  useGetInterestedPatientSQQuery,
  useUpdateInterestedPatientSQMutation,
  useGetPreviousIprImagesBySubmissionQuery,
  useGetSharedLinkDentistInfoQuery,
  useUpdateSharedLinkPatientSQMutation,
} = patientsApi;
