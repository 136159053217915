/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IUser } from './services/type';
import type { TRootState } from 'redux/store';

export type TAuthState = {
  user: IUser | null;
  isFullProfileLoaded: boolean;
  justLoggedIn: boolean;
  justSignUp?: boolean;
};

const INITIAL_STATE: TAuthState = {
  user: null,
  isFullProfileLoaded: false,
  justLoggedIn: false,
  justSignUp: false,
};

const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, justSignUp } }: PayloadAction<{ user: IUser; justSignUp?: boolean }>,
    ) => {
      state.user = user;
      state.isFullProfileLoaded = false;
      state.justLoggedIn = true;
      state.justSignUp = justSignUp;
    },
    updateUserData: (state, { payload: user }: PayloadAction<IUser>) => {
      state.user = user;
      state.isFullProfileLoaded = true;
    },
    updateJustLoggedIn: (state, { payload: { justLoggedIn } }: PayloadAction<{ justLoggedIn: boolean }>) => {
      state.justLoggedIn = justLoggedIn;
    },
    updateJustSignUp: (state, { payload: { justSignUp } }: PayloadAction<{ justSignUp: boolean }>) => {
      state.justSignUp = justSignUp;
    },
    logout: () => INITIAL_STATE,
  },
});

export const { setCredentials, updateUserData, updateJustLoggedIn, updateJustSignUp, logout } = slice.actions;

export default slice.reducer;

export const selectAuthState = (state: TRootState) => state.auth;
