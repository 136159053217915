import { AntdCheckboxGroup } from 'components/commons/antd-checkbox-group/antd-checkbox-group';
import { AntdRadioGroup, IAntdRadioGroupProps } from 'components/commons/antd-radio-group/antd-radio-group';
import { NiceRadioGroup, INiceRadioGroupProps } from 'components/commons/nice-radio-group/nice-radio-group';
import { INiceSelectProps, NiceSelect } from 'components/commons/nice-select/nice-select';
import { useMetaOptionsSubmission } from 'hooks/useMetaOptionsSubmission';
import { FC, useCallback, useMemo } from 'react';
import { IMetaOption } from 'services/api';

interface IProps
  extends Omit<INiceSelectProps, 'options' | 'onBlur' | 'onChange' | 'onFocus'>,
    Omit<INiceRadioGroupProps, 'options' | 'onBlur' | 'onChange' | 'onFocus'>,
    Omit<IAntdRadioGroupProps, 'options' | 'onBlur' | 'onChange' | 'onFocus'> {
  metaKey: string;
  optionKey: string;
  uiType?: 'select' | 'antdRadio' | 'niceRadio' | 'antdCheckbox';
  onChange?: (e: any) => void;
  getFilteredOptions?: (options: IMetaOption[]) => IMetaOption[];
}

export const MetaOptionsInput: FC<IProps> = (props) => {
  const { metaKey, optionKey, uiType = 'select', onChange, getFilteredOptions, ...rest } = props;
  const { data: optionData, getOptions } = useMetaOptionsSubmission(metaKey);

  const options = useMemo(() => {
    const optionList = getOptions(optionKey) || [];
    return getFilteredOptions ? getFilteredOptions(optionList) : optionList;
  }, [optionData, optionKey, getFilteredOptions]);

  const handleChange = useCallback((value) => onChange?.(value ?? null), []);

  if (uiType === 'niceRadio')
    return (
      <NiceRadioGroup
        options={options}
        itemStyle={{ minWidth: 120, textAlign: 'center' }}
        onChange={handleChange}
        {...rest}
      />
    );

  if (uiType === 'antdRadio') return <AntdRadioGroup options={options} onChange={handleChange} {...rest} />;

  if (uiType === 'antdCheckbox') return <AntdCheckboxGroup options={options} onChange={handleChange} {...rest} />;

  return (
    <NiceSelect
      placeholder="Please Select"
      allowClear
      showArrow
      loading={!options.length}
      options={options}
      onChange={handleChange}
      {...rest}
    />
  );
};
