import { FC, useEffect, useState } from 'react';
import { PatientRecapLogin } from './patient-recap-login/patient-recap-login';
import { PatientRecapSubmit } from './patient-recap-submit/patient-recap-submit';
import { useGetPatientCompletedTreatmentRecapByPasswordQuery } from 'services/completed-treatment-api/endpoints';
import { useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';

export const CompletedTreatmentRecap: FC = () => {
  const params = useParams<{ patientId: string }>();
  const token = useQuery().get('token')!;
  const isPublic = !!useQuery().get('public')!;
  const [password, setPassword] = useState(token);

  const { data: recap } = useGetPatientCompletedTreatmentRecapByPasswordQuery(
    {
      patientId: params?.patientId,
      ...(isPublic ? { token, isPublic } : { password }),
    },
    {
      skip: !password,
    },
  );

  useEffect(() => {
    if (recap && !isPublic) {
      trackEvent(EEventNames.PATIENT_SMILE_STORY_VIEWED, {
        'Patient Name': recap.fullName,
        'Patient ID': params?.patientId,
        'Smile Story Viewed At': new Date().toISOString(),
        'Dentist Full Name': recap.createdBy?.fullName,
        'Dentist ID': recap.createdBy?.id,
      });
    }
  }, [recap]);

  return (
    <div className="min-h-screen bg-black/5 p-0 md:p-8">
      {!password && <PatientRecapLogin onLoggedIn={(dob: string) => setPassword(dob)} patientId={params?.patientId} />}
      {password && <PatientRecapSubmit recap={recap} password={password} patientId={params?.patientId} />}
    </div>
  );
};
