import { api, formatUrl } from '../api';
import {
  ICreateOrderReplacementPayload,
  IDentistOrder,
  IFeedBackRequest,
  IGetOrderReplacementSummaryPayload,
  IOrderReplacement,
  TGetOrderReplacementSummaryResponse,
  TGetOrdersBySubmissionResponse,
  TGetOrderSummaryResponse,
} from './types';

export const ordersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrdersBySubmission: build.query<TGetOrdersBySubmissionResponse, string>({
      query: (submissionId) => formatUrl(`/orders?submission=${submissionId}`),
      providesTags: (result, error, submissionId) => [{ type: 'SubmissionOrders', id: submissionId }],
    }),
    getOrderSummary: build.mutation<TGetOrderSummaryResponse, any>({
      query: (credentials) => ({
        url: formatUrl('/orders/summary'),
        method: 'POST',
        body: credentials,
      }),
    }),
    createOrder: build.mutation<any, any>({
      query: (credentials) => ({
        url: formatUrl('/orders'),
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Discounts', id: 'LIST' },
        { type: 'Submissions', id: 'LIST' },
        { type: 'TreatmentDesigns', id: 'LIST' },
        { type: 'PatientProposals', id: 'LIST' },
        {
          type: 'SubmissionOrders',
          id: args.submission,
        },
        { type: 'InterestedPatients', id: 'LIST' },
      ],
    }),
    sendFeedback: build.mutation<void, IFeedBackRequest>({
      query: (payload) => ({
        url: formatUrl('/feedback'),
        method: 'POST',
        body: payload,
      }),
    }),
    // Replacement
    getOrderReplacementSummary: build.mutation<TGetOrderReplacementSummaryResponse, IGetOrderReplacementSummaryPayload>(
      {
        query: (credentials) => ({
          url: formatUrl('/orders/replacement-summary'),
          method: 'POST',
          body: credentials,
        }),
      },
    ),
    createOrderReplacement: build.mutation<{ id: string }, ICreateOrderReplacementPayload>({
      query: (credentials) => ({
        url: formatUrl('/orders/replacement'),
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: (result, _error, args) => [
        { type: 'Submissions', id: 'LIST' },
        {
          type: 'SubmissionOrders',
          id: args.submission,
        },
        {
          type: 'Submissions',
          id: args.submission,
        },
      ],
    }),
    updateOrderReplacement: build.mutation<
      { id: string },
      { replacementId: string; payload: ICreateOrderReplacementPayload }
    >({
      query: ({ replacementId, payload }) => ({
        url: formatUrl(`/orders/replacement/${replacementId}`),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, _error, { payload }) => [
        { type: 'Submissions', id: 'LIST' },
        {
          type: 'SubmissionOrders',
          id: payload.submission,
        },
      ],
    }),
    getDraftReplacement: build.query<
      IOrderReplacement,
      {
        submissionId: string;
        replacementId?: string; // just for clear cache
      }
    >({
      query: ({ submissionId }) => formatUrl(`/submissions/${submissionId}/replacement/draft`),
    }),
    getOrdersByPatient: build.query<IDentistOrder, string>({
      query: (patientId) => formatUrl(`/orders/patient/${patientId}`),
      providesTags: (result, error, patientId) => [{ type: 'SubmissionOrders', id: patientId }],
    }),
  }),
});

export const {
  useGetOrdersBySubmissionQuery,
  useGetOrderSummaryMutation,
  useCreateOrderMutation,
  useSendFeedbackMutation,
  useGetOrderReplacementSummaryMutation,
  useCreateOrderReplacementMutation,
  useUpdateOrderReplacementMutation,
  useGetDraftReplacementQuery,
  useGetOrdersByPatientQuery,
} = ordersApi;
