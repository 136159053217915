import { Button } from 'antd';
import { FC, ReactNode } from 'react';
import Tooltip from 'antd/es/tooltip';
import { DownloadOutlined } from '@ant-design/icons';
import { getReadableFileSizeString } from 'utils/files';

type TProps = {
  url: string;
  name: string;
  size?: number | undefined;
  isIcon?: boolean;
  isLarge?: boolean;
  className?: string;
  customTooltip?: ReactNode;
};

export const LinkDownload: FC<TProps> = ({ url, name, size, isIcon, isLarge, className = '', customTooltip }) => {
  const sizeText = size !== undefined ? getReadableFileSizeString(size || 0) : '';

  return (
    <Tooltip
      title={
        customTooltip || (
          <span>
            Download <b>{name}</b>
          </span>
        )
      }
    >
      {isIcon ? (
        <Button
          className={className}
          href={url}
          download={name}
          type="text"
          size={isLarge ? 'large' : 'small'}
          icon={<DownloadOutlined className={`${isLarge ? '!text-2xl' : 'text-base'}`} />}
        />
      ) : (
        <Button
          href={url}
          download={name}
          size={isLarge ? 'large' : 'small'}
          type="link"
          className={`flex max-w-full justify-start gap-2 p-0 ${className}`}
        >
          <span className="truncate">{name}</span>
          {sizeText && <span>({sizeText})</span>}
        </Button>
      )}
    </Tooltip>
  );
};
