import { FC, useState } from 'react';
import { Input, Button, Tooltip, FormInstance, Popover, Typography } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ScrollToView } from 'components/ScrollToView';
import { PopoverDelete } from 'components/popovers/popover-delete';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { AddressField, IAddress } from 'components/address-field/address-field';
import { ScannerSelect } from 'components/scanner-select/scanner-select';
import { NO_YES } from 'modules/gdp/case-submission/utils';
import { MetaOptionsInput } from 'components/meta-options-input/meta-options-input';
import { META_KEYS } from 'hooks/useMetaOptionsSubmission';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import classNames from 'classnames';
import { PracticeByPostCodeInput } from './practice-by-post-code-input';
import { IPractice } from 'services/practices-api/types';
import { NiceSelect } from 'components/commons/nice-select/nice-select';

interface IClinicItemProps {
  form: FormInstance<any>;
  formListName: string;
  index: number;
  field: FormListFieldData;
  onAdd?: () => void;
  onRemove: () => void;
}

export const PracticeInput: FC<IClinicItemProps> = ({ form, formListName, index, field, onAdd, onRemove }) => {
  const { key, name, ...restField } = field;
  const [isManualMode, setIsManualMode] = useState(false);

  const onPracticeByPostCodeChange = (practice: IPractice) => {
    setIsManualMode(true);
    const practices = form.getFieldValue(formListName);
    const newPractices = [...practices];
    newPractices[field.name] = {
      name: practice.name,
      address: practice.address,
      zipCode: practice.address?.zipCode || practice.address?.postCode,
      // email: practice.email,
      isScannerAvailable: practice.isScannerAvailable,
      scanners: practice.scanners,
      practiceFocused: practice.practiceFocused,
    };
    form.setFieldsValue({ [formListName]: newPractices });
  };

  const onAddressChange = (address: IAddress) => {
    const practices = form.getFieldValue(formListName);
    const newPractices = [...practices];
    newPractices[field.name] = { ...newPractices[field.name], address, zipCode: address.zipCode || address.postCode };
    form.setFieldsValue({ [formListName]: newPractices });
  };

  return (
    <>
      <ScrollToView smooth block={index === 0 ? 'center' : 'start'} />
      <div className={`grid grid-cols-2 gap-4 ${index > 0 ? 'border-top mb-2 mt-4' : ''}`}>
        {index > 0 && (
          <div className="col-span-2 mt-4">
            <div className="item-remove-container">
              <div className="practises-text-container">
                <span>Practice {index + 1}</span>
              </div>
              <PopoverDelete onDelete={onRemove} placement="leftTop">
                <Tooltip title="Delete">
                  <Button shape="circle" icon={<CloseOutlined />} />
                </Tooltip>
              </PopoverDelete>
            </div>
          </div>
        )}
        {!isManualMode ? (
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              label="Practice Postcode or Zip Code"
              name={[name, 'zipCode']}
              isRequired
              rules={[{ required: true, message: 'Please enter your practice Postcode or Zip Code' }]}
            >
              <PracticeByPostCodeInput onSelect={onPracticeByPostCodeChange} />
            </FormItemControl>
            {!isManualMode && (
              <Typography.Text className="cursor-pointer font-medium underline" onClick={() => setIsManualMode(true)}>
                Enter details manually
              </Typography.Text>
            )}
          </div>
        ) : (
          <>
            <div className="col-span-2 lg:col-span-1">
              <FormItemControl
                form={form}
                formListName={formListName}
                {...restField}
                label="Practice Name"
                name={[name, 'name']}
                isRequired
                rules={[{ required: true, message: 'Please tell us where you work' }]}
              >
                <Input className="custom-input" placeholder="Enter practice name" />
              </FormItemControl>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <AddressField
                form={form}
                formListName={formListName}
                {...restField}
                label="Practice Address"
                name={[name, 'address', 'address1']}
                isRequired
                rules={[{ required: true, message: 'Please add the practice address so we know where to send things' }]}
                onFormSetFieldsValueAddress={onAddressChange}
                placeholder="Start typing the address"
              />
              <FormItemControl name={[name, 'address', 'country']} hidden />
              <FormItemControl name={[name, 'address', 'coordinates']} hidden />
              <FormItemControl name={[name, 'address', 'zipCode']} hidden />
              <FormItemControl name={[name, 'address', 'postCode']} hidden />
              <FormItemControl name={[name, 'address', 'city']} hidden />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <FormItemControl
                form={form}
                formListName={formListName}
                {...restField}
                label="Practice Postcode or Zip Code"
                name={[name, 'zipCode']}
                isRequired
                rules={[{ required: true, message: 'Please enter your practice Postcode or Zip Code' }]}
              >
                <Input className="custom-input" placeholder="Enter practice Postcode or Zip Code" />
              </FormItemControl>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <FormItemControl
                form={form}
                formListName={formListName}
                {...restField}
                label={
                  <div>
                    Practice email
                    <Popover
                      placement="top"
                      content="We’ll need this for invoicing later. Don’t worry if you don’t have it right now"
                      overlayClassName="max-w-[300px]"
                    >
                      <ExclamationCircleOutlined className="ml-2" />
                    </Popover>
                  </div>
                }
                name={[name, 'email']}
                rules={[{ type: 'email', message: 'Please enter practice email' }]}
                normalize={(fieldValue) => fieldValue?.toLowerCase()}
              >
                <Input className="custom-input" placeholder="Enter practice email" />
              </FormItemControl>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <FormItemControl
                form={form}
                formListName={formListName}
                {...restField}
                name={[name, 'practiceFocused']}
                label="Is the practice general or cosmetic focused?"
                isRequired
                rules={[
                  {
                    required: true,
                    message: 'Please select an option',
                  },
                ]}
              >
                <MetaOptionsInput
                  metaKey={META_KEYS.dentistRegistration}
                  optionKey="practiceFocused"
                  placeholder="Select an option"
                  className="custom-input"
                />
              </FormItemControl>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <FormItemControl
                form={form}
                formListName={formListName}
                {...restField}
                label="Does the practice have a scanner?"
                isRequired
                name={[name, 'isScannerAvailable']}
                rules={[{ required: true, message: 'Please select option' }]}
              >
                <NiceSelect
                  className="custom-input"
                  options={NO_YES}
                  placeholder="Select Option"
                  onChange={(value) => !value && form.setFieldValue([formListName, name, 'scanners'], undefined)}
                />
              </FormItemControl>
            </div>
          </>
        )}

        <div className={classNames('col-span-2', isManualMode ? 'py-2 lg:col-span-1' : 'lg:col-span-2')}>
          {onAdd && (
            <NiceButton size="small" onClick={onAdd} id="add-another-clinic" niceType="default">
              Add another practice
            </NiceButton>
          )}
        </div>

        {isManualMode && (
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.clinics?.[index]?.isScannerAvailable !== currentValues.clinics?.[index]?.isScannerAvailable
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('clinics')?.[index]?.isScannerAvailable ? (
                  <FormItemControl
                    form={form}
                    formListName={formListName}
                    {...restField}
                    label="Select Scanner type"
                    name={[name, 'scanners']}
                    isRequired
                    rules={[{ required: true, message: 'Please select scanner type' }]}
                  >
                    <ScannerSelect className="custom-input" mode="multiple" />
                  </FormItemControl>
                ) : null
              }
            </FormItemControl>
          </div>
        )}
      </div>
    </>
  );
};
