import { Typography } from 'antd';
import { CaseStudyCard } from 'modules/gdp/case-studies/components/case-study-card/case-study-card';
import { FC } from 'react';
import { TListCaseStudyResponse } from 'services/case-studies-api/types';

interface IProps {
  caseStudies?: TListCaseStudyResponse[];
}

export const SimilarCase: FC<IProps> = ({ caseStudies }) => (
  <div>
    <div className="mt-4 flex w-full items-center gap-2 md:mt-8">
      <Typography.Title level={4} className="whitespace-nowrap">
        Similar Cases
      </Typography.Title>
      <div className="mb-1 flex-grow border-0 border-t border-solid border-t-gray-200" />
    </div>
    <Typography.Text>Other people with a similar case to you and have had successful treatment. </Typography.Text>
    <div className="mt-4 grid grid-cols-12 gap-2">
      {caseStudies?.map((caseStudy) => (
        <CaseStudyCard
          key={caseStudy.id}
          caseStudy={caseStudy}
          className="col-span-12 md:col-span-6 lg:col-span-4"
          preventGotoDetails
          hideTags
        />
      ))}
    </div>
  </div>
);
