import { FC, useEffect } from 'react';
import NiceAntModal from '../commons/nice-ant-modal/nice-ant-modal.component';
import { useGetLatestReleaseNotesQuery } from 'services/release-notes-api/endpoints';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useAuth } from 'hooks/useAuth';
import { NotionRenderer } from 'react-notion-x';
import { useGetNotionDataQuery, useUpdateUserProfileMutation } from 'services/api';
import 'react-notion-x/src/styles.css';
import './what-is-new-modal.less';
import { notification, Spin } from 'antd';
import { useAppDispatch } from 'hooks/store';
import { updateUserData } from 'modules/auth/authSlice';

const WhatIsNewNotionReader: FC<{ contentUrl: string }> = ({ contentUrl }) => {
  const { data: recordMap, error, isLoading } = useGetNotionDataQuery(contentUrl);

  return (
    <div>
      {error && <div className="flex items-center justify-center">Content URL is invalid!</div>}
      {isLoading && (
        <div className="flex items-center justify-center" style={{ minHeight: '500px' }}>
          <Spin tip="Loading content..." />
        </div>
      )}
      {!isLoading && recordMap && <NotionRenderer recordMap={recordMap.data} fullPage darkMode={false} />}
    </div>
  );
};

export const WhatIsNewModal = NiceModal.create(() => {
  const { user } = useAuth();
  const modal = useModal();
  const dispatch = useAppDispatch();

  const { data, isLoading, isError, error } = useGetLatestReleaseNotesQuery();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const onClose = async () => {
    modal.resolve();
    modal.hide();
    modal.remove();
    await updateUserProfile({
      newRelease: false,
    }).unwrap();
    dispatch(
      updateUserData({
        ...user!,
        newRelease: false,
      }),
    );
  };

  useEffect(() => {
    if (isError) {
      modal.hide();
      modal.remove();

      notification.error({
        message: 'Release notes loading error',
      });
    }
  }, [isError, error, modal]);

  if (isLoading || isError || !data) {
    return null;
  }

  return (
    <NiceAntModal
      width={700}
      modal={modal}
      onCancel={onClose}
      bodyStyle={{
        maxHeight: '95vh',
        overflow: 'auto',
      }}
    >
      <WhatIsNewNotionReader contentUrl={data.notionUrl} />
    </NiceAntModal>
  );
});
