import { Avatar, Image } from 'antd';
import classNames from 'classnames';
import { FC, useState } from 'react';

interface IProps {
  url?: string;
  name?: string;
  size?: number;
}

export const NiceAvatar: FC<IProps> = ({ url, name, size = 50 }) => {
  const [isImageFetched, setIsImageFetched] = useState(false);

  return url ? (
    <div className="relative contents">
      <Image
        src={url}
        width={size}
        height={size}
        className={classNames('rounded-full object-cover', isImageFetched ? 'block' : 'hidden')}
        onLoad={() => setIsImageFetched(true)}
      />
    </div>
  ) : (
    <Avatar size={size} className="bg-[#87d068] uppercase">
      {name?.charAt(0)}
    </Avatar>
  );
};
