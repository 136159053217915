import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { cancelOnboardingMeeting, loadOnboardingMeetingData } from 'services/calendly-api/endpoints';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { notification } from 'antd';
import moment from 'moment';
import { IMeetingData } from 'services/calendly-api/types';
import { showModal } from 'utils';

export interface ICalendlyScheduleDataType {
  eventName: string;
  url: string;
  reScheduleUrl: string;
  cancelUrl: string;
  startAt: string;
  status: string;
}

interface IProps {
  createUrl: string;
  meetingUrl: string | undefined; // require for rescheduled
  prefill?: Record<string, unknown>;
  onScheduled?: (params: ICalendlyScheduleDataType, meetingData: IMeetingData) => void;
  onClose?: () => void;
}

const CalendlyBookingModal = NiceModal.create<TModalProps<IProps>>(
  ({ createUrl, onScheduled, prefill, onClose, meetingUrl }) => {
    const modal = useModal();

    useCalendlyEventListener({
      onEventScheduled: async (event) => {
        modal.resolve();
        modal.hide();
        modal.remove();
        // `meetingUrl` exists mean reschedule, so need to cancel the previous booking
        const [_googeMeetString, uuid] = meetingUrl?.split('/').reverse() || [];
        if (uuid) {
          cancelOnboardingMeeting(uuid);
        }
        // if fetch api throw error is No permission. please update the new Calendly api KEY to process.env.REACT_APP_CALENDLY_API_TOKEN
        const [inviteeData, eventData] = await Promise.all([
          loadOnboardingMeetingData(event.data.payload.invitee.uri),
          loadOnboardingMeetingData(event.data.payload.event.uri),
        ]);
        onScheduled?.(
          {
            eventName: inviteeData.resource.name,
            reScheduleUrl: inviteeData.resource.reschedule_url,
            cancelUrl: inviteeData.resource.cancel_url,
            url: eventData.resource.location?.join_url,
            startAt: eventData.resource.start_time,
            status: eventData.resource.status,
          },
          eventData,
        );
        notification.success({
          message: `Your call is ${meetingUrl ? 'rescheduled' : 'booked'} for ${moment(
            eventData.resource.start_time,
          ).format('DD/MM/YYYY')} at ${moment(eventData.resource.start_time).format('HH:mm')} successfully.`,
        });
      },
    });

    return (
      <PopupModal
        prefill={prefill}
        onModalClose={() => {
          modal.resolve();
          modal.hide();
          modal.remove();
          onClose?.();
        }}
        open={modal.visible}
        url={createUrl}
        rootElement={document.body}
      />
    );
  },
);

export const showCalendlyBookingModal = showModal(CalendlyBookingModal);
