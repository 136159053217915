import { Select, SelectProps } from 'antd';
import { FC, useEffect } from 'react';
import { useGeolocationIp } from 'hooks/useGeolocationIp';
import { useCountryOptions } from 'hooks/useCountryOptions';

interface IProps extends SelectProps {
  onlyCountries?: string[];
}

export const CountryInput: FC<IProps> = (props) => {
  const { value, onChange, onlyCountries } = props;
  const geo = useGeolocationIp();
  const { countries, isLoading, getCountryInfoFromCode } = useCountryOptions();

  useEffect(() => {
    if (geo?.country && !value && getCountryInfoFromCode) {
      const country = getCountryInfoFromCode(geo.country);
      onChange?.(geo.country, {
        label: country?.name,
        value: country?.code,
      });
    }
  }, [geo, getCountryInfoFromCode]);

  return (
    <Select {...props} showSearch optionFilterProp="label" loading={isLoading}>
      {countries
        ?.filter((country) => (onlyCountries ? onlyCountries.includes(country.code) : true))
        ?.map((option) => (
          <Select.Option key={option.code} value={option.code} label={option.name}>
            <div className="flex items-center">
              <div
                style={{ backgroundImage: `url(${option.flag})`, width: 32, height: 20 }}
                className="bg-cover bg-center"
              />
              <div className="ml-2">{option.name}</div>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
};
