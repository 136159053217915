/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
import { Cascader, Select, SelectProps, Tooltip } from 'antd';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { DisplayValueType, BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { CheckOutlined } from '@ant-design/icons';

// const { Option } = Select;
const { SHOW_CHILD } = Cascader;

export interface INiceSelectProps<T = any, O = any> extends Omit<SelectProps<T, O>, 'onBlur' | 'onChange' | 'onFocus'> {
  onChange?: (e: any, option: any) => void;
  hasNAOption?: boolean;
  NAOptionLabel?: string;
  hasSelectAll?: boolean;
  matchInputWidth?: boolean;
}

const NA_DEFAULT_VALUE: any = 'NA_DEFAULT_VALUE';
const SELECT_ALL_DEFAULT_VALUE = 'SELECT_ALL_DEFAULT_VALUE';
const DEFAULT_NA_OPTION_LABEL = 'N/A';

export const NiceSelect = forwardRef<BaseSelectRef, INiceSelectProps>(
  (
    {
      options,
      onChange,
      hasNAOption = false,
      hasSelectAll = false,
      NAOptionLabel = DEFAULT_NA_OPTION_LABEL,
      children,
      matchInputWidth,
      ...rest
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const presentOptions = useMemo(
      () => [
        ...(hasNAOption ? [{ label: NAOptionLabel, value: NA_DEFAULT_VALUE }] : []),
        ...(hasSelectAll ? [{ label: 'Select All', value: SELECT_ALL_DEFAULT_VALUE }] : []),
        ...(options || []),
      ],
      [options, hasNAOption, hasSelectAll],
    );

    const presentValue = useMemo(
      () => (hasNAOption && rest.value?.length === 0 ? [NA_DEFAULT_VALUE] : rest.value ?? undefined),
      [rest.value, hasNAOption],
    );

    const handleChange = useCallback(
      (newValue, option) => {
        if (rest.mode === 'multiple') {
          const lastSelectValue = (newValue || []).at(-1);
          switch (lastSelectValue) {
            case SELECT_ALL_DEFAULT_VALUE:
              onChange?.(
                options?.map((item) => item.value),
                option,
              );
              break;
            case NA_DEFAULT_VALUE:
              onChange?.([], option);
              break;
            default:
              onChange?.(
                newValue?.filter((item: string) => ![SELECT_ALL_DEFAULT_VALUE, NA_DEFAULT_VALUE].includes(item)) ??
                  null,
                option,
              );
              break;
          }
        } else {
          onChange?.(newValue, option);
        }
      },
      [hasNAOption, hasSelectAll, options],
    );

    const commonProps = {
      ref,
      placeholder: 'Please Select',
      allowClear: true,
      showArrow: true,
      loading: !options?.length,
      maxTagPlaceholder: (omittedValues: any) => (
        <Tooltip
          title={omittedValues?.map((v: DisplayValueType) => (
            <div>{v.label}</div>
          ))}
        >
          +{omittedValues?.length}...
        </Tooltip>
      ),
      onChange: handleChange,
      ...rest,
      value: presentValue,
      options: children ? undefined : presentOptions,
    };

    return rest.mode === 'multiple' ? (
      <div ref={containerRef}>
        <Cascader
          {...commonProps}
          showCheckedStrategy={SHOW_CHILD}
          maxTagCount="responsive"
          multiple
          onChange={(newValue, selectedOptions) => {
            handleChange(
              newValue.flat(),
              selectedOptions.flat().map((item) => item.value),
            );
          }}
          value={presentValue?.map((item: any) => [item])}
          dropdownMenuColumnStyle={{
            width: matchInputWidth ? `${containerRef.current?.clientWidth}px` : 'auto',
          }}
        />
      </div>
    ) : (
      <Select
        menuItemSelectedIcon={({ isSelected }) => (isSelected ? <CheckOutlined /> : null)}
        maxTagCount="responsive"
        {...commonProps}
      >
        {children}
      </Select>
    );
  },
);
