import { Popover, Button, Typography } from 'antd';
import { CSSProperties, FC, useState } from 'react';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';

interface IProps {
  handleDelete: Function;
  item: any;
  name?: string;
  closeIcon?: boolean;
  iconStyle?: CSSProperties;
}

// TODO: remove/refactor DeleteIcon component
export const DeleteIcon: FC<IProps> = ({ handleDelete, item, name, closeIcon, iconStyle }) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleHoverChange = (visible: boolean) => {
    setHovered(visible);
    setClicked(false);
  };

  const handleClickChange = (visible: boolean) => {
    setHovered(false);
    setClicked(visible);
  };

  const hide = () => {
    setHovered(false);
    setClicked(false);
  };

  const confirmDelete = () => {
    hide();
    handleDelete(item, name);
  };

  return (
    <Popover
      content={<div className="text-center">Delete</div>}
      trigger="hover"
      open={hovered}
      onOpenChange={handleHoverChange}
    >
      <Popover
        content={
          <div>
            <Typography.Text className="text-sm"> Please confirm you want to delete?</Typography.Text>
            <div className="mt-2  flex">
              <Button onClick={hide}>Cancel</Button>
              <Button className="ml-2 rounded-standard" type="primary" danger onClick={confirmDelete}>
                Delete
              </Button>
            </div>
          </div>
        }
        title="Delete"
        trigger="click"
        open={clicked}
        onOpenChange={handleClickChange}
      >
        {closeIcon && <CloseCircleOutlined style={{ fontSize: 24 }} />}
        {!closeIcon && (
          <Button type="text" className="!p-0 disabled:hidden">
            <DeleteOutlined className="ml-3 cursor-pointer text-[lightgrey]" style={iconStyle} />
          </Button>
        )}
      </Popover>
    </Popover>
  );
};
