import { FC, ReactNode, useState } from 'react';
import { Alert } from 'antd';

interface IProps {
  className: string;
  children: ReactNode;
}
export const InfoTag: FC<IProps> = ({ className, children }) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  return visible ? <Alert className={className} message={children} closable onClose={handleClose} /> : null;
};
