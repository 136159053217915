import './preview-sent-sq.styles.less';
import { useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { Spin, Typography, Avatar } from 'antd';
import { useGetInterestedPatientSQQuery } from 'services/patients-api/endpoints';
import { SMILE_QUESTIONNAIRE_NEW_PATIENT_LABELING } from 'modules/gdp/interested-patients/utils';
import { SQFormPreviewNew } from 'modules/gdp/interested-patients/smile-questionnaire/sq-form/sq-form-preview/sq-form-preview-new';
import { NiceCollapse } from 'components/commons/nice-collapse/nice-collapse';
import sqSent1Jpg from 'assets/images/interested-patients/sq-sent-1.jpg';
import sqSent2Png from 'assets/images/interested-patients/sq-sent-2.png';
import sqSent3Png from 'assets/images/interested-patients/sq-sent-3.png';
import { getClickableLink } from 'helpers';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import { useEffect } from 'react';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';

const { Text } = Typography;

export const PreviewSentSQ = () => {
  const password = useQuery().get('token')!;
  const { patientId } = useParams<{ patientId: string }>();

  const { data, isLoading } = useGetInterestedPatientSQQuery(
    { password: password!, id: patientId },
    {
      skip: !password,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center">
        <div>
          <Spin />
        </div>
        <div>
          <Text>Loading...</Text>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center gap-4 px-6 pt-6">
        <div className="relative">
          {data?.createdBy?.profilePic && (
            <Avatar size={120} className="bg-gray-500" src={data?.createdBy?.profilePic} />
          )}
        </div>
        {data?.createdBy?.fullName && <div className="text-base font-semibold">Dr. {data?.createdBy?.fullName}</div>}
        <div className="text-center text-2xl font-bold text-[#050D43]">Thank you</div>
        <p className="text-base font-semibold text-[#039F61]">Your Smile Questionnaire was successfully sent to me.</p>
        <div className="flex flex-col items-center">
          <p className="mb-2 text-7xl">🚀</p>
          <p className="mb-2 text-lg font-semibold">🤩 You’ve taken the first step! 🤩</p>
          <p className="text-center text-sm font-medium md:text-left">
            My team and I will be in touch soon to discuss how we can transform your Smile
          </p>
        </div>
      </div>
      <NiceCollapse
        title="Your answers"
        onChange={() => trackEvent(EEventNames.SQ_VIEW_YOUR_ANSWERS_COLLAPSE_CLICKED)}
        open
        className="preview-sq-collapse px-6"
        expandIconPosition="end"
      >
        <SQFormPreviewNew
          className="m-auto w-full rounded-lg p-4 md:w-11/12"
          data={data?.smileQuestionnaire!}
          labeling={SMILE_QUESTIONNAIRE_NEW_PATIENT_LABELING}
        />
      </NiceCollapse>
      <div className="mx-4 mt-6 text-center md:mx-0">
        <p className="text-base font-semibold">
          43% of people in the United Kingdom get treatment to Improve confidence and boost self-esteem
        </p>
        <p className="text-sm font-medium">
          32Co have helped dentists give patients of all ages better smiles and more confidence.
        </p>
      </div>
      <div className="mt-4 flex flex-col items-center justify-between gap-4 bg-[#F5FBFF] p-6 md:flex-row md:items-baseline">
        <img src={sqSent1Jpg} alt="" className="h-40 w-max md:h-60 md:w-full" />
        <img src={sqSent2Png} alt="" className="h-40 w-max md:h-60 md:w-full" />
        <img src={sqSent3Png} alt="" className="" />
      </div>
      <div className="mb-6 mt-14">
        <div className="flex items-center justify-center gap-4">
          <div className="text-base font-normal">Still curious about 32Co?</div>
          <div className="text-xs font-semibold">
            <a
              href={getClickableLink('https://www.32co.com/patient/home')}
              onClick={(e: any) => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
            >
              <NiceButton className="min-w-[180px]" loading={isLoading} niceType="orange">
                Learn more
              </NiceButton>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
