import { CALENDLY_API_TOKEN } from '../../config/constants';
import { IMeetingData } from './types';

export const loadOnboardingMeetingData = (url: string): Promise<IMeetingData> =>
  fetch(url, {
    headers: {
      Authorization: `Bearer ${CALENDLY_API_TOKEN}`,
    },
  }).then((data) => data.json());

export const cancelOnboardingMeeting = (calendlyUuid: string): Promise<IMeetingData> =>
  fetch(`https://api.calendly.com/scheduled_events/${calendlyUuid}/cancellation`, {
    headers: {
      Authorization: `Bearer ${CALENDLY_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ reason: 'Reschedule' }),
  }).then((data) => data.json());
