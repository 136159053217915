/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-promise-executor-return */
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import type { MouseEvent } from 'react';
import type CropperRef from 'react-easy-crop';
import type { ModalProps } from 'antd';
import AntModal from 'antd/es/modal';
import AntUpload from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { PREFIX, ROTATION_INITIAL } from './constants';
import type { TBeforeUpload, TBeforeUploadReturnType, TEasyCropRef, TImgCropProps } from './types';
import EasyCrop from './EasyCrop';

export type { TImgCropProps } from './types';

export const ImgCrop = forwardRef<CropperRef, TImgCropProps>((props, cropperRef) => {
  const {
    quality = 0.4,
    fillColor = 'white',

    // @ts-ignore
    zoomSlider = true,
    // @ts-ignore
    rotationSlider = false,
    aspectSlider = false,
    showReset = false,

    aspect = 1,
    minZoom = 1,
    maxZoom = 3,
    // @ts-ignore
    cropShape = 'rect',
    // @ts-ignore
    showGrid = false,
    cropperProps,

    modalClassName,
    modalWidth,
    modalOk,
    modalCancel,
    onModalOk,
    onModalCancel,
    modalProps,

    beforeCrop,
    children,
  } = props;

  if ('onUploadFail' in props) {
    console.error(
      `\`onUploadFail\` is removed, because the only way it is called, is when the file is rejected by beforeUpload`,
    );
  }

  const cb = useRef<Pick<TImgCropProps, 'onModalOk' | 'onModalCancel' | 'beforeCrop'>>({});
  cb.current.onModalOk = onModalOk;
  cb.current.onModalCancel = onModalCancel;
  cb.current.beforeCrop = beforeCrop;

  /**
   * crop
   */
  const easyCropRef = useRef<TEasyCropRef>(null);
  const getCropCanvas = useCallback(
    (target: ShadowRoot) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      const context = (target?.getRootNode?.() as ShadowRoot) || document;

      type TImgSource = CanvasImageSource & {
        naturalWidth: number;
        naturalHeight: number;
      };
      const imgSource = context.querySelector(`.${PREFIX}-media`) as TImgSource;

      const { width: cropWidth, height: cropHeight, x: cropX, y: cropY } = easyCropRef.current!.cropPixelsRef.current;

      if (rotationSlider && easyCropRef.current!.rotation !== ROTATION_INITIAL) {
        const { naturalWidth: imgWidth, naturalHeight: imgHeight } = imgSource;
        const angle = easyCropRef.current!.rotation * (Math.PI / 180);

        // get container for rotated image
        const sine = Math.abs(Math.sin(angle));
        const cosine = Math.abs(Math.cos(angle));
        const squareWidth = imgWidth * cosine + imgHeight * sine;
        const squareHeight = imgHeight * cosine + imgWidth * sine;

        canvas.width = squareWidth;
        canvas.height = squareHeight;
        ctx.fillStyle = fillColor;
        ctx.fillRect(0, 0, squareWidth, squareHeight);

        // rotate container
        const squareHalfWidth = squareWidth / 2;
        const squareHalfHeight = squareHeight / 2;
        ctx.translate(squareHalfWidth, squareHalfHeight);
        ctx.rotate(angle);
        ctx.translate(-squareHalfWidth, -squareHalfHeight);

        // draw rotated image
        const imgX = (squareWidth - imgWidth) / 2;
        const imgY = (squareHeight - imgHeight) / 2;
        ctx.drawImage(imgSource, 0, 0, imgWidth, imgHeight, imgX, imgY, imgWidth, imgHeight);

        // crop rotated image
        const imgData = ctx.getImageData(0, 0, squareWidth, squareHeight);
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        ctx.putImageData(imgData, -cropX, -cropY);
      } else {
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        ctx.fillStyle = fillColor;
        ctx.fillRect(0, 0, cropWidth, cropHeight);

        ctx.drawImage(imgSource, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
      }

      return canvas;
    },
    [fillColor, rotationSlider],
  );

  /**
   * upload
   */
  const [modalImage, setModalImage] = useState('');

  const onCancel = useRef<ModalProps['onCancel']>();
  const onOk = useRef<ModalProps['onOk']>();

  const uploadComponent = useMemo(() => {
    const upload: any = Array.isArray(children) ? children[0] : children;
    const { beforeUpload, accept, customRequest, ...restUploadProps } = upload.props;

    const innerBeforeUpload: TBeforeUpload = (file, fileList) =>
      new Promise(async (resolve) => {
        if (typeof cb.current.beforeCrop === 'function') {
          try {
            const result = await cb.current.beforeCrop(file, fileList);
            if (result !== true) {
              return resolve(result);
            }
          } catch (err) {
            return resolve(err as TBeforeUploadReturnType);
          }
        }

        // get file result
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          if (typeof reader.result === 'string') {
            setModalImage(reader.result); // open modal
          }
        });
        reader.readAsDataURL(file as unknown as Blob);

        // on modal cancel
        onCancel.current = () => {
          setModalImage('');
          easyCropRef.current!.onReset();

          resolve(AntUpload.LIST_IGNORE);
          cb.current.onModalCancel?.();
        };

        // on modal confirm
        onOk.current = async (event: MouseEvent<HTMLElement>) => {
          setModalImage('');
          easyCropRef.current!.onReset();

          const canvas = getCropCanvas(event.target as ShadowRoot);

          const { type, name, uid } = file as UploadFile;
          canvas.toBlob(
            async (blob) => {
              const newFile = new File([blob as BlobPart], name, { type });
              Object.assign(newFile, { uid });

              if (typeof beforeUpload !== 'function') {
                resolve(newFile);
                cb.current.onModalOk?.(newFile);
                return;
              }

              try {
                // https://github.com/ant-design/ant-design/blob/master/components/upload/Upload.tsx#L128-L148
                // https://ant.design/components/upload-cn#api
                const result = await beforeUpload(newFile, [newFile]);
                const value = result === true ? newFile : result;
                resolve(value);
                cb.current.onModalOk?.(value);
              } catch (err) {
                resolve(err as TBeforeUploadReturnType);
                cb.current.onModalOk?.(err as TBeforeUploadReturnType);
              }
            },
            type,
            quality,
          );
        };
      });

    return {
      ...upload,
      props: {
        ...restUploadProps,
        accept: accept || 'image/*',
        customRequest,
        beforeUpload: innerBeforeUpload,
      },
    };
  }, [children, getCropCanvas, quality]);

  const webcamComponent = useMemo(() => {
    const webcam: any = Array.isArray(children) ? children[0] : children;
    const { customRequest, closeWebcamModal } = webcam.props;

    const innerBeforeUploadWebcam = () =>
      new Promise(async (resolve) => {
        // on modal cancel
        onCancel.current = () => {
          setModalImage('');
          easyCropRef.current!.onReset();
          cb.current.onModalCancel?.();
        };

        // on modal confirm
        onOk.current = async (event: MouseEvent<HTMLElement>) => {
          setModalImage('');
          easyCropRef.current!.onReset();
          const canvas = getCropCanvas(event.target as ShadowRoot);
          const type = 'image/jpeg';

          canvas.toBlob(
            async (blob) => {
              const newFile = new File([blob as BlobPart], 'photo.jpeg', { type });

              try {
                cb.current.onModalOk?.(newFile);
                customRequest(newFile);
                closeWebcamModal();
              } catch (err) {
                resolve(err as TBeforeUploadReturnType);
                cb.current.onModalOk?.(err as TBeforeUploadReturnType);
              }
            },
            type,
            quality,
          );
        };
      });

    innerBeforeUploadWebcam();

    return {
      ...webcam,
      props: {
        setModalImage,
      },
    };
  }, [children, getCropCanvas, quality]);

  /**
   * modal
   */
  const modalBaseProps = useMemo(() => {
    const obj: Pick<ModalProps, 'width' | 'okText' | 'cancelText'> = {};
    if (modalWidth !== undefined) obj.width = modalWidth;
    if (modalOk !== undefined) obj.okText = modalOk;
    if (modalCancel !== undefined) obj.cancelText = modalCancel;
    return obj;
  }, [modalCancel, modalOk, modalWidth]);

  const wrapClassName = `${PREFIX}-modal${modalClassName ? ` ${modalClassName}` : ''}`;

  const isWebcamMode = useMemo(() => {
    const upload: any = Array.isArray(children) ? children[0] : children;
    return !!upload.props.closeWebcamModal;
  }, [children]);

  return (
    <>
      {isWebcamMode ? webcamComponent : uploadComponent}
      {modalImage && (
        <AntModal
          {...modalProps}
          {...modalBaseProps}
          {...{ open: true }}
          title="Edit image"
          onCancel={onCancel.current}
          onOk={onOk.current}
          wrapClassName={wrapClassName}
          maskClosable={false}
          destroyOnClose
        >
          <EasyCrop
            ref={easyCropRef}
            cropperRef={cropperRef}
            zoomSlider={zoomSlider}
            rotationSlider={rotationSlider}
            aspectSlider={aspectSlider}
            showReset={showReset}
            resetBtnText="Reset"
            modalImage={modalImage}
            aspect={aspect}
            minZoom={minZoom}
            maxZoom={maxZoom}
            cropShape={cropShape}
            showGrid={showGrid}
            cropperProps={cropperProps}
          />
        </AntModal>
      )}
    </>
  );
});
