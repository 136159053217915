/* eslint-disable no-param-reassign */
import { USER_ROLES } from 'config/constants';
import type { TRootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TThemeState = {
  isChristmasTheme: boolean;
  christmasCrackerSeen: boolean;
};

const INITIAL_STATE: TThemeState = {
  isChristmasTheme: false,
  christmasCrackerSeen: false,
};

const slice = createSlice({
  name: 'theme',
  initialState: INITIAL_STATE,
  reducers: {
    toggleChristmasEnabled: (state, { payload: isChristmasTheme }: PayloadAction<boolean>) => {
      state.isChristmasTheme = isChristmasTheme;
    },
    setChristmasCrackerSeen: (state, { payload: { seen } }: PayloadAction<{ seen: boolean }>) => {
      state.christmasCrackerSeen = seen;
    },
  },
});

export const { toggleChristmasEnabled, setChristmasCrackerSeen } = slice.actions;

export default slice.reducer;

export const selectChristmasEnabled = (state: TRootState) =>
  state.theme.isChristmasTheme && state.auth.user?.type === USER_ROLES.DENTIST;
export const selectChristmasCrackerSeen = (state: TRootState) => state.theme.christmasCrackerSeen;
