import { Tag } from 'antd';
import { FC } from 'react';
import { CASE_STUDY_TAG_COLOR_MAPPING } from '../../utils';
import { twMerge } from 'tailwind-merge';

interface IProps {
  tagKey: keyof typeof CASE_STUDY_TAG_COLOR_MAPPING;
  className?: string;
}

export const CaseStudyTag: FC<IProps> = ({ children, tagKey, className }) => (
  <Tag
    style={{
      color: CASE_STUDY_TAG_COLOR_MAPPING[tagKey],
      borderColor: CASE_STUDY_TAG_COLOR_MAPPING[tagKey],
    }}
    className={twMerge('bg-white', className)}
  >
    {children}
  </Tag>
);
