import React, { FC } from 'react';
import { Button, Collapse, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './preview-proposal-more-info.styles.less';
import cn from 'classnames';
import mobileViewSvg from 'assets/images/patient-information/mobile_view.png';
import clearAlignerPng from 'assets/images/patient-information/clear_aligner.png';
import treatmentDurationPng from 'assets/images/patient-information/treatment_duration.png';
import interproximalReductionPng from 'assets/images/patient-information/interproximal_reduction.jpeg';
import attachmentsPng from 'assets/images/patient-information/attachments.png';
import retainerPng from 'assets/images/patient-information/retainer.png';
import externalSvg from 'assets/images/patient-information/external.svg';

interface IBigTypographyTitleProps {
  children: React.ReactNode;
  isFullSizePage: boolean;
}

export const BigTypographyTitle: FC<IBigTypographyTitleProps> = ({ children, isFullSizePage }) => (
  <Typography.Title
    level={3}
    className={cn('!mb-4 border-0 border-b border-solid border-gray-300 pb-2 text-sm font-semibold', {
      'md:text-lg': isFullSizePage,
    })}
  >
    {children}
  </Typography.Title>
);

interface IFurtherInfoComponentProps {
  title: string;
  paragraphs: string[];
  photo: any;
  photoAlt: string;
  isFullSizePage: boolean;
  revertUI?: boolean;
  moreContent?: React.ReactNode;
}

const FurtherInfoComponent: FC<IFurtherInfoComponentProps> = ({
  title,
  paragraphs,
  photo,
  photoAlt,
  revertUI,
  isFullSizePage,
  moreContent,
}) => (
  <div className={cn('custom-further-info py-3', { 'md:py-4': isFullSizePage })}>
    <Typography.Title
      level={4}
      className={`mb-3 pb-2 text-sm font-semibold ${isFullSizePage ? 'md:hidden md:text-base' : ''}`}
    >
      {title}
    </Typography.Title>
    <div
      className={cn('items-center justify-between', {
        'md:flex': isFullSizePage,
        'md:flex-row-reverse': revertUI && isFullSizePage,
      })}
    >
      <img
        className={`radius-10 flex w-full ${isFullSizePage ? (moreContent ? 'md:w-2/5' : 'md:w-1/3') : ''}`}
        src={photo}
        alt={photoAlt}
      />
      <div className={cn('w-full pt-3', { 'md:w-1/2 md:pt-0': isFullSizePage })}>
        <Typography.Title
          level={4}
          className={cn('mb-3 hidden pb-2 text-sm font-semibold', { 'md:block  md:text-base': isFullSizePage })}
        >
          {title}
        </Typography.Title>
        <ul>
          {paragraphs.map((text, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li className="py-1 text-sm font-normal" key={`${text}${index}`}>
              {text}
            </li>
          ))}
        </ul>
        {moreContent}
      </div>
    </div>
  </div>
);

interface IPreviewProposalMoreInfoComponentProps {
  isFullSizePage?: boolean;
}

export const PreviewProposalMoreInfoComponent: FC<IPreviewProposalMoreInfoComponentProps> = ({
  isFullSizePage = false,
}) => (
  <div className={cn('custom-faqs pt-4 pb-4', { 'md:pt-7 md:pb-7': isFullSizePage })}>
    <BigTypographyTitle isFullSizePage={isFullSizePage}>In Person and Virtual Check ins</BigTypographyTitle>

    <FurtherInfoComponent
      title=""
      photo={mobileViewSvg}
      photoAlt="mobile view image"
      paragraphs={[
        'I’ll be monitoring your treatment every step of the way to make sure your treatment stays on track',
        'We will be meeting in person to check on your progress every few weeks',
        'In-between appointments we’ll review your progress via remote check-ins',
        'You’ll receive a text message to submit selfie photographs with aligners both in & out',
        'You’ll be able to reach me easily and raise any concerns or questions',
      ]}
      isFullSizePage={isFullSizePage}
      moreContent={
        <div
          className={cn(
            'custom-external-link flex w-full justify-start border-0 border-t border-solid border-gray-300 pt-3 font-semibold',
            { 'md:mt-4': isFullSizePage },
          )}
        >
          <a
            className="flex"
            target="_blank"
            href={`${window.location.origin}/files/2022_general_patient_consent_form _for_clear_aligner_orthodontics.pdf`}
            rel="noreferrer"
          >
            Detailed consent <img src={externalSvg} alt="" />
          </a>
        </div>
      }
    />

    <div className="mt-6" />

    <BigTypographyTitle isFullSizePage={isFullSizePage}>Further information</BigTypographyTitle>

    <FurtherInfoComponent
      title="Clear Aligners"
      photo={clearAlignerPng}
      photoAlt="clear aligner image"
      paragraphs={[
        'Clear Aligners are the modern way to straighten teeth vs metal wires and brackets',
        'Much more subtle, almost invisible',
        'Much more flexible, can be removed',
        'Custom-made for each patient based on a 3D model of their teeth',
        'The design of the aligners is based on a Treatment Plan, which specifies how each tooth will be moved in fine detail',
        'High quality treatment plans are hard to achieve, need a great deal of experience',
      ]}
      isFullSizePage={isFullSizePage}
    />

    <FurtherInfoComponent
      title="Treatment duration"
      photo={treatmentDurationPng}
      photoAlt="treatment duration image"
      paragraphs={[
        'Even with good compliance, there is always a chance that biological factors can influence how predictably your teeth will move',
        'You may require additional aligners during or at the end of the first stage to achieve the desired result.',
        'This may increase the duration of treatment',
      ]}
      isFullSizePage={isFullSizePage}
      revertUI
    />

    <FurtherInfoComponent
      title="Interproximal reduction"
      photo={interproximalReductionPng}
      photoAlt="interproximal reduction image"
      paragraphs={[
        'Interproximal reduction (IPR) gently removes a small amount of tooth enamel between adjacent teeth to create space for tooth alignment.',
        "It's a quick, painless procedure that usually doesn't require anesthesia. You will only feel some vibrations and no pain.",
        "IPR involves minimal enamel removal, no more than a thickness of human hair (less than 0.25mm or <10% of enamel thickness). This means it won't increase sensitivity or the risk of cavities.",
        'Any spaces created will naturally close during your treatment.',
      ]}
      isFullSizePage={isFullSizePage}
    />

    <FurtherInfoComponent
      title="Attachments"
      photo={attachmentsPng}
      photoAlt="attachments image"
      paragraphs={[
        'Attachments are shapes added to the teeth to engage the aligner and assist with movements.',
        'They act like small handles for moving teeth',
        'Attachments are placed at the start of treatment and removed at the end of treatment',
      ]}
      isFullSizePage={isFullSizePage}
      revertUI
    />

    <FurtherInfoComponent
      title="Retainers"
      photo={retainerPng}
      photoAlt="retainers image"
      paragraphs={[
        'Removable retainers or fixed bonded retainers must be worn for as long as you want teeth to stay in their corrected position after treatment',
        'Fixed retainers are not suitable for every patient, for example, if there is insufficient space after treatment',
      ]}
      isFullSizePage={isFullSizePage}
    />

    <div className="mt-6" />

    <BigTypographyTitle isFullSizePage={isFullSizePage}>FAQ’s</BigTypographyTitle>

    <Collapse
      expandIconPosition="end"
      defaultActiveKey={[]}
      // eslint-disable-next-line react/no-unstable-nested-components
      expandIcon={({ isActive }) => (
        <Button className="collapse-btn flex items-center justify-center" shape="circle" size="small">
          <DownOutlined style={{ fontSize: 14 }} rotate={isActive ? 180 : 0} />
        </Button>
      )}
      ghost
    >
      {[
        {
          question: 'Can anyone tell that I’m wearing the aligners?',
          answer:
            'Clear aligners are virtually invisible. Some people may notice the small attachment ‘bumps’ on your teeth',
        },
        {
          question: 'Can I take my aligners off whenever I need to?',
          answer:
            'Yes, you can remove your aligners for eating, drinking, brushing, and flossing. You can take them off for special occasions, like a wedding or a job interview.. Just remember to wear them to 22 hours a day to get the best results.',
        },
        {
          question: 'Are there any restrictions on what I can eat?',
          answer: 'None! Clear aligners are removable, so there are no food restrictions at all.',
        },
        {
          question: 'Is clear aligner treatment painful?',
          answer:
            'Clear aligner treatment moves your teeth by applying gentle, constant force, but some people do feel temporary discomfort for the first few days of wearing a new set of aligners. Typically, people describe it as feeling “pressure” and it’s normal — it’s a sign your clear aligners are working and transforming your smile.',
        },
        {
          question: 'Will wearing clear aligners affect my speech?',
          answer:
            'It may take a couple of days for your tongue to get used to having your aligners in your mouth, and you may have a slight lisp. But this should disappear quickly.',
        },
        {
          question: 'What’s the best way to clean my aligners?',
          answer:
            'Clean your aligners at least twice daily with a soft brush and mild soap in lukewarm water. Do not use hot water, toothpaste or denture-cleaning agents to wash your aligners.',
        },
      ].map((faq) => (
        <Collapse.Panel key={faq.question} header={faq.question}>
          {faq.answer}
        </Collapse.Panel>
      ))}
    </Collapse>
  </div>
);
