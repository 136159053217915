import { buildQueryString } from 'services/utils';
import { api, formatUrl, VERSION } from '../api';
import {
  ICompetedTreatmentHowItWentRequest,
  ICompletedTreatmentHowItWent,
  ICompletedTreatmentRecap,
  ICompletedTreatmentRecapRequest,
  ICompletedTreatmentRequest,
  ICompletedTreatmentResponse,
  IPatientFeedbackCompletedTreatmentRecapRequest,
  IPatientCompletedTreatmentRecap,
  ICaseStudyRequest,
  IHowItWentReminderResponse,
  IRecapFeedback,
} from './types';
import { IPatient } from 'services/patients-api/types';

export const completedTreatmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompletedTreatments: build.query<ICompletedTreatmentResponse, ICompletedTreatmentRequest>({
      query: (params) =>
        formatUrl(`/patients?${new URLSearchParams(buildQueryString({ ...params, isCompletedTreatmentEnded: true }))}`),
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ id }) => ({ type: 'CompletedTreatment', id } as const)),
              { type: 'CompletedTreatment', id: 'LIST' },
            ]
          : [{ type: 'CompletedTreatment', id: 'LIST' }],
    }),
    getCompletedTreatmentById: build.query<IPatient, string>({
      query: (id) => formatUrl(`/patients/${id}`),
      providesTags: (result, error, id) => [{ type: 'CompletedTreatment', id }],
    }),
    startCompleteTreatment: build.mutation<void, string>({
      query(patientId) {
        return {
          url: formatUrl(`/patients/${patientId}/complete-treatment/start`),
          method: 'PUT',
        };
      },
      invalidatesTags: (_result, _error, params) => [
        { type: 'CompletedTreatment', id: 'LIST' },
        { type: 'CompletedTreatment', id: params },
      ],
    }),
    endCompleteTreatment: build.mutation<void, string>({
      query(patientId) {
        return {
          url: formatUrl(`/patients/${patientId}/complete-treatment/end`),
          method: 'PUT',
        };
      },
      invalidatesTags: (_result, _error, params) => [
        { type: 'CompletedTreatment', id: 'LIST' },
        { type: 'CompletedTreatment', id: params },
      ],
    }),
    getCompletedTreatmentHowItWent: build.query<ICompletedTreatmentHowItWent, string>({
      query: (patientId) => formatUrl(`/patients/${patientId}/complete-treatment/how-it-went`),
    }),
    upsertCompletedTreatmentHowItWent: build.mutation<
      ICompletedTreatmentHowItWent,
      { payload: ICompetedTreatmentHowItWentRequest; patientId: string }
    >({
      query({ payload, patientId }) {
        return {
          url: formatUrl(`/patients/${patientId}/complete-treatment/how-it-went`),
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error_, params) => [
        { type: 'CompletedTreatment', id: 'LIST' },
        { type: 'InterestedPatients', id: params.patientId },
        ...(params?.payload?.status === 'SUBMITTED'
          ? [{ type: 'CompletedTreatment', id: params.patientId } as const]
          : []),
      ],
    }),
    getCompletedTreatmentRecap: build.query<ICompletedTreatmentRecap, string>({
      query: (patientId) => formatUrl(`/patients/${patientId}/complete-treatment/recap`),
    }),
    upsertCompletedTreatmentRecap: build.mutation<
      { message: string },
      { patientId: string; payload: ICompletedTreatmentRecapRequest }
    >({
      query({ payload, patientId }) {
        return {
          url: formatUrl(`/patients/${patientId}/complete-treatment/recap`),
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error_, params) => [
        { type: 'CompletedTreatment', id: 'LIST' },
        ...(params.payload.status === 'SUBMITTED'
          ? [{ type: 'CompletedTreatment', id: params.patientId } as const]
          : []),
      ],
    }),
    upsertTreatmentRecapFeedbackViewed: build.mutation<
      { message: string },
      { patientId: string; payload: ICompletedTreatmentRecapRequest }
    >({
      query({ payload, patientId }) {
        return {
          url: formatUrl(`/patients/${patientId}/complete-treatment/recap/feedback-viewed`),
          method: 'PUT',
          body: payload,
        };
      },
    }),
    getPatientCompletedTreatmentRecapByPassword: build.query<
      IPatientCompletedTreatmentRecap,
      { patientId: string; password?: string; isPublic?: boolean }
    >({
      query: ({ patientId, ...params }) =>
        `/${VERSION}/user/patients/${patientId}/recap?${new URLSearchParams(buildQueryString(params))}`,
      providesTags: (result, error, { patientId }) => [{ type: 'PatientCompletedTreatment', id: patientId }],
    }),
    submitPatientFeedbackCompletedTreatmentRecap: build.mutation<
      ICompletedTreatmentRecap,
      { id: string; payload: IPatientFeedbackCompletedTreatmentRecapRequest }
    >({
      query: ({ id, payload }) => ({
        url: `/${VERSION}/user/patients/${id}/recap`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'PatientCompletedTreatment', id }],
    }),
    upsertCompletedTreatmentCaseStudy: build.mutation<
      void,
      { payload: ICaseStudyRequest; patientId: string; caseStudyId?: string }
    >({
      query({ payload, patientId }) {
        return {
          url: formatUrl(`/patients/${patientId}/complete-treatment/case-study`),
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error_, params) => [
        { type: 'CompletedTreatment', id: 'LIST' },
        ...(params?.payload?.status === 'SUBMITTED'
          ? [{ type: 'CompletedTreatment', id: params.patientId } as const]
          : []),
        { type: 'CaseStudies', id: params.caseStudyId },
      ],
    }),
    getCompletedTreatmentCaseStudy: build.query<ICaseStudyRequest, string>({
      query: (patientId) => formatUrl(`/patients/${patientId}/complete-treatment/case-study`),
    }),
    getCompletedTreatmentPatientReminder: build.query<IHowItWentReminderResponse, string>({
      query: (patientId) => formatUrl(`/patients/${patientId}/reminder`),
    }),
    getRecapFeedback: build.query<IRecapFeedback[], void>({
      query: () => formatUrl('/patients/feedbacks'),
    }),
  }),
});

export const {
  useGetCompletedTreatmentsQuery,
  useGetCompletedTreatmentByIdQuery,
  useStartCompleteTreatmentMutation,
  useEndCompleteTreatmentMutation,
  useGetCompletedTreatmentHowItWentQuery,
  useGetCompletedTreatmentRecapQuery,
  useUpsertCompletedTreatmentRecapMutation,
  useUpsertTreatmentRecapFeedbackViewedMutation,
  useGetPatientCompletedTreatmentRecapByPasswordQuery,
  useLazyGetPatientCompletedTreatmentRecapByPasswordQuery,
  useUpsertCompletedTreatmentHowItWentMutation,
  useSubmitPatientFeedbackCompletedTreatmentRecapMutation,
  useUpsertCompletedTreatmentCaseStudyMutation,
  useGetCompletedTreatmentCaseStudyQuery,
  useGetCompletedTreatmentPatientReminderQuery,
  useGetRecapFeedbackQuery,
  useLazyGetCompletedTreatmentHowItWentQuery,
} = completedTreatmentApi;
