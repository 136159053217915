import { Radio, RadioGroupProps } from 'antd';
import { FC, forwardRef } from 'react';
import './nice-radio-group.styles.less';

export interface INiceRadioGroupProps extends RadioGroupProps {
  options: Array<{ label: string; value: string | number | boolean }> | string[];
  itemClassName?: string;
  itemStyle?: React.CSSProperties;
}

export const NiceRadioGroup: FC<INiceRadioGroupProps> = forwardRef((props, ref: any) => {
  const { options, itemClassName, itemStyle, ...restProps } = props;

  return (
    <div className="nice-radio-group-wrapper overflow-hidden">
      <Radio.Group buttonStyle="solid" {...restProps} ref={ref}>
        {options?.map((item) => {
          const option = typeof item === 'string' ? { label: item, value: item } : item;
          return (
            <Radio.Button key={option.label} value={option.value} className={itemClassName} style={itemStyle}>
              {option.label}
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </div>
  );
});
