import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import notifySvg from 'assets/images/dashboard/notify.svg';
import { WhatIsNewAlert } from './what-is-new-alert/what-is-new-alert';
import { NotificationsContainer, UNREAD_NOTIS_CONTAINER_ID } from './notifications-container/notifications-container';
import { usePrimaryNotification } from './hooks/use-primary-notification';
import { showNotificationsPanel, TNotisType } from 'redux/notificationsSlice';
import { toggleBodyElmScroll } from 'utils/ui';
import { useAppDispatch } from 'hooks/store';
import { useAuth } from 'hooks/useAuth';
import { USER_ROLES } from 'config/constants';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';
import { CloseOutlined } from '@ant-design/icons';

const items: TabsProps['items'] = [
  {
    key: 'unread',
    label: `Unread`,
    children: <NotificationsContainer type="unread" />,
  },
  {
    key: 'all',
    label: `All`,
    children: <NotificationsContainer type="all" />,
  },
  {
    key: 'read',
    label: `Read`,
    children: <NotificationsContainer type="read" />,
  },
];

export const PrimaryNotification: FC = () => {
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState<TNotisType>('unread');
  const { unreadNotificationsCount, markAllNotificationsAsRead, showNotifications } = usePrimaryNotification();
  const dispatch = useAppDispatch();

  const closeNotifications = () => {
    dispatch(showNotificationsPanel(false));
  };

  const handleGotoUnread = () => {
    setSelectedTab('unread');
    setTimeout(() => document.getElementById(UNREAD_NOTIS_CONTAINER_ID)?.scrollTo(0, 0), 0);
  };

  useEffect(() => {
    toggleBodyElmScroll(!showNotifications);
  }, [showNotifications]);

  const isShowWhatIsNew = useMemo(
    () =>
      [USER_ROLES.DENTIST, USER_ROLES.DESIGNER, USER_ROLES.MANUFACTURER, USER_ROLES.SPECIALIST].includes(user?.type!),
    [user?.type],
  );

  const onNotificationClick = () => {
    dispatch(showNotificationsPanel(true));
    if (!showNotifications) {
      trackEvent(EEventNames.NOTIFICATION_VIEWED);
    }
  };

  return (
    <div>
      <div onClick={onNotificationClick}>
        <div className="flex h-6 w-6">
          <img src={notifySvg} alt="notifySvg" height={24} />
        </div>
        {unreadNotificationsCount > 0 && (
          <span
            className="absolute right-0 top-0 flex h-2 w-2"
            style={{
              marginRight: 3,
              marginTop: -1,
            }}
          >
            <span className="absolute inline-flex h-full w-full animate-pingSlow rounded-full bg-rose-400 opacity-75" />
            <span className="relative inline-flex h-2 w-2 rounded-full bg-rose-500 " />
          </span>
        )}
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={closeNotifications}
        visible={showNotifications}
        contentWrapperStyle={{
          width: '380px',
          maxWidth: '90vw',
        }}
        footer={isShowWhatIsNew && <WhatIsNewAlert toggleNotifications={closeNotifications} />}
        bodyStyle={{ padding: 0, overflow: 'hidden' }}
      >
        <div className="absolute z-10 flex w-full justify-between bg-white px-4 py-6">
          <div className="flex items-center">
            <span className="text-head">Notifications</span>
            <span className="relative ml-2 inline-flex cursor-pointer" onClick={handleGotoUnread}>
              <div className="flex items-center justify-center rounded-md bg-[#09363D] px-4 py-1">
                <span className="text-sm font-semibold text-white">{unreadNotificationsCount}</span>
              </div>
              {unreadNotificationsCount > 0 && (
                <span className="absolute right-0 top-0 -mr-1 -mt-1 flex h-3 w-3">
                  <span className="absolute inline-flex h-full w-full animate-pingSlow rounded-full bg-rose-400 opacity-75" />
                  <span className="relative inline-flex h-3 w-3 rounded-full bg-rose-500 " />
                </span>
              )}
            </span>
          </div>
          <Button shape="circle" icon={<CloseOutlined />} onClick={closeNotifications} />
        </div>

        <Tabs
          size="large"
          defaultActiveKey={selectedTab}
          activeKey={selectedTab}
          onChange={setSelectedTab as any}
          tabBarStyle={{
            padding: '0 16px',
            marginBottom: '0px',
            position: 'absolute',
            top: '58px',
            width: '100%',
            zIndex: 4,
            backgroundColor: 'white',
          }}
          tabBarExtraContent={
            unreadNotificationsCount > 0 && selectedTab !== 'read' ? (
              <Button type="link" id="mark-all-as-read" size="small" onClick={markAllNotificationsAsRead}>
                Mark all as read
              </Button>
            ) : null
          }
          items={items}
        />
      </Drawer>
    </div>
  );
};
