import { Typography } from 'antd';
import { formatClickableContent } from 'helpers/index';
import { IImage } from 'services/patients-api/types';

export const TreatmentAndGoal = ({ note, attachments }: { note?: string; attachments?: IImage[] }) => (
  <div>
    <div className="relative mt-8 flex w-full flex-col items-center gap-2">
      <div className="flex w-full items-center gap-2">
        <Typography.Title level={4} className="whitespace-nowrap">
          Treatment Summary & Goals
        </Typography.Title>
        <div className="mb-1 flex-grow border-0 border-t border-solid border-t-gray-200" />
      </div>
      <div className="flex flex-col self-start">
        {note?.split('\n').map((item) => (
          <p key={item} dangerouslySetInnerHTML={{ __html: formatClickableContent(item) }} />
        ))}
      </div>
      {!!attachments?.length && (
        <div className="mt-4 flex w-full flex-wrap items-start gap-2">
          {attachments.map((attachment) => (
            <img
              key={`${attachment.url}-${attachment.name}`}
              src={attachment.url}
              alt="sq"
              className="h-48 w-40 object-contain"
            />
          ))}
        </div>
      )}
    </div>
  </div>
);
