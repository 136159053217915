import { useCallback } from 'react';
import { IMetaOption, useGetMetaOptionsQuery } from 'services/api';

const optionByValueCache: Record<string, IMetaOption> = {};

export const META_KEYS = {
  duo: 'duo',
  solo: 'solo',
  refinement: 'refinementDuo',
  instruction: 'instruction',
  advice: 'advice',
  treatmentDesign: 'treatmentDesign',
  submissionComplexityCalculator: 'submissionComplexityCalculator',
  stlIssuesReport: 'stlIssuesReport',
  dentistRegistration: 'dentistRegistration',
  replacement: 'replacement',
  caseStudy: 'caseStudy',
  retainer: 'retainer',
  smileQuestionnaire: 'smileQuestionnaire',
  completeTreatment: 'completeTreatment',
};

const alignerMaterialOptions = [
  { name: 'Cachet', optionLayer: 'Single-Layer' },
  { name: 'Erkodur AL', optionLayer: 'Single-Layer' },
  { name: 'Iconic', optionLayer: 'Single-Layer' },
  { name: 'Scheu Duran', optionLayer: 'Single-Layer' },
  { name: 'Biolon*', optionLayer: 'Single-Layer' },
  { name: 'Taglus', optionLayer: 'Single-Layer' },
  { name: 'CA PRO+', optionLayer: 'Multi-Layer' },
  { name: 'Essix ACE', optionLayer: 'Multi-Layer' },
  { name: 'Zendura FLX', optionLayer: 'Multi-Layer' },
];

export const useMetaOptionsSubmission = (metaKey: string) => {
  const { data, refetch } = useGetMetaOptionsQuery(metaKey);

  const getOptions = useCallback(
    (optionKey: string) => data?.find((item) => item.fieldKey === optionKey)?.options,
    [data],
  );

  const getOptionByValue = useCallback(
    (optionKey: string, value: string) => {
      const cacheKey = `${metaKey}_${optionKey}_${value}`;
      if (!optionByValueCache[cacheKey])
        optionByValueCache[cacheKey] = getOptions(optionKey)?.find((item) => item.value === value)!;
      return optionByValueCache[cacheKey];
    },
    [data],
  );

  const getAlignerMaterialOptions = useCallback(
    (materialOptions) =>
      materialOptions.map((item: { label: string }) => {
        const matchingOption = alignerMaterialOptions.find((option) => option.name === item.label);
        if (matchingOption) {
          return {
            ...item,
            label: `${item.label} - ${matchingOption.optionLayer}`,
          };
        }

        return { ...item, optionLayer: null };
      }),
    [alignerMaterialOptions],
  );

  return {
    data,
    getOptions,
    getOptionByValue,
    getAlignerMaterialOptions,
    refetch,
  };
};
