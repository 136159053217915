export enum ECompletedTreatmentRecapStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
}

export enum EConsentToShareImages {
  YesFullFace = 'fullface',
  YesMyTeeth = 'onlyteeth',
  No = 'no',
  No32CoAsk = 'No, can 32Co ask for me',
}

export const EXTRACTIONS_TREATMENT_OPTIONS = { label: 'Extractions', value: 'Extractions' };
