import { Typography } from 'antd';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface IProps {
  className?: string;
  phoneNumber?: TPhone;
  email?: string;
  fullName?: string;
}
export const CallNow: FC<IProps> = ({ className, phoneNumber, email, fullName }) => (
  <div className="mx-0 md:mx-6">
    <div className={twMerge('flex flex-col rounded bg-branch-title px-8 py-4 text-center', className)}>
      <Typography.Title level={3} className="!text-xl !text-white md:!text-3xl">
        Take the next step on your smile journey
      </Typography.Title>
      {phoneNumber && (
        <a href={`tel:${phoneNumber.ext}${phoneNumber.number}`} className="mt-4">
          <Typography.Title level={4} className="!text-lg !text-white md:!text-2xl">
            {`Call: ${phoneNumber.ext}${phoneNumber.number}`}
          </Typography.Title>
        </a>
      )}
      {email && (
        <a
          href={`mailto:${email}?subject=${fullName} would like to book their next Clear Aligner Appointment&message=Hello, 

        I've had a chance to look at my Smile Review and would like to book in my next appointment to discuss my treatment options`}
        >
          <Typography.Title level={4} className="!mt-0 !text-lg !text-white md:!text-2xl">
            {`Email: ${email}`}
          </Typography.Title>
        </a>
      )}
    </div>
  </div>
);
