import StarIcon from 'assets/images/christmas/star_icon.png';
import CandyStickSvg from 'assets/images/christmas/candy_stick.svg';
import ChristmasHolidayMan from 'assets/images/christmas/christmas_holiday_man.svg';
import ChristmasHolidaysWreath from 'assets/images/christmas/christmas_holidays_wreath.svg';
import ChristmasSnowFlake from 'assets/images/christmas/christmas_snow_snowflake.svg';
import ChristmasTree from 'assets/images/christmas/christmas_tree.svg';
import { ISourceOptions } from 'tsparticles-engine';

export const confettiParticlesOptions: ISourceOptions = {
  name: 'ChristmasConfetti',
  fpsLimit: 70,
  preset: 'confetti',
  smooth: true,

  emitters: {
    life: {
      duration: 0.2,
      count: 1,
    },
  },
  particles: {
    number: {
      value: 120,
    },
    zIndex: {
      value: { min: 1, max: 10 },
    },
    shape: {
      type: 'image',
      options: {
        image: [
          {
            src: StarIcon,
            width: 20,
            height: 20,
            particles: {
              size: {
                value: 10,
              },
            },
          },
          {
            src: CandyStickSvg,
            width: 20,
            height: 20,
            particles: {
              size: {
                value: 10,
              },
            },
          },
          {
            src: ChristmasHolidayMan,
            width: 20,
            height: 20,
            particles: {
              size: {
                value: 5,
              },
            },
          },
          {
            src: ChristmasHolidaysWreath,
            width: 20,
            height: 20,
            particles: {
              size: {
                value: 15,
              },
            },
          },
          {
            src: ChristmasSnowFlake,
            width: 20,
            height: 20,
            particles: {
              size: {
                value: 15,
              },
            },
          },
          {
            src: ChristmasTree,
            width: 20,
            height: 20,
            particles: {
              size: {
                value: 20,
              },
            },
          },
        ],
      },
    },
  },
  fullScreen: {
    enable: false,
    zIndex: 99999,
  },
};

export const snowParticlesOption: ISourceOptions = {
  name: 'Snow',
  particles: {
    number: {
      value: 1500,
      density: {
        enable: true,
      },
    },
    color: {
      value: '#fff',
    },
    shape: {
      type: 'circle',
    },
    opacity: {
      value: 1,
    },
    size: {
      value: 10,
    },
    move: {
      enable: true,
      speed: 2,
      direction: 'bottom',
    },
    wobble: {
      enable: true,
      distance: 10,
      speed: 10,
    },
    zIndex: {
      value: {
        min: 0,
        max: 100,
      },
      opacityRate: 10,
      sizeRate: 10,
      velocityRate: 10,
    },
  },
  fullScreen: { enable: false },
};

export enum EParticlesType {
  christmasSnow,
  christmasConfetti,
}

export const particlesOptions = {
  [EParticlesType.christmasSnow]: snowParticlesOption,
  [EParticlesType.christmasConfetti]: confettiParticlesOptions,
};
