/* eslint-disable react/no-unstable-nested-components */
// import IRoute from "../interfaces/route"
import React, { Suspense } from 'react';
import { USER_ROLES } from '../../config/constants';
import { IRoute } from 'interface/IRoute';
import { PageLoader } from 'components/page-loader/page-loader.component';

const LazyDashboardComponent = React.lazy(() => import('./dashboard/dashboard.component'));
const LazyCaseDetailsComponent = React.lazy(() => import('./dashboard/case-details/case-details.component'));
const LazyProfileComponent = React.lazy(() => import('../../components/profile/profile'));

export const manufacturerRoutes: IRoute[] = [
  {
    path: '/manufacturer',
    exact: true,
    name: 'Dashboard',
    key: 'dashboard_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyDashboardComponent />
      </Suspense>
    ),
    role: USER_ROLES.MANUFACTURER,
  },
  {
    path: '/manufacturer/profile',
    exact: true,
    name: 'Profile',
    key: 'profile_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyProfileComponent userType={USER_ROLES.MANUFACTURER} />
      </Suspense>
    ),
    role: USER_ROLES.MANUFACTURER,
  },
  {
    path: '/manufacturer/orders/:submissionId',
    exact: true,
    name: 'Details',
    key: 'case_details_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyCaseDetailsComponent />
      </Suspense>
    ),
    role: USER_ROLES.MANUFACTURER,
  },
];

export const manufacturerRoutesToCheck = manufacturerRoutes.map((route) => route.path);
