import { Checkbox, Space } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { FC, forwardRef } from 'react';

export interface IAntdCheckboxGroupProps extends Omit<CheckboxGroupProps, 'options'> {
  options: Array<{ label: string; value: string | boolean }>;
  isHorizontal?: boolean;
}

export const AntdCheckboxGroup: FC<IAntdCheckboxGroupProps> = forwardRef(
  ({ options, isHorizontal, ...restProps }, ref: any) => (
    <Checkbox.Group {...restProps} ref={ref}>
      <Space direction={isHorizontal ? 'horizontal' : 'vertical'}>
        {options?.map((c) => (
          <Checkbox key={c.label} value={c.value}>
            {c.label}
          </Checkbox>
        ))}
      </Space>
    </Checkbox.Group>
  ),
);
