import { Button } from 'antd';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAuth } from 'hooks/useAuth';
import { useWindowSize } from 'hooks/useWindowSize';
import { selectChristmasEnabled, toggleChristmasEnabled } from 'redux/themeSlice';
import { EEventNames } from 'utils/mixpanel/events';
import { trackEvent } from 'utils/mixpanel/mixpanel';

export const ChristmasToggle = () => {
  const { isDentist } = useAuth();
  const isChristmasTheme = useAppSelector(selectChristmasEnabled);
  const dispatch = useAppDispatch();
  const { isMd } = useWindowSize();

  if (!isDentist) return null;

  // Get the current date
  const currentDate = new Date();
  const isDecember2023 = currentDate.getFullYear() === 2023 && currentDate.getMonth() === 11; // Note: Months are zero-based index

  if (!isDecember2023) {
    // If it's December 2023, set toggleChristmasEnabled to false
    if (isChristmasTheme) {
      dispatch(toggleChristmasEnabled(false));
    }
    return null; // Return null to prevent rendering the button
  }

  const handleToggle = (enabled: boolean) => {
    dispatch(toggleChristmasEnabled(enabled));
    trackEvent(enabled ? EEventNames.FELLING_CHRISTMASSY_CLICKED : EEventNames.LESS_CHRISTMASSY_CLICKED);
  };

  return (
    <Button
      onClick={() => handleToggle(!isChristmasTheme)}
      className={classNames(
        'h-auto py-2',
        isMd
          ? !isChristmasTheme &&
              'border-[#039F61] bg-gradient-to-b from-[#148718] to-[#153D2C]  font-medium text-white hover:border-[#039F61] hover:bg-gradient-to-b hover:from-[#148718] hover:to-[#153D2C] hover:text-white focus:border-[#039F61] focus:bg-gradient-to-b focus:from-[#148718] focus:to-[#153D2C] focus:text-white'
          : isChristmasTheme
          ? 'border-[#039F61] bg-gradient-to-b from-[#148718] to-[#153D2C] hover:focus:border-[#039F61] hover:focus:bg-gradient-to-b hover:focus:from-[#148718] hover:focus:to-[#153D2C]'
          : 'border-[#039F61] hover:focus:border-[#039F61]',
      )}
    >
      {isMd ? (
        <span className="">{isChristmasTheme ? 'Less Christmassy' : 'Feeling Christmassy 🧑‍🎄🎄🎅'}</span>
      ) : (
        <span>🧑‍🎄🎄🎅</span>
      )}
    </Button>
  );
};
