import { Card, Popover, Typography } from 'antd';
import { formatClickableContent } from 'helpers/index';
import { FC } from 'react';

interface IProps {
  estimatedPrice?: string;
  estimatedDuration?: string;
}

export const TreatmentEstimation: FC<IProps> = ({ estimatedPrice, estimatedDuration }) => (
  <div>
    <div className="mt-4 flex w-full items-center gap-2 md:mt-8">
      <Typography.Title level={4} className="whitespace-nowrap">
        Treatment Estimations
      </Typography.Title>
      <div className="mb-1 flex-grow border-0 border-t border-solid border-t-gray-200" />
    </div>
    <Typography.Text>
      These are estimates and may change as we narrow down what your perfect smile looks like
    </Typography.Text>
    <div className="mt-4 grid grid-cols-12 gap-2">
      <Card className="col-span-6 shadow-custom2">
        <Typography.Title level={5}>Estimated Price</Typography.Title>
        <Popover content={<div dangerouslySetInnerHTML={{ __html: formatClickableContent(estimatedPrice) }} />}>
          <div
            className="line-clamp-2 text-base md:text-xl"
            dangerouslySetInnerHTML={{ __html: formatClickableContent(estimatedPrice) }}
          />
        </Popover>
      </Card>
      <Card className="col-span-6 shadow-custom2">
        <Typography.Title level={5}>Estimated time</Typography.Title>
        <Typography.Text className="text-base md:text-xl">{estimatedDuration}</Typography.Text>
      </Card>
    </div>
  </div>
);
