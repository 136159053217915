import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from 'services/api';
import { onyxApi } from 'services/onyx-api/onyx-api';
import { patientsApi } from 'services/patients-api/endpoints';
import authReducer from '../modules/auth/authSlice';
import appSlice from './appSlice';
import caseReducer from './caseSlice';
import eduContentReducer from 'components/edu-content/edu-content-list/eduContentSlice';
import socketReducer from './socketSlice';
import notificationsReducer from './notificationsSlice';
import chatReducer from 'components/chat/redux/chatSlice';
import downloadProgressReducer from './downloadStlsSlice';
import themeSlice from './themeSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'theme'],
};

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  [patientsApi.reducerPath]: patientsApi.reducer,
  [onyxApi.reducerPath]: onyxApi.reducer,
  auth: authReducer,
  app: appSlice,
  submission: caseReducer,
  eduContent: eduContentReducer,
  socket: socketReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  downloadProgress: downloadProgressReducer,
  theme: themeSlice,
});

export const middlewares = [api.middleware, onyxApi.middleware];

export default persistReducer(persistConfig, reducers);
