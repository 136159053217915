import { CheckOutlined } from '@ant-design/icons';
import { Card, Avatar, Rate } from 'antd';
import ciaraAvtPng from 'assets/images/auth/ciara-avt.png';

export const FourthSlide = () => (
  <div className="flex max-h-[calc(100vh-145px)] flex-col items-baseline overflow-y-auto text-white">
    <Card className="w-full self-center !rounded-[20px] border-0 bg-white bg-opacity-10 text-white lg:w-[354px]">
      <div className="font-bold">Really a must do to provide aligners safely</div>
      <div>Explains everything, the learning materials are fantastic and the support is great</div>
      <div className="mt-2 flex items-center gap-3">
        <Avatar src={ciaraAvtPng} />
        <div>
          <div className="text-base font-bold">Ciara</div>
          <div className="text-sm">Dentist</div>
        </div>
        <Rate value={5} className="ml-4 lg:ml-16" disabled />
      </div>
    </Card>
    <div className="mt-8 py-4 text-2xl font-semibold lg:text-3xl">World-class education & support when you need it</div>
    <p className="text-base lg:text-lg">
      We can help you get started, super-charge your case numbers and increase profitability.
    </p>
    <div className="mb-4 flex items-baseline text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      Get started safely with the only Dental Protection approved Clear Aligner Course
    </div>
    <div className="mb-4 flex items-baseline text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      Over 100 hours of free CPD courses in our patent pending educational system
    </div>
    <div className="mb-4 flex items-baseline text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      On-call support team with less than 2 hours Average response time, even on the weekend
    </div>
  </div>
);
