import { FC, useEffect, useMemo } from 'react';
import { Checkbox, Form, FormInstance, Input, Typography } from 'antd';
import { PracticeInput } from './practice-input';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { MetaOptionsInput } from 'components/meta-options-input/meta-options-input';
import { META_KEYS, useMetaOptionsSubmission } from 'hooks/useMetaOptionsSubmission';
import { CorporatesSelect, NO_ASSOCIATED_WITH_DENTAL_CORPORATE } from './corporates-select';

interface IExperienceProps {
  isActive: boolean;
  formInstance: FormInstance;
}

export const Practice: FC<IExperienceProps> = ({ isActive, formInstance }) => {
  const { getOptions } = useMetaOptionsSubmission(META_KEYS.dentistRegistration);
  const associatedWithDentalCorporates = Form.useWatch('associatedWithDentalCorporates', formInstance);
  const practiceOwner = Form.useWatch('practiceOwner', formInstance);

  useEffect(() => {
    if (associatedWithDentalCorporates?.length > 0) {
      formInstance.setFieldValue('isAssociatedWithDentalCorporates', true);
    } else if (associatedWithDentalCorporates === NO_ASSOCIATED_WITH_DENTAL_CORPORATE) {
      formInstance.setFieldValue('isAssociatedWithDentalCorporates', false);
    } else formInstance.setFieldValue('isAssociatedWithDentalCorporates', undefined);
  }, [associatedWithDentalCorporates]);

  const onFinishFailed = ({ errorFields }: any) => {
    formInstance.scrollToField(errorFields[0].name, { block: 'center' });
  };

  const isPracticeOwner = useMemo(
    () => practiceOwner && practiceOwner === getOptions('practiceOwner')?.[0]?.value, // 'Yes'
    [practiceOwner],
  );

  return (
    <Form
      form={formInstance}
      layout="vertical"
      size="large"
      autoComplete="off"
      className="mt-4 animate-fadeIn"
      onFinishFailed={onFinishFailed}
    >
      <div className={isActive ? 'visible' : 'hidden'}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="practicesWorkAt"
              label="How many practices do you work at?"
              isRequired
              rules={[
                {
                  required: true,
                  message: 'Please select a number',
                },
              ]}
            >
              <MetaOptionsInput
                metaKey={META_KEYS.dentistRegistration}
                optionKey="practicesWorkAt"
                placeholder="Select a number"
                className="custom-input"
              />
            </FormItemControl>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="associatedWithDentalCorporates"
              label="Are you associated with any Dental Corporates"
              isRequired
              rules={[
                () => ({
                  validator(_, value) {
                    if (value?.length > 0 || value === null) return Promise.resolve();
                    return Promise.reject(new Error('Please select at least one option or select no'));
                  },
                }),
              ]}
            >
              <CorporatesSelect
                className="custom-input"
                mode="multiple"
                placeholder="Start typing or select one or more options"
              />
            </FormItemControl>
            <FormItemControl noStyle name="isAssociatedWithDentalCorporates" />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="practiceOwner"
              label="Are you a Practice Owner?"
              isRequired
              rules={[
                {
                  required: true,
                  message: 'Let us know if you’re a practice owner',
                },
              ]}
            >
              <MetaOptionsInput
                metaKey={META_KEYS.dentistRegistration}
                optionKey="practiceOwner"
                placeholder="Select an option"
                className="custom-input"
              />
            </FormItemControl>
          </div>
          {isPracticeOwner && (
            <div className="col-span-2 lg:col-span-1">
              <FormItemControl
                name="helpAcrossPractice"
                label="Do you want 32Co’s help across your practice(s)?"
                isRequired
                rules={[
                  {
                    required: true,
                    message: 'Please select an option',
                  },
                ]}
              >
                <MetaOptionsInput
                  metaKey={META_KEYS.dentistRegistration}
                  optionKey="helpAcrossPractice"
                  placeholder="Select an option"
                  className="custom-input"
                />
              </FormItemControl>
            </div>
          )}

          <div className="col-span-2">
            <Typography.Text className="font-semibold">Please provide details of your primary practice</Typography.Text>
          </div>
          <Form.List name="clinics">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div className="col-span-2" key={field.name}>
                    <PracticeInput
                      formListName="clinics"
                      form={formInstance}
                      index={index}
                      field={field}
                      onAdd={index === fields.length - 1 ? () => add() : undefined}
                      onRemove={() => remove(field.name)}
                    />
                  </div>
                ))}
              </>
            )}
          </Form.List>
          <div className="col-span-2">
            <Typography.Text className="font-semibold">Final, final questions</Typography.Text>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="hearAbout"
              label="How did you hear about 32Co?"
              isRequired
              rules={[
                {
                  required: true,
                  message: 'Please let us know how you found us',
                },
              ]}
            >
              <MetaOptionsInput
                metaKey={META_KEYS.dentistRegistration}
                optionKey="hearAbout"
                placeholder="Select an option"
                className="custom-input"
              />
            </FormItemControl>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl name="referredCode" label="If you have a referral code, please enter it here">
              <Input className="custom-input" placeholder="Enter referral code" />
            </FormItemControl>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="isTermsAndConditionsAccpeted"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Please confirm the T&Cs')),
                },
              ]}
            >
              <Checkbox>
                <div>
                  I have read and accept the{' '}
                  <a
                    href="https://www.32co.com/dentist/legal/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    terms and conditions
                  </a>
                  *
                </div>
              </Checkbox>
            </FormItemControl>
          </div>
        </div>
      </div>
    </Form>
  );
};
