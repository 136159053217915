import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Typography } from 'antd';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { ISkuType } from 'services/sku-api/types';
import { TGetOrderSummaryResponse } from 'services/order-api/types';
import { useCreateOrderMutation } from 'services/order-api/endpoints';
import { notificationApiError } from 'utils/ui';
import { pushToDataLayer } from 'utils/gtm';
import moment from 'moment';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';
import { ISubmission } from 'services/patients-api/types';
import { useAuth } from 'hooks/useAuth';
import { showModal } from 'utils';
import { useCurrency } from 'hooks/useCurrency';

const { Text } = Typography;

interface IProps {
  selectedSku: ISkuType | undefined;
  orderSummary: TGetOrderSummaryResponse | undefined;
  selectedFinishType: string | undefined;
  submissionInfo: ISubmission;
  treatmentDesignId: string;
}

const OrderSummaryModal = NiceModal.create<TModalProps<IProps>>(
  ({ onCancel, selectedSku, orderSummary, selectedFinishType, submissionInfo, treatmentDesignId }) => {
    const modal = useModal();
    const [createOrder, { isLoading: isOrderSubmitting }] = useCreateOrderMutation();
    const { user } = useAuth();
    const { moneyFormat, currencySymbol } = useCurrency();

    const handleAcceptAndOrder = async () => {
      const reqData = {
        submission: submissionInfo.id,
        discount: orderSummary?.discount?.id,
        sku: selectedSku?.id,
        treatmentDesign: treatmentDesignId,
        finishOption: selectedFinishType,
        estDelivery: selectedSku?.estDelivery,
      };
      try {
        await createOrder(reqData).unwrap();
        const orderDataForAnalytics = {
          productName: selectedSku?.name!,
          productId: selectedSku?.skuId!,
          productPrice: selectedSku?.price!,
          checkoutAmount: orderSummary
            ? Math.max(orderSummary!.total - (orderSummary?.discountAmount || 0), 0)
            : selectedSku?.price!,
          currency: currencySymbol,
        };
        pushToDataLayer({
          event: 'clearAlignerPurchase',
          eventDataForOrder: orderDataForAnalytics,
        });

        trackEvent(EEventNames.CASE_ORDERED, {
          'Case ID': submissionInfo.id,
          'Patient Name': submissionInfo.patient?.firstName,
          'Plan Type': submissionInfo.planType,
          'Service Type': submissionInfo.serviceType,
          'Case Status': submissionInfo.status,
          'Practice ID': submissionInfo.practice?.id,
          'Corporate ID': user?.associatedWithDentalCorporates?.map((corporate) => corporate.id),
          'SKU ID': selectedSku?.skuId,
          'Number of Refinements': selectedSku?.numberOfRefinementsIncluded,
          'Package Name': selectedSku?.name,
          Manufacturer: selectedSku?.manufacturer?.business?.name,
          'Order Total': orderSummary
            ? Math.max(orderSummary!.total - (orderSummary?.discountAmount || 0), 0)
            : selectedSku?.price!,
          'Discount Code': orderSummary?.discount?.externalDiscountCode || '-',
          'Discount Value': orderSummary?.discountAmount || 0,
          'Ordered Date': new Date().toISOString(),
        });

        modal.resolve(true);
        modal.hide();
      } catch (error) {
        notificationApiError(error);
      }
    };

    const handleGoBack = () => {
      modal.resolve(false);
      modal.hide();
    };

    const handleCancel = (e: any) => {
      onCancel?.(e);
      modal.reject();
    };

    const renderPrice = () => {
      let price = selectedSku?.price?.toFixed(2);
      if (orderSummary) {
        price = Math.max(orderSummary!.total - (orderSummary?.discountAmount || 0), 0).toFixed(2);
      }
      return (
        <div>
          {/* check min?? */}
          {!!orderSummary && (
            <Typography.Title level={5} className="m-0 text-sm font-medium text-gray-500 line-through">
              {moneyFormat(orderSummary.total, { fractionDigits: 2 })}
            </Typography.Title>
          )}
          <Typography.Title level={5} className="!m-0">
            {moneyFormat(price || 0)}
          </Typography.Title>
        </div>
      );
    };

    return (
      <NiceAntModal
        modal={modal}
        width={600}
        onCancel={handleCancel}
        title="Order Summary"
        footer={
          <div className="flex w-full items-center gap-2 md:flex-row">
            <Button
              className="btn-secondary-redesigned mr-3 w-1/2"
              size="large"
              type="default"
              shape="round"
              onClick={handleGoBack}
            >
              Go back
            </Button>
            <Button
              className="btn-primary-redesigned w-1/2"
              id="accept-and-order"
              size="large"
              type="primary"
              style={{ minWidth: 200 }}
              onClick={handleAcceptAndOrder}
              loading={isOrderSubmitting}
            >
              Accept and Order
            </Button>
          </div>
        }
      >
        <div>
          <div>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 pt-3 md:col-span-6">
                <div className="flex flex-col">
                  <Text className="text-head">Product option:</Text>
                  <Text className="text-body">{selectedSku?.name}</Text>
                </div>
              </div>
              <div className="col-span-12 pt-3 md:col-span-6">
                <div className="flex flex-col">
                  <Text className="text-head">Plastic type:</Text>
                  <Text className="text-body lowercase">{selectedSku?.materialType}</Text>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 pt-3 md:col-span-6">
                <div className="flex flex-col">
                  <Text className="text-head">Shipping speed:</Text>
                  <Text className="text-body">
                    {selectedSku?.shippingSpeed === 0 ? '-' : `${selectedSku?.shippingSpeed} days`}
                  </Text>
                </div>
              </div>
              <div className="col-span-12 pt-3 md:col-span-6">
                <div className="flex flex-col">
                  <Text className="text-head">Finish type:</Text>
                  <Text className="text-body lowercase">{selectedFinishType}</Text>
                </div>
              </div>
            </div>
            {orderSummary?.discount && (
              <div className="mt-3 flex flex-row items-center justify-between">
                <div className="col-span-6 flex flex-col p-0">
                  <Text className="text-head">Reward Code</Text>
                  <div className="offer-badge mt-2 rounded-standard border-2 px-4 py-2 text-center !text-base font-bold">
                    {orderSummary?.discount?.externalDiscountCode}
                  </div>
                  <Typography.Text className="mt-2 text-xs" style={{ color: '#6B7D8B' }}>
                    {orderSummary?.discount?.externalDiscountDescription}
                  </Typography.Text>
                </div>
                <div className="flex flex-col pt-4">
                  {!!orderSummary?.discountAmount && orderSummary?.discount.discountType === 'CASH' && (
                    <Typography.Text className="text-right font-bold text-mintColor">
                      -{moneyFormat(Math.min(orderSummary.discountAmount, selectedSku?.price!))}
                    </Typography.Text>
                  )}
                  {!!orderSummary?.discountAmount && orderSummary?.discount?.discountType === 'PERCENT' && (
                    <Typography.Text className="text-right font-bold text-mintColor">
                      -{orderSummary.discount.discountValue}% ( {moneyFormat(orderSummary.discountAmount)} )
                    </Typography.Text>
                  )}
                  {!orderSummary?.discountAmount && (
                    <div>
                      <Text className="text-head">No Reward</Text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="my-4">
              <div className="flex items-end justify-between bg-btnSecondaryColor p-3">
                <Typography.Title level={5} className="!m-0">
                  Total Price:
                </Typography.Title>
                {renderPrice()}
              </div>
              {!!selectedSku?.estDelivery && (
                <div className="flex items-end justify-between  p-3">
                  <Typography.Text className="!m-0">Estimated Delivery date:</Typography.Text>
                  <Typography.Text className="!m-0">{moment(selectedSku.estDelivery).format('Do MMM')}</Typography.Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </NiceAntModal>
    );
  },
);

export const showOrderSummaryModal = showModal<boolean, IProps>(OrderSummaryModal);
