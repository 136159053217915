import { FC, useState } from 'react';
import { Image, Row, Empty, Divider, Typography } from 'antd';
import { ITreatmentDesign, ITreatmentDesignImage } from 'services/treatment-designs-api/types';
import { NiceCollapse } from 'components/commons/nice-collapse/nice-collapse';
import { IprSchematicsItem } from './ipr-schematics-item';
import { useGetPreviousIprImagesQuery } from 'services/treatment-designs-api/endpoints';
import { ViewItem } from 'components/view-item/view-item';

interface IProps {
  treatmentDesign?: ITreatmentDesign;
  showPrevious?: boolean;
}

export const IprSchematicsCollapse: FC<IProps> = ({ treatmentDesign, showPrevious }) => {
  const { iprImages } = treatmentDesign || {};
  const [iprListInPreview, setIprListInPreview] = useState<ITreatmentDesignImage[] | null>(null);
  const [currentPreview, setCurrentPreviewImage]: any = useState(0);
  const { data: previousSubmissions } = useGetPreviousIprImagesQuery(
    { treatmentDesignId: treatmentDesign?.id! },
    {
      skip: !showPrevious,
    },
  );

  const previewImage = (activeIprList: ITreatmentDesignImage[], index: number) => {
    setCurrentPreviewImage(index);
    setIprListInPreview(activeIprList);
  };

  return (
    <NiceCollapse title="IPR Schematics" open>
      {iprImages && iprImages.length > 0 && (
        <div className="px-3">
          {iprImages?.map((image, index: number) => (
            <IprSchematicsItem key={image.url} image={image} onPreview={() => previewImage(iprImages, index)} />
          ))}
        </div>
      )}
      {(!iprImages || iprImages?.length === 0) && <Empty description="No data to show!" />}

      {/* Previous IPR Schematics */}
      {previousSubmissions?.length! > 0 && (
        <>
          <Divider className="my-4" />
          <Typography.Title level={5}>Previous IPR Schematics</Typography.Title>
          {previousSubmissions?.map((previousSubmission) => (
            <ViewItem
              key={previousSubmission.submission}
              title={`${previousSubmission?.planType} ${previousSubmission?.refinementNumber || ''} Submission`}
            >
              {previousSubmission?.treatmentDesigns?.[0]?.iprImages?.map((image, index: number) => (
                <IprSchematicsItem
                  key={image.url}
                  image={image}
                  onPreview={() => previewImage(previousSubmission?.treatmentDesigns?.[0]?.iprImages, index)}
                />
              ))}
            </ViewItem>
          ))}
        </>
      )}
      {/* Preview */}
      <div className="hidden">
        {iprListInPreview && (
          <Image.PreviewGroup
            preview={{
              visible: true,
              onVisibleChange: () => setIprListInPreview(null),
              current: currentPreview,
            }}
          >
            <Row gutter={32}>
              {iprListInPreview?.map(({ url: iprUrl }) => (
                <Image key={iprUrl} src={iprUrl} alt="" />
              ))}
            </Row>
          </Image.PreviewGroup>
        )}
      </div>
    </NiceCollapse>
  );
};
