import { ImgCrop } from 'components/img-crop/img-crop';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { TFile, UploadWebcamCapture } from './upload-webcam-capture/upload-webcam-capture';

type TProps = {
  customRequest: (file: TFile) => void;
  isImgCrop?: boolean;
};

export const WebcamModal = NiceModal.create<TProps>(({ isImgCrop, customRequest }) => {
  const modal = useModal();

  const closeWebcamModal = () => {
    modal.hide();
  };

  return (
    <NiceAntModal modal={modal} width={650} onCancel={closeWebcamModal} maskClosable={false}>
      <div>
        <div className="mb-4 text-lg font-bold text-darkBlueColor">Take your photo</div>
        {isImgCrop ? (
          <ImgCrop rotationSlider cropShape="rect" aspectSlider>
            <UploadWebcamCapture customRequest={customRequest} closeWebcamModal={closeWebcamModal} />
          </ImgCrop>
        ) : (
          <UploadWebcamCapture customRequest={customRequest} closeWebcamModal={closeWebcamModal} />
        )}
      </div>
    </NiceAntModal>
  );
});
