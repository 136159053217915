import { api, formatUrl } from '../api';
import { IPricingsResponse, ISkuType, ISkuRecommendation } from './types';

export const skusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSkus: build.query<
      { skus: ISkuType[]; refinementRecomendation: ISkuRecommendation },
      { submission: string; treatmentDesign: string }
    >({
      query: ({ submission, treatmentDesign }) => {
        let paramsString = '';
        if (submission) paramsString += `submission=${submission}`;
        if (treatmentDesign) paramsString += `&treatmentDesign=${treatmentDesign}`;
        return formatUrl(`${'/skus'}?page=1&perPage=20&${paramsString}`);
      },
    }),
    getPricings: build.query<IPricingsResponse, void>({
      query: () => formatUrl('/pricings'),
    }),
  }),
});

export const { useGetSkusQuery, useGetPricingsQuery } = skusApi;
