import { Typography } from 'antd';
import clearAlignerPng from 'assets/images/clear_aligner.png';
import clearAlignerPrototypeJpeg from 'assets/images/interested-patients/clear-aligner-prototype.jpeg';
import dentalProtectionPng from 'assets/images/interested-patients/dental-protection.png';
import profAmaPng from 'assets/images/interested-patients/prof-Ama.png';
import rajRattanPng from 'assets/images/interested-patients/raj-rattan.png';
import patientRebeccaPng from 'assets/images/interested-patients/patientRebecca.png';
import { FC } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { ReactComponent as QuoteSvg } from 'assets/images/interested-patients/quote-icon.svg';
import { ReactComponent as Logo } from 'assets/images/interested-patients/32co-logo.svg';

export const ClearAlignersInformationDetails: FC<{ image?: string }> = ({ image = clearAlignerPrototypeJpeg }) => (
  <>
    <div className="">
      <div className="mb-4 flex flex-col items-center justify-center lg:flex-row">
        <div className="flex w-full justify-center lg:w-1/2">
          <div className="relative h-[200px] w-[200px] rounded-full border border-solid border-gray-300 bg-white lg:h-[290px] lg:w-[290px]">
            <img src={clearAlignerPng} alt="clear aligner" className="h-5/6 w-full" />
            <div className="absolute bottom-8 left-0 w-full text-center font-bold">
              Discrete adult teeth <br /> straightening
            </div>
          </div>
        </div>
        <div className="mt-4 w-full px-4 lg:mt-0 lg:w-1/2">
          <Typography.Title level={2} className="!text-2xl md:!text-3xl">
            What are Clear Aligners?
          </Typography.Title>
          <p>
            Easily removable and lifestyle-friendly, Clear Aligners not only straighten teeth efficiently but also offer
            the option to enhance your smile with tooth whitening. Avoid those cumbersome metal braces and choose Clear
            Aligners for a discreet, effective, and aesthetically pleasing solution to achieve both a straighter and
            brighter smile.
          </p>
          <p>
            Easily removable and lifestyle-friendly, Clear Aligners not only straighten teeth efficiently but also offer
            the option to enhance your smile with tooth whitening. Avoid those cumbersome metal braces and choose Clear
            Aligners for a discreet, effective, and aesthetically pleasing solution to achieve both a straighter and
            brighter smile.
          </p>
          <div className="mt-6 flex w-full flex-col gap-4">
            <div className="flex items-baseline gap-2">
              <CheckOutlined className="[&>svg]:fill-[#039F61]" />
              <Typography.Text className="font-semibold text-[#039F61]">
                Removable and virtually invisible
              </Typography.Text>
            </div>
            <div className="flex items-baseline gap-2">
              <CheckOutlined className="[&>svg]:fill-[#039F61]" />
              <Typography.Text className="font-semibold text-[#039F61]">Comfortable & discreet</Typography.Text>
            </div>
            <div className="flex items-baseline gap-2">
              <CheckOutlined className="[&>svg]:fill-[#039F61]" />
              <Typography.Text className="font-semibold text-[#039F61]">Eat and drink as normal</Typography.Text>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-14 rounded-[10px] bg-[#F5FBFF] p-8 text-center md:text-left">
        <div className="mt-2 flex flex-col items-center gap-4 md:flex-row md:items-start">
          <img src={rajRattanPng} className="h-24 w-24" alt="patient" />
          <div className="flex-1 self-center">
            <Typography.Text className="text-sm font-semibold text-[#E82A63]">
              RAJ Rattan MBE, Dental Protection Dental Director
            </Typography.Text>
          </div>
          <div className="flex flex-col items-end gap-4">
            <img width={200} src={dentalProtectionPng} className="" alt="dental-protection" />
          </div>
        </div>
        <div className="mt-6 flex flex-col items-center gap-4 md:mt-0 md:flex-row">
          <QuoteSvg width={100} />
          <span className="mb-0 flex-row text-sm font-medium !leading-10 text-[#060A43] md:flex-col md:text-lg">
            We’re delighted to partner with 32Co, a company that is revolutionising the dental industry. Their
            innovative model improves clinical outcomes and patient satisfaction.
          </span>
        </div>
      </div>
      <div className="mt-12 flex flex-col gap-4 rounded-2xl lg:mt-10 lg:flex-row">
        <div className="w-full px-4 lg:w-1/2">
          <Typography.Title level={2} className="!text-2xl !font-bold md:!text-3xl">
            Are all tooth straightening treatments the same?
          </Typography.Title>
          <p>
            You’ll likely have heard of Invisalign. Much like 'Hoover' is synonymous with vacuum cleaners, 'Invisalign'
            is a brand, not the treatment itself. Your treatment would be planned with 32Co. 32Co provide specialist
            expertise, quality aligners, and personalised care; offering various material options for a custom fit, the
            aligners are tailored to you.
          </p>
          <p>
            32Co are unique in the Clear Aligner industry. The company is led by orthodontic specialists and dentists,
            ensuring a personalised, and effective treatment plan, often finishing months earlier than industry
            competitors.
          </p>
          <p>
            Safety and efficacy are a priority, it might not sound glamorous but 32Co are partnered with Dental
            Protection, the UK and Ireland’s leading indemnity organisation. This is a massive vote of confidence and
            32Co are the only aligner company in the UK and Ireland to have achieved this.
          </p>
        </div>
        <div className="flex items-start justify-center lg:w-1/2 lg:justify-end">
          <div className="h-[235] w-[274] rounded-br-2xl rounded-tr-2xl bg-active-menu p-4">
            <img src={image} alt="" className="w-full object-cover" />
          </div>
        </div>
      </div>
      <div className="mb-6 mt-12 rounded-lg bg-[#F5FBFF] p-5">
        <div className="flex flex-col items-center gap-4 text-center md:flex-row md:items-start md:text-left">
          <img src={profAmaPng} className="h-24 w-24" alt="patient" />
          <div className="mt-2 flex-1">
            <Typography.Text className="text-sm font-bold text-cerise">
              Professor Ama Johal - BDS (Hons), MSc, PhD, FDS, MOrth RCS, DMS, FDS (Orth) RCS, FHEA{' '}
            </Typography.Text>
            <ul className="mt-6 pl-5">
              <li className="text-sm">Lead Orthodontist at 32Co</li>
              <li className="text-sm">Professor of Orthodontics & Consultant Orthodontist at Bart's</li>
              <li className="text-sm">
                Academic Lead for Orthodontics at the Institute of Dentistry, Queen Mary College.{' '}
              </li>
            </ul>
          </div>
          <Logo />
        </div>
        <div className="mb-6 mt-6 flex flex-col items-center justify-start md:flex-row md:px-2">
          <QuoteSvg width={200} />
          <p className="mb-0 ml-8 flex-row text-sm font-medium !leading-10 text-[#060A43] md:flex-col md:text-lg">
            We're doing something completely different with dentists working with orthodontists hand-in-hand to benefit
            patients. It’s incredibly rewarding to be able to share what I’ve learnt across 1000s of cases to help
            dentists give their patients the best results.
          </p>
        </div>
      </div>
      <div className="mt-12 rounded bg-[#FAFAFA] p-6 py-8">
        <Typography.Title level={3} className="!text-2xl !font-bold md:!text-3xl">
          The benefits go beyond just aesthetics - improve the long-term health of your teeth
        </Typography.Title>
        <Typography.Text>
          With adult tooth straightening, you can achieve a straighter smile, better oral health, and improved
          self-confidence. Many people didn’t have braces when they were younger, or they did and their teeth have moved
          over the years.
          <br />
          <br />
          Straightened teeth are easier to clean - reducing plaque build-up, cavities, and gum disease risk.
          Improvements can also be made to your bite, which can reduce tooth wear as well as improving jaw pain and
          headaches
        </Typography.Text>
        <Typography.Title level={2} className="text-center !text-xl">
          Did you know {'>70%'} of the adult population suffer from Crooked or misaligned teeth
        </Typography.Title>
      </div>
    </div>
    <div className="mt-6 rounded-lg bg-[#F5FBFF] p-4 lg:p-9">
      <div className="flex items-center gap-4">
        <img src={patientRebeccaPng} className="h-24 w-24" alt="patient" />
        <div className="mt-2">
          <Typography.Text className="text-sm font-bold text-cerise">
            Rebecca, 32Co Clear Aligner Patient
          </Typography.Text>
        </div>
      </div>
      <div className="flex flex-col items-center justify-start px-0 md:flex-row md:px-2">
        <QuoteSvg width={150} />
        <p className="mb-0 ml-8 flex-row text-sm font-medium !leading-10 text-[#060A43] md:flex-col md:text-lg">
          I knew I was in safe hands with my dentist, any concerns or questions were answered promptly. Now I've
          finished I feel like I want to smile more and I'm happier to pose for a picture.
        </p>
      </div>
    </div>
  </>
);
