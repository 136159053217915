import { FC, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface IProps {
  content: string;
  className?: string;
}
export const LineClamp: FC<IProps> = ({ content, className }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const moreContent =
        contentRef.current.offsetHeight < contentRef.current.scrollHeight ||
        contentRef.current.offsetWidth < contentRef.current.scrollWidth;
      setHasMoreContent(moreContent);
    }
  }, [contentRef.current]);

  return (
    <div>
      <div
        className={twMerge('line-clamp-5 h-[112px]', className, isExpanded && 'line-clamp-none h-auto')}
        ref={contentRef}
      >
        {content}
      </div>
      {hasMoreContent && (
        <span
          className="cursor-pointer text-blue-500 underline"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded((value) => !value);
          }}
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </span>
      )}
    </div>
  );
};
