import { useEffect } from 'react';
import { IPINFO_TOKEN } from '../config/constants';
import { IUseGeolocationIpReturnType, selectGeoData, setGeoData } from 'redux/appSlice';
import { useAppDispatch, useAppSelector } from './store';

let geoDataLoading = false;

export const useGeolocationIp = (): IUseGeolocationIpReturnType | undefined => {
  const geo = useAppSelector(selectGeoData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!geo && !geoDataLoading && IPINFO_TOKEN) {
      geoDataLoading = true;
      fetch(`https://ipinfo.io/?token=${IPINFO_TOKEN}`)
        .then((response) => response.json())
        .then((payload) => {
          if (payload) {
            dispatch(setGeoData({ geo: payload }));
          }
        })
        .finally(() => {
          geoDataLoading = false;
        });
    }
  }, []);

  return geo;
};
