import snowLandSvg from 'assets/images/christmas/snow-land.svg';
import pineTreeSvg from 'assets/images/christmas/pine-tree.svg';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface IProps {
  smallMode: boolean;
}

export const ChristmasPine: FC<IProps> = ({ smallMode }) => (
  <div className="relative w-full">
    <img src={snowLandSvg} alt="" className={twMerge('absolute bottom-0 left-0 right-0 w-[103%]')} />
    <img src={pineTreeSvg} alt="" className="absolute -left-6 bottom-0" />
    <img
      src={pineTreeSvg}
      alt=""
      className={twMerge('absolute bottom-0 left-24 delay-75', smallMode && 'invisible delay-0')}
    />
    <img
      src={pineTreeSvg}
      alt=""
      className={twMerge('absolute bottom-0 left-32 delay-75', smallMode && 'invisible delay-0')}
    />
  </div>
);
