import { Card, Typography } from 'antd';
import { FC } from 'react';
import { ClearAlignersInformationDetails } from '../../components/clear-aligners-information-details';
import tryClearAlignerPng from 'assets/images/interested-patients/try-clear-aligner.png';

interface IProps {
  className?: string;
}

export const ClearAlignersInformation: FC<IProps> = ({ className = '' }) => (
  <Card bordered={false} className={className} bodyStyle={{ padding: 0 }}>
    <div className="p-0 py-4 md:p-6">
      <Typography.Title level={3} className="!mb-4 whitespace-nowrap px-4 !text-lg !font-semibold md:px-0 md:!text-2xl">
        Information on Clear Aligners
      </Typography.Title>
      <ClearAlignersInformationDetails image={tryClearAlignerPng} />
    </div>
  </Card>
);
