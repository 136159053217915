import { buildQueryString } from 'services/utils';
import { api, formatUrl, VERSION } from '../api';
import {
  IInterestedPatientSubmitPayload,
  IInterestedPatientsRequest,
  IInterestedPatientsResponse,
  ISendToPatientsRequest,
  ISqPatientInfo,
  TInterestedPatientPisRequest,
  IPersonalInfoSheet,
  IArchiveInterestedPatientRequest,
  ISmileQuestionnaire,
  TBulkSmileQuestionnairePayload,
} from './types';
import { omit } from 'lodash';
import { IPatient } from 'services/patients-api/types';

const INTERESTED_PATIENTS_URL = '/patients';

export const interestedPatientsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInterestedPatients: build.query<IInterestedPatientsResponse, IInterestedPatientsRequest>({
      query: (params) => formatUrl(`${INTERESTED_PATIENTS_URL}?${new URLSearchParams(buildQueryString(params))}`),
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ id }) => ({ type: 'InterestedPatients', id } as const)),
              { type: 'InterestedPatients', id: 'LIST' },
            ]
          : [{ type: 'InterestedPatients', id: 'LIST' }],
    }),
    getInterestedPatientById: build.query<IPatient, string>({
      query: (id) => formatUrl(`${INTERESTED_PATIENTS_URL}/${id}`),
      providesTags: (result, error, id) => [{ type: 'InterestedPatients', id }],
    }),
    addInterestedPatient: build.mutation<IPatient, IInterestedPatientSubmitPayload>({
      query(body) {
        return {
          url: formatUrl(`${INTERESTED_PATIENTS_URL}`),
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'InterestedPatients', id: 'LIST' }],
    }),
    archiveInterestedPatient: build.mutation<{ message: string }, IArchiveInterestedPatientRequest>({
      query({ archiveReason, id }) {
        const body = { archiveReason };
        return {
          url: formatUrl(`${INTERESTED_PATIENTS_URL}/${id}/archive`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: 'InterestedPatients', id: 'LIST' }],
    }),
    unArchiveInterestedPatient: build.mutation<{ message: string }, IArchiveInterestedPatientRequest>({
      query({ archiveReason, id }) {
        const body = { archiveReason };
        return {
          url: formatUrl(`${INTERESTED_PATIENTS_URL}/${id}/un-archive`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: 'InterestedPatients', id: 'LIST' }],
    }),
    startPatientSQ: build.query<void, string>({
      query: (id) => formatUrl(`/patients/${id}/sq/start`),
    }),
    getPatientSQ: build.query<ISmileQuestionnaire, { patientId: string; sqId: string }>({
      query: ({ patientId, sqId }) => formatUrl(`/patients/${patientId}/sq/${sqId}`),
    }),
    upsertInterestedPatientSQ: build.mutation<void, { payload: ISqPatientInfo; id: string }>({
      query({ payload, id }) {
        return {
          url: formatUrl(`/patients/${id}/sq`),
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error_, params) => [
        { type: 'InterestedPatients', id: 'LIST' },
        ...(params?.payload?.status === 'SUBMITTED' ? [{ type: 'InterestedPatients', id: params.id } as const] : []),
      ],
    }),
    sentToPatientSQ: build.mutation<void, ISendToPatientsRequest>({
      query: (credentials) => ({
        url: formatUrl(`/patients/${credentials?.id}/sq/send-to-patient`),
        method: 'POST',
        body: omit(credentials, 'id'),
      }),
      invalidatesTags: (_result, _error_, params) => [
        { type: 'InterestedPatients', id: 'LIST' },
        { type: 'InterestedPatients', id: params.id } as const,
      ],
    }),
    sentToPatientSQs: build.mutation<void, TBulkSmileQuestionnairePayload>({
      query: (credentials) => ({
        url: formatUrl(`/patients/sq/send-to-patients`),
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: () => [{ type: 'InterestedPatients', id: 'LIST' }],
    }),
    getPis: build.query<IPersonalInfoSheet, { patientId: string; pisId: string }>({
      query: ({ patientId, pisId }) => formatUrl(`${INTERESTED_PATIENTS_URL}/${patientId}/pis/${pisId}`),
      providesTags: (result, error, { patientId }) => [{ type: 'InterestedPatients', patientId }],
    }),
    getInterestedPatientByPassword: build.query<
      IPatient & {
        createdBy?: {
          fullName: string;
          profilePic: string;
        };
      },
      { id: string; password: string }
    >({
      query: ({ id, password }) => `/${VERSION}/user/patients/${id}/pis?password=${password}`,
    }),
    upsertInterestedPatientPis: build.mutation<
      { message: string; id: string },
      { id: string; payload: TInterestedPatientPisRequest }
    >({
      query({ payload, id }) {
        return {
          url: formatUrl(`${INTERESTED_PATIENTS_URL}/${id}/pis`),
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error_, params) => [
        { type: 'InterestedPatients', id: 'LIST' },
        ...(params.payload.status === 'SENT' ? [{ type: 'InterestedPatients', id: params.id } as const] : []),
      ],
    }),
    sentPisToPatient: build.mutation<{ message: string }, string>({
      query(id) {
        return {
          url: formatUrl(`${INTERESTED_PATIENTS_URL}/${id}/pis/send-to-patient`),
          method: 'POST',
        };
      },
      invalidatesTags: [{ type: 'InterestedPatients', id: 'LIST' }],
    }),
    getSqSharableLink: build.query<{ sqLink: string }, void>({
      query: () => formatUrl(`${INTERESTED_PATIENTS_URL}/sq-link`),
    }),
  }),
});

export const {
  useGetInterestedPatientsQuery,
  useLazyGetInterestedPatientsQuery,
  useGetInterestedPatientByIdQuery,
  useAddInterestedPatientMutation,
  useArchiveInterestedPatientMutation,
  useUnArchiveInterestedPatientMutation,
  useLazyStartPatientSQQuery,
  useGetPatientSQQuery,
  useUpsertInterestedPatientSQMutation,
  useSentToPatientSQMutation,
  useSentToPatientSQsMutation,
  useUpsertInterestedPatientPisMutation,
  useGetPisQuery,
  useSentPisToPatientMutation,
  useGetInterestedPatientByPasswordQuery,
  useGetSqSharableLinkQuery,
} = interestedPatientsApi;
