import { UserOutlined } from '@ant-design/icons';
import { Avatar, Input, Spin, Typography } from 'antd';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { hideTooltipContent, toggleBodyElmScroll } from 'utils/ui';
import { SUBMISSION_SERVICE_TYPE } from 'config/constants';
import { useHistory } from 'react-router-dom';
import { buildSubmisionUrl } from 'utils/submission';
import { useGetInterestedPatientsQuery } from 'services/interested-patients-api/endpoints';
import { GDP_IP_PATH } from 'modules/gdp/gdp-routes';
import { EEventNames } from 'utils/mixpanel/events';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { IPatient } from 'services/patients-api/types';
import { CREATE_PATIENT_PROPOSAL_TOOLTIP_ID } from 'modules/gdp/patients/patient-details/treatment-design-detail/treatment-design-detail';

type TSearchOption = TOptionString & {
  href: string;
};

const DIV = styled.div`
  line-height: 24px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #eee;
  &:hover {
    background-color: #efefef;
  }
`;

export const PatientSearch: FC = () => {
  const history = useHistory();
  const [input, setInput] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const boxRef = useRef<HTMLDivElement>(null);
  const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
  useOnClickOutside(boxRef, () => setIsBoxOpen(false));

  const { searchedPatients, isSearchingPatients, isFetchingPatients } = useGetInterestedPatientsQuery(
    { page: 1, perPage: 20, fullName: searchValue },
    {
      skip: searchValue.length < 3,
      selectFromResult: ({ data, isLoading, isFetching, isUninitialized }) => ({
        searchedPatients: data?.docs.map((item: IPatient) => ({
          ...item,
          label: item.fullName,
          value: item.id,
          href: item.submission ? buildSubmisionUrl(item.submission) : `${GDP_IP_PATH}/${item.id}`,
        })),
        isSearchingPatients: isLoading,
        isFetchingPatients: isFetching,
        isUninitializedPatients: isUninitialized,
      }),
    },
  );

  const handleChange = (value: string) => {
    setInput(value);
  };

  const setSearchValueDebounce = useCallback(
    debounce((value) => setSearchValue(value?.trim()), 500),
    [],
  );

  useEffect(() => {
    setSearchValueDebounce(input);
  }, [input, setSearchValueDebounce]);

  const handleClick = (option: TSearchOption) => {
    history.push(option.href);
    setIsBoxOpen(false);
  };

  return (
    <div
      className="relative"
      ref={boxRef}
      onMouseOver={(_e) => toggleBodyElmScroll(false)}
      onMouseOut={(_e) => toggleBodyElmScroll(true)}
      onBlur={() => undefined}
      onFocus={() => undefined}
    >
      <Input
        placeholder="Search for a patient"
        value={input}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => {
          hideTooltipContent(CREATE_PATIENT_PROPOSAL_TOOLTIP_ID);
          setIsBoxOpen(true);
        }}
        className="md:w-72"
        onClick={() => trackEvent(EEventNames.SEARCH_FOR_A_PATIENT_CLICKED)}
      />
      {isBoxOpen && (
        <div
          className="custom-shadow-2 radius-5 absolute w-full overflow-y-scroll bg-white md:w-72"
          style={{ maxHeight: 400 }}
        >
          {input.length < 3 ? (
            <div className="text-center">Please input at least 3 letters</div>
          ) : isSearchingPatients || isFetchingPatients ? (
            <div className="flex items-center justify-center" style={{ minHeight: '100px' }}>
              <Spin size="small" tip="Searching patient..." />
            </div>
          ) : searchedPatients && searchedPatients.length ? (
            searchedPatients.map((patient) => (
              <DIV key={patient.id} onClick={() => handleClick(patient)} className="px-3 py-2">
                <Avatar size={30} icon={<UserOutlined />} src={patient?.profilePic} className="mr-2" />
                <Typography.Text ellipsis className="flex-1">
                  {patient.label}
                </Typography.Text>
                {patient.submission && (
                  <Typography.Text
                    className={`${
                      patient.submission?.serviceType === SUBMISSION_SERVICE_TYPE.DUO
                        ? 'text-primary !border-[#307eff]'
                        : 'border-[#000]'
                    } ml-2 w-12 rounded-[20px] border-[0.5px] border-solid bg-white text-center capitalize opacity-80`}
                  >
                    {patient.submission?.serviceType?.toLowerCase()}
                  </Typography.Text>
                )}
              </DIV>
            ))
          ) : (
            <div className="text-center">No patient found</div>
          )}
        </div>
      )}
    </div>
  );
};
