import { FC, useEffect } from 'react';
import { Card, Spin, Typography, Avatar } from 'antd';
import { PoweredBy32Co } from 'components/powered-by-32co/powered-by-32co';
import { PreviewSentSQ } from './preview-sent-sq/preview-sent-sq';
import { PatientSQForm } from './patient-sq-form/patient-sq-form';
import { useQuery } from 'hooks/useQuery';
import { useParams } from 'react-router-dom';
import { EEventNames } from 'utils/mixpanel/events';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { useGetInterestedPatientSQQuery, useGetSharedLinkDentistInfoQuery } from 'services/patients-api/endpoints';
import ReactCompareImage from 'react-compare-image';
import CaseStudyBefore from 'assets/images/interested-patients/case-study-before.png';
import CaseStudyAfter from 'assets/images/interested-patients/case-study-after.png';
import classNames from 'classnames';

interface IParams {
  id: string;
  dentist: string;
}

export const ViewInterestedPatientSQ: FC = () => {
  const token = useQuery().get('token')!;
  const params: IParams = useParams();
  const { patientId } = useParams<{ patientId: string }>();
  const { data: interestedPatient, isLoading } = useGetInterestedPatientSQQuery(
    { id: patientId, password: token },
    { skip: !patientId, refetchOnFocus: true },
  );

  const { data: sharedLinkDentistData } = useGetSharedLinkDentistInfoQuery(
    { sqLinkId: params?.id },
    { skip: !params?.id },
  );

  useEffect(() => {
    if (interestedPatient) {
      trackEvent(EEventNames.INTERESTED_PATIENT_SQ_VIEWED, {
        patientId: interestedPatient?.id,
        patientName: interestedPatient?.fullName,
      });
    }
  }, [interestedPatient]);

  const isPatientSQSubmitted = interestedPatient?.smileQuestionnaire?.status === 'COMPLETED';

  if (isLoading) {
    return (
      <div className="flex h-[100vh] items-center justify-center">
        <Spin />
      </div>
    );
  }

  const sharedLinkDentistFullName = `${sharedLinkDentistData?.firstName} ${sharedLinkDentistData?.lastName}`;

  const showProfilePic =
    sharedLinkDentistData?.profilePic ?? interestedPatient?.smileQuestionnaire?.includeDentistProfilePic;

  const dentistProfileSection = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(sharedLinkDentistData?.profilePic || interestedPatient?.createdBy?.profilePic) && (
        <div className="relative">
          <Avatar size={150} src={sharedLinkDentistData?.profilePic ?? interestedPatient?.createdBy?.profilePic} />
        </div>
      )}
    </>
  );

  const dentistFullName = (sharedLinkDentistFullName && !sharedLinkDentistFullName.includes('undefined')) || '';
  const createdByFullName = interestedPatient?.createdBy?.fullName;
  const fullName = dentistFullName ? sharedLinkDentistFullName : createdByFullName || '';

  return (
    <>
      {!isPatientSQSubmitted && (sharedLinkDentistData || interestedPatient) && (
        <div className="bg-[#F8F9FD] py-6">
          <div className="m-auto max-w-[900px] px-4">
            <div
              className={classNames(
                '!text-3xl font-bold !text-[#3568D4]',
                showProfilePic && dentistProfileSection ? 'max-w-[600px]' : 'w-full',
              )}
            >
              <span className="text-4xl">👋</span> <span className="text-[#000]">Hello to a new smile</span>
              <br />
              <span className="text-xl font-bold md:text-2xl">
                2 in 3 people are unhappy with their smile, but have never spoken to their dentist about it. Complete
                &nbsp;
                {fullName && (
                  <>
                    <span className="text-[#000]">Dr. {fullName}</span>'s Smile Questionnaire to see what's possible for
                    you!
                  </>
                )}
              </span>
            </div>
            {showProfilePic && dentistProfileSection}
          </div>
        </div>
      )}
      {!isPatientSQSubmitted && (
        <div className="bg-white py-4 text-center">
          <Typography.Title level={4} className="!text-xl !font-bold">
            See what can be done in just 6 months
          </Typography.Title>
          <div className="m-auto max-w-[300px]">
            <ReactCompareImage handleSize={40} leftImage={CaseStudyBefore} rightImage={CaseStudyAfter} />
          </div>
          <div className="my-6">
            <Typography.Title level={4} className="px-2 !text-xl !font-bold !text-[#3568D4]">
              Want a brighter, straighter, or healthier smile? Unlock your dream smile in just 2 minutes!
            </Typography.Title>
          </div>
        </div>
      )}
      <div className="bg-[#f1f2f6]">
        <div className="m-auto w-full max-w-[1076px] md:pt-12">
          <Card bodyStyle={{ padding: isPatientSQSubmitted ? 0 : 24 }}>
            {isPatientSQSubmitted && <PreviewSentSQ />}
            {!isPatientSQSubmitted && (sharedLinkDentistData || interestedPatient) && (
              <div className="mt-2">
                <PatientSQForm sqLinkId={params?.id} interestedPatient={interestedPatient} token={token} />
                <PoweredBy32Co />
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};
