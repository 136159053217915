import packageJson from '../../package.json';

const { REACT_APP_IPINFO_TOKEN, REACT_APP_CALENDLY_API_TOKEN, REACT_APP_ENV, REACT_APP_GOOGLE_MAPS_API_TOKEN } =
  process.env;

export const GDP_SIGNUP_FORM_STEPS = 4;
export const DESIGNER_SIGNUP_FORM_STEPS = 2;
export const SPECIALIST_SIGNUP_FORM_STEPS = 2;
export const IPINFO_TOKEN = REACT_APP_IPINFO_TOKEN;
export const CALENDLY_API_TOKEN = REACT_APP_CALENDLY_API_TOKEN;
export const APP_ENV = REACT_APP_ENV || 'dev';
export const APP_VERSION = packageJson.version;
export enum USER_ROLES { // TODO: need to change name as enum convention
  DENTIST = 'dentist',
  DESIGNER = 'designer',
  SPECIALIST = 'specialist',
  MANUFACTURER = 'manufacturer',
  ADMIN = 'admin',
  TECHNICIAN = 'technician',
}
export const GOOGLE_MAPS_API_TOKEN = REACT_APP_GOOGLE_MAPS_API_TOKEN;

export enum SUBMISSION_SERVICE_TYPE {
  DUO = 'DUO',
  SOLO = 'SOLO',
}

export enum SUBMISSION_STATUS_TYPE {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  AWAITING_FILES = 'AWAITING_FILES',
  AWAITING_DESIGNER_ACCEPTANCE = 'AWAITING_DESIGNER_ACCEPTANCE',
  AWAITING_STL_VERIFICATION = 'AWAITING_STL_VERIFICATION',
  AWAITING_SPECIALIST_ACCEPTANCE = 'AWAITING_SPECIALIST_ACCEPTANCE',
  AWAITING_INSTRUCTION = 'AWAITING_INSTRUCTION',
  ON_HOLD = 'ON_HOLD',
  NOT_SUITABLE_PENDING = 'NOT_SUITABLE_PENDING',
  AWAITING_DESIGN = 'AWAITING_DESIGN',
  AWAITING_ADVICE = 'AWAITING_ADVICE',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  AWAITING_DENTIST_APPROVAL = 'AWAITING_DENTIST_APPROVAL',
  APPROVED = 'APPROVED',
  REVIEW_STL_FILES = 'REVIEW_STL_FILES',
  WITH_MANUFACTURER = 'WITH_MANUFACTURER',
  WITH_32CO = 'WITH_32CO',
  IN_TREATMENT = 'IN_TREATMENT',
  NOT_SUITABLE = 'NOT_SUITABLE',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  SHIPPED = 'SHIPPED',
  DELETED = 'DELETED',
}

export const API_STATUS_CODES = {
  BAD_REQUEST: 400,
  SUCCESS: 200,
  FORBIDDEN: 403,
};

export enum ESubmissionStatus {
  ALL = '',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  AWAITING_FILES = 'AWAITING_FILES',
  SUBMITTED = 'SUBMITTED',
  AWAITING_DESIGNER_ACCEPTANCE = 'AWAITING_DESIGNER_ACCEPTANCE',
  AWAITING_STL_VERIFICATION = 'AWAITING_STL_VERIFICATION',
  AWAITING_SPECIALIST_ACCEPTANCE = 'AWAITING_SPECIALIST_ACCEPTANCE',
  AWAITING_INSTRUCTION = 'AWAITING_INSTRUCTION',
  ON_HOLD = 'ON_HOLD',
  NOT_SUITABLE_PENDING = 'NOT_SUITABLE_PENDING',
  AWAITING_DESIGN = 'AWAITING_DESIGN',
  AWAITING_ADVICE = 'AWAITING_ADVICE',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  AWAITING_DENTIST_APPROVAL = 'AWAITING_DENTIST_APPROVAL',
  APPROVED = 'APPROVED',
  REVIEW_STL_FILES = 'REVIEW_STL_FILES',
  WITH_MANUFACTURER = 'WITH_MANUFACTURER',
  WITH_32CO = 'WITH_32CO',
  SHIPPED = 'SHIPPED',
  IN_TREATMENT = 'IN_TREATMENT',
  COMPLETED = 'COMPLETED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  EXPIRED = 'EXPIRED',
}

// NOTIFICATION TYPES AS PER BACKEND
export const NOTIFICATION_TYPE = {
  SUBMISSION_REJECTED: 'case_rejected',
  AWAITING_APPROVAL_ADMIN: 'awaiting_approval_admin',
  NEW_PATIENT_SUBMISSION: 'new_patient_submission',
  NEW_SUBMISSION: 'new_case',
  NEW_ORDER: 'new_order',
  NEW_MESSAGE: 'new_message',
  NEW_USER_SIGNUP: 'new_user_signed_up',
  SUBMISSION_SUBMITTED: 'submission_submitted',
  AWAITING_APPROVAL: 'awaiting_approval',
  PROPOSAL_APPROVED: 'proposal_approved',
  ORDER_SHIPPED: 'order_shipped',
  ORDER_APPROVED: 'order_approved',
  ORDER_DELAYED: 'order_delayed',
  CASE_DELAYED: 'case_delayed',
  THANK_YOU: 'thank_you',
  CASE_NOT_SUITABLE: 'case_not_suitable',
  REFERRAL_APPROVED_SUBMISSION: 'referral_approved_case',
  REVISION_REQUEST: 'revision_request',
  REFERRALS: 'referrals',
  NEW_TREATMENT_DESIGN: 'newSubmission',
  PATIENT_PROPOSAL: 'patient_proposal',
  EDUCATION_CONTENT: 'education_content',
  CASE_INSTRUCTION_READY: 'case_instructions_ready',
  NEW_STL_UPLOADED: 'new_stl_uploaded',
  CASE_NOT_SUITABLE_REVIEW_PENDING: 'case_not_suitable_review_pending',
  REPLACEMENT_ORDER_SUBMITTED: 'replacement_order_submitted',
  REPLACEMENT_ORDER_APPROVED: 'replacement_order_approved',
  REPLACEMENT_ORDER_SHIPPED: 'replacement_order_shipped',
  REPLACEMENT_ORDER_ACCEPTED: 'replacement_order_accepted',
  REPLACEMENT_ORDER_REJECTED: 'replacement_order_rejected',
  REPLACEMENT_REPORT_ACCEPTED: 'replacement_report_accepted',
  ANIMATION_UPDATED: 'animation_updated',
  RETAINER_ORDER_SUBMITTED: 'retainer_order_submitted',
  RETAINER_ORDER_SHIPPED: 'retainer_order_shipped',
  TECHNICIAN_RETAINER_ORDER: 'technician_retainer_order',
  RETAINER_ORDER: 'retainer_order',
};

export enum ESTLDownloadStatues {
  NOT_STARTED = 'Not Started',
  PENDING = 'Pending',
  DOWNLOADING_STLS = 'Downloading STls',
  CREATING_ARCHIVE = 'Creating Archive',
  ARCHIVE_UPLOADING = 'Archive Uploading',
  COMPLETED = 'Completed',
  CANCEL = 'Cancel',
  ERROR = 'Error',
}

export enum EContactPreference {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
