import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import handSvg from 'assets/images/auth/hand.svg';
import './signup-success.styles.less';

export const SignupSuccess = () => (
  <AuthLayout showGotoSignin background2>
    <div className="m-auto mt-20 flex items-start" style={{ letterSpacing: '0.5px', maxWidth: '600px' }}>
      <div className="mr-6">
        <img src={handSvg} alt="key" />
      </div>

      <div className="flex-1">
        <p className="mb-2 text-base font-semibold text-darkBlueColor">
          Seal the deal with a click..(by verifying your email)
        </p>
        <p style={{ color: '#6B7D8B' }} className="mb-20">
          Please check your email and spam folders to verify your email and log straight into your platform to book your
          onboarding!
        </p>
        <p style={{ color: '#6B7D8B' }}>
          Please contact{' '}
          <a href="mailto:upport@32co.com" style={{ color: '#EB1F6D' }}>
            support@32co.com
          </a>{' '}
          if you have any questions.
        </p>
      </div>
    </div>
  </AuthLayout>
);
