import { TStatusRecord } from 'components/status-tag/status-tag';
import { IAdvice } from '../specialist-api/types';
import { SUBMISSION_PLAN_TYPE } from 'modules/gdp/case-submission/utils';

export enum TREATMENT_DESIGN_STATUS {
  AWAITING_DESIGN_APPROVAL = 'AWAITING_DESIGN_APPROVAL',
  APPROVED = 'APPROVED',
  REVISION_REQUESTED = 'REVISION_REQUESTED',
  NO_FURTHER_ACTION = 'NO_FURTHER_ACTION',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
}

export const TREATMENT_DESIGN_STATUS_NAME_MAPPING: TStatusRecord = {
  AWAITING_DESIGN_APPROVAL: {
    bgColor: '#3F69FF',
    text: 'Awaiting Approval',
  },
  REVIEW_DESIGN: {
    bgColor: '#3F69FF',
    text: 'Review Design',
  },
  REVISION_REQUESTED: {
    bgColor: '#00B1FF',
    text: 'Revision Requested',
  },
  NO_FURTHER_ACTION: {
    bgColor: '#999',
    text: 'No Further Action',
  },
  APPROVED: {
    bgColor: '#49DCB2',
    text: 'Approved',
  },
};

export const SPECIALIST_SUBMISSION_STATUS_NAME_MAPPING: TStatusRecord = {
  SUBMIT_INSTRUCTION: {
    bgColor: '#3F69FF',
    text: 'Submit Instruction',
  },
  ON_HOLD: {
    bgColor: '#8465FC',
    text: 'On Hold',
  },
  NOT_SUITABLE: {
    bgColor: '#999',
    text: 'Not Suitable',
  },
  AWAITING_DESIGN: {
    bgColor: '#E0FFFF',
    textColor: '#000000',
    text: 'Awaiting Design',
  },
  REVIEW_DESIGN: {
    bgColor: '#3F69FF',
    text: 'Review Design',
  },
  APPROVED: {
    bgColor: '#49DCB2',
    text: 'Approved',
  },
  COMPLETED: {
    bgColor: '#49DCB2',
    text: 'Completed',
  },
  EXPIRED: {
    bgColor: '#DC5536',
    text: 'Expired',
  },
  ARCHIVED: {
    bgColor: '#999',
    text: 'Archived',
  },
};

export interface ITreatmentDesignImage {
  url: string;
  type?: string;
  name: string;
}

export interface ITreatmentDesign {
  id: string;
  status: TREATMENT_DESIGN_STATUS;
  createdAt: string;
  updatedAt: string;
  arches: string;
  totalSteps: number;
  lowerStageNumber: number;
  upperStageNumber: number;
  attachment: boolean;
  designNumber: number;
  toothAnimationUrl: string;
  havingIpr: boolean;
  iprImages: ITreatmentDesignImage[];
  iprStages: string[];
  attachmentStages: string[];
  toothMovementTableImage: { url: string; name: string };
  elastics: string[];
  comment: string;
  stls: any[];
  specialistTreatmentDesignStatus: TREATMENT_DESIGN_STATUS;
  auxiliaryDetail: string;
  additionalAttachments: { url: string }[];
  advice: IAdvice;
  patientProposal: string;
  isRevisionRequested?: boolean;
  designerTreatmentDesignStatus: TREATMENT_DESIGN_STATUS;
  havingAttachment: boolean;
  havingElastic: boolean;
  dentistDesignNumber?: number;
  submission?: string;
}

export interface ITreatmentDesignsResponse {
  docs: ITreatmentDesign[];
}
export interface ITreatmentDesignsRequest {
  page: number;
  perPage: number;
  submissionId: string;
}

export interface IUploadSTLRequest {
  stls: any[];
  treatmentDesignId: string;
}

export interface ICreateAnimationUrl {
  animationUrl: string;
  submissionId?: string;
}

export type TPreviousIprImagesResponse = {
  submission: string;
  planType: SUBMISSION_PLAN_TYPE;
  refinementNumber: string;
  treatmentDesigns: {
    iprImages: ITreatmentDesignImage[];
  }[];
}[];
