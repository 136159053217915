import { StyleSheet, Font } from '@react-pdf/renderer';
import tooth11 from 'assets/images/teeth-updated/11.png';
import tooth12 from 'assets/images/teeth-updated/12.png';
import tooth13 from 'assets/images/teeth-updated/13.png';
import tooth14 from 'assets/images/teeth-updated/14.png';
import tooth15 from 'assets/images/teeth-updated/15.png';
import tooth16 from 'assets/images/teeth-updated/16.png';
import tooth17 from 'assets/images/teeth-updated/17.png';
import tooth18 from 'assets/images/teeth-updated/18.png';
import tooth21 from 'assets/images/teeth-updated/21.png';
import tooth22 from 'assets/images/teeth-updated/22.png';
import tooth23 from 'assets/images/teeth-updated/23.png';
import tooth24 from 'assets/images/teeth-updated/24.png';
import tooth25 from 'assets/images/teeth-updated/25.png';
import tooth26 from 'assets/images/teeth-updated/26.png';
import tooth27 from 'assets/images/teeth-updated/27.png';
import tooth28 from 'assets/images/teeth-updated/28.png';
import tooth31 from 'assets/images/teeth-updated/31.png';
import tooth32 from 'assets/images/teeth-updated/32.png';
import tooth33 from 'assets/images/teeth-updated/33.png';
import tooth34 from 'assets/images/teeth-updated/34.png';
import tooth35 from 'assets/images/teeth-updated/35.png';
import tooth36 from 'assets/images/teeth-updated/36.png';
import tooth37 from 'assets/images/teeth-updated/37.png';
import tooth38 from 'assets/images/teeth-updated/38.png';
import tooth41 from 'assets/images/teeth-updated/41.png';
import tooth42 from 'assets/images/teeth-updated/42.png';
import tooth43 from 'assets/images/teeth-updated/43.png';
import tooth44 from 'assets/images/teeth-updated/44.png';
import tooth45 from 'assets/images/teeth-updated/45.png';
import tooth46 from 'assets/images/teeth-updated/46.png';
import tooth47 from 'assets/images/teeth-updated/47.png';
import tooth48 from 'assets/images/teeth-updated/48.png';
import Larsseit from 'assets/fonts/Larsseit.otf';
import LarsseitMedium from 'assets/fonts/Larsseit-Medium.otf';
import GilroyLight from 'assets/fonts/gilroy/Gilroy-Light.otf';
import GilroyRegular from 'assets/fonts/gilroy/Gilroy-Regular.otf';
import GilroyMedium from 'assets/fonts/gilroy/Gilroy-Medium.otf';
import GilroySemiBold from 'assets/fonts/gilroy/Gilroy-SemiBold.otf';
import GilroyBold from 'assets/fonts/gilroy/Gilroy-Bold.otf';

Font.register({
  family: 'Larsseit',
  fontStyle: 'normal',
  fonts: [
    {
      src: LarsseitMedium,
      fontWeight: 600,
    },
    {
      src: Larsseit,
      fontWeight: 300,
    },
  ],
});

Font.register({
  family: 'Gilroy',
  fonts: [
    {
      src: GilroyLight,
      fontWeight: 300,
    },
    {
      src: GilroyRegular,
      fontWeight: 400,
    },
    {
      src: GilroyMedium,
      fontWeight: 500,
    },
    {
      src: GilroySemiBold,
      fontWeight: 600,
    },
    {
      src: GilroyBold,
      fontWeight: 700,
    },
  ],
});

// Create styles
export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#f8f9fd',
    fontFamily: 'Gilroy',
  },
  header: {
    margin: 10,
    marginTop: -10,
    padding: 10,
    height: 90,
    flexDirection: 'row',
    alignItems: 'center',
  },
  author: {
    marginTop: -20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    border: '1px',
    borderColor: '#d9d9d9',
    backgroundColor: 'white',
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 16,
    paddingHorizontal: 20,
    paddingVertical: 15,
    fontWeight: 700,
    borderBottom: '1px',
    borderBottomColor: '#d9d9d9',
  },
  sectionSubHeader: {
    borderBottom: '1px',
    borderBottomColor: '#d9d9d9',
    fontSize: 16,
    fontWeight: 600,
    marginHorizontal: 20,
    marginVertical: 15,
  },
  keyValue: {
    marginHorizontal: 20,
    marginVertical: 15,
    fontFamily: 'Larsseit',
  },
  h1: {
    fontSize: 20,
    padding: 20,
    fontWeight: 700,
  },
  label: {
    fontSize: 14,
    marginBottom: 4,
    fontWeight: 600,
    color: '#09263d',
    width: '100%',
    wordBreak: 'break-word',
  },
  labelSingle: {
    fontSize: 14,
    marginBottom: 4,
    marginLeft: 20,
    fontWeight: 600,
    color: '#09263d',
  },
  value: {
    fontSize: 14,
    color: '#686c80',
    fontWeight: 300,
    wordBreak: 'break-word',
  },
  valueSingle: {
    fontSize: 14,
    color: '#686c80',
    fontWeight: 300,
    marginLeft: 20,
  },
  description: {
    fontSize: 10,
    color: '#686c80',
    alignSelf: 'center',
    marginTop: '2px',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 20,
  },
  image: {
    width: '40%',
    marginVertical: 10,
  },
  fallbackImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    width: 100,
    height: 100,
    marginTop: 20,
    backgroundColor: '#87d068',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '40px',
  },
});

export const getYesNoValue = (value?: boolean) => (value === true ? 'Yes' : value === false ? 'No' : '-');

export interface ITooth {
  url: string;
  value: string;
  label: string;
}

export const TEETH_CONFIG: { upper: ITooth[]; lower: ITooth[] } = {
  upper: [
    { url: tooth18, value: '1.8', label: 'UR8' },
    { url: tooth17, value: '1.7', label: 'UR7' },
    { url: tooth16, value: '1.6', label: 'UR6' },
    { url: tooth15, value: '1.5', label: 'UR5' },
    { url: tooth14, value: '1.4', label: 'UR4' },
    { url: tooth13, value: '1.3', label: 'UR3' },
    { url: tooth12, value: '1.2', label: 'UR2' },
    { url: tooth11, value: '1.1', label: 'UR1' },

    { url: tooth21, value: '2.1', label: 'UL1' },
    { url: tooth22, value: '2.2', label: 'UL2' },
    { url: tooth23, value: '2.3', label: 'UL3' },
    { url: tooth24, value: '2.4', label: 'UL4' },
    { url: tooth25, value: '2.5', label: 'UL5' },
    { url: tooth26, value: '2.6', label: 'UL6' },
    { url: tooth27, value: '2.7', label: 'UL7' },
    { url: tooth28, value: '2.8', label: 'UL8' },
  ],
  lower: [
    { url: tooth48, value: '4.8', label: 'LR8' },
    { url: tooth47, value: '4.7', label: 'LR7' },
    { url: tooth46, value: '4.6', label: 'LR6' },
    { url: tooth45, value: '4.5', label: 'LR5' },
    { url: tooth44, value: '4.4', label: 'LR4' },
    { url: tooth43, value: '4.3', label: 'LR3' },
    { url: tooth42, value: '4.2', label: 'LR2' },
    { url: tooth41, value: '4.1', label: 'LR1' },

    { url: tooth31, value: '3.1', label: 'LL1' },
    { url: tooth32, value: '3.2', label: 'LL2' },
    { url: tooth33, value: '3.3', label: 'LL3' },
    { url: tooth34, value: '3.4', label: 'LL4' },
    { url: tooth35, value: '3.5', label: 'LL5' },
    { url: tooth36, value: '3.6', label: 'LL6' },
    { url: tooth37, value: '3.7', label: 'LL7' },
    { url: tooth38, value: '3.8', label: 'LL8' },
  ],
};
