import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { Button, Image, Typography } from 'antd';
import { getImage, getShortcut, saveUserAcceptedBookmarkSuggestion, saveUserDeclinedBookmarkSuggestion } from './utils';

import styles from './add-to-bookmarks.module.less';

import classNames from 'classnames';

enum AddToBookmarksModalContent {
  QuestionScreen,
  InstructionsScreen,
}

interface IAddToBookmarksModalProps {}

export const AddToBookmarksModal = NiceModal.create<IAddToBookmarksModalProps>(() => {
  const [screen, setScreen] = useState(AddToBookmarksModalContent.QuestionScreen);

  const modal = useModal();

  const handleAcceptBookmarkSuggestion = () => {
    setScreen(AddToBookmarksModalContent.InstructionsScreen);

    saveUserAcceptedBookmarkSuggestion();
  };

  const handleDeclineBookmarkSuggestion = () => {
    modal.resolve();
    modal.hide();
    modal.remove();

    saveUserDeclinedBookmarkSuggestion();
  };

  const content = (() => {
    switch (screen) {
      case AddToBookmarksModalContent.QuestionScreen: {
        return {
          width: 400,
          title: 'Would you like to bookmark 32Co?',
          body: (
            <>
              <Typography.Paragraph className="text-base">
                If you haven't bookmarked us already, would you like to add 32Co to your bookmarks to find us more
                easily?
              </Typography.Paragraph>
              <div className="mt-8 flex justify-evenly">
                <Button
                  id="add-bookmarks-yes"
                  type="primary"
                  size="large"
                  onClick={handleAcceptBookmarkSuggestion}
                  className="w-24"
                >
                  Yes
                </Button>
                <Button id="add-bookmarks-no" size="large" onClick={handleDeclineBookmarkSuggestion}>
                  No, thank you
                </Button>
              </div>
            </>
          ),
        };
      }
      case AddToBookmarksModalContent.InstructionsScreen: {
        const shortcut = getShortcut();
        const imageSrc = getImage();

        return {
          width: 700,
          title: 'How to:',
          body: (
            <div className={classNames('loadevent flex items-center justify-evenly', styles.minHeight200)}>
              {shortcut && (
                <div className={classNames('flex flex-col items-center justify-center', styles.width15pr)}>
                  <Typography.Title level={5}>Shortcut</Typography.Title>
                  <Typography.Title level={4} className={classNames('text-gray-300', styles.shortcut)}>
                    {shortcut}
                  </Typography.Title>
                </div>
              )}
              {shortcut && imageSrc && <Typography.Title className="ml-2 mr-2 text-gray-200">or</Typography.Title>}
              {imageSrc && (
                <div className={classNames('flex flex-col items-center justify-center', styles.width35pr)}>
                  <Typography.Title level={5}>Mouse</Typography.Title>
                  <div className={styles.minHeight60}>
                    <Image src={imageSrc!} width={250} />
                  </div>
                </div>
              )}
            </div>
          ),
        };
      }
      default:
        return {};
    }
  })();

  return (
    <NiceAntModal
      width={content.width}
      modal={modal}
      onCancel={() => {
        if (screen === AddToBookmarksModalContent.InstructionsScreen) {
          modal.resolve();
          modal.hide();
          modal.remove();
        } else if (screen === AddToBookmarksModalContent.QuestionScreen) {
          handleDeclineBookmarkSuggestion();
        }
      }}
      title={content.title}
    >
      {content.body}
    </NiceAntModal>
  );
});
