import { CheckOutlined } from '@ant-design/icons';
import soniaCeoPng from 'assets/images/auth/sonia-ceo.png';

export const FistSlide = () => (
  <div className="flex max-h-[calc(100vh-145px)] flex-col items-baseline overflow-y-auto text-white">
    <div className="flex flex-col">
      <img src={soniaCeoPng} alt="aligner" className="h-[180px] lg:h-[214px] " />
      <div className="self-center">Sonia Szamocki - Founder</div>
    </div>
    <div className="py-4 text-2xl font-semibold lg:text-3xl">The 32Co Mission</div>
    <p className="text-base lg:text-lg">To fix the broken clear aligner industry with:</p>
    <p className="text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      Expert support, including 1:1 mentorship on cases
    </p>
    <p className="text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      Premium quality, wholesale prices from day 1
    </p>
    <p className="text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      Continuous free training for all
    </p>
    <p className="text-base lg:text-lg">We built 32Co because Dentists and Patients deserve better.</p>
  </div>
);
