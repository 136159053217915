import { Avatar, Col, Row } from 'antd';
import profAmaPng from 'assets/images/auth/prof-Ama.png';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import ReactConfetti from 'react-confetti';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { NiceParticles } from 'components/nice-particles/nice-particles';
import { useAppSelector } from 'hooks/store';
import { selectChristmasEnabled } from 'redux/themeSlice';
import { EParticlesType } from 'components/nice-particles/particles-options';

export const SignUpSuccessModal = NiceModal.create(() => {
  const modal = useModal();
  const { user } = useAuth();
  const [showConfetti, onShowConfetti] = useState(true);
  const isChristmasTheme = useAppSelector(selectChristmasEnabled);

  return (
    <NiceAntModal
      modal={modal}
      width={800}
      maskClosable={false}
      bodyStyle={{ padding: '54px 36px 36px 36px' }}
      onCancel={() => {
        modal.resolve(false);
        modal.hide();
      }}
    >
      {showConfetti &&
        (isChristmasTheme ? (
          <div className="absolute top-0 h-full">
            <NiceParticles id="tsparticles-confetti" optionType={EParticlesType.christmasConfetti} />
          </div>
        ) : (
          <ReactConfetti
            width={800}
            tweenDuration={200}
            recycle={false}
            numberOfPieces={1000}
            onConfettiComplete={() => onShowConfetti(false)}
          />
        ))}
      <Row gutter={[36, 36]}>
        <Col span={24} md={8}>
          <Avatar size={184} src={profAmaPng} />
        </Col>
        <Col span={24} md={16}>
          <div className="mb-6 text-3xl font-bold text-darkBlueColor">Thank you {user?.firstName}</div>
          <p className="mb-6 text-sm" style={{ color: '#333' }}>
            <b>You’re logged in; you can now:</b>
            <ul className="mb-4 mt-1">
              <li className="mb-1">Watch our Quick Start Videos on how to use 32Co</li>
              <li className="mb-1">Learn by reading your first personalised educational content</li>
              <li>Understand our pricing with the case calculator.</li>
            </ul>
            <b>What’s next? Book a 1:1 Call with our team.</b>
            <div className="mt-1">
              We’ll sure you’ve got everything you need to submit a case or sign-up for our courses.
            </div>
            <br />
            Please book a time in our diary - we work late so I’m sure we can find a time that works.
            <br />
            <br />
            p.s. Please check your inbox so we can verify your email.
          </p>
        </Col>
      </Row>
      <div className="mt-1 text-right">
        <NiceButton
          onClick={() => {
            modal.resolve(true);
            modal.hide();
          }}
          niceType="orange"
        >
          Book a 1:1 Call
        </NiceButton>
      </div>
    </NiceAntModal>
  );
});
