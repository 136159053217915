import { Avatar } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import profAmaPng from 'assets/images/auth/prof-Ama.png';
import { AuthButtonComponent } from '../../components/auth-button/auth-button.component';

const DIV = styled.div`
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  margin-top: -40px;
`;

interface IGreetingProps {
  onNext: () => void;
}
export const Greeting: FC<IGreetingProps> = ({ onNext }) => (
  <DIV>
    <div className="flex animate-fadeIn flex-col xl:flex-row">
      <div style={{ width: 184 }} className="m-auto md:m-0 md:mr-16">
        <Avatar size={184} className="mt-8 mb-6" src={profAmaPng} />
      </div>
      <div className="text-center font-medium md:text-left">
        <div className="mb-6 text-3xl font-bold text-darkBlueColor">👋 Welcome to 32Co</div>
        <div className="mb-8 text-base font-bold" style={{ color: '#2d2d2d' }}>
          I’m Prof. Ama Johal, the Clinical Lead at 32Co. It’s time to get you providing Clear Aligners differently.
        </div>
        <p className="mb-6 text-sm" style={{ color: '#333' }}>
          Please tell us a little bit about yourself and what you‘re looking for. You’ll gain immediate access to the
          platform with our educational content and case calculator for assessing case suitability and price estimates.
        </p>
        <p className="mb-6 text-sm" style={{ color: '#333' }}>
          If you get stuck contact us at{' '}
          <a href="mailto:support@32co.com">
            <b className="c-black">support@32co.com</b>
          </a>
          , we’re here to help.
        </p>
        <div className="flex justify-center xl:justify-start">
          <AuthButtonComponent onClick={onNext}>Get Started</AuthButtonComponent>
        </div>
      </div>
    </div>
  </DIV>
);
