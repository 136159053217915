import React, { Suspense } from 'react';
import { USER_ROLES } from '../../config/constants';
import { IRoute } from 'interface/IRoute';
import { PageLoader } from 'components/page-loader/page-loader.component';

const LazyDashboardComponent = React.lazy(() => import('./technician-dashboard/technician-dashboard'));
const LazyPatientsComponent = React.lazy(() => import('modules/designer/patients/patients.component'));
const LazyProfileComponent = React.lazy(() => import('components/profile/profile'));

export const technicianRoutes: IRoute[] = [
  {
    path: `/${USER_ROLES.TECHNICIAN}`,
    exact: true,
    name: 'Dashboard',
    key: 'dashboard_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyDashboardComponent />
      </Suspense>
    ),
    role: USER_ROLES.TECHNICIAN,
  },
  {
    path: `/${USER_ROLES.TECHNICIAN}/patients`,
    exact: false,
    name: 'Patients',
    key: 'patients_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyPatientsComponent />
      </Suspense>
    ),
    role: USER_ROLES.TECHNICIAN,
  },
  {
    path: `/${USER_ROLES.TECHNICIAN}/profile`,
    exact: true,
    name: 'Profile',
    key: 'profile_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyProfileComponent userType={USER_ROLES.TECHNICIAN} />
      </Suspense>
    ),
    role: USER_ROLES.TECHNICIAN,
  },
];

const nestedRoutes = [
  `/${USER_ROLES.TECHNICIAN}/patients/:submissionId`,
  `/${USER_ROLES.TECHNICIAN}/patients/:submissionId/design/:designId`,
  `/${USER_ROLES.TECHNICIAN}/patients/:submissionId/retainer/:retainerId`,
];
export const technicianRoutesToCheck = technicianRoutes.map((route) => route.path).concat(...nestedRoutes);
