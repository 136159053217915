import { FC, useState } from 'react';
import { Button, Popconfirm, Progress, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { CloseOutlined, FileZipOutlined } from '@ant-design/icons';
import { notificationApiError } from 'utils/ui';
import { useDeleteDownloadingStlsAsZipMutation } from 'services/api';
import { useDownloadQuery } from './use-download-query/useDownloadQuery';
import { useAppDispatch } from 'hooks/store';
import { removeDownloadProgress, setIsIntervalRunning } from 'redux/downloadStlsSlice';

type TPros = {
  downloadCode: string;
  backUrl: string;
  payload: any;
};

export const DownloadProgressItem: FC<TPros> = ({ downloadCode, backUrl, payload }) => {
  const { downloadingPercent, downloadedData, downloadKey, isFinished } = useDownloadQuery(downloadCode, payload);
  const [cancelDownload, { isLoading }] = useDeleteDownloadingStlsAsZipMutation();
  const [hovered, setHovered] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onClose = async () => {
    try {
      await cancelDownload({ downloadId: downloadedData.id, treatmentDesign: downloadedData.treatmentDesign }).unwrap();
      dispatch(removeDownloadProgress(downloadCode));
      dispatch(setIsIntervalRunning(false));
    } catch (err) {
      notificationApiError(err);
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className="download-progress-box flex flex-row items-center justify-between"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex flex-row gap-4">
        <FileZipOutlined style={{ fontSize: '25px', color: '#C7C7C7', marginTop: 5 }} />
        <div className="mt-1 flex max-w-[200px] flex-row items-center">
          {downloadKey === 'TreatmentDesignStls' && payload?.patientFullName && (
            <Tooltip overlayClassName="capitalize" title={`Go to ${payload.patientFullName}`}>
              <Link to={backUrl} className="format capitalize">
                {payload.patientFullName}
              </Link>
            </Tooltip>
          )}

          {downloadKey === 'Common' && (
            <Tooltip overlayClassName="capitalize" title="Go to source page">
              <Link to={backUrl} className="format capitalize">
                {payload.fileName}
              </Link>
            </Tooltip>
          )}
        </div>
      </div>

      <div className="relative flex">
        <Progress
          type="circle"
          percent={isFinished ? 100 : downloadingPercent}
          strokeColor="#49dcb2"
          strokeWidth={10}
          width={30}
        />
        {hovered && downloadingPercent > 0 && downloadingPercent < 100 && (
          <div className="close-button absolute right-[3px] top-[3px] z-50 !rounded-2xl bg-white">
            <Popconfirm title="Are you sure you want to cancel the download?" onConfirm={onClose}>
              <Button
                onMouseEnter={(event) => event.stopPropagation()}
                type="text"
                loading={isLoading}
                className="h-[24px] p-1 pt-[2px]"
              >
                <CloseOutlined className="text-sm" />
              </Button>
            </Popconfirm>
          </div>
        )}
      </div>
    </div>
  );
};
