import { FC } from 'react';
import { Button, Form, Input } from 'antd';
import { useAddRevisionRequestMutation } from 'services/revision-requests-api/endpoints';
import { notificationApiError } from 'utils/ui';
import { TUserTypeUpperCase } from 'modules/auth/services/type';

const { TextArea } = Input;

type TProps = {
  userRole: TUserTypeUpperCase;
  designId: string;
  submissionId: string;
};

export const RevisionRequestsForm: FC<TProps> = ({ userRole, designId, submissionId }) => {
  const [form] = Form.useForm();
  const [addRevisionRequest, { isLoading: addingModificationRequest }] = useAddRevisionRequestMutation();

  const onFinish = async (values: any) => {
    const formData = {
      submission: submissionId,
      treatmentDesign: designId,
      comment: values.revision_request,
    };
    try {
      await addRevisionRequest(formData).unwrap();
      form.resetFields();
    } catch (e) {
      notificationApiError(e);
    }
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" size="large">
      <Form.Item
        name="revision_request"
        label={<b>If you'd like to request edits please provide details here</b>}
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <TextArea rows={2} placeholder="Enter here..." />
      </Form.Item>
      <div className="flex justify-end">
        <Form.Item className="mb-0">
          <Button
            loading={addingModificationRequest}
            className="custom-shadow rounded-standard"
            type="primary"
            htmlType="submit"
            style={{ minWidth: 150 }}
            id={userRole === 'SPECIALIST' ? 'submit-treatment-proposal-detail' : 'submit-revision-request'} // TODO: GR update 2e2 test ids to one
          >
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
