import { Button } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import logo from 'assets/images/clear_aligner_logo.png';
import './sku-card-market.styles.less';
import { ISkuType } from 'services/sku-api/types';

interface ISkuCardMarketProps {
  sku: ISkuType;
  currencySymbol?: string;
  onViewDetail?: (sku: ISkuType) => void;
}

export const SkuCardMarket: FC<ISkuCardMarketProps> = ({ sku, currencySymbol = '$', onViewDetail }) => (
  <div className="sku-card-market-container flex h-full shadow-custom3">
    <div className="flex w-full flex-col p-2">
      <div className="flex items-center justify-between">
        <img src={sku.logo?.url || logo} alt="32Co logo" style={{ maxHeight: 30, maxWidth: 'calc(100% - 120px)' }} />
        {sku?.discounts?.length > 0 && <div className="offer-badge mb-0 ml-3 whitespace-nowrap">Offers Available</div>}
      </div>
      <div className="clear-aligner-bg flex-1">
        <div className="flex">
          <div className="text-lg">
            <strong>{sku?.name}</strong>
          </div>
        </div>
        <div className="pt-5">
          <ul className="pl-3">
            {sku?.description &&
              sku.description.split?.('/').map((element: any, decsIndex: number) => {
                if (decsIndex < 3)
                  return (
                    <li key={element} className="mb-1 text-base font-medium">
                      {element}
                    </li>
                  );
                return null;
              })}
          </ul>
        </div>
      </div>
      {sku?.estDelivery && (
        <div className="flex w-full flex-row items-center justify-end">
          <div className="font-xs text-[#9B9B9B]">Est Delivery</div>
          <div className="ml-1 text-sm font-bold">{moment(sku?.estDelivery).format('Do MMM')}</div>
        </div>
      )}
      {onViewDetail && (
        <div className="flex w-full flex-row items-end justify-between">
          <div className="flex flex-col">
            <div className="font-medium text-[#9B9B9B]">Price</div>
            <div className="text-2xl font-bold">
              {currencySymbol}
              {sku?.price?.toFixed(2) ?? '0.00'}
            </div>
          </div>

          <Button
            type="default"
            size="large"
            className="btn-default sku-card-market__view-details"
            onClick={(event) => {
              event.stopPropagation();
              onViewDetail?.(sku);
            }}
            id="view-sku-card-details"
          >
            Order
          </Button>
        </div>
      )}
    </div>
  </div>
);
