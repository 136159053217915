import { api, VERSION, formatUrl } from '../api';
import { IPerformanceResponse } from './types';

export interface IThankyouMessage {
  id: string;
  from: {
    fullName: string;
    profilePic: string;
    id: string;
  };
  submission: {
    id: string;
    patient: {
      id: string;
      fullName: string;
    };
  };
  message: string;
  chatroom: string;
}

type TThankyouMessages = {
  docs: IThankyouMessage[];
  total: number;
}[];

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThankYouMessageForSpecialist: build.query<TThankyouMessages, any>({
      query: ({ page, perPage }) => {
        let paramsString = '';
        if (page) paramsString += `page=${page}`;
        if (perPage) paramsString += `&perPage=${perPage}`;
        return formatUrl(`/dashboard/thank-you-messages?${paramsString}`);
      },
    }),
    getThankYouMessageBySubmission: build.query<TThankyouMessages, any>({
      query: ({ submissionId }) => formatUrl(`/dashboard/thank-you-messages/${submissionId}`),
    }),
    updateLastActiveHubspot: build.mutation<any, any>({
      query() {
        return {
          url: `/${VERSION}/user/profiles/last-active`,
          method: 'PUT',
          body: {},
        };
      },
    }),
    getAnalyticsReferralData: build.query<any, any>({
      query: () => formatUrl(`/analytics/referral`),
    }),
    getSpecialistPerformanceData: build.query<IPerformanceResponse, void>({
      query: () => formatUrl('/dashboard/performance'),
    }),
  }),
});

export const {
  useGetThankYouMessageForSpecialistQuery,
  useGetThankYouMessageBySubmissionQuery,
  useUpdateLastActiveHubspotMutation,
  useGetAnalyticsReferralDataQuery,
  useGetSpecialistPerformanceDataQuery,
} = dashboardApi;
