import '../treatment-design.styles.less';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, Link, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Button, Typography, Skeleton, Menu, Spin, Image, Row, Col, Space, Empty, Tooltip } from 'antd';
import { ITreatmentDesign } from 'services/treatment-designs-api/types';
import { useGetTreatmentDesignsQuery } from 'services/treatment-designs-api/endpoints';
import { patientsApi } from 'services/patients-api/endpoints';
import { globalStorage } from 'services/window';
import isEmpty from 'lodash/isEmpty';
import Chip from 'components/chip/chip.component';
import { EyeOutlined } from '@ant-design/icons';
import { CLEAR_ALIGNER_PACKAGES_HELP_CONTENT } from 'modules/gdp/constants';
import { ClearAlignerOptions } from './clear-aligner-options/clear-aligner-options';
import { RevisionRequests } from 'components/revision-requests/revision-requests';
import { NiceCollapse } from 'components/commons/nice-collapse/nice-collapse';
import { SUBMISSION_SERVICE_TYPE } from 'config/constants';
import { TreatmentDesignDetailCollapse } from 'components/treatment-design-detail-collapse/treatment-design-detail-collapse';
import { useGetAllPatientProposalsQuery } from 'services/patient-proposal-api/endpoints';
import { ReactComponent as InfoSVG } from 'assets/images/info.svg';
import { ViewItem } from 'components/view-item/view-item';
import { ContentNotion } from 'components/edu-content/content-notion/content-notion';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { SUBMISSION_PLAN_TYPE } from 'modules/gdp/case-submission/utils';
import { isMobile } from 'react-device-detect';
import { SkuRecommendation } from './sku-recommendation/sku-recommendation';
import { useWindowSize } from 'hooks/useWindowSize';
import { ToothMovementAnimationCollapse } from 'components/tooth-movement-animation-collapse/tooth-movement-animation-collapse';
import { IprSchematicsCollapse } from 'components/ipr-schematics-collapse/ipr-schematics-collapse';
import { SpecialistAdviceDetailCollapse } from 'components/specialist-advice-detail-collapse/specialist-advice-detail-collapse';
import classNames from 'classnames';
import { DownloadTreatmentDesignAsPdf } from './download-treatment-design-as-pdf/download-treatment-design-as-pdf';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';
import { useAuth } from 'hooks/useAuth';
import { LinkDownload } from 'components/commons/link-download/link-download';
import { useUpdateUserProfileMutation } from 'services/api';
import { useAppDispatch } from 'hooks/store';
import { updateUserData } from 'modules/auth/authSlice';

export const CREATE_PATIENT_PROPOSAL_TOOLTIP_ID = 'CREATE_PATIENT_PROPOSAL_TOOLTIP_ID';

const getProposalQuery = (submissionId: string) => {
  if (!isEmpty(globalStorage.patientDetailScreen) && !isEmpty(globalStorage.patientDetailScreen.proposalUrlQueries)) {
    return globalStorage.patientDetailScreen.proposalUrlQueries;
  }
  return { page: 1, perPage: 20, submissionId };
};

type TParams = { designId: string; submissionId: string };

export const TreatmentDesignDetails = () => {
  const { isMd } = useWindowSize();
  const { designId, submissionId }: TParams = useParams();
  const { url } = useRouteMatch();
  const [visibleToothMovementImage, setShowPreviewToothMovementImage] = useState(false);
  const clearAlignerOptionsRef = useRef<HTMLDivElement>(null);
  const [viewClearAlignerNotionContent, setViewClearAlignerNotionContent] = useState(false);
  const { user } = useAuth();
  const { isUserViewedCreatePatientProposal } = user || {};

  const { data: submissionInfo, isLoading: caseLoading } = patientsApi.useGetSubmissionByIdQuery(submissionId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: treatmentDesignResData, isLoading: isTreatmentDesignLoading } = useGetTreatmentDesignsQuery(
    getProposalQuery(submissionId),
  );

  const [updateUserProfile] = useUpdateUserProfileMutation();
  const dispatch = useAppDispatch();

  const menuItems = useMemo(() => treatmentDesignResData?.docs || [], [treatmentDesignResData]);
  const treatmentDesign = useMemo(
    () => treatmentDesignResData?.docs?.find((pInfo: ITreatmentDesign) => pInfo.id === designId),
    [treatmentDesignResData, designId],
  );

  const { data: proposalsData, isLoading: isProposalsLoading } = useGetAllPatientProposalsQuery({
    treatmentDesign: designId,
    submission: submissionId,
    page: 1,
    perPage: 100,
  });

  const getTrackingInfo = () => ({
    'Case ID': submissionInfo?.id,
    'Patient Name': submissionInfo?.patient?.fullName,
    'Plan Type': submissionInfo?.planType,
    'Service Type': submissionInfo?.serviceType,
    'Case Status': submissionInfo?.status,
    'Practice ID': submissionInfo?.practice?.id,
    'Corporate ID': user?.associatedWithDentalCorporates?.map((corporate) => corporate.id),
  });

  useEffect(() => {
    if (submissionInfo && treatmentDesignResData) {
      trackEvent(EEventNames.TREATMENT_DESIGN_VIEWED, getTrackingInfo());
    }
    return () => {
      trackEvent(EEventNames.TREATMENT_DESIGN_VIEW_ENDED, getTrackingInfo());
    };
  }, [submissionInfo, treatmentDesignResData]);

  const menu = useMemo(
    () => (
      <Menu>
        {menuItems.map((pInfo) => (
          <Menu.Item key={pInfo.id}>
            <Link to={`/dentist/patients/${submissionId}/design/${pInfo.id}`}>Design {pInfo?.dentistDesignNumber}</Link>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [menuItems, submissionId],
  );

  const previewToothMovementImage = () => {
    setShowPreviewToothMovementImage(true);
  };

  const getIndex = () => menuItems?.find((pInfo) => pInfo.id === designId)?.dentistDesignNumber || 1;

  const renderCreatePatientProposalContent = (
    <div className="flex items-center gap-1 px-2 text-center text-black">
      <span className="text-3xl">💡</span>
      <span className="text-content">
        Create a patient proposal to show the <br /> plan to patients without the lab bill
      </span>
    </div>
  );

  const renderCreatePatientProposalBtn = () => {
    if (caseLoading || isTreatmentDesignLoading || isProposalsLoading) {
      return (
        <Space className="flex items-center justify-center" style={{ height: '100%' }}>
          <Typography.Text>Loading patient proposal data</Typography.Text> <Spin />
        </Space>
      );
    }

    if (!proposalsData?.docs?.length) {
      return (
        <Tooltip
          title={renderCreatePatientProposalContent}
          placement={isMd ? 'left' : 'top'}
          color="white"
          defaultOpen={!isUserViewedCreatePatientProposal}
          overlayStyle={{ maxWidth: 'none', zIndex: 1 }}
          id={CREATE_PATIENT_PROPOSAL_TOOLTIP_ID}
        >
          <Link to={`${url}/patient-proposal/create`}>
            <Button
              size={isMd ? 'large' : 'middle'}
              type="primary"
              id="create-patient-proposal"
              shape="round"
              className="btn-primary-redesigned"
              onClick={() => {
                if (!isUserViewedCreatePatientProposal) {
                  updateUserProfile({
                    isUserViewedCreatePatientProposal: true,
                  }).unwrap();
                  dispatch(
                    updateUserData({
                      ...user!,
                      isUserViewedCreatePatientProposal: true,
                    }),
                  );
                }
                trackEvent(EEventNames.PATIENT_PROPOSAL_STARTED, {
                  'Case ID': submissionId,
                  'Patient Name': submissionInfo?.patient?.fullName,
                  'Plan Type': submissionInfo?.planType,
                  'Service Type': submissionInfo?.serviceType,
                  'Practice ID': submissionInfo?.practice?.id,
                  'Corporate ID': user?.associatedWithDentalCorporates?.map((corporate) => corporate.id),
                });
              }}
            >
              Create Patient Proposal
            </Button>
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link to={`${url}/patient-proposal/${proposalsData?.docs?.[0]?.id}/edit`}>
        <Button size="large" id="edit-patient-proposal" shape="round" className="btn-secondary-redesigned">
          Edit Patient Proposal
        </Button>
      </Link>
    );
  };

  const scrollToClearAlignersSection = () => {
    setTimeout(() => {
      clearAlignerOptionsRef.current!.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }, 100);
  };

  const clearAlignerPackageExtraContent = (
    <div
      onClick={(e: any) => {
        e.stopPropagation();
        setViewClearAlignerNotionContent(true);
      }}
      className="ml-6 mt-1 flex w-full flex-row items-center justify-center text-sm font-normal lg:ml-0"
    >
      <InfoSVG width={isMobile ? 25 : 18} height={isMobile ? 25 : 18} className="mr-2" />
      Help choosing materials
    </div>
  );

  const hideSummaryForSoloRefinement =
    submissionInfo?.serviceType === SUBMISSION_SERVICE_TYPE.SOLO &&
    submissionInfo?.planType === SUBMISSION_PLAN_TYPE.REFINEMENT;

  return (
    <Skeleton loading={caseLoading || isTreatmentDesignLoading}>
      <div className="patients-list">
        <div className="patients-header flex flex-col items-center justify-between md:flex-row">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to="/dentist/patients"> Patients </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/dentist/patients/${submissionInfo?.id}`}>{submissionInfo?.patient?.fullName}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item overlay={menu}>
              <Link to={`/dentist/patients/${submissionId}/design/${designId}`} className="underline">
                Design {getIndex()}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="my-2 flex h-full flex-row items-center justify-between md:my-0">
            {treatmentDesign && submissionInfo && (
              <DownloadTreatmentDesignAsPdf treatmentDesign={treatmentDesign} submission={submissionInfo} />
            )}
            {renderCreatePatientProposalBtn()}
          </div>
        </div>
        <div className="mt-2 h-[calc(100vh-172px)] overflow-y-auto md:h-[calc(100vh-130px)]">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-7">
              {!hideSummaryForSoloRefinement && (
                <>
                  <div>
                    <NiceCollapse title="Summary" open>
                      <div className="grid grid-cols-12 gap-4 border-0 border-t border-solid border-gray-100 px-2 pt-4">
                        {submissionInfo?.serviceType === SUBMISSION_SERVICE_TYPE.DUO && (
                          <>
                            {treatmentDesign?.advice?.personalNote && (
                              <div className="col-span-12">
                                <ViewItem title="Personal note to dentist">
                                  {treatmentDesign?.advice?.personalNote}
                                </ViewItem>
                              </div>
                            )}

                            <div className="col-span-12 py-0 md:col-span-6">
                              <ViewItem title="Complexity">
                                {treatmentDesign?.advice?.complexity ? (
                                  <Chip
                                    className="modify-chip col-span-6"
                                    text={treatmentDesign?.advice?.complexity}
                                    key={treatmentDesign?.advice?.complexity}
                                  />
                                ) : (
                                  '-'
                                )}
                              </ViewItem>
                            </div>

                            <div className="col-span-12 py-0 md:col-span-6">
                              <ViewItem title="Expected Length of Treatment">
                                {treatmentDesign?.advice?.estimatedTreatmentRange}
                              </ViewItem>
                            </div>
                          </>
                        )}

                        <div className="col-span-12 md:col-span-6">
                          <ViewItem title="Arches in treatment">{treatmentDesign?.arches}</ViewItem>
                        </div>

                        <div className="col-span-12 md:col-span-6">
                          <ViewItem title="Total Stages">{treatmentDesign?.totalSteps}</ViewItem>
                        </div>

                        <div className="col-span-12 md:col-span-3">
                          <ViewItem title="IPR">{treatmentDesign?.havingIpr}</ViewItem>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                          <ViewItem title="Attachments">{treatmentDesign?.havingAttachment}</ViewItem>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                          <ViewItem title="Auxiliaries">{treatmentDesign?.havingElastic}</ViewItem>
                        </div>
                        <div className="col-span-12 md:col-span-3">{/* only for alignment */}</div>
                      </div>
                    </NiceCollapse>
                  </div>
                  {!submissionInfo?.isSubmissionApproved && (
                    <SkuRecommendation
                      submission={submissionId}
                      treatmentDesign={designId}
                      scrollToClearAlignersSection={scrollToClearAlignersSection}
                    />
                  )}
                </>
              )}

              {isMobile && <ToothMovementAnimationCollapse treatmentDesign={treatmentDesign} />}

              {submissionInfo?.serviceType === SUBMISSION_SERVICE_TYPE.DUO && (
                <div className="mt-3">
                  <SpecialistAdviceDetailCollapse
                    specialistAdviceData={treatmentDesign?.advice}
                    specialistInfo={submissionInfo?.specialist}
                  />
                </div>
              )}

              {treatmentDesign && (
                <div className="mt-3">
                  <TreatmentDesignDetailCollapse
                    treatmentDesign={treatmentDesign}
                    showComment={submissionInfo?.serviceType === SUBMISSION_SERVICE_TYPE.SOLO}
                  />
                </div>
              )}

              {submissionInfo?.serviceType === SUBMISSION_SERVICE_TYPE.SOLO && (
                <div className="mt-3">
                  <NiceCollapse title="Revision Requests" open>
                    <div className="flex border-0 border-t border-solid border-gray-100 px-2 pt-4">
                      {treatmentDesign && (
                        <RevisionRequests
                          submissionInfo={submissionInfo}
                          treatmentDesignStatus={treatmentDesign?.status}
                        />
                      )}
                    </div>
                  </NiceCollapse>
                </div>
              )}
              {!submissionInfo?.isSubmissionApproved && (
                <div id="wizard-clear-aligners-info" className={classNames('mt-3')}>
                  <NiceCollapse
                    title="Clear Aligner Packages"
                    open
                    extra={
                      submissionInfo?.planType === SUBMISSION_PLAN_TYPE.INITIAL ? clearAlignerPackageExtraContent : null
                    }
                  >
                    <ClearAlignerOptions submissionInfo={submissionInfo!} ref={clearAlignerOptionsRef} />
                  </NiceCollapse>
                </div>
              )}
            </div>
            <div className="col-span-12 md:col-span-5">
              {!isMobile && <ToothMovementAnimationCollapse treatmentDesign={treatmentDesign} />}
              <div>
                <div className="mt-2">
                  <IprSchematicsCollapse treatmentDesign={treatmentDesign} />
                </div>
                <div className="mt-3">
                  <NiceCollapse title="Tooth movement table" open>
                    <div className="px-3">
                      {treatmentDesign?.toothMovementTableImage?.url ? (
                        <Col span={30} className="mb-3">
                          <div className="small-image-container mb-2 w-full cursor-pointer" style={{ minHeight: 240 }}>
                            <img src={treatmentDesign?.toothMovementTableImage?.url} className="h-full w-full" alt="" />
                            <div className="overlay h-full w-full">
                              <div className="image-backdrop" />
                              <div className="image-preview-btn flex h-full w-full flex-row items-center justify-center space-x-1">
                                <Tooltip title="Preview">
                                  <EyeOutlined
                                    style={{ color: 'lightgrey', fontSize: 26 }}
                                    onClick={() => previewToothMovementImage()}
                                  />
                                </Tooltip>
                                <LinkDownload
                                  url={treatmentDesign?.toothMovementTableImage?.url}
                                  name={treatmentDesign?.toothMovementTableImage?.name}
                                  isIcon
                                  isLarge
                                  className="-mb-1 text-lightGrey"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Empty description="No data to show!" />
                      )}
                    </div>
                    <div className="hidden">
                      {visibleToothMovementImage && (
                        <Image.PreviewGroup
                          preview={{
                            visible: visibleToothMovementImage,
                            onVisibleChange: (vis) => setShowPreviewToothMovementImage(vis),
                          }}
                        >
                          <Row gutter={32}>
                            <Image src={treatmentDesign?.toothMovementTableImage?.url} />
                          </Row>
                        </Image.PreviewGroup>
                      )}
                    </div>
                  </NiceCollapse>
                </div>
              </div>
              <div className="mt-3">
                <NiceCollapse title="Additional Images" open>
                  <div className="px-3">
                    {treatmentDesign?.additionalAttachments && (
                      <div className="uploaded-image-container mt-3">
                        <Row gutter={16}>
                          <Image.PreviewGroup>
                            {treatmentDesign.additionalAttachments.map(({ url: imgUrl }: any) => (
                              <Col className="gutter-row" span={12} key={imgUrl}>
                                <div
                                  className="small-image-container mb-2 w-full cursor-pointer"
                                  style={{ minHeight: 100 }}
                                >
                                  <Image src={imgUrl} />
                                </div>
                              </Col>
                            ))}
                          </Image.PreviewGroup>
                        </Row>
                      </div>
                    )}
                    {!treatmentDesign?.additionalAttachments?.length && <Empty description="No data to show!" />}
                  </div>
                </NiceCollapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NiceAntModal
        visible={viewClearAlignerNotionContent}
        onCancel={() => setViewClearAlignerNotionContent(false)}
        width={800}
        footer={null}
      >
        <ContentNotion className="help-choosing-materials-modal" contentUrl={CLEAR_ALIGNER_PACKAGES_HELP_CONTENT} />
      </NiceAntModal>
    </Skeleton>
  );
};
