import { PlusOutlined } from '@ant-design/icons';
import { notification, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useGetProfileSignedUrlMutation } from 'modules/auth/services/authApi';
import { FC, useState } from 'react';
import { useUploadFileMutation } from 'services/api';
import { beforeImage5mbUpload } from 'utils/index';

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const ProfilePicUpload: FC<IProps> = ({ value, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [getProfileSignedUrl] = useGetProfileSignedUrlMutation();
  const [uploadFile] = useUploadFileMutation();

  const handleFileChange = (info: any) => {
    const { status } = info.file;

    if (status === 'done') {
      onChange?.(info.file.response?.imageUrl);
    } else if (status === 'error') {
      notification.error({
        message: `Sorry ${info.file.name} didn't upload, please try again.`,
        placement: 'topRight',
      });
    }
    return true;
  };

  const uploadImageRequest = async ({ file, onSuccess, filename, onError }: any) => {
    const { type } = file;
    const supportedFileType = type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg';
    if (!supportedFileType) {
      return false;
    }
    if (file) {
      try {
        setLoading(true);
        const fileData = {
          fileName: file.name,
          mimeType: file.type,
          folderName: 'profile',
        };
        const signedUrl = await getProfileSignedUrl(fileData).unwrap();
        await uploadFile({ url: signedUrl.url, file });
        const imageUrl = signedUrl && signedUrl.url.split('?')[0];
        onSuccess({ imageUrl, filename });
      } catch (e) {
        onError(e, { filename });
      } finally {
        setLoading(false);
      }
    }
    return true;
  };

  return (
    <ImgCrop rotationSlider>
      <Upload
        listType="picture-card"
        showUploadList={false}
        beforeUpload={beforeImage5mbUpload}
        customRequest={uploadImageRequest}
        onChange={handleFileChange}
        className="auth-upload-avatar"
      >
        {loading ? (
          <Spin size="small" />
        ) : value ? (
          <img src={value} alt="avatar" style={{ width: '100%', borderRadius: '10px' }} />
        ) : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>
    </ImgCrop>
  );
};
