import { Card, Spin } from 'antd';
import { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { NotionRenderer } from 'react-notion-x';
import { useGetNotionDataQuery } from 'services/api';
import classNames from 'classnames';
import 'react-notion-x/src/styles.css';
import './content-notion.less';

interface IContentNotionProps {
  contentUrl: string;
  markAsRead?: () => Promise<void>;
  className?: string;
}

export const ContentNotion: FC<IContentNotionProps> = ({ contentUrl, markAsRead, className }) => {
  const { data: recordMap, isLoading } = useGetNotionDataQuery(contentUrl!);

  useEffect(() => {
    if (!isLoading && recordMap && markAsRead) {
      markAsRead();
      setTimeout(() => {
        const videoElements = document.getElementsByTagName('VIDEO');
        Object.values(videoElements).forEach((ele) => {
          ele?.setAttribute?.('controlsList', 'nodownload');
        });
      }, 1000);
    }
  }, [isLoading]);

  return isLoading ? (
    <div className="flex items-center justify-center" style={{ minHeight: '500px' }}>
      <Spin tip="Loading content..." />
    </div>
  ) : (
    <Card
      className={classNames('custom-shadow h-full', className)}
      bodyStyle={{ padding: isMobile ? '30px' : '30px 60px' }}
    >
      {recordMap?.data ? (
        <NotionRenderer recordMap={recordMap.data} fullPage darkMode={false} />
      ) : (
        <div className="flex items-center justify-center">Content URL is invalid!</div>
      )}
    </Card>
  );
};
