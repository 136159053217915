import NiceModal from '@ebay/nice-modal-react';
import { showCalendlyBookingModal, ICalendlyScheduleDataType } from 'components/calendly-booking-modal';
import { useAppDispatch } from 'hooks/store';
import { useAuth } from 'hooks/useAuth';
import { updateJustSignUp, updateUserData } from 'modules/auth/authSlice';
import { useCallback } from 'react';
import { SignUpSuccessModal } from './sign-up-success-modal';
import { USER_ROLES } from 'config/constants';
import { IMeetingData } from 'services/calendly-api/types';
import { EEventNames } from 'utils/mixpanel/events';
import { trackEvent } from 'utils/mixpanel/mixpanel';

interface IReturn {
  isLoaded: boolean;
  needToShowSignUpWelcomePopup?: boolean;
  onShowSignUpWelcomePopup: () => Promise<boolean>;
  needToShowBookCalendlyPopup?: boolean;
  onShowBookCalendlyPopup: () => Promise<unknown>;
}

export const useSignUpWelcome = (): IReturn => {
  const dispatch = useAppDispatch();
  const { user, justSignUp, isFullProfileLoaded } = useAuth();
  const { calendlyEventLink, isOnboardingBooked, isOnboardingComplete } = user?.onBoarding || {};
  const isDentist = user?.type === USER_ROLES.DENTIST;

  const trackBookingEvent = (eventData: IMeetingData) => {
    if (user?.meeting?.url) {
      trackEvent(EEventNames.ONBOARDING_REBOOKED, {
        'Onboarding Re-Booked Date': new Date().toISOString(),
        'Onboarding Meeting Date Re-Booked': eventData.resource.start_time,
      });
    } else {
      trackEvent(EEventNames.ONBOARDING_BOOKED, {
        'Onboarding Booked Date': new Date().toISOString(),
        'Onboarding Meeting Date': eventData.resource.start_time,
      });
    }
  };

  const handleScheduleCompleted = (scheduleData: ICalendlyScheduleDataType, eventData: IMeetingData) => {
    dispatch(
      updateUserData({
        ...user!,
        meeting: {
          ...user!.meeting,
          ...scheduleData,
        },
        onBoarding: {
          ...user!.onBoarding,
          isOnboardingBooked: true,
        },
      }),
    );
    trackBookingEvent(eventData);
  };

  const onShowBookCalendlyPopup = useCallback(
    async () =>
      calendlyEventLink &&
      showCalendlyBookingModal({
        createUrl: calendlyEventLink,
        meetingUrl: user?.meeting?.url,
        onScheduled: handleScheduleCompleted,
        prefill: {
          email: user?.email,
          name: `${user?.firstName} ${user?.lastName}`,
        },
      }),
    [user],
  );

  const onShowSignUpWelcomePopup = useCallback(async () => {
    const isConfirm = await NiceModal.show(SignUpSuccessModal);
    dispatch(updateJustSignUp({ justSignUp: false }));
    if (isConfirm) {
      await onShowBookCalendlyPopup();
      return true;
    }
    return false;
  }, [onShowBookCalendlyPopup]);

  return {
    isLoaded: isFullProfileLoaded,
    needToShowSignUpWelcomePopup: justSignUp && isDentist,
    onShowSignUpWelcomePopup,
    needToShowBookCalendlyPopup: !isOnboardingBooked && !isOnboardingComplete && isDentist,
    onShowBookCalendlyPopup,
  };
};
