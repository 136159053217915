import { useEffect, useMemo, useState } from 'react';
import { useLazyGetNotificationsQuery, useUpdateNotificationsIsViewMutation } from 'services/notification/endpoints';
import { useAuth } from 'hooks/useAuth';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { TNotificationItem } from 'services/notification/types';
import {
  setNotisList,
  addMoreNotisList,
  setUnreadNotificationsCount,
  TNotisType,
  updateNotisItem,
} from 'redux/notificationsSlice';
import * as Sentry from '@sentry/react';

type TUseNotificationsContainer = {
  isLoading: boolean;
  notifications?: TNotificationItem[];
  loadMoreNotification: () => Promise<void>;
  markNotificationsAs: (notisItem: TNotificationItem, isViewedTo: boolean) => Promise<void>;
};

export const useNotificationsContainer = ({ type }: { type: TNotisType }): TUseNotificationsContainer => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { unreadNotificationsCount, notisList } = useAppSelector((state) => state.notifications);

  const [getNotifications] = useLazyGetNotificationsQuery();
  const [updateNotificationsIsView] = useUpdateNotificationsIsViewMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [remainingCount, setRemainingCount] = useState<number | undefined>(
    type === 'unread' ? (unreadNotificationsCount || 0) - (notisList.unread?.length || 0) : undefined,
  );

  const lastId = useMemo(() => (notisList[type] ? notisList[type]?.at(-1)?.id : undefined), [notisList]);
  const isViewed: boolean | undefined = useMemo(
    () => (type === 'read' ? true : type === 'unread' ? false : undefined),
    [type],
  );

  useEffect(() => {
    const initLoad = async () => {
      try {
        setIsLoading(true);
        const res = await getNotifications({ limit: 10, isViewed }, false).unwrap();
        setIsLoading(false);
        dispatch(setNotisList({ type, items: res.docs }));
        if (type === 'unread') dispatch(setUnreadNotificationsCount(res.total));
        setRemainingCount(res.total - res.docs.length);
      } catch (error) {
        console.warn('notification initLoad error: ', error);
        Sentry.captureException(new Error(`notification initLoad error: ${JSON.stringify(error)}`));
      }
    };

    if (user && user?.id && notisList[type] === undefined) {
      initLoad();
    }
  }, [user, notisList]);

  const loadMoreNotification = async () => {
    if (!isLoading && remainingCount && remainingCount > 0) {
      setIsLoading(true);
      const res = await getNotifications({ limit: 10, isViewed, lastId }, false).unwrap();
      setIsLoading(false);
      setRemainingCount((r) => r! - res.docs.length);
      dispatch(addMoreNotisList({ type, items: res.docs }));
    }
  };

  const markNotificationsAs = async (notisItem: TNotificationItem, isViewedTo: boolean) => {
    await updateNotificationsIsView({ id: notisItem.id, isViewed: isViewedTo }).unwrap();
    dispatch(updateNotisItem({ type, notisId: notisItem.id, isViewedTo }));
  };

  return {
    isLoading,
    notifications: notisList[type],
    loadMoreNotification,
    markNotificationsAs,
  };
};
