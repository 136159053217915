import { useHistory } from 'react-router-dom';
import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import mailSvg from 'assets/images/auth/mail.svg';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';

const PasswordRecovered = () => {
  const history = useHistory();

  return (
    <AuthLayout>
      <div className="mt-20 flex items-start tracking-wide">
        <div className="mr-6">
          <img src={mailSvg} alt="key" />
        </div>

        <div className="flex-1">
          <p className="mb-2 text-base font-semibold text-darkBlueColor">Check your email</p>
          <p style={{ color: '#6B7D8B' }}>We have sent a password recovery instruction to your email.</p>

          <div className="mt-16 flex">
            <div className="mr-8 border-b-2 border-gray-200 text-sm font-medium">Did not receive the email?</div>
            <AuthButtonComponent onClick={() => history.replace('/forgot-password')}>
              Try another email
            </AuthButtonComponent>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default PasswordRecovered;
