import { useCallback, useMemo } from 'react';
import { useAuth } from './useAuth';
import { useTranslation } from 'react-i18next';
import { useGetCurrencyOptionsQuery } from 'services/api';
import { TDiscountType } from 'services/sku-api/types';

let currencyCodeMap: Record<string, string>;

export const useCurrency = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { data: currencyList, isLoading: isCurrencyListLoading } = useGetCurrencyOptionsQuery();

  const currencySymbol = useMemo(() => user?.currency?.symbol || t('currency.symbol'), [user?.currency?.symbol]);

  const moneyFormat = useCallback(
    (amount: string | number, options?: { fractionDigits?: number }) =>
      `${currencySymbol}${Number(amount).toLocaleString(
        navigator.language,
        options?.fractionDigits
          ? {
              minimumFractionDigits: options?.fractionDigits,
              maximumFractionDigits: options?.fractionDigits,
            }
          : undefined,
      )}`,
    [currencySymbol],
  );

  const getCurrencySymbolByCode = useCallback(
    (currencyCode: string) => {
      if (!currencyCodeMap && currencyList) {
        currencyCodeMap = currencyList.reduce(
          (newMap, item) => ({ ...newMap, [item.currencyCode]: item.currencySymbol }),
          {} as Record<string, string>,
        );
      }

      return currencyCodeMap?.[currencyCode];
    },
    [currencyList],
  );

  const getDiscountDisplay = useCallback(
    (discountType: TDiscountType, discountValue: number) =>
      discountType === 'CASH' ? `${currencySymbol}${discountValue}` : `${discountValue}%`,
    [currencySymbol],
  );

  return {
    currencySymbol,
    moneyFormat,
    getCurrencySymbolByCode,
    currencyList,
    isCurrencyListLoading,
    getDiscountDisplay,
  };
};
