import moment from 'moment';
import { notification } from 'antd';
import { isEmpty, isPlainObject, pickBy } from 'lodash';
import NiceModal from '@ebay/nice-modal-react';

const DEFAULT_DATE_FORMAT = 'Do MMM YYYY';

export const beforeImage5mbUpload = (file: any) => {
  const supportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  if (!supportedFileType) {
    notification.error({
      message: 'Sorry, we can only accept JPG, PNG or JPEG files',
      placement: 'topRight',
    });
    return false;
  }

  // TODO: some patient profile pics are more than 5 MB. So, no need to restrict file size now
  // const isValidFileSize = file.size < 1024 * 1024 * 5;
  // if (!isValidFileSize) {
  //     message.error('Sorry, image must smaller than 5MB', 5);
  //     return false;
  // }

  return true;
};

export const calculateDueIn = (createdAt: string) => {
  const date1 = moment();
  const date2 = moment(createdAt);
  let dueTime = 'overdue';
  if (date1.diff(date2, 'minutes') < 60) {
    dueTime = `${date1.diff(date2, 'minutes')} mins`;
  } else if (date1.diff(date2, 'hours') > 0 && date1.diff(date2, 'hours') < 48) {
    dueTime = `${48 - date1.diff(date2, 'hours')} hrs`;
  } else if (date1.diff(date2, 'days') < 2) {
    dueTime = `${date1.diff(date2, 'days')} days`;
  }
  return dueTime;
};

export const formatDate = (date: string | undefined, fr: string) => moment(date).format(fr);

export const formatToUtcDate = (date: string | undefined, fr: string = DEFAULT_DATE_FORMAT) =>
  moment(date).utc().format(fr);

export const roundDecimalNumber = (number: number) => {
  const roundOfNumber = Math.round(number * 10);
  return roundOfNumber / 10;
};

export const searchParams = (url: string, key: string): string | null => {
  try {
    const params = new URL(url).searchParams;
    return params.get(key);
  } catch {
    return '';
  }
};

export const upperCaseFirstLetterOfWord = (str: string) =>
  str
    ?.split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ') ?? '';

export const checkIsStlFileSizeValid = (sizeInBytes: number): boolean => sizeInBytes > 100000;

export function capitalize(s: string) {
  if (!s) return s;
  return s[0].toUpperCase() + (s.slice(1) || '').toLowerCase();
}

export const discardUndefined = (data: object | null | undefined) => {
  if (!isPlainObject(data)) return data;
  const leanData: any = pickBy(data, (v) => v !== undefined);
  // clean nested
  Object.entries(leanData).forEach(([key, value]) => {
    leanData[key] = isPlainObject(value) ? discardUndefined(value as object) : value;
  });
  return leanData;
};

export const discardEmpty = (data: object | null | undefined) => {
  if (!isPlainObject(data)) return data;
  const leanData: any = pickBy(data, (v) => !isEmpty(v));
  // clean nested
  Object.entries(leanData).forEach(([key, value]) => {
    leanData[key] = isPlainObject(value) ? discardEmpty(value as object) : value;
  });
  return leanData;
};

export const discardEmptyLevel = (data: object | null | undefined, nestedLevel: number = 1) => {
  if (nestedLevel < 1) return data;
  let ans = discardEmpty(data);
  let times = nestedLevel;

  while (times > 1 && !isEmpty(ans)) {
    ans = discardEmpty(ans);
    times -= 1;
  }

  return ans;
};

export const showModal =
  <TReturnValue = void, TCompProps = undefined>(ModalElm: React.FC<TModalProps<TCompProps>>) =>
  async (args: TModalArgs<TCompProps>): Promise<TReturnValue> => {
    const value = await NiceModal.show<TReturnValue, TModalProps<TCompProps>>(
      ModalElm,
      args as TModalProps<TCompProps>,
    );
    return value;
  };
