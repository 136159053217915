import { SelectProps } from 'antd';
import { NiceSelect } from 'components/commons/nice-select/nice-select';
import { useGetScannerQuery } from 'services/api';

export interface IScanner {
  type: string;
  name: string;
  id: string;
  supportScannerUpload: boolean;
}

export const ScannerSelect = (props: SelectProps<IScanner>) => {
  const { data, isFetching, isLoading } = useGetScannerQuery();

  return (
    <NiceSelect
      placeholder="Select one or more scanner"
      allowClear
      showArrow
      loading={isFetching || isLoading}
      fieldNames={{ label: 'name', value: 'id' }}
      options={data as any}
      {...props}
    />
  );
};
