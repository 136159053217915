import { Typography } from 'antd';
import customerServiceAwardPng from 'assets/images/awards/customer_service_award.png';
import newProductAwardPng from 'assets/images/awards/new_product_award.png';
import shorttermPostgraduateCourseAwardPng from 'assets/images/awards/shortterm_postgraduate_course_award.png';

export const CompanyAwardsImages = () => (
  <div className="mb-4 mt-4 flex gap-8">
    <div className="flex flex-col items-center justify-center">
      <img src={newProductAwardPng} alt="32co awards" className="h-40" />
      <Typography.Text className="text-center">
        New Product /<br />
        Service of the Year
      </Typography.Text>
    </div>
    <div className="flex flex-col items-center justify-center">
      <img src={customerServiceAwardPng} alt="32co awards" className="h-40" />
      <Typography.Text className="text-center">Customer Service Provider of the Year</Typography.Text>
    </div>
    <div className="flex flex-col items-center justify-center">
      <img src={shorttermPostgraduateCourseAwardPng} alt="32co awards" className="h-40" />
      <Typography.Text className="text-center">Short-Term Postgraduate Course</Typography.Text>
    </div>
  </div>
);
