import { TTreatmentQuoteTableColumn } from './types/TreatmentQuoteTableColumnType';

export const columns: TTreatmentQuoteTableColumn[] = [
  {
    title: 'Description',
    dataIndex: 'title',
    key: 'title',
    editable: false,
    width: 120,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    editable: true,
    render: (value, record) => (
      <span>
        <b>{record?.currencyCode ?? '£'}</b>
        &nbsp;&nbsp;
        {value}
      </span>
    ),
    width: 120,
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    editable: true,
    width: 120,
  },
];
