import { FC } from 'react';

interface IProps {
  email: string;
  subject?: string;
  body?: string;
}
export const MailTo: FC<IProps> = ({ email, subject = '', body = '', children }) => (
  <a href={`mailto:${email}?subject=${subject}&body=${body}`}>{children}</a>
);
