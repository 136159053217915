import { FC, useEffect } from 'react';
import { Form, FormInstance, Input } from 'antd';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { FormItemPhoneNumber } from 'components/form/form-item-phone-number/form-item-phone-number';
import { PasswordField } from 'components/password-field/password-field';
import { MetaOptionsInput } from 'components/meta-options-input/meta-options-input';
import { META_KEYS } from 'hooks/useMetaOptionsSubmission';
import classNames from 'classnames';
import { IMobileNumberInputValue } from 'components/mobile-number-input/mobile-number-input';
import { useCountryOptions } from 'hooks/useCountryOptions';
import { EmailField } from './email-field/email-field';
import { useTranslation } from 'react-i18next';

interface IPersonalDetailsProps {
  isActive: boolean;
  formInstance: FormInstance;
}

export const PersonalDetails: FC<IPersonalDetailsProps> = ({ isActive, formInstance }) => {
  const phone: IMobileNumberInputValue = Form.useWatch('phone', formInstance);
  const { getCountryInfoFromExt } = useCountryOptions();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (phone?.ext && getCountryInfoFromExt) {
      const countryObject = getCountryInfoFromExt(phone.ext);
      formInstance.setFieldValue('country', countryObject.code);
      i18n.changeLanguage(countryObject.code);
    }
  }, [phone?.ext, getCountryInfoFromExt]);

  const onFinishFailed = ({ errorFields }: any) => {
    formInstance.scrollToField(errorFields[0].name, { block: 'center' });
  };

  return (
    <Form
      form={formInstance}
      layout="vertical"
      size="large"
      autoComplete="off"
      className="mt-4 animate-fadeIn"
      onFinishFailed={onFinishFailed}
    >
      <div className={classNames('grid grid-cols-2 gap-4', isActive ? 'visible' : 'hidden')}>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name="firstName"
            label="What’s your first name?"
            isRequired
            rules={[
              {
                required: true,
                message: 'Please tell us your name so we know what to call you',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Phil" />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name="lastName"
            label="And your second name?"
            isRequired
            rules={[
              {
                required: true,
                message: 'We need your last name as well please',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Ling" />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <EmailField />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemPhoneNumber
            label="And your phone number (Personal)"
            name="phone"
            isRequired
            inputClassName="custom-input"
            numberPlaceholder="Enter your phone number"
          />
          <FormItemControl name="country" hidden />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <PasswordField form={formInstance} placeholder="Password" label="Choose a memorable Password" isRequired />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name="confirmPassword"
            label="Confirm your Password (just in case)"
            isRequired
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please enter the same password again, just to be sure',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Sorry, those passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password className="custom-input" placeholder="Password" />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name="licenseNumber"
            label="Please provide your Dental License Number"
            isRequired
            rules={[
              {
                required: true,
                message: 'We can only provide access to qualified dentists',
              },
            ]}
          >
            <Input className="custom-input" placeholder={t('SIGN_UP.dentalLicensePlaceholder')} />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name="bestDescribes"
            label="Which of the following best describes you?"
            isRequired
            rules={[
              {
                required: true,
                message: 'This will help us give you the best quality support',
              },
            ]}
          >
            <MetaOptionsInput
              metaKey={META_KEYS.dentistRegistration}
              optionKey="bestDescribes"
              placeholder="Select an option"
              className="custom-input"
            />
          </FormItemControl>
        </div>
      </div>
    </Form>
  );
};
