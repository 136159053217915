import { CloseOutlined } from '@ant-design/icons';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import { Button, Divider, Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { Prompt } from 'react-router-dom';
import './nice-ant-modal.styles.less';

// USAGE
// There are two ways to manage visible state
// 1. pass `modal` as prop from useModal value. Refer `DiscountsLearnMoreModal` or `AddToQuoteModal` with custom footer
// 2. use as normal as Modal component (`visible`, `onCancel`... props)

export type TNiceAntModalProps = ModalProps & {
  modal?: NiceModalHandler | null;
  subTitle?: React.ReactNode;
  hideDivider?: boolean;
  keepOnRouteChange?: boolean;
};

const NiceAntModal: React.FC<TNiceAntModalProps> = (props) => {
  const {
    children,
    afterClose,
    onCancel,
    className,
    modal = null,
    title,
    footer,
    destroyOnClose = true,
    bodyStyle,
    style,
    subTitle,
    hideDivider,
    keepOnRouteChange,
    ...restProps
  } = props;
  const isLowestModal = useRef(false); // incase there are many popups showing at the same time

  useEffect(() => {
    if (modal?.visible) {
      if (document.body.style.overflow !== 'hidden') {
        document.body.style.overflow = 'hidden';
        isLowestModal.current = true;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isLowestModal.current) {
        document.body.style.overflow = 'unset';
      }
    }

    return () => {
      if (isLowestModal.current) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [modal?.visible]);

  const handleCancel = useCallback(
    (e) => {
      onCancel?.(e);
      modal?.hide();
    },
    [onCancel, modal],
  );

  const onAfterClose = useCallback(() => {
    afterClose?.();
    if (destroyOnClose) modal?.remove();
  }, [afterClose, modal, destroyOnClose]);

  return (
    <Modal
      open={modal?.visible}
      onCancel={handleCancel}
      afterClose={onAfterClose}
      closeIcon={<Button className="bg-white" shape="circle" icon={<CloseOutlined />} size="middle" />}
      centered
      footer={null}
      className={classNames('nice-ant-modal mt-4', className)}
      style={{ maxHeight: 'calc(100vh - 30px)', ...style }}
      bodyStyle={{ overflowY: 'auto', ...bodyStyle }}
      {...restProps}
    >
      <Prompt
        when={!keepOnRouteChange}
        message={(_location, _action) => {
          modal?.remove();
          return true;
        }}
      />
      {title && (
        <>
          <h2 className="title mb-1">{title}</h2>
          {subTitle && <div className="mb-2 text-xs">{subTitle}</div>}
          {!hideDivider && <Divider className="divider" />}
        </>
      )}
      {typeof children === 'function' ? children({ modal }) : children}
      {footer && <div className="footer">{typeof footer === 'function' ? footer({ modal }) : footer}</div>}
    </Modal>
  );
};

export default NiceAntModal;
