import { globalStorage } from '../window';
import { api, formatUrl } from '../api';
import {
  ITreatmentDesignsResponse,
  ITreatmentDesignsRequest,
  ITreatmentDesign,
  IUploadSTLRequest,
  ICreateAnimationUrl,
  TPreviousIprImagesResponse,
} from './types';

const TREATMENT_DESIGNS_API_URL = {
  TREATMENT_DESIGNS: '/treatment-designs',
  LATEST: '/latest',
};

export const treatmentDesignsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTreatmentDesigns: build.query<ITreatmentDesignsResponse, ITreatmentDesignsRequest>({
      query: ({ page, perPage, submissionId }) => {
        globalStorage.patientDetailScreen.proposalUrlQueries = { page, perPage, submissionId };
        let paramsString = '';
        if (page) paramsString += `page=${page}`;
        if (perPage) paramsString += `&perPage=${perPage}`;
        if (submissionId) paramsString += `&submission=${submissionId}`;

        return formatUrl(`${TREATMENT_DESIGNS_API_URL.TREATMENT_DESIGNS}?${paramsString}`);
      },
      // Provides a list of `Proposals` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Posts` element was added.
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.docs.map(({ id }) => ({ type: 'TreatmentDesigns', id } as const)),
              { type: 'TreatmentDesigns', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'TreatmentDesigns', id: 'LIST' }` is invalidated
            [{ type: 'TreatmentDesigns', id: 'LIST' }],
      keepUnusedDataFor: 5,
    }),
    addTreatmentDesign: build.mutation<ITreatmentDesign, Partial<ITreatmentDesign>>({
      query(body) {
        return {
          url: formatUrl(TREATMENT_DESIGNS_API_URL.TREATMENT_DESIGNS),
          method: 'POST',
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      invalidatesTags: [
        { type: 'Submissions', id: 'LIST' },
        { type: 'TreatmentDesigns', id: 'LIST' },
      ],
    }),
    getTreatmentDesignById: build.query<ITreatmentDesign, { id: string }>({
      query: ({ id }) => formatUrl(`${TREATMENT_DESIGNS_API_URL.TREATMENT_DESIGNS}/${id}`),
    }),
    getLatestTreatmentDesign: build.query<ITreatmentDesign, { id: string }>({
      query: ({ id }) =>
        formatUrl(`${TREATMENT_DESIGNS_API_URL.TREATMENT_DESIGNS}${TREATMENT_DESIGNS_API_URL.LATEST}?submission=${id}`),
      // providesTags: (result, error, id) => [{ type: 'Proposals', id }],
    }),
    uploadTreatmentDesignStls: build.mutation<ITreatmentDesign, IUploadSTLRequest>({
      query({ treatmentDesignId, stls }) {
        return {
          url: formatUrl(`${TREATMENT_DESIGNS_API_URL.TREATMENT_DESIGNS}/${treatmentDesignId}/upload-stls`),
          method: 'PUT',
          body: { stls },
        };
      },
      // Invalidates all queries that subscribe to this Proposals `id` only.
      // In this case, `getProposals` will be re-run. `getProposals` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { treatmentDesignId }) => [{ type: 'TreatmentDesigns', id: treatmentDesignId }],
    }),
    addAnimationURL: build.mutation<void, ICreateAnimationUrl>({
      query({ submissionId, animationUrl }) {
        return {
          url: formatUrl(`/submissions/${submissionId}/animation`),
          method: 'PUT',
          body: { animationUrl },
        };
      },
    }),
    getPreviousIprImages: build.query<TPreviousIprImagesResponse, { treatmentDesignId: string }>({
      query: ({ treatmentDesignId }) =>
        formatUrl(`${TREATMENT_DESIGNS_API_URL.TREATMENT_DESIGNS}/${treatmentDesignId}/previous-iprImages`),
    }),
  }),
});

export const {
  useGetTreatmentDesignsQuery,
  useAddTreatmentDesignMutation,
  // useGetTreatmentDesignQuery,
  useUploadTreatmentDesignStlsMutation,
  useLazyGetLatestTreatmentDesignQuery,
  useGetLatestTreatmentDesignQuery,
  useAddAnimationURLMutation,
  useGetPreviousIprImagesQuery,
} = treatmentDesignsApi;
