import { IDiscount } from 'services/sku-api/types';
import { api, formatUrl } from '../api';

export interface IReferral {
  amount: number;
  appliedDiscount: IDiscount;
  condition: string;
  confirmed: boolean;
  id: string;
  createdAt: string;
  referredTo: {
    firstName: string;
    lastName: string;
  };
  referredBy: {
    firstName: string;
    lastName: string;
  };
  updatedAt: string;
}

type TGetDiscountsResponse = {
  discounts: IDiscount[];
  referrals: IReferral[];
};

export const discountsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDiscounts: build.query<TGetDiscountsResponse, any>({
      query: () => formatUrl(`/discounts`),
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.referrals.map(({ id }: { id: string }) => ({ type: 'Discounts', id } as const)),
              { type: 'Discounts', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Discounts', id: 'LIST' }` is invalidated
            [{ type: 'Discounts', id: 'LIST' }],
    }),
    updateDiscountConfirm: build.mutation<any, any>({
      query(body) {
        return {
          url: formatUrl(`/discounts/confirm`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: 'Discounts', id: 'LIST' }],
    }),
    getDiscountClaimable: build.query<{ id: string }, void>({
      query: () => formatUrl('/discounts/claimable'),
      providesTags: ['Discounts'],
    }),
    claimDiscount: build.mutation<void, { discountId: string }>({
      query(data) {
        const { discountId } = data;
        return {
          url: formatUrl(`/discounts/${discountId}/claim`),
          method: 'PUT',
        };
      },
      invalidatesTags: ['Discounts'],
    }),
  }),
});

export const {
  useGetDiscountsQuery,
  useUpdateDiscountConfirmMutation,
  useGetDiscountClaimableQuery,
  useClaimDiscountMutation,
} = discountsApi;
