import { Link } from 'react-router-dom';
import { Form, Checkbox, Input } from 'antd';
import type { ILoginRequest } from '../services/authApi';
import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import midLogoSvg from 'assets/images/auth/mid-logo.svg';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';
import { SigninFormItem } from '../components/signin-form-item/signin-form-item';
import { pushToDataLayer } from 'utils/gtm';
import { useQuery } from 'hooks/useQuery';
import { useForm } from 'antd/lib/form/Form';
import { FC } from 'react';
import { useAuth } from 'hooks/useAuth';

const Signin: FC<any> = () => {
  const params = useQuery();
  const [form] = useForm();
  const { userLogin, isUserLoginLoading } = useAuth();

  const redirectUrl = params.get('redirectUrl');

  const onSubmit = async (formState: ILoginRequest) => {
    userLogin(formState, redirectUrl);
  };

  return (
    <AuthLayout hideSmallLogo>
      <div>
        <div className="mb-20 text-center">
          <img src={midLogoSvg} alt="logo" className="mx-auto" />
        </div>
        <div className="animate-fadeIn">
          <Form
            form={form}
            name="basic"
            onFinish={onSubmit}
            size="large"
            layout="vertical"
            autoComplete="off"
            className="w-full"
          >
            <SigninFormItem
              form={form}
              formName="email"
              className="!mb-8"
              label="Enter your email address"
              formRules={[
                { required: true, message: 'Please enter your email address' },
                { type: 'email', message: 'Please enter your full email address' },
              ]}
              hasEmailSuffix
            >
              <Input />
            </SigninFormItem>
            <SigninFormItem
              form={form}
              formName="password"
              label="Password"
              className="!mb-8"
              formRules={[
                { required: true, message: "You'll need to enter your password" },
                { min: 8, message: 'Please enter a password with at least 8 characters' },
              ]}
            >
              <Input.Password />
            </SigninFormItem>

            <Form.Item className="relative !mb-6">
              <div className="flex items-end justify-between text-xs md:text-sm" style={{ color: '#767675' }}>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox className="text-gray-500">Remember me</Checkbox>
                </Form.Item>
                <div className="flex tracking-wide">
                  <Link
                    to="/joinus"
                    className="mr-1 text-gray-500 md:mr-3"
                    onClick={() => {
                      pushToDataLayer({ event: 'signin.notregistered.click' });
                    }}
                  >
                    Not Registered?
                  </Link>
                  <span>|</span>
                  <Link
                    to="/forgot-password"
                    className="ml-1 text-gray-500 md:ml-3"
                    onClick={() => {
                      pushToDataLayer({ event: 'signin.forgotpassword.click' });
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </Form.Item>

            <Form.Item className="text-right">
              <AuthButtonComponent isLoading={isUserLoginLoading}>Login</AuthButtonComponent>
            </Form.Item>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Signin;
