import { api, formatUrl, VERSION } from '../api';
import {
  IRevisionRequestsResponse,
  IRevisionRequestsRequest,
  IAddRevisionRequestsRequest,
  ICreateNoteRequest,
  INote,
  IUpdateNoteRequest,
} from './types';

const REVISION_REQUESTS_API_URL = {
  REVISION_REQUESTS: '/revision-requests',
  NOTES: '/notes',
};

export const revisionRequestsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRevisionRequests: build.query<IRevisionRequestsResponse, IRevisionRequestsRequest>({
      query: ({ page, perPage, submissionId }) => {
        let paramsString = '';
        if (page) paramsString += `page=${page}`;
        if (perPage) paramsString += `&perPage=${perPage}`;
        if (submissionId) paramsString += `&submission=${submissionId}`;

        return formatUrl(`${REVISION_REQUESTS_API_URL.REVISION_REQUESTS}?${paramsString}`);
      },

      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.docs.map(({ submission }) => ({ type: 'Modifications', submission } as const)),
              { type: 'Modifications', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Modifications', id: 'LIST' }` is invalidated
            [{ type: 'Modifications', id: 'LIST' }],
      keepUnusedDataFor: 5,
    }),
    addRevisionRequest: build.mutation<any, Partial<IAddRevisionRequestsRequest>>({
      query(body) {
        return {
          url: formatUrl(REVISION_REQUESTS_API_URL.REVISION_REQUESTS),
          method: 'POST',
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      invalidatesTags: [
        { type: 'Modifications', id: 'LIST' },
        { type: 'TreatmentDesigns', id: 'LIST' },
      ],
    }),
    createCaseNotes: build.mutation<ICreateNoteRequest, any>({
      query(body) {
        return {
          url: `/${VERSION}/user/notes`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Notes', id: 'LIST' }],
    }),
    getCaseNotes: build.query<INote[], { submissionId: string }>({
      query: ({ submissionId }) => `/${VERSION}/user/notes/${submissionId}`,
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...(result || []).map(({ id }: { id: string }) => ({ type: 'Notes', id } as const)),
              { type: 'Notes', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Notes', id: 'LIST' }` is invalidated
            [{ type: 'Notes', id: 'LIST' }],
    }),
    updateNotes: build.mutation<IUpdateNoteRequest, any>({
      query({ id, ...payload }) {
        return {
          url: `/${VERSION}/user/notes/${id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'Notes', id: 'LIST' }],
    }),
    deleteNote: build.mutation<{ id: string }, any>({
      query({ id }) {
        return {
          url: `/${VERSION}/user/notes/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Notes', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetRevisionRequestsQuery,
  useAddRevisionRequestMutation,
  useCreateCaseNotesMutation,
  useGetCaseNotesQuery,
  useUpdateNotesMutation,
  useDeleteNoteMutation,
} = revisionRequestsApi;
