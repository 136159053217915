import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { SmileQuestionnaireForm } from 'modules/gdp/interested-patients/smile-questionnaire/sq-form/sq-form';
import { Form, Typography, Avatar } from 'antd';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import {
  useUpdateInterestedPatientSQMutation,
  useUpdateSharedLinkPatientSQMutation,
} from 'services/patients-api/endpoints';
import { notificationApiError, notificationDesSafe } from 'utils/ui';
import { ReactComponent as QuoteSvg } from 'assets/images/interested-patients/quote-icon.svg';
import { IPatient } from 'services/patients-api/types';
import DavidBImg from 'assets/images/interested-patients/david-b.png';
import { AboutYouForm } from './about-you-form';
import { useWindowSize } from 'hooks/useWindowSize';

interface IProps {
  interestedPatient?: IPatient;
  token?: string;
  sqLinkId?: string;
}

export const PatientSQForm: FC<IProps> = ({ interestedPatient, token, sqLinkId }) => {
  const password = useQuery().get('token')!;
  const from = useQuery().get('from')!;
  const { patientId } = useParams<{ patientId: string }>();
  const [form] = Form.useForm();
  const isAboutYouPhoneEntered = Form.useWatch('phone', form);
  const isAboutYouEmailEntered = Form.useWatch('email', form);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!token && (isAboutYouPhoneEntered || isAboutYouEmailEntered)) {
      setIsSubmitting(false);
    }
  }, [token, isAboutYouPhoneEntered, isAboutYouEmailEntered]);

  const [updateInterestedPatientSQ, { isLoading }] = useUpdateInterestedPatientSQMutation();
  const [updateSharedLinkPatientSQ, { isLoading: isUpdatingSQ }] = useUpdateSharedLinkPatientSQMutation();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const { isMd } = useWindowSize();
  const history = useHistory();

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (!token && !isAboutYouPhoneEntered && !isAboutYouEmailEntered) {
          setIsSubmitting(true);
          throw new Error('Please add at least 1 contact method');
        }
        const payload = {
          ...values,
          id: patientId,
          status: 'COMPLETED',
        };
        if (password) payload.password = password;
        if (sqLinkId) payload.sqLinkId = sqLinkId;
        if (from) payload.from = from;
        try {
          if (sqLinkId) {
            const { patientId: resPatientId, password: resPassword } = await updateSharedLinkPatientSQ(
              payload,
            ).unwrap();
            history.push(`/patients/interested-patient/${resPatientId}/sq?token=${resPassword}`);
          } else {
            await updateInterestedPatientSQ(payload).unwrap();
          }
          notificationDesSafe.success({
            message: 'Smile Questionnaire updated successfully!🎉',
          });
        } catch (e) {
          notificationApiError(e);
        }
      })
      .catch((errors) => {
        const firstErrorItem = errors.errorFields?.[0];
        form.scrollToField(firstErrorItem.name.length === 1 ? firstErrorItem.name[0] : firstErrorItem.name, {
          block: 'center',
        });
        if (!token && !isAboutYouPhoneEntered && !isAboutYouEmailEntered) {
          setIsSubmitting(true);
          throw new Error('Please add at least 1 contact method');
        }
      });
  };

  const onValuesChange = () => {
    form
      .validateFields()
      .then(() => {
        setIsFormValid(true);
      })
      .catch(() => {
        setIsFormValid(false);
      });
  };

  return (
    <>
      <SmileQuestionnaireForm
        form={form}
        initialData={interestedPatient?.smileQuestionnaire}
        onValuesChange={onValuesChange}
        showContentForPatient
        patientToken={token}
        shouldAutoSave
      />
      {isFormValid && (
        <div className="my-4">
          All done! - Click send and I’ll be in touch to get you started on your journey to a new smile
        </div>
      )}
      {!token && (
        <Form form={form} autoComplete="off" layout="vertical" size="large" preserve={false} className="mb-2 w-full">
          <AboutYouForm />
          {isSubmitting && !isAboutYouPhoneEntered && !isAboutYouEmailEntered && (
            <span className="text-red ml-4">Please add at least 1 contact method</span>
          )}
        </Form>
      )}
      <NiceButton
        className="mt-5 w-full md:w-28 md:min-w-[200px]"
        loading={isLoading || isUpdatingSQ}
        niceType="orange"
        onClick={handleSubmit}
      >
        Complete and send
      </NiceButton>
      <div className="mt-8 flex flex-col items-center gap-3 rounded-3xl bg-[#F8F9FD] p-6 md:mx-10 md:flex-row md:gap-15">
        <div>
          <Avatar size={100} src={DavidBImg} alt="" />
        </div>
        <div className="flex flex-col">
          <Typography.Title level={4} className="!mb-2 text-center font-bold !text-[#E82A63] md:text-left">
            David B, 32Co Clear Aligner Patient
          </Typography.Title>
          {!isMd && (
            <div className="mt-0 text-center md:mt-4">
              <QuoteSvg width={50} />
            </div>
          )}
          <Typography.Text className="font-semibold">
            I feel more comfortable smiling for photos and more confident when public speaking or on any video calls for
            work. A number of people have commented on the improvement to my teeth and smile. I also find them easier to
            clean.
          </Typography.Text>
        </div>
        {isMd && (
          <div className="-mt-10 md:-mt-15">
            <QuoteSvg width={50} />
          </div>
        )}
      </div>
    </>
  );
};
