/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState, forwardRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Skeleton, Checkbox, Popover, Radio } from 'antd';
import { useAuth } from 'hooks/useAuth';
import { useGetSkusQuery } from 'services/sku-api/endpoints';
import NiceModal from '@ebay/nice-modal-react';
import { ESkuFilterOption } from 'modules/gdp/constants';
import { showFinishTypeModal as showFinishTypeModalFunc } from './modals/finish-type-modal';
import { ThankYouSpecialistModal } from './modals/thank-you-specialist-modal';
import { SkuCardMarket } from '../../../components/sku-card-market/sku-card-market';
import { SUBMISSION_SERVICE_TYPE } from 'config/constants';
import { ISkuType } from 'services/sku-api/types';
import { ISubmission } from 'services/patients-api/types';
import { TGetOrderSummaryResponse } from 'services/order-api/types';
import { showAddToBasketModal as showAddToBasketModalFunc } from './modals/add-to-basket-modal';
import { showOrderSummaryModal as showOrderSummaryModalFunc } from './modals/order-summary-modal';
import { isEmpty, orderBy, isEqual } from 'lodash';
import { SUBMISSION_PLAN_TYPE } from 'modules/gdp/case-submission/utils';
import { isMobile } from 'react-device-detect';
import { ReactComponent as CloseFilterSVG } from 'assets/images/close-circle.svg';
import { ReactComponent as FilterSVG } from 'assets/images/filter-svg.svg';
import { ReactComponent as AlertCircleSVG } from 'assets/images/alert-circle.svg';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { useCurrency } from 'hooks/useCurrency';

type TSortValue = {
  iteratees: string;
  orders: 'asc' | 'desc';
};

const SORT_OPTIONS: { label: string; value: TSortValue }[] = [
  {
    label: 'Price low to high',
    value: {
      iteratees: 'price',
      orders: 'asc',
    },
  },
  {
    label: 'Price high to low',
    value: {
      iteratees: 'price',
      orders: 'desc',
    },
  },
  {
    label: 'Delivery low to high',
    value: {
      iteratees: 'estDelivery',
      orders: 'asc',
    },
  },
  {
    label: 'Delivery high to low',
    value: {
      iteratees: 'estDelivery',
      orders: 'desc',
    },
  },
];

export const ClearAlignerOptions = forwardRef(
  (
    {
      submissionInfo,
    }: {
      submissionInfo: ISubmission;
    },
    ref: React.Ref<any>,
  ) => {
    const params: { designId: string; submissionId: string } = useParams();
    const history = useHistory();
    const { user } = useAuth();
    const orderRef = useRef<{
      selectedSku?: ISkuType;
      selectedFinishType?: string;
      orderSummary?: TGetOrderSummaryResponse;
      isAgreeTermCondition?: boolean;
    }>({});
    const { data, isLoading } = useGetSkusQuery({
      submission: params.submissionId,
      treatmentDesign: params.designId,
    });
    const [filteredData, setFilteredData] = useState<any>([]);
    const [alignersFilteredData, setAlignersFilteredData] = useState<string[]>([]);
    const [alignersTrimLinesFilteredData, setAlignersTrimLinesFilteredData] = useState<string[]>([]);
    const [sortBy, setSortBy] = useState<TSortValue | undefined>();
    const { currencySymbol: currency } = useCurrency();

    const [refinementsIncludedFiltersSelected, setRefinementsIncludedFiltersSelected] = useState(false);
    const [trimLinesFiltersSelected, setTrimLinesFiltersSelected] = useState(false);
    const clearAlignersData = data?.skus;

    const onModalCancel = () => {
      orderRef.current = {};
    };

    const viewSkuDetails = (sku: ISkuType) => {
      orderRef.current.selectedSku = sku;
      showAddToBasketModal();
    };

    const showAddToBasketModal = async () => {
      const { selectedSku, orderSummary, isAgreeTermCondition } = orderRef.current;

      if (!selectedSku) return;

      trackEvent(EEventNames.ALIGNER_PACKAGE_VIEWED, {
        'Case ID': submissionInfo?.id,
        'Patient Name': submissionInfo?.patient?.fullName,
        'Plan Type': submissionInfo?.planType,
        'Service Type': submissionInfo?.serviceType,
        'Case Status': submissionInfo?.status,
        'Practice ID': submissionInfo?.practice?.id,
        'Corporate ID': user?.associatedWithDentalCorporates?.map((corporate) => corporate.id),
        'SKU ID': selectedSku?.id,
        'Number of Refinements': selectedSku?.numberOfRefinementsIncluded,
        'Package Name': selectedSku?.name,
        Manufacturer: selectedSku?.manufacturer?.business?.name,
      });

      const newOrderSummary = await showAddToBasketModalFunc({
        selectedSku,
        initOrderSummary: orderSummary,
        planType: submissionInfo?.planType,
        submissionId: params.submissionId,
        treatmentDesignId: params.designId,
        initialAgreeTC: isAgreeTermCondition,
        onCancel: onModalCancel,
      });

      if (newOrderSummary) orderRef.current.orderSummary = newOrderSummary;
      orderRef.current.isAgreeTermCondition = true;
      showFinishTypeModal();
    };

    const showFinishTypeModal = async () => {
      const { selectedSku, selectedFinishType } = orderRef.current;

      const newSelectedFinishType = await showFinishTypeModalFunc({
        availableFinishTypeOptions: selectedSku?.finishOptions || [],
        initialFinishType: selectedFinishType,
        onCancel: onModalCancel,
      });

      if (newSelectedFinishType) {
        orderRef.current.selectedFinishType = newSelectedFinishType;
        showOrderSummaryModal();
      } else {
        // go back
        showAddToBasketModal();
      }
    };

    const showOrderSummaryModal = async () => {
      const { selectedSku, selectedFinishType, orderSummary } = orderRef.current;

      const isOrdered = await showOrderSummaryModalFunc({
        selectedSku,
        orderSummary,
        selectedFinishType,
        submissionInfo,
        treatmentDesignId: params.designId,
        onCancel: onModalCancel,
      });

      if (isOrdered) {
        if (submissionInfo?.serviceType === SUBMISSION_SERVICE_TYPE.DUO) {
          NiceModal.show(ThankYouSpecialistModal, { ...params, estDelivery: selectedSku?.estDelivery });
        } else {
          history.push(`/${user?.type}/patients/${params.submissionId}?activeTab=order-summary`);
        }
      } else {
        // go back
        showFinishTypeModal();
      }
    };

    const packagesOptionsMapping: Record<string, (item: ISkuType) => boolean> = {
      [ESkuFilterOption.ONE_REFINEMENT]: (item) =>
        Boolean(item?.refinementsIncluded && item?.numberOfRefinementsIncluded === 1),
      [ESkuFilterOption.TWO_REFINEMENTS]: (item) =>
        Boolean(item?.refinementsIncluded && item?.numberOfRefinementsIncluded === 2),
      [ESkuFilterOption.PAY_AS_YOU_GO]: (item) => !item.refinementsIncluded,
    };

    const trimLineOptionsMapping: Record<string, (item: ISkuType) => boolean> = {
      [ESkuFilterOption.STRAIGHT_EDGE]: (item) => Boolean(item?.finishOptions?.includes('Straight')),
      [ESkuFilterOption.SCALLOPED]: (item) => Boolean(item?.finishOptions?.includes('Scalloped')),
    };

    const handleChange = (event: any) => {
      setAlignersFilteredData(event);
      setRefinementsIncludedFiltersSelected(event.length > 0);
    };

    const handleTrimLinesChange = (event: any) => {
      setAlignersTrimLinesFilteredData(event);
      setTrimLinesFiltersSelected(event.length > 0);
    };

    const clearAllFilters = () => {
      setAlignersFilteredData([]);
      setAlignersTrimLinesFilteredData([]);
      setRefinementsIncludedFiltersSelected(false);
      setTrimLinesFiltersSelected(false);
      setSortBy(undefined);
    };

    const handleSortChange = (value: TSortValue) => {
      setSortBy((prev) => (isEqual(prev, value) ? undefined : value));
    };

    useEffect(() => {
      if (!refinementsIncludedFiltersSelected && !trimLinesFiltersSelected && !sortBy) {
        setFilteredData(clearAlignersData ?? []);
        return;
      }

      const filteredPackages =
        clearAlignersData?.filter((item) => {
          const isPackageFilterSelected =
            alignersFilteredData.length === 0 ||
            alignersFilteredData.some((filter) => packagesOptionsMapping[filter](item));

          const isTrimLineFilterSelected =
            alignersTrimLinesFilteredData.length === 0 ||
            alignersTrimLinesFilteredData.some((filter) => trimLineOptionsMapping[filter](item));

          return isPackageFilterSelected && isTrimLineFilterSelected;
        }) ?? [];

      setFilteredData(sortBy ? orderBy(filteredPackages, sortBy?.iteratees, sortBy?.orders) : filteredPackages);
    }, [
      clearAlignersData,
      alignersFilteredData,
      refinementsIncludedFiltersSelected,
      trimLinesFiltersSelected,
      alignersTrimLinesFilteredData,
      sortBy,
    ]);

    const clearAllFiltersSection = (
      <div
        onClick={clearAllFilters}
        className="flex cursor-pointer items-center justify-center text-center text-red-600 underline"
      >
        Clear All
      </div>
    );

    const filterTooltip = (
      <div className="p-2 text-black">
        Packages are automatically sorted by price. Click <br /> on the buttons to show packages that are available{' '}
        <br /> by number of refinements or available trim-line. <br /> Please note: most materials, but not all, are{' '}
        <br /> available with scalloped or straight-edge trim lines.
      </div>
    );

    const renderCheckbox = (label: string) => (
      <Checkbox value={label}>
        {label}
        <CloseFilterSVG />
      </Checkbox>
    );

    return (
      <div className="clearaligner-options grid grid-cols-12 gap-4 border-0 border-t border-solid border-gray-100 py-2">
        <Skeleton loading={isLoading}>
          {submissionInfo?.planType === SUBMISSION_PLAN_TYPE.INITIAL && (
            <div className="col-span-12 my-1">
              <div className="flex flex-row items-center gap-2 p-3">
                <FilterSVG width={18} height={18} />
                <div className="filter-new-feature">Filter</div>
                <Popover content={filterTooltip} placement="right" color="white" overlayStyle={{ maxWidth: 'none' }}>
                  <AlertCircleSVG width={18} height={18} className="cursor-context-menu" />
                </Popover>
              </div>
              <div className="recommended-filters flex flex-row flex-wrap justify-between">
                <div className="flex flex-wrap">
                  <Checkbox.Group
                    value={alignersFilteredData}
                    className="flex flex-row text-sm"
                    onChange={handleChange}
                  >
                    <div className="flex flex-col">
                      <div className="col-span-12 mb-2 ml-3 sm:col-span-6">Refinements Included</div>
                      <div className="flex flex-col flex-wrap md:flex-row">
                        {renderCheckbox(ESkuFilterOption.PAY_AS_YOU_GO)}
                        {renderCheckbox(ESkuFilterOption.ONE_REFINEMENT)}
                        {renderCheckbox(ESkuFilterOption.TWO_REFINEMENTS)}
                      </div>
                    </div>
                  </Checkbox.Group>
                  <Checkbox.Group
                    value={alignersTrimLinesFilteredData}
                    className="flex flex-row text-sm"
                    onChange={handleTrimLinesChange}
                  >
                    <div className="ml-1 flex flex-col">
                      <div className="col-span-12 mb-2 ml-3 sm:col-span-6">Trim Lines Available</div>
                      <div className="flex flex-col flex-wrap md:flex-row">
                        {renderCheckbox(ESkuFilterOption.STRAIGHT_EDGE)}
                        {renderCheckbox(ESkuFilterOption.SCALLOPED)}
                        <div className="ml-6 mt-2 flex">
                          {(refinementsIncludedFiltersSelected || trimLinesFiltersSelected) &&
                            isMobile &&
                            clearAllFiltersSection}
                        </div>
                      </div>
                    </div>
                  </Checkbox.Group>
                  <Radio.Group value={sortBy} className="flex flex-row  text-sm">
                    <div className="flex flex-col">
                      <div className="col-span-12 mb-2 ml-3 sm:col-span-6">Sort by</div>
                      <div className="flex flex-col flex-wrap gap-1 border md:flex-row">
                        {SORT_OPTIONS.map(({ label, value }) => (
                          <Radio.Button
                            value={value}
                            className="!rounded-2xl !border-l before:!hidden"
                            onClick={() => handleSortChange(value)}
                            key={label}
                          >
                            {value.orders === 'asc' ? (
                              <SortAscendingOutlined className="text-xs" />
                            ) : (
                              <SortDescendingOutlined className="text-xs" />
                            )}
                            <span className="ml-1">{label}</span>
                          </Radio.Button>
                        ))}
                      </div>
                    </div>
                  </Radio.Group>
                </div>
                {(refinementsIncludedFiltersSelected || trimLinesFiltersSelected || sortBy) && !isMobile && (
                  <div className="ml-4 mt-4 flex min-w-fit items-center">{clearAllFiltersSection}</div>
                )}
              </div>
            </div>
          )}
          {filteredData?.map((sku: ISkuType, index: number) => (
            <div key={sku.id} className="col-span-12 px-[1px] lg:col-span-6" ref={index === 0 ? ref : null}>
              <SkuCardMarket sku={sku} currencySymbol={currency} onViewDetail={() => viewSkuDetails(sku)} />
            </div>
          ))}
          {!isEmpty(clearAlignersData) && isEmpty(filteredData) && (
            <div className="col-span-12 text-center text-[#555770]">
              <div className="text-2xl">😕</div>
              That’s odd. We’re sorry but nothing matches your choices. You can adjust your filters or contact the Admin
              in the chat tool. We’ll see if we can create a package that works for you.
            </div>
          )}
        </Skeleton>
      </div>
    );
  },
);
