import NiceModal from '@ebay/nice-modal-react';
import { WhatIsNewModal } from 'components/what-is-new-modal/what-is-new-modal';
import { useAuth } from './useAuth';

export const useWhatIsNewModal = (): { isLoaded: boolean; onShow: (() => Promise<unknown>) | undefined } => {
  const { user } = useAuth();

  const onShow = async () => NiceModal.show(WhatIsNewModal);

  return {
    isLoaded: !!user,
    onShow: user?.newRelease ? onShow : undefined,
  };
};
