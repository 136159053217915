import './react-phone-input-2-fork.css';
import './styles.less';
import './flag-override.less';
import cn from 'classnames';
import { FC, useEffect } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import { useGeolocationIp } from 'hooks/useGeolocationIp';
import { isNil } from 'lodash';
import { useCountryOptions } from 'hooks/useCountryOptions';
import { formatNumber } from './utils';

export interface IMobileNumberInputValue {
  ext?: string;
  number?: string | number;
}
export interface IMobileNumberInputProps extends Omit<PhoneInputProps, 'value' | 'onChange'> {
  value?: IMobileNumberInputValue;
  onChange?: (value: IMobileNumberInputValue | null) => void;
  numberPlaceholder?: string;
  inputClassName?: string;
  disabled?: boolean;
  autoDetectCode?: boolean;
  renderDropdownAbove?: boolean;
  id?: string;
}

export const MobileNumberInput: FC<IMobileNumberInputProps> = (props) => {
  const {
    numberPlaceholder = 'Enter phone number',
    value,
    onChange,
    inputClassName = '',
    disabled,
    autoDetectCode = true,
    renderDropdownAbove = false,
    id,
    ...rest
  } = props;
  const geo = useGeolocationIp();
  const { getCountryInfoFromCode } = useCountryOptions();

  useEffect(() => {
    if (geo?.country && !value?.ext && getCountryInfoFromCode && autoDetectCode) {
      const country = getCountryInfoFromCode(geo.country);
      onChange?.({ ext: country.ext });
    }
  }, [geo, getCountryInfoFromCode, autoDetectCode]);

  const inputValue = `${(value?.ext ?? '')?.replace('+', '')}${formatNumber(value?.ext, value?.number)}` || null;

  const handleChange = (phone: string, country: { dialCode: string }) => {
    if (country?.dialCode ? phone?.length < country?.dialCode?.length : phone?.length === 0) {
      // clear country once user hit backspace dial code
      onChange?.(null);
    } else {
      const ext = country?.dialCode ? `+${country?.dialCode}` : undefined;
      const number = !isNil(phone) && phone !== '' ? `+${phone}`.replace(ext || '', '').replace('+', '') : phone;
      const formattedNumber = formatNumber(ext, number);
      onChange?.({ ext, number: formattedNumber });
    }
  };

  return (
    // need add id here for scroll to error
    // ref https://ant.design/components/form#scrolltofirsterror-and-scrolltofield-not-working-on-custom-form-control
    <div id={id}>
      <PhoneInput
        {...rest}
        containerClass={cn(
          'ant-input mobile-number-input-container',
          disabled && 'cursor-not-allowed bg-gray-100 hover:border-[#d9d9d9]',
          inputClassName,
        )}
        inputClass="mobile-number-input ant-input"
        buttonClass="mobile-number-button"
        dropdownClass={cn('mobile-number-dropdown', renderDropdownAbove && 'bottom-8')}
        value={inputValue}
        onChange={handleChange}
        placeholder={numberPlaceholder}
        searchPlaceholder="Search country"
        enableSearch
        disabled={disabled}
        masks={{ gb: '.... .......' }}
      />
    </div>
  );
};
