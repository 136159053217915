export enum EEventNames {
  SIGN_UP_STARTED = 'Sign Up Started',
  SIGN_UP_ENDED = 'Sign Up Ended',
  SIGN_UP_COMPLETED = 'Sign Up Completed',
  LOGIN_COMPLETED = 'Login Completed',
  ONBOARDING_BOOKED = 'Onboarding Booked',
  ONBOARDING_REBOOKED = 'Onboarding Rebooked',
  DENTIST_ONBOARDED = 'Dentist Onboarded',
  PATIENT_VIEWED = 'Patient Viewed',
  NOTIFICATION_VIEWED = 'Notification Viewed',
  NOTIFICATION_OPENED = 'Notification Opened',
  CHAT_MESSAGE_SENT = 'Chat Message Sent',
  SUBMISSION_STARTED = 'Submission Started',
  SUBMISSION_EDITED = 'Submission Edited',
  SUBMISSION_COMPLETED = 'Submission Completed',
  SUBMISSION_ENDED = 'Submission Ended',
  TREATMENT_DESIGN_VIEWED = 'Treatment Design Viewed',
  TREATMENT_DESIGN_VIEW_ENDED = 'Treatment Design View Ended',
  ALIGNER_PACKAGE_VIEWED = 'Aligner Package Viewed',
  CASE_ORDERED = 'Case Ordered',
  INVOICE_PAID = 'Invoice Paid',
  CALCULATOR_STARTED = 'Calculator Started',
  CALCULATOR_COMPLETED = 'Calculator Completed',
  EDUCATION_VIEWED = 'Education Viewed',
  EDUCATION_VIEW_ENDED = 'Education View ENDED',
  EDUCATION_RATED = 'Education Rated',
  GUIDE_VIEWED = 'Guide Viewed',
  GUIDE_VIEW_END = 'Guide View ended',
  PATIENT_PROPOSAL_STARTED = 'Patient Proposal Started',
  PATIENT_PROPOSAL_COMPLETED = 'Patient Proposal Completed',
  REMOTE_MONITORING_STARTED = 'Remote Monitoring Started',
  REMOTE_MONITORING_VIEWED = 'Remote Monitoring Viewed',
  FELLING_CHRISTMASSY_CLICKED = 'Feeling Christmassy Clicked',
  LESS_CHRISTMASSY_CLICKED = 'Less Christmassy Clicked',
  FIRE_CRACKLE_CLICKED = 'Fire Crackle Clicked',
  CHRISTMAS_CRACKER_CLICKED = 'Christmas Cracker Clicked',
  // interested patient
  INTERESTED_PATIENT_SENT_PIS = 'Potential Patient Sent PIS',
  INTERESTED_PATIENT_SENT_SQ = 'Potential Patient Sent SQ',
  INTERESTED_PATIENT_SENT_BULK_SQS = 'Potential Patient Sent BULK SQs',
  INTERESTED_PATIENT_BULK_SQS_CLICKED = 'Potential Patient BULK SQs Clicked',
  INTERESTED_PATIENT_BULK_SQS_CANCELED = 'Potential Patient BULK SQs Canceled',
  INTERESTED_PATIENT_BULK_SQS_ADD_PATIENT = 'Potential Patient BULK SQs Add Patient',
  INTERESTED_PATIENT_SHARE_SQ_LINK_CLICKED = 'Potential Patient Share SQ Link Clicked',
  INTERESTED_PATIENT_SHARE_SQ_LINK_COPIED = 'Potential Patient Share SQ Link Copied',
  INTERESTED_PATIENT_SHARE_SQ_EMAIL_TEMPLATE_COPIED = 'Potential Patient Share SQ Email Template Copied',
  INTERESTED_PATIENT_ADD_NEW_PATIENT = 'Potential Patient Add New Patient',
  INTERESTED_PATIENT_ARCHIVE_PATIENT = 'Potential Patient Archive Patient',
  INTERESTED_PATIENT_PIS_VIEWED = 'Potential Patient PIS VIEWED',
  INTERESTED_PATIENT_SQ_VIEWED = 'Potential Patient SQ VIEWED',
  PATIENT_TREATMENT_COMPLETED = 'Patient Treatment Completed',
  PATIENT_SMILE_STORY_SENT = 'Patient Smile Story Sent',
  PATIENT_CASE_STUDY_CREATED = 'Patient Case Study Created',
  PATIENT_CONSENT = 'Patient Consent',
  PATIENT_CASE_STUDY_SENT_TO_ADMIN_APPROVAL = 'Patient Case Sent To Admin Approval',
  PATIENT_TESTIMONIAL_SUBMITTED = 'Patient Testimonial Submitted',
  PATIENT_SMILE_STORY_VIEWED = 'Patient Smile Story Viewed',
  PATIENT_SMILE_STORY_SHARED_VIA_WHATSAPP = 'Patient Smile Story Shared Via WhatsApp',
  PATIENT_SMILE_STORY_SHARED_VIA_EMAIL = 'Patient Smile Story Shared Via Email',
  PATIENT_SMILE_STORY_LINK_COPIED = 'Patient Smile Story Link Copied',
  // growth hub
  GROWTH_HUB_VIEWED = 'Growth Hub Viewed',
  GROWTH_HUB_SEND_QUESTIONNARIE_CLICKED = 'Growth Hub Send Questionnarie Clicked',
  GROWTH_HUB_SHARE_REVIEW_CLICKED = 'Growth Hub Share Review Clicked',
  GROWTH_HUB_SEND_PROPOSAL_CLICKED = 'Growth Hub Send Proposal Clicked',
  GROWTH_HUB_CREATE_CASE_STUDY_CLICKED = 'Growth Hub Create Case Study Clicked',
  GROWTH_HUB_VIEW_MY_CASES_CLICKED = 'Growth Hub View My Cases Clicked',
  GROWTH_HUB_SHARE_SMILE_STORY_CLICKED = 'Growth Hub Share Smile Story  Clicked',
  // new events shared by stephen
  GROWTH_HUB_DASHBOARD_MENU_CLICKED = 'Growth Hub Dashboard Menu Clicked',
  CASE_STUDIES_DASHBOARD_MENU_CLICKED = 'Case Studies Dashboard Menu Clicked',
  PATIENT_JOURNEY_POTENTIAL_PATIENTS_CLICKED = 'Patient Journey Potential Patients Clicked',
  PATIENT_JOURNEY_DRAFTS_CLICKED = 'Patient Journey Drafts Clicked',
  PATIENT_JOURNEY_IN_DESIGN_CLICKED = 'Patient Journey In Design Clicked',
  PATIENT_JOURNEY_AWAITING_APPROVAL_CLICKED = 'Patient Journey Awaiting Approval Clicked',
  PATIENT_JOURNEY_APPROVED_CLICKED = 'Patient Journey Approved Clicked',
  PATIENT_JOURNEY_IN_TREATMENT_CLICKED = 'Patient Journey In Treatment Clicked',
  PATIENT_JOURNEY_COMPLETE_CLICKED = 'Patient Journey Complete Clicked',
  GROWTH_HUB_FEEDBACK_THUMBS_UP_CLICKED = 'Growth Hub Feedback Thumbs Up Clicked',
  GROWTH_HUB_FEEDBACK_THUMBS_DOWN_CLICKED = 'Growth Hub Feedback Thumbs Down Clicked',
  SEARCH_FOR_A_PATIENT_CLICKED = 'Search For a Patient Clicked',
  NEW_POTENTIAL_PATIENT_CLICKED = 'New Potential Patient Clicked',
  START_CASE_CLICKED = 'Start Case Clicked',
  INTERESTED_PATIENT_EDIT_PATIENT_DETAILS_CLICKED = 'Interested Patient Edit Patient Details Clicked',
  INTERESTED_PATIENT_START_QUESTIONNAIRE_CLICKED = 'Interested Patient Start Questionnaire Clicked',
  INTERESTED_PATIENT_SEND_TO_PATIENT_CLICKED = 'Interested Patient Send To Patient Clicked',
  INTERESTED_PATIENT_CREATE_SMILE_REVIEW_CLICKED = 'Interested Patient Create Smile Review Clicked',
  INTERESTED_PATIENT_START_CASE_CLICKED = 'Interested Patient Start Case Clicked',
  // case study gallery
  CASE_STUDY_GALLERY_HOW_IT_WORKS = 'Case Study Gallery How It Works',
  CASE_STUDY_GALLERY_HALL_OF_FAME_CLICKED = 'Case Study Gallery Hall Of Fame Clicked',
  CASE_STUDY_GALLERY_MORE_FILTERS_CLICKED = 'Case Study Gallery More Filters Clicked',
  CASE_STUDY_GALLERY_CARD_CLICKED = 'Case Study Gallery Card Clicked',
  CASE_STUDY_DETAILS_BEFORE_IMAGE_PREVIEW_CLICKED = 'Case Study Details Before Image Preview Clicked',
  CASE_STUDY_DETAILS_AFTER_IMAGE_PREVIEW_CLICKED = 'Case Study Details After Image Preview Clicked',
  CASE_STUDY_DETAILS_PREVIOUS_CASE_CLICKED = 'Case Study Details Previous Case Clicked',
  CASE_STUDY_DETAILS_NEXT_CASE_CLICKED = 'Case Study Details Next Case Clicked',
  CASE_STUDY_BACK_TO_GALLERY_CLICKED = 'Case Study Details Back To Gallery Clicked',

  // SQ events
  SQ_SMILE_RATING_SELECTED = 'Smile Rating Selected',
  SQ_SMILE_RATING_INFO_CLOSE_ICON_CLICKED = 'Smile Rating Info Close Icon Clicked',
  SQ_QUESTION_NEW_CHANGE_INFO_CLOSE_ICON_CLICKED = 'SQ Question New Change Info Close Icon Clicked',
  SQ_QUESTION_NEW_CONCERN_INFO_CLOSE_ICON_CLICKED = 'SQ Question New Concern Info Close Icon Clicked',
  SQ_QUESTION_IMAGE_UPLOADED = 'SQ Question Image Uploaded',
  SQ_QUESTION_IMAGE_DELETED = 'SQ Question Image Deleted',
  SQ_COMPLETE_AND_SEND_CLICKED = 'SQ Complete And Send Clicked',
  SQ_VIEW_YOUR_ANSWERS_COLLAPSE_CLICKED = 'SQ View Your Answers Collapse Clicked',

  // banner video event
  BANNER_VIDEO_CLOSE = 'Close banner video',
}

export type TTrackingSubmission = {
  submissionId: string;
  planType: string;
  serviceType: string;
  submissionStatus: string;
  practiceId?: string;
};
