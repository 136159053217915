import { ICalendlyScheduleDataType } from 'components/calendly-booking-modal';
import { IClinic } from 'services/patients-api/types';
import { IDiscount } from 'services/sku-api/types';
import { USER_ROLES } from 'config/constants';
import { ICorporate } from '../dentist-signup/steps/practice/corporates-select';
import { EProvider } from 'components/sniper-link/utils';

export enum UserStatuses {
  APPLIED = 'APPLIED',
  INVITED = 'INVITED',
  PENDING_PACKAGE = 'PENDING_PACKAGE',
  LIVE = 'LIVE',
}

export type TUserType = 'dentist' | 'designer' | 'manufacturer' | 'specialist' | 'admin' | 'technician';

// remove TUserTypeUpperCase when BE add user type lowerCase
export type TUserTypeUpperCase = 'DENTIST' | 'DESIGNER' | 'MANUFACTURER' | 'SPECIALIST' | 'ADMIN';
export interface IUserOnboarding {
  calendlyEventLink: string;
  isValidDentist: boolean;
  isEmailVerified: boolean;
  isOnboardingBooked: boolean;
  isOnboardingComplete: boolean;
  reminded: boolean;
  onboardingDate: string;
  onBoardingAnswerDetails: { onBoardingAnswer: string }[];
}

export interface IGrowthHubMeeting {
  calendlyEventLink: string;
  createdAt: string;
  isBooked: boolean;
  startAt: string;
  isCompleted: boolean;
  reminded: boolean;
  updatedAt: string;
  url?: string;
}

export interface IUser {
  firstName: string;
  lastName: string;
  fullName?: string;
  prefix?: string;
  dob: string;
  phone: TPhone;
  email: string;
  isEmailVerified: boolean;
  country: string;
  currency?: {
    name: string;
    symbol: string;
  };
  profilePic: string;
  role: TUserTypeUpperCase;
  type: USER_ROLES; // is the lower case of `role`, client only
  deleted: boolean;
  isActive: boolean;
  isOnline: boolean;
  referralCode: string;
  id: string;
  meeting: ICalendlyScheduleDataType;
  status: UserStatuses;
  showOnboardingTutorial?: boolean;

  newContentAssigned: boolean;
  completePercent: number;
  newRelease?: boolean;

  completeProfileDiscountClaimed?: boolean;
  onBoarding: IUserOnboarding;
  clinics?: IClinic[];
  preferences: {
    screenName: string;
    bio: string;
    professionalCV: string;
  };
  licenseNumber?: string;

  isUnlockRequested?: boolean;
  practices?: IClinic[];
  discount?: IDiscount;
  isViewedComplexityCalculator?: boolean;
  corporates?: ICorporate[];
  emailProvider: EProvider;
  associatedWithDentalCorporates?: ICorporate[];
  lastLoggedInAt?: string;
  viewedCaseStudyCount?: number;
  viewedGrowthHubCount?: number;
  shownGrowthHubMeetingGuide?: boolean;
  growthHubMeeting: IGrowthHubMeeting;
  isShownInterestedPatientGuide?: boolean;
  isUserViewedCreatePatientProposal?: boolean;
  isShownCaseStudyGuide?: boolean;
  bannerVideoSetting: {
    isPreLaunchVideoShown: boolean;
    isPostLaunchVideoShown: boolean;
    preLauchVideoUrl: string;
    postLauchVideoUrl: string;
  };
}
