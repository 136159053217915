/* eslint-disable prefer-template */
import { SUBMISSION_STATUS_TYPE } from 'config/constants';
import { ISubmission } from 'services/patients-api/types';

export const buildSubmisionUrl = (
  submision: Pick<ISubmission, 'status' | 'serviceType' | 'id' | 'planType'>,
  additionalParams?: Record<string, any>,
) => {
  const queryString = new URLSearchParams(additionalParams).toString();

  return submision.status === SUBMISSION_STATUS_TYPE.DRAFT
    ? `/dentist/patients/submit/${submision.serviceType}/${submision.planType}?submissionId=${submision.id}${
        queryString ? '&' + queryString : ''
      }`
    : `/dentist/patients/${submision.id}${queryString ? '?' + queryString : ''}`;
};

export const buildOrderElmId = (orderId: string) => `ORDER_ID_${orderId}`;

export const buildRetainerElmId = (retainerId: string) => `RETAINER_${retainerId}`;
