import { Radio, RadioGroupProps, Space } from 'antd';
import { FC, forwardRef } from 'react';

export interface IAntdRadioGroupProps extends Omit<RadioGroupProps, 'options' | 'onChange'> {
  options: Array<{ label: string; value: string | boolean }>;
  isHorizontal?: boolean;
  onChange?: (v: string) => void;
}

export const AntdRadioGroup: FC<IAntdRadioGroupProps> = forwardRef(
  ({ options, isHorizontal, value, onChange, ...restProps }, ref: any) => (
    <Radio.Group {...restProps} value={value} ref={ref}>
      <Space direction={isHorizontal ? 'horizontal' : 'vertical'}>
        {options?.map((c) => (
          <Radio
            key={c.label}
            value={c.value}
            onClick={(e) => {
              const nextValue = (e.target as any).value;
              onChange?.(nextValue !== value ? nextValue : undefined);
            }}
          >
            {c.label}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  ),
);
