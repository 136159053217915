import { TMetaOptionsResponse } from 'services/api';

export const VIEWS_TO_SHOW_NEW_CASE_STUDY_BADGE = 3;
export const VIEWS_TO_SHOW_NEW_GROWTH_HUB_BADGE = 3;

export const CASE_STUDY_TAG_COLOR_MAPPING: Record<string, string> = {
  diagnosisCrowding: '#1890FF',
  diagnosisOcclusion: '#1890FF',
  diagnosisOther: '#1890FF',
  diagnosisRotations: '#1890FF',
  treatmentArches: '#722ED1',
  treatmentCosmetic: '#722ED1',
  treatmentOrthodontic: '#722ED1',
  result: '#039F61',
  totalStages: '#EB2F96',
  treatmentLength: '#EB2F96',
  totalRefinements: '#EB2F96',
  material: '#555770',
  trimLine: '#555770',
};

export enum EImageTag {
  Before = 'Before',
  After = 'After',
  PortraitBefore = 'Portrait Before',
  LandscapeBefore = 'Landscape Before',
  PortraitAfter = 'Portrait After',
  LandscapeAfter = 'Landscape After',
}

export const DIAGNOSIS_PARENT_MAPPING = [
  {
    label: 'Crowding',
    value: 'diagnosisCrowding',
  },
  {
    value: 'diagnosisRotations',
    label: 'Rotations',
  },
  {
    value: 'diagnosisOcclusion',
    label: 'Occlusion',
  },
  {
    value: 'diagnosisOther',
    label: 'Other Clinical Factors',
  },
];

export const TREATMENT_PARENT_MAPPING = [
  {
    label: 'Orthodontic',
    value: 'treatmentOrthodontic',
  },
  {
    label: 'Cosmetic',
    value: 'treatmentCosmetic',
  },
];

export type TOption = TOptionString & {
  children?: TOption[];
};

export const getFilterOptions = (metaOptions: TMetaOptionsResponse[], parentMapping: TOptionString[]): TOption[] => {
  const options = parentMapping?.reduce<TOption[]>((acc, item) => {
    const metaOptionItem = metaOptions.find((option) => option.fieldKey === item.value);
    const children =
      metaOptionItem?.options?.map((option) => ({
        label: option.label,
        value: option.value,
      })) || [];
    if (children.length > 0) {
      return acc.concat({
        ...item,
        children,
      });
    }
    return acc;
  }, []);

  return options;
};

export const getFilterOptionsForDiagnosis = (metaOptions: TMetaOptionsResponse[], parentMapping: any[]): TOption[] => {
  const options = parentMapping?.reduce<TOption[]>((acc, item) => {
    const metaOptionItem = metaOptions.find((option) => option.fieldKey === item.value);
    const children =
      metaOptionItem?.options?.map((option) => ({
        label: option.label,
        value: option.value,
      })) || [];
    if (children.length > 0) {
      return acc.concat({
        ...item,
        children,
        checkable: false,
      });
    }
    return acc;
  }, []);

  return options;
};

export const getCascaderFiltersValue = (values?: string[]): Record<string, string[]> | undefined =>
  values?.reduce<Record<string, string[]>>((acc, datum) => {
    const [key, value] = datum.split(',');
    if (acc[key]) {
      acc[key] = acc[key].concat(value);
    } else {
      acc[key] = [value];
    }
    return acc;
  }, {});

export const queryParamToFilterValue = (queryParam?: string[]): string[][] | undefined =>
  queryParam?.map((value) => value.split(','));
