/* eslint-disable no-underscore-dangle */
import { ReactNode } from 'react';
import { roundDecimalNumber } from 'utils';
import { getBoldValue } from './get-bold-value';

export interface IFormatSaveSTLs {
  url: string;
  type: string | undefined;
  name: string;
  uploadedAt?: string;
  id?: string;
  size?: number;
}

export const IPRCSVparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_'),
};

export const handleIPRCSVFileChange = (data: any) => {
  const maxilla: any = [];
  let maxillaHeaders: any = [];
  const mandible: any = [];
  let mandibleHeaders: any = [];

  let isMandible = false;

  data.forEach((d: any) => {
    if (d.maxilla === 'Mandible') {
      isMandible = true;
    }
    if (!isMandible) {
      if (d.maxilla !== 'Total') {
        maxilla.push(d.__parsed_extra);
      }
    } else if (d.maxilla !== 'Total' && d.maxilla !== 'Mandible') {
      mandible.push(d.__parsed_extra);
    }
  });

  maxillaHeaders = [...maxilla.splice(0, 1)[0]].filter((d: any) => d != null);
  mandibleHeaders = [...mandible.splice(0, 1)[0]].filter((d: any) => d != null);

  const maxillaArrayObject: any = maxilla.map((m: any) => {
    const obj: any = {};
    m.forEach((d: any, i: any) => {
      obj[maxillaHeaders[i]] = d;
    });
    return obj;
  });

  const mandibleArrayObject: any = mandible.map((m: any) => {
    const obj: any = {};
    m.forEach((d: any, i: any) => {
      obj[mandibleHeaders[i]] = d;
    });
    return obj;
  });

  const finalMaxillaArrayObject = maxillaArrayObject.map((res: any) => {
    const obj = {
      '18m-17d': roundDecimalNumber(parseFloat(res['18m'] ? res['18m'] : 0) + parseFloat(res['17d'] ? res['17d'] : 0)),
      '17m-16d': roundDecimalNumber(parseFloat(res['17m'] ? res['17m'] : 0) + parseFloat(res['16d'] ? res['16d'] : 0)),
      '16m-15d': roundDecimalNumber(parseFloat(res['16m'] ? res['16m'] : 0) + parseFloat(res['15d'] ? res['15d'] : 0)),
      '15m-14d': roundDecimalNumber(parseFloat(res['15m'] ? res['15m'] : 0) + parseFloat(res['14d'] ? res['14d'] : 0)),
      '14m-13d': roundDecimalNumber(parseFloat(res['14m'] ? res['14m'] : 0) + parseFloat(res['13d'] ? res['13d'] : 0)),
      '13m-12d': roundDecimalNumber(parseFloat(res['13m'] ? res['13m'] : 0) + parseFloat(res['12d'] ? res['12d'] : 0)),
      '12m-11d': roundDecimalNumber(parseFloat(res['12m'] ? res['12m'] : 0) + parseFloat(res['11d'] ? res['11d'] : 0)),
      '11m-21m': roundDecimalNumber(parseFloat(res['11m'] ? res['11m'] : 0) + parseFloat(res['21m'] ? res['21m'] : 0)),
      '21d-22m': roundDecimalNumber(parseFloat(res['21d'] ? res['21d'] : 0) + parseFloat(res['22m'] ? res['22m'] : 0)),
      '22d-23m': roundDecimalNumber(parseFloat(res['22d'] ? res['22d'] : 0) + parseFloat(res['23m'] ? res['23m'] : 0)),
      '23d-24m': roundDecimalNumber(parseFloat(res['23d'] ? res['23d'] : 0) + parseFloat(res['24m'] ? res['24m'] : 0)),
      '24d-25m': roundDecimalNumber(parseFloat(res['24d'] ? res['24d'] : 0) + parseFloat(res['25m'] ? res['25m'] : 0)),
      '25d-26m': roundDecimalNumber(parseFloat(res['25d'] ? res['25d'] : 0) + parseFloat(res['26m'] ? res['26m'] : 0)),
      '26d-27m': roundDecimalNumber(parseFloat(res['26d'] ? res['26d'] : 0) + parseFloat(res['27m'] ? res['27m'] : 0)),
      '27d-28m': roundDecimalNumber(parseFloat(res['27d'] ? res['27d'] : 0) + parseFloat(res['28m'] ? res['28m'] : 0)),
    };
    return obj;
  });

  const finalMandibleArrayObject = mandibleArrayObject.map((res: any) => {
    const obj = {
      '48m-47d': roundDecimalNumber(parseFloat(res['48m'] ? res['48m'] : 0) + parseFloat(res['47d'] ? res['47d'] : 0)),
      '47m-46d': roundDecimalNumber(parseFloat(res['47m'] ? res['47m'] : 0) + parseFloat(res['46d'] ? res['46d'] : 0)),
      '46m-45d': roundDecimalNumber(parseFloat(res['46m'] ? res['46m'] : 0) + parseFloat(res['45d'] ? res['45d'] : 0)),
      '45m-44d': roundDecimalNumber(parseFloat(res['45m'] ? res['45m'] : 0) + parseFloat(res['44d'] ? res['44d'] : 0)),
      '44m-43d': roundDecimalNumber(parseFloat(res['44m'] ? res['44m'] : 0) + parseFloat(res['43d'] ? res['43d'] : 0)),
      '43m-42d': roundDecimalNumber(parseFloat(res['43m'] ? res['43m'] : 0) + parseFloat(res['42d'] ? res['42d'] : 0)),
      '42m-41d': roundDecimalNumber(parseFloat(res['42m'] ? res['42m'] : 0) + parseFloat(res['41d'] ? res['41d'] : 0)),
      '41m-31m': roundDecimalNumber(parseFloat(res['41m'] ? res['41m'] : 0) + parseFloat(res['31m'] ? res['31m'] : 0)),
      '31d-32m': roundDecimalNumber(parseFloat(res['31d'] ? res['31d'] : 0) + parseFloat(res['32m'] ? res['32m'] : 0)),
      '32d-33m': roundDecimalNumber(parseFloat(res['32d'] ? res['32d'] : 0) + parseFloat(res['33m'] ? res['33m'] : 0)),
      '33d-34m': roundDecimalNumber(parseFloat(res['33d'] ? res['33d'] : 0) + parseFloat(res['34m'] ? res['34m'] : 0)),
      '34d-35m': roundDecimalNumber(parseFloat(res['34d'] ? res['34d'] : 0) + parseFloat(res['35m'] ? res['35m'] : 0)),
      '35d-36m': roundDecimalNumber(parseFloat(res['35d'] ? res['35d'] : 0) + parseFloat(res['36m'] ? res['36m'] : 0)),
      '36d-37m': roundDecimalNumber(parseFloat(res['36d'] ? res['36d'] : 0) + parseFloat(res['37m'] ? res['37m'] : 0)),
      '37d-38m': roundDecimalNumber(parseFloat(res['37d'] ? res['37d'] : 0) + parseFloat(res['38m'] ? res['38m'] : 0)),
    };
    return obj;
  });

  const result: { step: null | number; data: any }[] = [];
  for (let i = 0; i < finalMaxillaArrayObject.length; i++) {
    const maxillaObj = finalMaxillaArrayObject[i];
    const mandibleObj = finalMandibleArrayObject[i];

    let hasValues = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in maxillaObj) {
      if (maxillaObj[key] * 10 > 0) {
        hasValues = true;
      }
    }

    if (!hasValues) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in mandibleObj) {
        if (mandibleObj[key] * 10 > 0) {
          hasValues = true;
        }
      }
    }

    if (hasValues) {
      result.push({
        step: i,
        data: {
          ...(finalMaxillaArrayObject[i] as {}),
          ...(finalMandibleArrayObject[i] as {}),
        },
      });
    }
  }

  return { iprData: result, totalStep: finalMaxillaArrayObject.length - 1 };
};

const IGNORE_ITEMS = ['Strip Mesial [mm]', 'Strip Distal [mm]', 'Inclination [°]', 'Angulation [°]'];

const VALUE_MAPPING: Record<
  string,
  { negative?: string; positive?: string; boldRange?: number; title: string; order: number }
> = {
  Tooth: {
    title: '',
    order: 0,
  },
  'Occlusal +/- [mm]': {
    negative: 'l',
    positive: 'E',
    boldRange: 2,
    title: 'Extrusion/ Intrusion [mm]',
    order: 6,
  },
  'Vestibular +/- [mm]': {
    negative: 'L',
    positive: 'B',
    boldRange: 3,
    title: 'Translation Buccal/Lingual [mm]',
    order: 2,
  },
  'Mesial +/- [mm]': {
    negative: 'D',
    positive: 'M',
    boldRange: 3,
    title: 'Translation Mesial/Distal [mm]',
    order: 1,
  },

  'Rotation +/- [°]': {
    negative: 'D',
    positive: 'M',
    boldRange: 35,
    title: 'Rotation +/- [°]',
    order: 3,
  },
  'Angulation +/- [°]': {
    negative: 'D',
    positive: 'M',
    boldRange: 10,
    title: 'Angulation +/- [°]',
    order: 4,
  },
  'Inclination +/- [°]': {
    negative: 'L',
    positive: 'B',
    boldRange: 10,
    title: 'Inclination +/- [°]',
    order: 5,
  },
};

const parseValue = (
  title: string,
  values: (number | null)[],
): { parsedValues: (number | string | ReactNode | null)[]; hasBoldValue: boolean } => {
  let hasBoldValue: boolean = false;

  const valueMapping = VALUE_MAPPING[title];
  if (!valueMapping?.boldRange || !valueMapping?.negative || !valueMapping?.positive) {
    return {
      parsedValues: values,
      hasBoldValue: false,
    };
  }

  const parsedValues = values.map((value) => {
    if (value === null) {
      return null;
    }
    const isNegative = value < 0;
    const isBold = Math.abs(value) > valueMapping.boldRange!;
    if (isBold) {
      hasBoldValue = true;
    }
    return isNegative
      ? getBoldValue(`${Math.abs(value)} ${valueMapping.negative}`, isBold)
      : getBoldValue(`${value} ${valueMapping.positive}`, isBold);
  });
  return { parsedValues, hasBoldValue };
};

const NUMBER_OF_ROWS = 7;

export const handleTMCSVFileChange = (data: any) => {
  const maxilla: any = new Array(NUMBER_OF_ROWS).fill(null);
  const maxillaLeftItems: any = new Array(NUMBER_OF_ROWS).fill(null);
  let maxillaHeaders: any = [];

  const mandible: any = new Array(NUMBER_OF_ROWS).fill(null);
  let mandibleHeaders: any = [];
  const mandibleLeftItems: any = new Array(NUMBER_OF_ROWS).fill(null);

  let isMandible = false;
  let hasBoldValue = false;
  data.forEach((d: any) => {
    if (d.maxilla === 'Mandible') {
      isMandible = true;
    }
    if (!IGNORE_ITEMS.includes(d.maxilla)) {
      const valueMapping = VALUE_MAPPING[d.maxilla];
      if (!isMandible) {
        d.__parsed_extra.splice(d.__parsed_extra.length - 1, 1);
        const { parsedValues, hasBoldValue: hasBoldValueInRow } = parseValue(d.maxilla, d.__parsed_extra);
        if (hasBoldValueInRow) {
          hasBoldValue = true;
        }
        maxilla.splice(valueMapping.order, 1, [d[''], ...parsedValues]);
        maxillaLeftItems.splice(valueMapping.order, 1, valueMapping?.title ?? d.maxilla);
      } else if (d.maxilla !== 'Mandible') {
        d.__parsed_extra.splice(d.__parsed_extra.length - 1, 1);
        const { parsedValues, hasBoldValue: hasBoldValueInRow } = parseValue(d.maxilla, d.__parsed_extra);
        if (hasBoldValueInRow) {
          hasBoldValue = true;
        }
        mandible.splice(valueMapping.order, 1, [d[''], ...parsedValues]);
        mandibleLeftItems.splice(valueMapping.order, 1, valueMapping?.title ?? d.maxilla);
      }
    }
  });

  maxillaHeaders = [...maxilla.splice(0, 1)[0]].filter((d: any) => d != null).map((d: number) => d / 10);
  mandibleHeaders = [...mandible.splice(0, 1)[0]].filter((d: any) => d != null).map((d: number) => d / 10);

  return {
    maxillaLeftItems,
    mandibleLeftItems,
    maxillaHeaders,
    mandibleHeaders,
    maxilla,
    mandible,
    hasBoldValue,
  };
};

export const formatSaveImage = (data: any[] = []) =>
  data.map((item: any) => ({
    type: '',
    ...item,
    url: item.url,
    name: item.name,
  }));

export const formatSaveSTLsImage = (data: any[] = []): IFormatSaveSTLs[] =>
  data.map((item: any) => ({
    type: '',
    ...item,
    size: item.size,
    url: item.url,
    name: item.name,
  }));

export const renderValue = (value: string | undefined) => value || '-';

export function duration(date: string) {
  const d = new Date().valueOf() - new Date(date).valueOf();
  const weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
  const days = Math.floor(d / 1000 / 60 / 60 / 24);
  const hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
  const minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60);
  const seconds = Math.floor(
    d / 1000 - weekdays * 7 * 24 * 60 * 60 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60,
  );
  const milliseconds = Math.floor(
    d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000,
  );

  return {
    weekdays: weekdays > 0 ? weekdays : undefined,
    days: days > 0 ? days : undefined,
    hours: hours > 0 ? hours : undefined,
    minutes: minutes > 0 ? minutes : undefined,
    seconds: seconds > 0 ? seconds : undefined,
    milliseconds: milliseconds > 0 ? milliseconds : undefined,
  };
}

export const getClickableLink = (link: string) => {
  if (!link) {
    return '#';
  }

  const hasProtocol = link.startsWith('http://') || link.startsWith('https://');
  const href = hasProtocol ? link : `https://${link}`;

  return href;
};

export const sIfPlural = (n: number): string => (n === 1 ? '' : 's');

const URL_REGEX = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const formatClickableContent = (text?: string) =>
  text?.replace(URL_REGEX, (url) => `<a href="${getClickableLink(url)}" target="_blank">${url}</a>`) || '';
