/* eslint-disable react/no-unstable-nested-components */
import { FC, useState } from 'react';
import { Spin } from 'antd';
import { useGetPracticeByPostCodeQuery } from 'services/api';
import { useDebounceState } from 'hooks/useDebounce';
import { IPractice } from 'services/practices-api/types';
import { SearchOutlined } from '@ant-design/icons';
import { NiceSelect } from 'components/commons/nice-select/nice-select';

export interface IMobileNumberInputValue {
  ext?: string;
  number?: string | number;
}

interface IProps {
  onSelect?: (value: IPractice) => void;
}

export const PracticeByPostCodeInput: FC<IProps> = (props) => {
  const { onSelect } = props;
  const [searchValue, setSearchValue] = useState('');
  const postCodeInput = useDebounceState(searchValue, 300);

  const { data, isFetching, isLoading } = useGetPracticeByPostCodeQuery(
    { postCode: postCodeInput },
    {
      refetchOnMountOrArgChange: true,
      skip: !postCodeInput,
    },
  );

  return (
    <NiceSelect
      placeholder="Find your practice by Postcode or Zip Code"
      showSearch
      searchValue={searchValue}
      onSearch={setSearchValue}
      allowClear={false}
      autoClearSearchValue={false}
      size="large"
      loading={isFetching || isLoading}
      onSelect={(_, option: IPractice) => {
        onSelect?.(option);
        setSearchValue('');
      }}
      className="custom-input"
      filterOption={false}
      options={data?.map((item) => ({
        ...item,
        label: item.name || item.address?.address1,
        value: item.id || item.address?.address1,
      }))}
      notFoundContent={
        !data?.length && (isFetching || isLoading) ? (
          <div className="text-center">
            <Spin size="small" />
          </div>
        ) : null
      }
      suffixIcon={<SearchOutlined />}
      dropdownRender={(menu) => (
        <Spin spinning={(isFetching || isLoading) && data?.length! > 0} size="small">
          {menu}
        </Spin>
      )}
    />
  );
};
