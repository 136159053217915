import { NiceCarousel } from 'components/commons/nice-carousel/nice-carousel';
import { sIfPlural } from 'helpers';
import { useDisableContextMenu } from 'hooks/useDisableContextMenu';
import { FC } from 'react';
import { IImage } from 'services/patients-api/types';
import { useAttachmentImages } from '../../useAttachmentImages';

interface IProps {
  attachments: IImage[];
  treatmentLength: number;
  totalStages: number;
}

export const CaseStudyImageCarousel: FC<IProps> = ({ attachments, treatmentLength, totalStages }) => {
  useDisableContextMenu();

  const { beforeImage, afterImage } = useAttachmentImages(attachments);

  return (
    <NiceCarousel dots={false}>
      <div className="flex h-full flex-col gap-[2px]">
        <div className="absolute left-0 right-0 top-1/2 -mt-3 ml-auto mr-auto w-fit rounded-sm bg-white px-1 py-[2px] text-sm">
          {`${treatmentLength} Month${sIfPlural(treatmentLength)} & ${totalStages} Stage${sIfPlural(totalStages)}`}
        </div>
        <img src={beforeImage[0]?.url} alt="after-img" className="w-full rounded-t-md object-contain" />
        <img src={afterImage[0]?.url} alt="before-img" className="w-full rounded-b-md object-contain" />
      </div>
      {/* {(portraitAfterImage ? [portraitAfterImage] : []).concat(portraitBeforeImage || []).map((img) => (
        <img src={img?.url} alt="after-img" className="h-full w-full rounded-md object-contain" key={img.url} />
      ))} */}
    </NiceCarousel>
  );
};
