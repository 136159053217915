import { Form, Input } from 'antd';
import { FC } from 'react';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import { RecapCardLayout } from '../recap-card-layout/recap-card-layout';
import { useLazyGetPatientCompletedTreatmentRecapByPasswordQuery } from 'services/completed-treatment-api/endpoints';

interface IProps {
  onLoggedIn: (password: string) => void;
  patientId: string;
}

export const PatientRecapLogin: FC<IProps> = ({ onLoggedIn, patientId }) => {
  const [form] = Form.useForm();
  const dob = Form.useWatch('dob', form);
  const [getPatientCompletedTreatmentRecapByPassword, { isLoading }] =
    useLazyGetPatientCompletedTreatmentRecapByPasswordQuery();

  const handleFinish = async (values: { dob: string }) => {
    try {
      await getPatientCompletedTreatmentRecapByPassword({ password: values.dob, patientId }).unwrap();
      onLoggedIn(values.dob);
    } catch (_e) {
      form.setFields([
        {
          name: 'dob',
          errors: ['Sorry, that password is incorrect. Please check and try again'],
        },
      ]);
    }
  };

  return (
    <RecapCardLayout title="Your Smile Story">
      <div className="mb-8 mt-16 text-center">
        Your dentist has created a recap of your <br />
        journey. Log in using your date of birth to check it out.
      </div>

      <Form form={form} layout="vertical" size="large" className="m-auto w-72 text-center" onFinish={handleFinish}>
        <div className="mb-4 text-base font-semibold">Enter your date of birth (e.g 02101998)</div>
        <FormItemControl
          name="dob"
          rules={[
            {
              pattern: /^[0-9]{2}[0-9]{2}[0-9]{4}$/,
              message: 'Please enter your DOB in format ddmmyyyy',
            },
          ]}
        >
          <Input placeholder="ddmmyyyy" className="custom-input w-56" />
        </FormItemControl>
        <NiceButton
          niceType="orange"
          disabled={!dob}
          size="small"
          className="mt-7 w-36"
          htmlType="submit"
          loading={isLoading}
        >
          Log in
        </NiceButton>
      </Form>
    </RecapCardLayout>
  );
};
