import { NiceModalHandler } from '@ebay/nice-modal-react';
import { Avatar } from 'antd';
import React, { FC } from 'react';
import ReactConfetti from 'react-confetti';
import referalDiscountRewardConfetti from 'assets/images/referral-discount-reward-confetti.png';
import NiceAntModal from '../commons/nice-ant-modal/nice-ant-modal.component';
import { NiceParticles } from 'components/nice-particles/nice-particles';
import { useAppSelector } from 'hooks/store';
import { selectChristmasEnabled } from 'redux/themeSlice';
import { EParticlesType } from 'components/nice-particles/particles-options';

const MODAL_WIDTH = 300;

type TProps = {
  modal: NiceModalHandler<Record<string, unknown>>;
  value: string;
  children: React.ReactNode;
  afterClose?: () => void;
  noAnimation?: boolean;
};

export const ModalCongratulation: FC<TProps> = ({ modal, value, children, afterClose, noAnimation }) => {
  const isChristmasTheme = useAppSelector(selectChristmasEnabled);

  return (
    <NiceAntModal
      width={MODAL_WIDTH}
      afterClose={afterClose}
      bodyStyle={{ padding: 14, paddingTop: 0, paddingBottom: 70 }}
      modal={modal}
    >
      <div className="flex flex-col items-center justify-center">
        {noAnimation ? (
          <div
            className="bg-darkBlueColor"
            style={{
              height: 124,
              width: MODAL_WIDTH,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          />
        ) : (
          <>
            {isChristmasTheme ? (
              <div className="absolute top-0 h-full">
                <NiceParticles id="tsparticles-confetti" optionType={EParticlesType.christmasConfetti} />
              </div>
            ) : (
              <ReactConfetti width={MODAL_WIDTH} tweenDuration={3000} recycle={false} />
            )}
            <img
              alt=""
              src={referalDiscountRewardConfetti}
              width={MODAL_WIDTH}
              height={124}
              className="bg-darkBlueColor"
              style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            />
          </>
        )}
        {!!value && (
          <Avatar
            style={{
              background: 'linear-gradient(261.89deg, #D8396D 0%, #DA5636 100%)',
            }}
            size={120}
            className="-mt-15 ml-1 mr-2 !text-4xl"
          >
            {value}
          </Avatar>
        )}
        {children}
      </div>
    </NiceAntModal>
  );
};
