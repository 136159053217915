import * as React from 'react';
import { AutoComplete } from 'antd';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import { FormItemControl, IFormItemControlProps } from 'components/form/form-item-control/form-item-control';
import { IMobile } from 'services/patients-api/types';

export interface IAddress {
  address1?: string;
  formattedAddress?: string; // TODO: remove
  country?: string;
  city?: string;
  state?: string;
  zipCode?: string | null;
  postCode?: string | null;
  coordinates?: [number, number];
  latitude?: number;
  longitude?: number;

  // added for order replacement
  name?: string;
  flatNumberOrSuite?: string;
  phone?: IMobile;
}

interface IAddressFieldProps extends IFormItemControlProps {
  placeholder?: string;
  onFormSetFieldsValueAddress: (address: IAddress) => void;
  countryCode?: string;
}

export const AddressField: React.FunctionComponent<IAddressFieldProps> = (props) => {
  const { label, name, form, rules, placeholder, onFormSetFieldsValueAddress, countryCode, ...restProps } = props;
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: countryCode ? { componentRestrictions: { country: countryCode } } : {},
    debounce: 300,
  });

  const handleAddressChange = (val: any) => {
    setValue(val);
  };

  const handleAddressSelect = ({ description }: any) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    // setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(async (results) => {
        const { address_components } = results[0] || {};
        const countryObj = address_components?.find((item) => item.types?.[0] === 'country');
        const city = address_components?.find((item) => item.types?.includes('locality'));
        const latLng = await getLatLng(results[0]);
        const zipCode = await getZipCode(results[0], false);
        return { ...latLng, zipCode, country: countryObj?.short_name || '', city: city?.long_name };
      })
      .then(({ lat, lng, zipCode, country, city }) => {
        const address: IAddress = {};
        address.address1 = description;
        address.country = country;
        address.coordinates = [lat, lng];
        address.zipCode = zipCode;
        address.postCode = zipCode;
        address.city = city;

        onFormSetFieldsValueAddress(address);
      })
      .catch((error) => {
        console.log('😱 Error: ', error);
      });
  };

  return (
    <FormItemControl label={label} name={name} rules={rules} {...restProps}>
      <AutoComplete className="custom-input" value={value} onChange={handleAddressChange} placeholder={placeholder}>
        {status === 'OK' &&
          data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;
            return (
              <AutoComplete.Option
                className="places-options"
                key={place_id}
                onClick={() => handleAddressSelect(suggestion)}
                value={`${main_text} ${secondary_text || ''}`}
              >
                <div key={place_id} onClick={() => handleAddressSelect(suggestion)}>
                  <strong>{main_text}</strong> <small>{secondary_text}</small>
                </div>
              </AutoComplete.Option>
            );
          })}
      </AutoComplete>
    </FormItemControl>
  );
};
