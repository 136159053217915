import alignerPng from 'assets/images/auth/aligner.png';
import { useTranslation } from 'react-i18next';

export const ThirdSlide = () => {
  const { t } = useTranslation();

  return (
    <div className="flex max-h-[calc(100vh-145px)] flex-col items-baseline overflow-y-auto text-white">
      <img src={alignerPng} alt="aligner" className="w-[180px] lg:w-[228px]" />
      <div>Zendura FLX (used by Straumann)</div>
      <div className="mt-6 py-4 text-2xl font-semibold lg:text-3xl">Premium Quality at Wholesale Prices </div>
      <p className="text:base lg:text-lg">Tired of labs getting paid more than you?</p>
      <p className="text:base lg:text-lg">
        The world's only Clear Aligner Marketplace offers low prices without years of discount building.
      </p>
      <p className="text:base lg:text-lg">
        {t(`SIGN_UP.Leading manufacturers are competing for your business with lab bills from £300`)}.
      </p>
    </div>
  );
};
