import { Image, Popover, Progress, Space } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { DeleteIcon } from 'components/DeleteIcon';
import { IImage } from 'services/patients-api/types';
import placeholderPng from 'assets/images/placeholder.png';
import NiceModal from '@ebay/nice-modal-react';
import { NiceImageCropModal } from 'components/commons/nice-image-crop/nice-image-crop';
import { twMerge } from 'tailwind-merge';

interface IProps {
  images: IImage[];
  deleteImage?: Function;
  name: string;
  imageHeight?: number;
  className?: string;
  croppable?: boolean;
  onCropped?: (croppedImage: IImage, image: IImage) => void;
}

export const ImageUploadList: FC<IProps> = ({
  images,
  deleteImage,
  name,
  imageHeight = 100,
  className,
  croppable,
  onCropped,
}) => {
  const [currentPreview, setCurrentPreviewImage]: any = useState(0);
  const [visible, setShowPreviewImage] = useState(false);

  const previewImage = (index: number) => {
    setCurrentPreviewImage(index);
    setShowPreviewImage(true);
  };

  const onCrop = async (image: IImage) => {
    const croppedImage: IImage = await NiceModal.show(NiceImageCropModal, { image });

    onCropped?.(croppedImage, image);
  };

  return (
    <Space wrap className={twMerge('w-full', className)}>
      {images?.map((image, index: number) => {
        const { url, status, uid, percent } = image;
        return status && status !== 'done' ? (
          <div
            style={{
              backgroundImage: `url(${placeholderPng})`,
              height: imageHeight,
            }}
            className="w-32 rounded bg-cover bg-center"
            key={url || uid}
          >
            <Progress percent={Math.floor(percent || 0)} size="small" className="custom-progress p-1 pt-[30%]" />
          </div>
        ) : (
          <div className="relative flex cursor-pointer items-center justify-center" key={url || uid}>
            <img id={url} src={url} className="object-contain" height={imageHeight} alt="" />
            <div className="absolute bottom-0 top-0 z-10 h-full w-full bg-black/40 opacity-0 hover:opacity-100">
              <div className="image-preview-btn flex h-full w-full flex-row items-center justify-center opacity-100">
                <Popover content={<div className="text-center">Preview</div>}>
                  <EyeOutlined className="text-lightGrey" onClick={() => previewImage(index)} />
                </Popover>
                {!!deleteImage && <DeleteIcon handleDelete={deleteImage} name={name ?? ''} item={url} />}
                {croppable && (
                  <Popover content={<div className="text-center">Edit</div>}>
                    <EditOutlined className="ml-3 text-white" onClick={() => onCrop(image)} />
                  </Popover>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div style={{ display: 'none' }}>
        {visible && (
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setShowPreviewImage(vis), current: currentPreview }}
          >
            {images.map(({ url }: any) => (
              <Image key={url} src={url} />
            ))}
          </Image.PreviewGroup>
        )}
      </div>
    </Space>
  );
};
