import React from 'react';
import { ReactComponent as Logo } from 'assets/images/interested-patients/32co-logo.svg';
import { getClickableLink } from 'helpers';

interface IPoweredBy32CoProps {
  isSQCompleted?: boolean;
}

export const PoweredBy32Co: React.FC<IPoweredBy32CoProps> = ({ isSQCompleted }) => (
  <div className="mt-8 flex flex-col items-center gap-3">
    <div className="mt-2 text-base font-bold">Powered by</div>
    <Logo />
    {!isSQCompleted && (
      <div className="mt-8 text-center text-xs font-semibold md:w-6/12">
        By completing this questionnaire you consent to sharing your data with your dentist. Data will be collected by
        32Co.&nbsp;
        <a
          href={getClickableLink('https://www.32co.com/dentist/legal/privacy-policy')}
          onClick={(e: any) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
        >
          Click here to view our privacy policy
        </a>
      </div>
    )}
  </div>
);
