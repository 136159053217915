import { Form, Input, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import { useQuery } from 'hooks/useQuery';
import { useResetPasswordMutation, useVerifyResetPasswordTokenMutation } from '../services/authApi';
import lockSvg from 'assets/images/auth/lock.svg';
import expiredSvg from 'assets/images/auth/expired.svg';
import { SigninFormItem } from '../components/signin-form-item/signin-form-item';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';
import { useForm } from 'antd/lib/form/Form';
import { notificationDefaultError } from 'utils/ui';
import { PasswordField } from 'components/password-field/password-field';

const ResetPassword = () => {
  const query = useQuery();
  const resetToken = query.get('id');
  const history = useHistory();
  const [form] = useForm();

  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const [verifyResetPasswordToken, verifyStatus] = useVerifyResetPasswordTokenMutation();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (!resetToken) {
      // TODO: show invalid link message
    }

    const verifyToken = async () => {
      await verifyResetPasswordToken({ resetToken }).unwrap();
    };

    verifyToken();
  }, []);

  useEffect(() => {
    if (!verifyStatus.isUninitialized && !verifyStatus.isLoading) {
      if (verifyStatus.isSuccess) {
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }
    }
  }, [verifyStatus]);

  const onSubmit = async (formState: any) => {
    try {
      const { confirmPassword, ...rest } = formState;
      const userResponse = await resetPassword({ resetToken, ...rest }).unwrap();
      if (userResponse) {
        history.replace('/signin');
        notification.success({
          message: 'Password reset',
          description: 'Password updated successfully!, Please login with new password',
          placement: 'topRight',
        });
      }
    } catch (_err) {
      notificationDefaultError();
    }
  };

  return (
    <AuthLayout showGotoSignin>
      {isVerified === null && (
        <div className="flex h-80 items-center justify-center">
          <Spin />
        </div>
      )}

      {isVerified === true && (
        <div className="mt-20 flex items-start" style={{ letterSpacing: '0.5px' }}>
          <div className="mr-4">
            <img src={lockSvg} alt="key" />
          </div>

          <div className="flex-1">
            <p className="mb-2 text-base font-semibold text-darkBlueColor">Create new password</p>
            <p style={{ color: '#6B7D8B' }}>Your password must be different from the previous used password.</p>

            <Form
              form={form}
              name="basic"
              onFinish={onSubmit}
              size="large"
              layout="vertical"
              autoComplete="off"
              className="mt-12 w-full animate-fadeIn"
            >
              <PasswordField form={form} isSigninField className="mb-5" name="password" />

              <SigninFormItem
                form={form}
                formName="confirmPassword"
                className="mb-5"
                label="Confirm Password"
                formDependencies={['password']}
                formRules={[
                  {
                    required: true,
                    message: 'Please input confirm password',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </SigninFormItem>

              <Form.Item className="mt-16 text-right">
                <AuthButtonComponent isLoading={isLoading}>Reset password</AuthButtonComponent>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}

      {isVerified === false && (
        <div className="m-auto mt-20 flex items-start" style={{ letterSpacing: '0.5px', maxWidth: '400px' }}>
          <div className="mr-6">
            <img src={expiredSvg} alt="key" />
          </div>

          <div className="flex-1">
            <p className="color-darkBlueColor mb-2 text-base font-semibold">Reset password link expired</p>
            <p style={{ color: '#6B7D8B' }}>To generate new link please click below.</p>

            <AuthButtonComponent className="mt-7" onClick={() => history.push('/forgot-password')}>
              Go to forgot password
            </AuthButtonComponent>
          </div>
        </div>
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
