import { ReactComponent as NewMessageSVG } from 'assets/images/new_message.svg';
import { ReactComponent as CaseSubmittedSVG } from 'assets/images/case_submitted.svg';
import { ReactComponent as NewCaseSVG, ReactComponent as CaseInstructionsReadySVG } from 'assets/images/new_case.svg';
import { ReactComponent as OrderDelayedSVG } from 'assets/images/order_delayed.svg';
import { ReactComponent as ReviewNewDesignSVG } from 'assets/images/review_new_design.svg';
import { ReactComponent as ThankYouSVG } from 'assets/images/thank_you.svg';
import { ReactComponent as OrderShippedSVG } from 'assets/images/order_shipped.svg';
import { ReactComponent as AwaitingApprovalSVG } from 'assets/images/awaiting_approval.svg';
import { ReactComponent as ProposalApprovalSVG } from 'assets/images/proposal_approved.svg';
import { ReactComponent as OrderApprovedSVG } from 'assets/images/order_approved.svg';
import { ReactComponent as ModificationRequestedSVG } from 'assets/images/modification_requested.svg';
import { ReactComponent as ReferralsSVG } from 'assets/images/referrals.svg';
import { ReactComponent as BookMeIn } from 'assets/images/book_me_in.svg';
import { duration } from 'helpers';
import { TNotificationItem } from 'services/notification/types';
import { NOTIFICATION_TYPE, USER_ROLES } from 'config/constants';
import { buildOrderElmId, buildRetainerElmId } from 'utils/submission';

export const dateToDuration = (date: string) => {
  const d: any = duration(date);

  if (d.days) return `${d.days} day${d.days > 1 ? 's' : ''} ago`;
  if (d.hours) return `${d.hours} hour${d.hours > 1 ? 's' : ''} ago`;
  if (d.minutes) return `${d.minutes} min${d.minutes > 1 ? 's' : ''} ago`;
  if (d.seconds) return `${d.seconds} secs ago`;

  return '';
};

export const parseNotificationData = (notification: TNotificationItem, userType?: string) => {
  let actionText = '';
  let actionUrl = '';
  let svgIcon = null;
  switch (notification.type) {
    case NOTIFICATION_TYPE.NEW_SUBMISSION:
      if (userType === USER_ROLES.DESIGNER || userType === USER_ROLES.SPECIALIST) {
        actionText = 'View Case Instructions';
        actionUrl = `/${userType}`;
      } else {
        actionText = 'Go to Case Submission';
        actionUrl = `/${userType}/patients/${notification.submission}`;
      }
      svgIcon = <NewCaseSVG width={40} height={40} />;
      break;
    case NOTIFICATION_TYPE.SUBMISSION_SUBMITTED:
      actionText = 'Go to Case Submission';
      svgIcon = <CaseSubmittedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.NEW_MESSAGE:
      actionText = 'View Message';
      svgIcon = <NewMessageSVG width={40} height={40} />;
      if (userType === USER_ROLES.MANUFACTURER) {
        actionUrl = `/${userType}/orders/${notification.submission}?open=message_box&active_channel=${notification.roomId}`;
      } else {
        actionUrl = `/${userType}/patients/${notification.submission}?open=message_box&active_channel=${notification.roomId}`;
      }
      break;
    case NOTIFICATION_TYPE.AWAITING_APPROVAL:
      actionText = 'Go to Treatment Proposal';
      svgIcon = <AwaitingApprovalSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}/design/${notification.treatmentDesign}`;
      break;
    case NOTIFICATION_TYPE.ORDER_SHIPPED:
      actionText = 'Go to Order Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary`;
      break;
    case NOTIFICATION_TYPE.ORDER_APPROVED:
      actionText = 'Go to Order Details';
      svgIcon = <OrderApprovedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary`;
      break;
    case NOTIFICATION_TYPE.ORDER_DELAYED:
      actionText = 'Go to Order Details';
      svgIcon = <OrderDelayedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary`;
      break;
    case NOTIFICATION_TYPE.REFERRALS:
      actionText = 'Referrals';
      svgIcon = <ReferralsSVG width={40} height={40} />;
      actionUrl = '';
      break;
    case NOTIFICATION_TYPE.THANK_YOU:
      actionText = 'View Message';
      svgIcon = <ThankYouSVG width={40} height={40} />;
      actionUrl = `/${userType}?submissionId=${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.REVISION_REQUEST:
      actionText = 'View modification request';
      svgIcon = <ModificationRequestedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}/design/${notification.treatmentDesign}`;
      break;
    case NOTIFICATION_TYPE.PROPOSAL_APPROVED:
      actionText = 'View approved design';
      svgIcon = <ProposalApprovalSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}/design/${notification.treatmentDesign}`;
      break;
    case NOTIFICATION_TYPE.NEW_ORDER:
      actionText = 'Mark as viewed';
      svgIcon = <OrderApprovedSVG width={40} height={40} />;
      actionUrl = `/${userType}/orders/${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.NEW_TREATMENT_DESIGN:
      actionText = 'Go to Treatment check in';
      svgIcon = <NewCaseSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=treatment-checkins`;
      break;
    case NOTIFICATION_TYPE.NEW_PATIENT_SUBMISSION:
      actionText = 'Go to Treatment check in';
      svgIcon = <NewCaseSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=treatment-checkins`;
      break;
    case NOTIFICATION_TYPE.PATIENT_PROPOSAL:
      actionText = 'Go to Patient proposal';
      svgIcon = <BookMeIn width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=patient-proposal`;
      break;
    case NOTIFICATION_TYPE.CASE_NOT_SUITABLE:
      actionText = 'Go to Case Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.CASE_DELAYED:
      actionText = 'Go to Case Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.EDUCATION_CONTENT:
      actionText = 'Go to Education content';
      svgIcon = <ReviewNewDesignSVG width={40} height={40} />;
      actionUrl = `/${userType}/education/${notification.educationContent}`;
      break;
    case NOTIFICATION_TYPE.CASE_INSTRUCTION_READY:
      actionText = 'Case Instructions Ready';
      actionUrl = `/${userType}/patients/${notification.submission}`;
      svgIcon = <CaseInstructionsReadySVG width={40} height={40} />;
      break;
    case NOTIFICATION_TYPE.NEW_STL_UPLOADED:
      actionText = 'View New STL uploaded';
      svgIcon = <ModificationRequestedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.SUBMISSION_REJECTED:
      actionText = 'Go to Case Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.REPLACEMENT_ORDER_SUBMITTED:
    case NOTIFICATION_TYPE.REPLACEMENT_ORDER_SHIPPED:
    case NOTIFICATION_TYPE.REPLACEMENT_ORDER_APPROVED:
    case NOTIFICATION_TYPE.REPLACEMENT_ORDER_ACCEPTED:
    case NOTIFICATION_TYPE.REPLACEMENT_REPORT_ACCEPTED:
      actionText = 'Go to Order Details';
      svgIcon = <OrderApprovedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary&scrollTo=${buildOrderElmId(
        notification?.order || '',
      )}`;
      break;
    case NOTIFICATION_TYPE.REPLACEMENT_ORDER_REJECTED:
      actionText = 'Go to Order Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary&scrollTo=${buildOrderElmId(
        notification?.order || '',
      )}`;
      break;
    case NOTIFICATION_TYPE.ANIMATION_UPDATED:
      actionText = 'Go to Case Details';
      svgIcon = <NewCaseSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case NOTIFICATION_TYPE.RETAINER_ORDER_SUBMITTED:
      actionText = 'Go to Retainer';
      svgIcon = <CaseSubmittedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=retainers&scrollTo=${buildRetainerElmId(
        notification?.retainer || '',
      )}`;
      break;
    case NOTIFICATION_TYPE.RETAINER_ORDER_SHIPPED:
      actionText = 'Go to Retainer';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=retainers&scrollTo=${buildRetainerElmId(
        notification?.retainer || '',
      )}`;
      break;
    case NOTIFICATION_TYPE.TECHNICIAN_RETAINER_ORDER:
      actionText = 'Go to Retainer Order';
      svgIcon = <NewCaseSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}/retainer/${notification?.retainer}`;
      break;
    case NOTIFICATION_TYPE.RETAINER_ORDER:
      actionText = 'Go to Retainer Order';
      svgIcon = <OrderApprovedSVG width={40} height={40} />;
      actionUrl = `/${userType}/orders/${notification.order}`;
      break;
    default:
  }

  return {
    actionText,
    actionUrl,
    svgIcon,
  };
};
