import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { FC, useState } from 'react';
import NiceAntModal from '../nice-ant-modal/nice-ant-modal.component';
import Cropper, { Area } from 'react-easy-crop';
import { Slider, Typography } from 'antd';
import { ReactComponent as PortraitIcon } from 'assets/images/completed-treatment/portrait-icon.svg';
import { ReactComponent as LandscapeIcon } from 'assets/images/completed-treatment/landscape-icon.svg';
import { RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';
import { NiceButton } from '../nice-button/nice-button';
import { getCroppedImg } from './utils';
import { ICropConfig, IImage } from 'services/patients-api/types';
import { useGetSignedUrlMutation } from 'services/api';
import { s3Upload } from 'services/s3-api/endpoints';
import { useGetProfileSignedUrlMutation } from 'modules/auth/services/authApi';
import { notificationApiError } from 'utils/ui';

export interface INiceImageCropModalProps extends NiceModalHocProps {
  image: IImage;
  publicUpload?: boolean;
  zoomConfig?: { min: number; max: number };
  disabledPlugins?: Array<'rotate' | 'landscape' | 'portrait'>;
  defaultAspectRatio?: EAspectRatio;
}

export enum EAspectRatio {
  Portrait = 4 / 6,
  Landscape = 16 / 9,
}

const defaultZoomConfig = {
  min: 0.8,
  max: 4,
};

const zoomStep = 0.1;

export const NiceImageCropModal: FC<INiceImageCropModalProps> = NiceModal.create(
  ({ image, publicUpload, zoomConfig = defaultZoomConfig, disabledPlugins = [], defaultAspectRatio }) => {
    const { cropConfigs } = image;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(cropConfigs?.zoom ?? 1);
    const [aspect, setAspect] = useState<EAspectRatio>(
      cropConfigs?.aspect ?? (defaultAspectRatio || EAspectRatio.Portrait),
    );
    const [rotation, setRotation] = useState(cropConfigs?.rotation ?? 0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [croppedArea, setCroppedArea] = useState<Area>();
    const modal = useModal();
    const [isUploading, setIsUploading] = useState(false);

    const [getSignedUrl] = useGetSignedUrlMutation();
    const [getPublicSignedUrl] = useGetProfileSignedUrlMutation();

    const onCropComplete = (area: Area, croppedPixels: Area) => {
      setCroppedArea(area);
      setCroppedAreaPixels(croppedPixels);
    };

    const onSave = async () => {
      try {
        setIsUploading(true);
        const fileData = {
          fileName: `${image.name}-cropped.jpeg`,
          mimeType: 'image/jpeg',
          folderName: 'completed-treatment',
        };
        const img = (await getCroppedImg(cropConfigs?.originalUrl ?? image.url, croppedAreaPixels!, rotation)) as Blob;
        const file = new File([img], image.name, { type: 'image/jpeg' });
        const signedUrl = await (publicUpload ? getPublicSignedUrl : getSignedUrl)(fileData).unwrap();
        await s3Upload(signedUrl.url, file);
        const imageUrl = signedUrl && signedUrl.url.split('?')[0];
        const cropConf: ICropConfig = {
          cropArea: croppedArea,
          rotation,
          aspect,
          zoom,
          originalUrl: cropConfigs?.originalUrl ?? image.url,
        };
        modal.resolve({
          url: imageUrl,
          name: file.name,
          size: file.size,
          type: file.type,
          uid: image.uid,
          cropConfigs: cropConf,
        });
        modal.hide();
        modal.remove();
      } catch (error) {
        notificationApiError(error);
      } finally {
        setIsUploading(false);
      }
    };

    return (
      <NiceAntModal
        title={<div>Edit Photo</div>}
        subTitle="Resize according to frame or crop"
        hideDivider
        modal={modal}
        width={800}
        maskClosable={false}
      >
        <div className="relative mt-4 h-96 [&>div]:!bg-black">
          <Cropper
            image={cropConfigs ? cropConfigs?.originalUrl : image.url}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            rotation={rotation}
            initialCroppedAreaPercentages={cropConfigs?.cropArea}
          />
        </div>
        <div className="mt-8 flex justify-between">
          <div>
            <Slider
              value={zoom}
              className="w-40"
              onChange={(value) => setZoom(value)}
              min={zoomConfig.min}
              max={zoomConfig.max}
              step={zoomStep}
            />
            <Typography.Text>Zoom</Typography.Text>
          </div>
          <div className="flex items-center gap-8">
            {!disabledPlugins.includes('portrait') && (
              <div
                className="flex flex-col items-center justify-center"
                onClick={() => setAspect(EAspectRatio.Portrait)}
              >
                <PortraitIcon />
                <Typography.Text>Portrait</Typography.Text>
              </div>
            )}
            {!disabledPlugins.includes('landscape') && (
              <div
                className="flex flex-col items-center justify-center"
                onClick={() => setAspect(EAspectRatio.Landscape)}
              >
                <LandscapeIcon />
                <Typography.Text>Landscape</Typography.Text>
              </div>
            )}
            {!disabledPlugins.includes('rotate') && (
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-baseline justify-center gap-4">
                  <RotateLeftOutlined className="text-xl text-black" onClick={() => setRotation((prev) => prev - 90)} />
                  <RotateRightOutlined
                    className="text-xl text-black"
                    onClick={() => setRotation((prev) => prev + 90)}
                  />
                </div>
                <Typography.Text>Rotate</Typography.Text>
              </div>
            )}
          </div>
        </div>
        <div className="mt-6 flex justify-between">
          <NiceButton size="small" className="w-32" onClick={() => modal.remove()} disabled={isUploading}>
            Cancel
          </NiceButton>
          <NiceButton niceType="orange" size="small" className="w-32" onClick={onSave} loading={isUploading}>
            Save
          </NiceButton>
        </div>
      </NiceAntModal>
    );
  },
);
