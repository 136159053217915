import { FC } from 'react';
import { pdfStyles } from './utils';
import { View, Image, Link as PDFLink, Text } from '@react-pdf/renderer';
import { IImage } from 'services/patients-api/types';

export const PDfImages: FC<{ images?: IImage[] }> = ({ images }) => (
  <View style={pdfStyles.imageContainer}>
    {images?.length ? (
      images?.map((image) => (
        <View style={{ ...pdfStyles.image }}>
          <PDFLink src={image.url}>
            <Image src={{ uri: image.url, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }} />
          </PDFLink>
          <Text style={pdfStyles.description}>(Click on image to expand)</Text>
        </View>
      ))
    ) : (
      <Text style={pdfStyles.value}>No uploaded images</Text>
    )}
  </View>
);
