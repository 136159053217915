import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Typography } from 'antd';
import { useMemo } from 'react';
import { ModalCongratulation } from 'components/modals/modal-congratulation';
import { showModal } from 'utils';
import { IReferral } from 'services/discounts-api/endpoints';
import { useCurrency } from 'hooks/useCurrency';

interface IProps {
  newDiscounts: IReferral[];
}

const ReferralDiscountRewardModal = NiceModal.create<TModalProps<IProps>>(({ afterClose, newDiscounts }) => {
  const modal = useModal();
  const { getDiscountDisplay } = useCurrency();

  const discount: IReferral = newDiscounts?.[0];

  const discountText = useMemo(
    () => getDiscountDisplay(discount?.appliedDiscount?.discountType, discount?.amount),
    [discount],
  );

  return (
    <ModalCongratulation modal={modal} value={discountText} afterClose={afterClose}>
      <div className="mt-3 flex flex-col items-center text-center">
        {discount?.condition === 'DENTIST_SIGNED_UP' && (
          <>
            <div className="text-lg text-primaryDarkBlueColor mt-5">
              Congratulations! Your friend <br />
              <span className="font-semibold">{discount?.referredBy?.firstName}</span> just saved you money on your
              first case
            </div>
            <div className="text-sm mt-10 text-gray-500">Start referring to earn £100s more</div>
          </>
        )}

        {discount?.condition === 'REFERRED_DENTIST_FIRST_ORDER' && (
          <>
            <Typography.Text className="text-lg text-primaryDarkBlueColor" style={{ marginTop: 20 }}>
              Congratulations! Your friend{' '}
              <Typography.Text className="font-semibold">{discount?.referredTo?.firstName}</Typography.Text> just
              ordered their first case!
            </Typography.Text>
            <Typography.Text className="text-sm" style={{ color: '#6B7D8B', width: '80%', marginTop: 40 }}>
              They saved £200 and you've earnt <Typography.Text className="font-semibold">£100 off</Typography.Text>{' '}
              your next case!
            </Typography.Text>
          </>
        )}
      </div>
    </ModalCongratulation>
  );
});

export const showReferralDiscountRewardModal = showModal<void, IProps>(ReferralDiscountRewardModal);
