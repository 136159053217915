import { useMemo } from 'react';
import { IImage } from 'services/patients-api/types';
import { EImageTag } from './utils';

export const useAttachmentImages = (attachments: IImage[]) =>
  useMemo(() => {
    const beforeImage = attachments.filter((attachment) => attachment.tag === EImageTag.Before);
    const afterImage = attachments.filter((attachment) => attachment.tag === EImageTag.After);
    const portraitBeforeImage = attachments.find((attachment) => attachment.tag === EImageTag.PortraitBefore);
    const portraitAfterImage = attachments.find((attachment) => attachment.tag === EImageTag.PortraitAfter);
    return {
      beforeImage,
      afterImage,
      portraitBeforeImage,
      portraitAfterImage,
    };
  }, [attachments]);
