import { Card, Tag, Checkbox, Typography, Popover } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { TListCaseStudyResponse } from 'services/case-studies-api/types';
import { useQuery } from 'hooks/useQuery';
import Overflow from 'rc-overflow';
import { CaseStudyImageCarousel } from './case-study-image-carousel';
import { CaseStudyTag } from '../case-study-tag/case-study-tag';
import { LineClamp } from 'components/line-clamp/line-clamp';
import { ReactComponent as StartSvg } from 'assets/images/case-studies/start-icon.svg';
import './case-study-card.less';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';
import { useAuth } from 'hooks/useAuth';

interface IProps {
  className?: string;
  caseStudy: TListCaseStudyResponse;
  selectable?: boolean;
  onSelectCaseStudy?: (caseStudy: TListCaseStudyResponse) => void;
  isSelected?: boolean;
  preventGotoDetails?: boolean;
  isMyCase?: boolean;
  hideTags?: boolean;
}

export const CaseStudyCard: FC<IProps> = ({
  className,
  caseStudy,
  selectable,
  onSelectCaseStudy,
  isSelected,
  preventGotoDetails,
  isMyCase,
  hideTags,
}) => {
  const query = useQuery();
  const { user } = useAuth();
  const {
    attachments,
    treatmentLength,
    totalStages,
    description,
    diagnosisCrowding = [],
    diagnosisOcclusion = [],
    diagnosisOther = [],
    diagnosisRotations = [],
    treatmentArches = [],
    treatmentCosmetic = [],
    treatmentOrthodontic = [],
    specialist,
    patient,
    dentist,
    approvedDescription,
  } = caseStudy;

  const getOptions = (data: Record<string, string[] | undefined>) =>
    Object.entries(data)
      .map(
        ([key, value]) =>
          value?.map((item) => ({
            label: item,
            value: item,
            key,
          })) || [],
      )
      .flat();

  const history = useHistory();

  const goToDetails = () => {
    history.push(`/dentist/case-studies/${caseStudy.id}?${query.toString()}`);
  };

  const onCaseStudyCLick = () => {
    trackEvent(EEventNames.CASE_STUDY_GALLERY_CARD_CLICKED);
    if (selectable) {
      onSelectCaseStudy?.(caseStudy);
      return;
    }
    if (!preventGotoDetails) {
      goToDetails();
    }
  };

  const { approvedPatientFeedbackSummary, patientFeedbackSummary } = patient?.treatmentRecap || {};
  const { approvedSpecialistTestimonial, specialistTestimonial } = patient?.treatmentJourney || {};

  const showApprovedPatientFeedbackSummary = approvedPatientFeedbackSummary !== undefined && user?.id !== dentist;
  const showApprovedSpecialistTestimonial = approvedSpecialistTestimonial !== undefined && user?.id !== dentist;
  const showApprovedDescription = approvedDescription && user?.id !== dentist;

  return (
    <Card
      className={classNames('hover:border-[#1890FF]', className, {
        'border-2 border-[#050D43]': isSelected,
        'hover:cursor-pointer': !preventGotoDetails || selectable,
      })}
      bodyStyle={{ padding: isSelected ? '7px' : '8px' }}
      onClick={onCaseStudyCLick}
    >
      <div className="flex flex-col gap-2">
        {selectable && <Checkbox className="h-5 self-end" checked={isSelected} />}
        <div className="relative">
          {isMyCase && (
            <Popover
              overlayInnerStyle={{ borderRadius: '2px' }}
              showArrow
              content={<div>One of yours - nice work!</div>}
              overlayClassName="my-case-study-popover"
              placement="bottomRight"
            >
              <div className="absolute right-0 top-0 z-50 flex h-7 w-7 items-center justify-center rounded-bl rounded-tr bg-[#EB2F96]">
                <StartSvg />
              </div>
            </Popover>
          )}
          <CaseStudyImageCarousel
            attachments={attachments}
            totalStages={totalStages}
            treatmentLength={treatmentLength}
          />
        </div>
        {!hideTags && (
          <Overflow
            data={getOptions({
              diagnosisCrowding,
              diagnosisOcclusion,
              diagnosisOther,
              diagnosisRotations,
              treatmentArches,
              treatmentCosmetic,
              treatmentOrthodontic,
            })}
            renderItem={(item) => (
              <CaseStudyTag key={item.value} tagKey={item.key}>
                {item.label}
              </CaseStudyTag>
            )}
            renderRest={(omittedItems) => <Tag className="border-0">+{omittedItems.length} More</Tag>}
            maxCount="responsive"
            className="flex w-full"
          />
        )}

        <LineClamp
          className="line-clamp-5 h-[112px]"
          content={showApprovedDescription ? approvedDescription : description}
        />
        {(showApprovedPatientFeedbackSummary || patientFeedbackSummary) && (
          <div className="rounded-md bg-[#F1F7FF] p-2">
            <LineClamp
              className="line-clamp-2 h-[50px]"
              content={`“${
                showApprovedPatientFeedbackSummary ? approvedPatientFeedbackSummary : patientFeedbackSummary
              }”`}
            />
            <Typography.Text className="font-semibold">Patient Testimonial</Typography.Text>
          </div>
        )}
        {(showApprovedSpecialistTestimonial || specialistTestimonial) && (
          <div className="rounded-md bg-[#E5F0FF] p-2">
            <LineClamp
              className="line-clamp-2 h-[50px]"
              content={`“${showApprovedSpecialistTestimonial ? approvedSpecialistTestimonial : specialistTestimonial}”`}
            />
            {specialist?.fullName && (
              <Typography.Text className="font-semibold">
                {specialist?.prefix} {specialist?.fullName} - 32Co Specialist Orthodontist
              </Typography.Text>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
