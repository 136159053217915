import { DoubleLeftOutlined, DoubleRightOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { Divider, Drawer, Dropdown, Layout, Menu, Popover, Spin, Typography } from 'antd';
import messagesSvg from 'assets/images/chat/messages.svg';
import logoutSvg from 'assets/images/dashboard/logout.svg';
import userSvg from 'assets/images/dashboard/user.svg';
import { RedirectToSignInWithBackUrl } from 'components/PrivateRoute';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppVersionChecking } from 'hooks/useAppVersionChecking';
import { useAuth } from 'hooks/useAuth';
import { usePendo } from 'hooks/usePendo';
import { useWhatIsNewModal } from 'hooks/useWhatIsNewModal';
import { useWindowSize } from 'hooks/useWindowSize';
import { IRoute } from 'interface/IRoute';
import { updateJustLoggedIn } from 'modules/auth/authSlice';
import { UserStatuses } from 'modules/auth/services/type';
import { FC, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { selectPageLoader } from 'redux/appSlice';
import { showNotificationsPanel } from 'redux/notificationsSlice';
import { setIsConnected } from 'redux/socketSlice';
import socketService from 'services/socket';
import { ZINDEX } from 'utils/ui';
import { AddToBookmarksModal } from '../../../modules/gdp/dashboard/components/add-to-bookmarks/add-to-bookmarks-modal';
import { shouldShowBookmarkUsModal } from '../../../modules/gdp/dashboard/components/add-to-bookmarks/utils';
import PageOverlayLoader from '../../page-overlay-loader/page-overlay-loader.component';
import './dashboard-layout.styles.less';
import { DashboardMenu, MENU_DURATION } from './dashboard-menu';
import { PatientSearch } from './patient-search/patient-search';
import { usePrimaryNotification } from './primary-notification/hooks/use-primary-notification';
import { PrimaryNotification } from './primary-notification/primary-notification';
import { useSideBar } from 'hooks/useSidebar';
import { useSignUpWelcome } from './sign-up-welcome/use-sign-up-welcome';
import { DownloadProgress } from './download-progress/download-progress';
import classNames from 'classnames';
import { ChristmasToggle } from './christmas-theme/christmas-toggle';
import { ChristmasPine } from './christmas-theme/christmas-pine';
import { selectChristmasEnabled } from 'redux/themeSlice';
import { NiceParticles } from 'components/nice-particles/nice-particles';
import { EParticlesType } from 'components/nice-particles/particles-options';
import { useGetDiscountsQuery, useUpdateDiscountConfirmMutation } from 'services/discounts-api/endpoints';
import { showReferralDiscountRewardModal } from 'modules/gdp/dashboard/components/modals/referral-discount-reward-modal';
import { useTranslation } from 'react-i18next';

const { Header, Content } = Layout;
let timeout: any;

const DashboardLayoutComponent: FC = ({ children }) => {
  const { user, justLoggedIn, userLogout, isUserLogoutLoading } = useAuth();
  const { isMd } = useWindowSize();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const showAppPageLoader = useAppSelector(selectPageLoader);
  const { isConnected } = useAppSelector((state) => state.socket);
  const { progressList, display } = useAppSelector((state) => state.downloadProgress);
  const [activeMenu, setActiveMenu] = useState<string>(user?.type || 'dentist');
  const { isCollapsedMenu, sideBarSize, toggleSideBar } = useSideBar();
  const [showDrawer, setShowDrawer] = useState(false);
  const isChristmasTheme = useAppSelector(selectChristmasEnabled);
  const [updateDiscountConfirm] = useUpdateDiscountConfirmMutation();
  const { data: discountData } = useGetDiscountsQuery({}, { refetchOnMountOrArgChange: true });
  const { i18n } = useTranslation();

  usePendo(user);
  useAppVersionChecking();

  useEffect(() => {
    i18n.changeLanguage(user?.country);
  }, [user?.country]);

  const connectSocket = async () => {
    console.log('inside connect', user);
    if (!user?.id) return;

    try {
      const socket = await socketService.connect(user.id, user.role);
      console.log('socket connected', socket);
      dispatch(setIsConnected(true));
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const disconnectSocket = (from: string) => {
    console.log('socket disconnected', from);
    dispatch(setIsConnected(false));
    socketService.disconnect();
  };

  useEffect(() => {
    console.log('inside use effect', user);
    if (user && user.id && !isConnected) {
      timeout = setTimeout(connectSocket, 500);
    }

    const handleConnect = () => {
      console.log('connected called');
      dispatch(setIsConnected(true));
    };

    const handleDisconnect = () => {
      console.log('disconnected called');
      dispatch(setIsConnected(false));
    };

    const handleOnline = () => {
      console.log('online called');
      connectSocket();
    };

    const handleOffline = () => {
      console.log('offline called');
      disconnectSocket('offline');
    };

    socketService.socket?.on('connect', handleConnect);
    socketService.socket?.on('disconnect', handleDisconnect);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      clearTimeout(timeout);
      disconnectSocket('unmount');
      socketService.socket?.off('connect', handleConnect);
      socketService.socket?.off('disconnect', handleDisconnect);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const getActiveMenu = () => {
      const path = location.pathname;
      return (
        ['patients', 'education', 'orders', 'pricing', 'calculator', 'case-studies', 'growth-hub'].find((key) =>
          path.includes(key),
        ) ||
        user?.type ||
        'dentist'
      );
    };

    const actMenu = getActiveMenu();
    setActiveMenu(actMenu);
    setShowDrawer(false);
  }, [location, user]);

  /* POPUPs CONTROL */
  const {
    isLoaded: isSignUpWelcomeLoaded,
    needToShowSignUpWelcomePopup,
    needToShowBookCalendlyPopup,
    onShowBookCalendlyPopup,
    onShowSignUpWelcomePopup,
  } = useSignUpWelcome();
  const { isLoaded: isWhatIsNewLoaded, onShow: onWhatIsNewModalShow } = useWhatIsNewModal();
  const showBookmarkSuggestionModal = shouldShowBookmarkUsModal();
  const { isLoaded: isNotificationLoaded, unreadNotificationsCount } = usePrimaryNotification();

  const newDiscounts = useMemo(
    () => (discountData ? discountData?.referrals?.filter((d: any) => !d?.confirmed) : []),
    [discountData],
  );

  useEffect(() => {
    // ALL INITIAL POPUPS SHOWING CONTROL
    const showPopups = async () => {
      // show congrats sign up success modal
      let isWelcomePopupShown = false;
      if (needToShowSignUpWelcomePopup) isWelcomePopupShown = await onShowSignUpWelcomePopup();
      // show Calendly modal if needed
      if (needToShowBookCalendlyPopup && !isWelcomePopupShown) await onShowBookCalendlyPopup();
      // show release note modal
      if (onWhatIsNewModalShow) await onWhatIsNewModalShow();
      // show bookmark modal
      if ([UserStatuses.LIVE, UserStatuses.PENDING_PACKAGE].includes(user?.status!) && showBookmarkSuggestionModal) {
        await NiceModal.show(AddToBookmarksModal);
      }

      // Show reward popup in the signup
      if (newDiscounts?.length) {
        const afterClose = async () => {
          const body = {
            referrers: [newDiscounts[0]?.id],
            confirm: true,
          };
          try {
            await updateDiscountConfirm(body);
          } catch (error) {
            console.error(error);
          }
        };

        await showReferralDiscountRewardModal({ newDiscounts, afterClose });
      }

      // open Notification Panel
      if (justLoggedIn && unreadNotificationsCount) {
        dispatch(showNotificationsPanel(true));
        dispatch(updateJustLoggedIn({ justLoggedIn: false }));
      }
    };

    if (isSignUpWelcomeLoaded && isWhatIsNewLoaded && isNotificationLoaded) showPopups();
  }, [isWhatIsNewLoaded, isNotificationLoaded, isSignUpWelcomeLoaded, newDiscounts]);
  /* POPUPs CONTROL */

  const toggleSideDrwer = () => {
    setShowDrawer(!showDrawer);
  };

  const onClose = () => {
    setShowDrawer(false);
  };

  const userProfile = () => {
    history.push(`/${user?.type}/profile`);
  };

  const helpPopoverContent = (
    <div className="w-60 p-2">
      <Typography.Text className="text-sm font-bold">Help and Support</Typography.Text>
      <Divider className="mb-2 mt-1" />
      <div className="flex">
        <div className="mr-1 grow flex-row text-justify">
          <Typography.Text className="text-xs leading-none text-gray-500">
            If you have a question about a patient click into their profile and use the <strong>chat tool!</strong> Look
            for this icon bottom right
          </Typography.Text>
          <div className="mt-2">
            <Typography.Text className="text-xs leading-none text-gray-500">
              For anything else please contact <br />{' '}
              <a href="mailto:support@32co.com" className="font-bold text-gray-500">
                support@32co.com
              </a>
            </Typography.Text>
          </div>
        </div>
        <div className="ml-3 mt-3 flex flex-none">
          <div className="chat-icon-container flex h-15 w-15 cursor-pointer items-center justify-center">
            <img alt="" src={messagesSvg} />
          </div>
        </div>
      </div>
    </div>
  );

  const renderRightMoreMenu = () => (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            <Popover
              content={helpPopoverContent}
              title=""
              placement="right"
              trigger="click"
              className="cursor-pointer"
              showArrow={false}
            >
              <span className="text-sm">Help</span>
            </Popover>
          </Menu.Item>
          <Menu.Item>
            <div onClick={userProfile}>
              <span className="text-sm">Profile</span>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div onClick={userLogout}>
              Sign out
              {isUserLogoutLoading && <Spin size="small" className="ml-2" />}
            </div>
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
      trigger={['click']}
      overlayClassName="w-28 pr-2"
    >
      <div className="flex cursor-pointer items-start px-3 md:hidden">
        <MoreOutlined className="flex text-lg font-semibold" style={{ color: '#999' }} />
      </div>
    </Dropdown>
  );

  const renderChristmasTheme = () =>
    isChristmasTheme && (
      <>
        <div className="absolute bottom-0 left-0 right-0 animate-fadeIn">
          <ChristmasPine smallMode={isCollapsedMenu} />
        </div>
        <div className="absolute -right-[6px] bottom-0 top-0 w-[10px] animate-fadeIn bg-[url('assets/images/christmas/striped-border.svg')]" />
        <div className="absolute bottom-0 left-0 right-0 top-0 z-[-1] animate-fadeIn">
          <NiceParticles id="ts-particles-confetti-dashboard" optionType={EParticlesType.christmasSnow} />
        </div>
      </>
    );

  return (
    <Layout className="layout">
      <div
        className="custom-menu-s z-10 hidden md:block"
        style={{
          width: sideBarSize,
          transition: `width ${MENU_DURATION}`,
        }}
      >
        <DashboardMenu user={user!} activeMenu={activeMenu} smallMode={isCollapsedMenu} />
        {isMd && renderChristmasTheme()}
        <div
          className="fixed bottom-0 z-50 hidden cursor-pointer p-3 text-lg text-white"
          onClick={toggleSideBar}
          style={{
            display: 'block',
            left: isCollapsedMenu ? 60 : 160,
            transition: `width ${MENU_DURATION}`,
          }}
        >
          {isCollapsedMenu ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
        </div>
      </div>

      <Drawer
        className="md:hidden"
        placement="left"
        onClose={onClose}
        visible={showDrawer}
        bodyStyle={{ padding: 0 }}
        contentWrapperStyle={{
          width: '200px',
          background: '#060A43',
        }}
        closable={false}
      >
        <div className="custom-menu-s-mobile">
          <DashboardMenu user={user!} activeMenu={activeMenu} smallMode={false} />
          {!isMd && showDrawer && renderChristmasTheme()}
        </div>
      </Drawer>

      <Layout
        className="site-layout"
        style={{
          marginLeft: isMd ? sideBarSize : '0px',
          transition: `margin-left ${MENU_DURATION}`,
        }}
      >
        <Header
          id="MAIN_HEADER_ID"
          className="app-header fixed bg-white px-0"
          style={{
            width: isMd ? `calc(100% - ${sideBarSize})` : '100%',
            zIndex: ZINDEX.LAYOUT_HEADER,
          }}
        >
          <div className="flex h-full justify-between !px-0 md:!px-3">
            <div className="flex cursor-pointer items-center px-3 md:hidden" onClick={toggleSideDrwer}>
              <MenuOutlined className="text-base" style={{ color: '#bbb' }} />
            </div>
            {/* Hidden 2023 Award, but keep code here for the next one */}
            {/* {isLg && isDentistDashboard && <CompanyAwards />} */}
            <div className="mr-2">
              <ChristmasToggle />
            </div>
            <div />
            <div className="flex flex-1 justify-between md:flex-none">
              <div className="flex w-full items-center">
                <div className="flex-1">{user && user?.type === 'dentist' && <PatientSearch />}</div>
                <Popover
                  content={helpPopoverContent}
                  title=""
                  placement="bottomRight"
                  trigger="click"
                  className="hidden cursor-pointer md:block"
                >
                  <span className="ml-8 text-sm">Help</span>
                </Popover>

                <div className="relative ml-4 flex h-6 w-6 cursor-pointer items-center md:ml-8">
                  <PrimaryNotification />
                </div>

                <div onClick={userProfile} className="ml-8 hidden h-6 w-6 cursor-pointer md:flex">
                  <img src={userSvg} alt="userProfile" height={24} />
                </div>
                <div onClick={userLogout} className="ml-8 hidden h-6 w-6 cursor-pointer md:flex">
                  {isUserLogoutLoading ? <Spin /> : <img src={logoutSvg} alt="userLogout" height={24} />}
                </div>

                {renderRightMoreMenu()}
              </div>
            </div>
          </div>
        </Header>
        <Content className="content-section mx-3">
          {children}
          <PageOverlayLoader loading={showAppPageLoader} />
        </Content>
        {progressList.length > 0 && (
          <div className={classNames(display ? '' : 'hidden')}>
            <DownloadProgress />
          </div>
        )}
      </Layout>
    </Layout>
  );
};

const AuthenticatedRoute: FC<IRoute & RouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          // not logged in so redirect to login page with the return url
          return <RedirectToSignInWithBackUrl />;
        }

        // check if route is restricted by role
        if (user.type !== rest.role) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: `/not-authorized` }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export const DashboardLayout: FC<{ routes: IRoute[] }> = ({ routes }) => (
  <DashboardLayoutComponent>
    {routes.map((route) => (
      <AuthenticatedRoute {...route} />
    ))}
  </DashboardLayoutComponent>
);
