import { FC, useCallback } from 'react';
import type { Container, Engine } from 'tsparticles-engine';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim'; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { EParticlesType, particlesOptions } from './particles-options';

interface IProps {
  optionType: EParticlesType;
  id: string;
}

export const NiceParticles: FC<IProps> = ({ id, optionType }) => {
  const particlesInit = useCallback(async (engine: Engine) => {
    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadConfettiPreset(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (_container: Container | undefined) => {}, []);

  return (
    <Particles
      id={id}
      init={particlesInit}
      loaded={particlesLoaded}
      options={particlesOptions[optionType]}
      className="h-full w-full"
    />
  );
};
