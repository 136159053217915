import { FC } from 'react';
import { Form, FormInstance, Input, InputNumber, Popover } from 'antd';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { MetaOptionsInput } from 'components/meta-options-input/meta-options-input';
import { META_KEYS } from 'hooks/useMetaOptionsSubmission';
import classNames from 'classnames';
import { NO_YES } from 'modules/gdp/case-submission/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NiceSelect } from 'components/commons/nice-select/nice-select';

interface IExperienceProps {
  isActive: boolean;
  formInstance: FormInstance;
  isConfident: boolean;
}

export const Experience: FC<IExperienceProps> = ({ isActive, formInstance, isConfident }) => {
  const dentalProtectionMember = Form.useWatch('dentalProtectionMember', formInstance);

  const onFinishFailed = ({ errorFields }: any) => {
    formInstance.scrollToField(errorFields[0].name, { block: 'center' });
  };

  return (
    <Form
      form={formInstance}
      layout="vertical"
      size="large"
      autoComplete="off"
      className="mt-4 animate-fadeIn"
      onFinishFailed={onFinishFailed}
    >
      <div className={classNames('grid grid-cols-2 gap-4', isActive ? 'visible' : 'hidden')}>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name="practicesYear"
            label="Roughly, how long have you been practicing?"
            isRequired
            rules={[
              {
                required: true,
                message: `Please tell us how long you’ve been practicing`,
              },
            ]}
          >
            <InputNumber className="custom-input w-full" min={0} placeholder="Enter number of years" />
          </FormItemControl>
        </div>
        {isConfident ? (
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="totalSubmissionsExp"
              label="How many Clear Aligner cases have you treated in the past year?"
              isRequired
              rules={[
                {
                  required: true,
                  message: 'Please select an option',
                },
              ]}
            >
              <MetaOptionsInput
                metaKey={META_KEYS.dentistRegistration}
                optionKey="totalSubmissionsExp"
                placeholder="Select an option"
                className="custom-input"
              />
            </FormItemControl>
          </div>
        ) : (
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="havePatientForTreatment"
              label="Do you have a patient ready for treatment?"
              isRequired
              rules={[
                {
                  required: true,
                  message: 'Please let us know if you have a patient waiting',
                },
              ]}
            >
              <MetaOptionsInput
                metaKey={META_KEYS.dentistRegistration}
                optionKey="havePatientForTreatment"
                placeholder="Select an option"
                className="custom-input"
              />
            </FormItemControl>
          </div>
        )}
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name={isConfident ? 'helpWithClearAlignerConfident' : 'helpWithClearAlignerNotConfident'}
            label="How can 32Co help you with Clear Aligners?"
            isRequired
            rules={[
              {
                required: true,
                message: isConfident ? 'Please select one or more options' : 'Please select an option',
              },
            ]}
          >
            <MetaOptionsInput
              metaKey={META_KEYS.dentistRegistration}
              optionKey={isConfident ? 'helpWithClearAlignerConfident' : 'helpWithClearAlignerNotConfident'}
              placeholder={isConfident ? 'Select one more options' : 'Select an option'}
              className="custom-input"
              mode={isConfident ? 'multiple' : undefined}
            />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name={isConfident ? 'wantToLearnConfident' : 'wantToLearnNotConfident'}
            label="Do you want to learn about anything in particular?"
            isRequired
            rules={[
              {
                required: true,
                message: 'Please select one or more options',
              },
            ]}
          >
            <MetaOptionsInput
              metaKey={META_KEYS.dentistRegistration}
              optionKey={isConfident ? 'wantToLearnConfident' : 'wantToLearnNotConfident'}
              placeholder="Select one or more options"
              className="custom-input"
              mode="multiple"
            />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            name="dentalProtectionMember"
            label={
              <div>
                Are you a Dental Protection member
                <span className="text-orange">*</span>
                <Popover
                  placement="top"
                  content="Dental Protection Members get priority access to 32Co’s free training courses"
                  overlayClassName="max-w-[300px]"
                >
                  <ExclamationCircleOutlined className="ml-2" />
                </Popover>
              </div>
            }
            rules={[
              {
                required: true,
                message: 'Please tell us if you’re a member or not',
              },
            ]}
          >
            <NiceSelect options={NO_YES} placeholder="Select an option" className="custom-input" />
          </FormItemControl>
        </div>
        {!isConfident && (
          <div className="col-span-2 lg:col-span-1">
            <FormItemControl
              name="applyForCourse"
              label="Would you like to apply for our Starter course?"
              isRequired
              rules={[
                {
                  required: true,
                  message: 'Let us know, you can change your mind later',
                },
              ]}
            >
              <MetaOptionsInput
                metaKey={META_KEYS.dentistRegistration}
                optionKey="applyForCourse"
                placeholder="Select an option"
                className="custom-input"
              />
            </FormItemControl>
          </div>
        )}
        <div className="col-span-2 lg:col-span-1">
          {dentalProtectionMember && (
            <FormItemControl
              name="dentalProtectionNumber"
              label="Please provide your Dental Protection Number"
              isRequired
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input className="custom-input" placeholder="Your Membership Number" />
            </FormItemControl>
          )}
        </div>
        {!isConfident && (
          <div className="col-span-2 lg:col-span-1">
            <div className="rounded-md bg-lightBlue px-3 py-2">
              32Co provides the only Clear Aligner Course approved by Dental Protection (p.s. it’s free 🤫)
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};
