import './about-you-form.styles.less';
import { FC } from 'react';
import { Typography, Input } from 'antd';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { FormItemPhoneNumber } from 'components/form/form-item-phone-number/form-item-phone-number';

export const AboutYouForm: FC = () => (
  <div className="about-you-form-styling mb-4 ml-4 mt-4">
    <Typography.Title level={4}>About you</Typography.Title>
    <Typography.Text>Lets get some details about you.</Typography.Text>
    <div className="mt-4 grid grid-cols-12 gap-4">
      <div className="col-span-6 md:col-span-3">
        <FormItemControl
          name="firstName"
          label="First Name"
          isRequired
          rules={[
            {
              transform: (value) => value?.trim(),
              required: true,
              message: 'Please enter first name',
            },
          ]}
        >
          <Input placeholder="Enter first name" className="custom-input" />
        </FormItemControl>
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormItemControl
          name="lastName"
          label="Last Name"
          isRequired
          rules={[
            {
              transform: (value) => value?.trim(),
              required: true,
              message: 'Please enter last name',
            },
          ]}
        >
          <Input placeholder="Enter last name" className="custom-input" />
        </FormItemControl>
      </div>
    </div>
    <Typography.Title level={5} className="mt-4">
      Preferred Contact method
    </Typography.Title>
    <div className="grid grid-flow-row md:grid-flow-col md:grid-cols-12">
      <FormItemPhoneNumber
        label="Telephone"
        name="phone"
        numberPlaceholder="Mobile number"
        autoDetectCode={false}
        className="custom-input col-span-6 mb-2 lg:col-span-4 2xl:col-span-4"
      />
    </div>
    <div className="grid grid-flow-row md:grid-flow-col md:grid-cols-12">
      <FormItemControl
        name="email"
        label="Email"
        rules={[
          {
            message: 'Please enter your email address',
          },
          { type: 'email', message: 'Please enter your full email address' },
        ]}
        normalize={(value) => value?.toLowerCase()}
        className="col-span-6 lg:col-span-4 2xl:col-span-4"
      >
        <Input placeholder="Email" className="custom-input" />
      </FormItemControl>
    </div>
  </div>
);
