import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { USER_ROLES } from '../../../config/constants';
import styled from 'styled-components';
import smallLogoSvg from 'assets/images/auth/small-logo.svg';
import logoSvg from 'assets/images/dashboard/logo.svg';
import { ReactComponent as MainSVG } from 'assets/images/dashboard/main.svg';
import { ReactComponent as PatientsSVG } from 'assets/images/dashboard/patients.svg';
import { ReactComponent as EducationSVG } from 'assets/images/dashboard/education.svg';
import { ReactComponent as LiveCoursesSVG } from 'assets/images/dashboard/livecourses.svg';
import { ReactComponent as CartSVG } from 'assets/images/dashboard/cart.svg';
import { ReactComponent as CalculatorSVG } from 'assets/images/dashboard/calculator.svg';
import { ReactComponent as CaseStudiesSVG } from 'assets/images/dashboard/case-studies.svg';
import { ReactComponent as GrowthHubSVG } from 'assets/images/dashboard/growth-hub.svg';
import { IUser, UserStatuses } from '../../../modules/auth/services/type';
import classNames from 'classnames';
import './dashboard-layout.styles.less';
import { VIEWS_TO_SHOW_NEW_CASE_STUDY_BADGE, VIEWS_TO_SHOW_NEW_GROWTH_HUB_BADGE } from 'modules/gdp/case-studies/utils';
import { useAppSelector } from 'hooks/store';
import { selectChristmasEnabled } from 'redux/themeSlice';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';
import { twMerge } from 'tailwind-merge';

export const MENU_DURATION = '0.2s';

const ItemStyled = styled.div<{ active: boolean; smallMode: boolean; disabled: boolean }>`
  position: relative;
  padding-left: ${({ smallMode }) => (smallMode ? '30px' : '22px')};
  padding-top: 10px;
  padding-bottom: 10px;
  color: 'white';
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  :hover {
    background: ${({ disabled }) => (disabled ? 'transparent' : '#1890ff5e')};
  }

  .menuBack {
    transition: width ${MENU_DURATION};
    width: ${({ active, smallMode }) => (active && !smallMode ? '172px' : '40px')};
    height: 40px;
    box-shadow: inset 0px 0.5px 4px rgb(96 97 112 / 32%);
    border-radius: 20px;
    position: absolute;
    color: ${({ disabled }) => (disabled ? 'black' : 'white')};
    background: ${({ disabled }) => (disabled ? 'rgba(225, 225, 225, 0.75)' : '#f9f9fa')};
  }

  .menuContext {
    margin-left: 11px;
    padding-top: 11px;
    z-index: 1;
    position: absolute;

    svg {
      fill: ${({ active }) => (active ? 'white' : '#050D43')};
      g {
        path {
          fill: ${({ active }) => (active ? 'white' : '#050D43')};
        }
      }

      &.growth-hub-menu {
        fill: none;
        path {
          stroke: ${({ active }) => (active ? 'white' : '#050D43')};
        }
        circle {
          stroke: ${({ active }) => (active ? 'white' : '#050D43')};
        }
      }
    }
  }
`;

const NotificationDot = styled.div<{ active: boolean }>`
  position: absolute;
  top: 3px;
  left: 20px;

  width: 10px;
  height: 10px;
  border-radius: 5px;

  background-color: ${(props) => (props.active ? 'white' : 'red')};
`;

interface IMenuItemProps {
  active: boolean;
  onClick?: () => void;
  icon: React.ReactNode;
  text: string;
  smallMode: boolean;
  notification?: boolean;
  disabled?: boolean;
  tooltip?: string;
  id?: string;
  isNew?: boolean;
}

const MenuItem: FC<IMenuItemProps> = ({
  active,
  onClick,
  icon,
  text,
  smallMode,
  notification,
  disabled = false,
  tooltip,
  id,
  isNew,
}) => (
  <ItemStyled active={active} id={id} smallMode={smallMode} onClick={disabled ? () => {} : onClick} disabled={disabled}>
    <Tooltip placement="right" title={disabled ? tooltip : null}>
      <div style={{ height: '40px' }}>
        <div className="align-items-center menuContext relative flex">
          {icon}
          {smallMode ? (
            isNew && (
              <div
                className={classNames(
                  'absolute right-[-10px] top-0 h-[10px] w-[10px] rounded-full',
                  active ? 'bg-white' : 'bg-[#E82A63]',
                )}
              />
            )
          ) : (
            <div className="gap-2">
              <span className="ml-6 text-white">{text}</span>
              {isNew && (
                <span
                  className={classNames(
                    'ml-2 rounded-2xl px-1 py-1',
                    active ? 'bg-white text-orangeColor' : 'bg-orangeColor text-white',
                  )}
                >
                  New
                </span>
              )}
            </div>
          )}
          {notification && <NotificationDot active={active} />}
        </div>
        <div
          className={classNames('menuBack', {
            '!bg-active-menu': active,
          })}
        />
      </div>
    </Tooltip>
  </ItemStyled>
);

interface IDashboardMenuProps {
  user: IUser;
  activeMenu: string;
  smallMode?: boolean;
}

const GET_COSY_PATHNAME = '/dentist/get-cosy';

export const DashboardMenu: FC<IDashboardMenuProps> = ({ user, activeMenu, smallMode = false }) => {
  const isChristmasTheme = useAppSelector(selectChristmasEnabled);
  const history = useHistory();
  const getCosyActive = history.location.pathname === GET_COSY_PATHNAME;
  const viewedGrowthHubCount = user?.viewedGrowthHubCount || 0;

  const onFireCrackleClick = () => {
    trackEvent(EEventNames.FIRE_CRACKLE_CLICKED);
    if (getCosyActive) history.goBack();
    else history.push(`/${user?.type}/get-cosy`);
  };

  return (
    <div className="flex h-screen flex-col text-xs font-normal text-white">
      <div className="flex justify-center py-8">
        <Link to="/">
          {smallMode ? <img src={smallLogoSvg} height={33} alt="logo" /> : <img src={logoSvg} alt="logo" height={33} />}
        </Link>
      </div>

      <div className="flex-1 overflow-y-auto overflow-x-hidden">
        <Link id="dashboard-menu" to={`/${user?.type || USER_ROLES.DENTIST}`}>
          <MenuItem
            smallMode={smallMode}
            active={activeMenu === (user?.type || USER_ROLES.DENTIST)}
            icon={<MainSVG fill="white" />}
            text="Dashboard"
          />
        </Link>

        {user?.type !== USER_ROLES.MANUFACTURER && (
          <Link id="patients-menu" to={`/${user?.type}/patients`}>
            <MenuItem smallMode={smallMode} active={activeMenu === 'patients'} icon={<PatientsSVG />} text="Patients" />
          </Link>
        )}

        {user?.type === USER_ROLES.DENTIST && (
          <>
            <Link id="education-menu" to={`/${user?.type}/education`}>
              <MenuItem
                smallMode={smallMode}
                active={activeMenu === 'education'}
                icon={<EducationSVG fill="black" />}
                text="Education"
                notification={user.newContentAssigned}
                // disabled={user?.status === 'applied'}
              />
            </Link>
            <a id="livecourses-menu" rel="noreferrer" href="https://32co.talentlms.com/index" target="_blank">
              <MenuItem
                smallMode={smallMode}
                // activeMenu not in use here
                active={activeMenu === 'livecourses'}
                icon={<LiveCoursesSVG fill="black" />}
                text="Live Courses"
              />
            </a>
          </>
        )}
        {user?.type === USER_ROLES.DENTIST && (
          <Link id="calculator-menu" to={`/${user?.type}/calculator`}>
            <MenuItem
              smallMode={smallMode}
              active={activeMenu === 'calculator'}
              icon={<CalculatorSVG fill="black" />}
              text="Calculator"
              isNew={!user.isViewedComplexityCalculator}
            />
          </Link>
        )}
        {user?.type === USER_ROLES.DENTIST && (
          <Link
            id="pricing-menu"
            to={`/${user?.type}/pricing`}
            onClick={user?.status === UserStatuses.APPLIED ? (e) => e.preventDefault() : undefined}
          >
            <MenuItem
              smallMode={smallMode}
              active={activeMenu === 'pricing'}
              icon={<CartSVG fill={activeMenu ? 'white' : 'none'} />}
              text="Pricing"
              disabled={user?.status === UserStatuses.APPLIED}
              tooltip="Complete your onboarding steps to unlock Pricing"
            />
          </Link>
        )}
        {user?.type === USER_ROLES.DENTIST && (
          <Link
            id="growth-hub-menu"
            to={`/${user?.type}/growth-hub`}
            onClick={() => trackEvent(EEventNames.GROWTH_HUB_DASHBOARD_MENU_CLICKED)}
          >
            <MenuItem
              smallMode={smallMode}
              active={activeMenu === 'growth-hub'}
              icon={<GrowthHubSVG className="growth-hub-menu" fill="white" />}
              text="Growth Hub"
              isNew={viewedGrowthHubCount < VIEWS_TO_SHOW_NEW_GROWTH_HUB_BADGE}
            />
          </Link>
        )}
        {user?.type === USER_ROLES.DENTIST && (
          <Link
            id="case-studies-menu"
            to={`/${user?.type}/case-studies`}
            onClick={() => trackEvent(EEventNames.CASE_STUDIES_DASHBOARD_MENU_CLICKED)}
          >
            <MenuItem
              smallMode={smallMode}
              active={activeMenu === 'case-studies'}
              icon={<CaseStudiesSVG fill="white" />}
              text="Case Studies"
              isNew={
                user.viewedCaseStudyCount !== undefined &&
                user.viewedCaseStudyCount < VIEWS_TO_SHOW_NEW_CASE_STUDY_BADGE
              }
            />
          </Link>
        )}
        {isChristmasTheme && (
          <div className={classNames('z-50 pr-6', smallMode ? 'pl-[30px]' : 'pl-[22px]')}>
            <Button
              className={twMerge(
                'z-50 my-2 h-auto w-28 animate-fadeIn py-2 text-center font-medium',
                smallMode && 'w-10 px-2',
              )}
              onClick={onFireCrackleClick}
            >
              <span className={smallMode ? '!hidden' : ''}>{getCosyActive ? 'Less Cosy' : 'Get Cosy'}&nbsp;</span>
              🔥
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
