import './sq-form.styles.less';
import { Typography, Form, FormInstance, notification } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import { SmileQuestionnaireFormItems } from './sq-form-items/sq-form-items';
import { useHistory, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useUpsertInterestedPatientSQMutation } from 'services/interested-patients-api/endpoints';
import { notificationApiError } from 'utils/ui';
import { ISqPatientInfo } from '../../../../../services/interested-patients-api/types';
import { useUpdateInterestedPatientSQMutation } from 'services/patients-api/endpoints';
import moment from 'moment';

interface IProps {
  isFormDisabled?: boolean;
  showContentForPatient?: boolean;
  form?: FormInstance;
  isLoading?: boolean;
  handleCompleteSQ?: () => void;
  initialData?: any;
  onValuesChange?: () => void;
  patientToken?: string;
  onAutoSaveChange?: (val: boolean) => void;
  shouldAutoSave?: boolean;
  onLastSave?: (time: string) => void;
  onSetRouteLeavingGuard?: (b: boolean) => void;
}

export const SmileQuestionnaireForm: FC<IProps> = ({
  form,
  initialData,
  patientToken,
  isFormDisabled = false,
  showContentForPatient = false,
  isLoading = false,
  handleCompleteSQ,
  onValuesChange,
  onAutoSaveChange,
  shouldAutoSave,
  onLastSave,
  onSetRouteLeavingGuard,
}) => {
  const history = useHistory();
  const params: { interestedPatientId: string; patientId: string } = useParams();
  const [createPatientSQ, { isLoading: isUpdatingPatientSQ }] = useUpsertInterestedPatientSQMutation();
  const [updateInterestedPatientSQ, { isLoading: isUpdatingSQ }] = useUpdateInterestedPatientSQMutation();

  const handleSave = async (values: any) => {
    const saveValues = { ...values, status: 'DRAFT' };
    try {
      if (showContentForPatient && params?.patientId) {
        saveValues.id = params.patientId!;
        saveValues.status = 'DRAFT';
        saveValues.password = patientToken;
        await updateInterestedPatientSQ(saveValues).unwrap();
        onAutoSaveChange?.(isUpdatingSQ);
      } else {
        await createPatientSQ({ id: params.interestedPatientId!, payload: saveValues }).unwrap();
        if (!shouldAutoSave) {
          notification.success({ message: `Draft saved at ${moment().format('HH:mmA DD/MM/YYYY')}` });
        }
        onLastSave?.(new Date().toISOString());
      }

      onSetRouteLeavingGuard?.(false);
    } catch (error) {
      notificationApiError(error);
    }
  };

  const handleAutoSave = debounce(async (values: any) => {
    if (!shouldAutoSave) return;
    handleSave(values);
  }, 700);

  useEffect(
    () => () => {
      handleAutoSave.cancel();
    },
    [handleAutoSave],
  );

  const [attachmentsUrls, setAttachmentsUrls] = useState<string>('');

  const handleOnValuesChange = (changedValues: Partial<ISqPatientInfo>, allValues: any) => {
    onValuesChange?.();
    onSetRouteLeavingGuard?.(true);

    const attachmentsUrlsChanged =
      changedValues?.attachments
        ?.filter((v) => v.url)
        .map((v) => v.url)
        .join(',') || '';
    const skipAutoSave = changedValues?.attachments && attachmentsUrls === attachmentsUrlsChanged;
    setAttachmentsUrls(attachmentsUrlsChanged);
    if (!skipAutoSave && patientToken) {
      handleAutoSave(allValues);
    }
  };

  const initialValues: Partial<ISqPatientInfo> = useMemo(
    () => ({
      rating: initialData?.rating,
      consideredBefore: initialData?.consideredBefore,
      newChange: initialData?.newChange,
      attachments: initialData?.attachments,
      note: initialData?.note,
      annoyIssues: initialData?.annoyIssues,
      newEventNote: initialData?.newEventNote,
      newConcern: initialData?.newConcern,
    }),
    [initialData],
  );

  return (
    <>
      <div className="sq-form-container">
        {showContentForPatient && (
          <div className="col-span-12 mb-2">
            <div className="border-radius text-center">
              <p className="mb-4 text-5xl font-bold text-darkBlueColor">Your Smile Questionnaire</p>
            </div>

            <div className="mv-3 mb-4 flex items-center justify-center rounded p-2">
              <div className="ml-4 flex flex-col text-center text-[#555770]">
                <p className="mb-1 text-base font-semibold">
                  Help me achieve the smile you have always dreamed of... 😍
                </p>
                <p className="mb-1 text-base font-normal">
                  I’ll review these to provide you with your personalised treatment options
                </p>
              </div>
            </div>
            <Typography.Title level={3}>You and your Smile</Typography.Title>
          </div>
        )}
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={handleOnValuesChange}
          layout="vertical"
          size="large"
          className="pb-1"
        >
          <SmileQuestionnaireFormItems
            form={form}
            onForceAutoSave={handleAutoSave}
            isFormDisabled={isFormDisabled}
            showContentForPatient={showContentForPatient}
          />
        </Form>
      </div>
      {!showContentForPatient && (
        <div className="sticky bottom-0 z-50 -mx-9 mt-10 flex flex-col-reverse items-center justify-between gap-2 bg-white  p-3 shadow-custom2 lg:flex-row lg:py-6">
          <NiceButton niceType="default" onClick={() => history.goBack()} className="md:w-28" size="small">
            Cancel
          </NiceButton>
          <div className="flex flex-row items-center justify-between gap-4">
            <NiceButton
              size="small"
              className="min-w-[100px]"
              onClick={() => {
                const values = form?.getFieldsValue();
                handleSave(values);
              }}
              loading={isUpdatingPatientSQ}
            >
              Save
            </NiceButton>
            <NiceButton
              className="ml-2 min-w-[7rem]"
              loading={isLoading}
              niceType="orange"
              onClick={handleCompleteSQ}
              size="small"
            >
              Complete Questionnaire
            </NiceButton>
          </div>
        </div>
      )}
    </>
  );
};
