import { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { pdfStyles } from './utils';

type TValueType = string | number;
interface IProps {
  label: string;
  value?: TValueType | TValueType[];
  width?: string;
  wrap?: boolean;
}

export const PdfKeyValue: FC<IProps> = ({ label, value, width, wrap = false }) => (
  <View style={{ ...pdfStyles.keyValue, width }} wrap={wrap}>
    {label && <Text style={pdfStyles.label}>{label}</Text>}
    {Array.isArray(value) ? (
      value.length > 0 ? (
        value.map((datum) => (
          <Text key={datum} style={pdfStyles.value}>
            {datum || '-'}
          </Text>
        ))
      ) : (
        <Text style={pdfStyles.value}>-</Text>
      )
    ) : (
      <Text style={pdfStyles.value}>{value || '-'}</Text>
    )}
  </View>
);

export const PdfSectionHeader: FC<{ value: string }> = ({ value }) => (
  <Text style={pdfStyles.sectionHeader}>{value}</Text>
);
export const PdfSubSectionHeader: FC<{ value: string }> = ({ value }) => (
  <Text style={pdfStyles.sectionSubHeader}>{value}</Text>
);
