import { ILoginRequest, useLoginMutation, useSignOutMutation } from 'modules/auth/services/authApi';
import { useSelector } from 'react-redux';
import { logout, selectAuthState, setCredentials, TAuthState } from 'modules/auth/authSlice';
import { useAppDispatch } from './store';
import { useHistory } from 'react-router-dom';
import { api } from 'services/api';
import { notificationApiError } from 'utils/ui';
import { useMemo } from 'react';
import { USER_ROLES } from 'config/constants';
import { endUserSession } from 'utils/mixpanel/mixpanel';

type TUseAuth = TAuthState & {
  userLogout: () => Promise<void>;
  isUserLogoutLoading: boolean;
  userLogin: (formState: ILoginRequest, redirectUrl: string | null) => Promise<void>;
  isUserLoginLoading: boolean;
  isDentist: boolean;
  isDesigner: boolean;
  isSpecialist: boolean;
  isManufacture: boolean;
  isTechnician: boolean;
};

export const useAuth = (): TUseAuth => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [signOut, { isLoading: isSignOutLoading }] = useSignOutMutation();

  const userLogout = async () => {
    try {
      await signOut().unwrap();
    } finally {
      dispatch(logout());
      history.push('');
      dispatch(api.util.resetApiState());
      endUserSession();
    }
  };

  const userLogin = async (formState: ILoginRequest, redirectUrl: string | null) => {
    try {
      const userResponse: any = await login({ ...formState, email: formState.email.toLowerCase() }).unwrap();
      if (userResponse.forcePasswordReset) {
        localStorage.setItem('resetPasswordToken', JSON.stringify(userResponse.resetPasswordToken));
        history.push('/reset-password');
      } else {
        const userObject = { ...userResponse, type: userResponse.role?.toLowerCase() };
        localStorage.setItem('userType', JSON.stringify(userObject?.type));
        dispatch(setCredentials({ user: userObject }));

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          history.push('/');
        }
      }
    } catch (err: any) {
      notificationApiError(err);
    }
  };

  const authState = useSelector(selectAuthState);

  const userIdentity = useMemo(
    () => ({
      isDentist: authState.user?.type === USER_ROLES.DENTIST,
      isDesigner: authState.user?.type === USER_ROLES.DESIGNER,
      isSpecialist: authState.user?.type === USER_ROLES.SPECIALIST,
      isManufacture: authState.user?.type === USER_ROLES.MANUFACTURER,
      isTechnician: authState.user?.type === USER_ROLES.TECHNICIAN,
    }),
    [authState.user?.type],
  );

  return {
    userLogout,
    isUserLogoutLoading: isSignOutLoading,
    userLogin,
    isUserLoginLoading: isLoginLoading,
    ...authState,
    ...userIdentity,
  };
};
