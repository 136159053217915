import { CheckOutlined } from '@ant-design/icons';
import { Card, Avatar } from 'antd';
import simonAvtPng from 'assets/images/auth/simon-avt.png';

export const SecondSlide = () => (
  <div className="flex max-h-[calc(100vh-145px)] flex-col items-baseline overflow-y-auto text-white">
    <Card className="w-full self-center !rounded-[20px] border-0 bg-white bg-opacity-10 text-white lg:w-[354px]">
      <div className="font-bold">I wouldn’t go back to working without 32Co</div>
      <div>I've used many aligner systems in the past. 32Co’s support cannot be overstated.</div>
      <div className="mt-2 flex items-center gap-3">
        <Avatar className="mt-2 self-baseline" src={simonAvtPng} />
        <div>
          <div className="text-base font-bold">Simon</div>
          <div className="text-sm">Dentist (Over 80 successful 32Co Cases)</div>
        </div>
      </div>
    </Card>
    <div className="mt-8 py-4 text-2xl font-semibold lg:text-3xl">50% fewer refinements than traditional systems</div>
    <p className="text-base lg:text-lg">
      1:1 case mentorship from specialist orthodontists means predictable and repeatable outcomes
    </p>
    <div className="mb-4 flex items-baseline text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      {`Peace of mind with DUO. Work 1:1 with orthodontists to plan treatments in <4 days.`}
    </div>
    <div className="mb-4 flex items-baseline text-sm lg:text-base">
      <CheckOutlined className="pr-2 text-xs" />
      Plan cases yourself with SOLO and get a plan back in less than 48 hours with no set up fees.
    </div>
  </div>
);
