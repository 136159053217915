import { Form, Input, InputNumber } from 'antd';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { ITreatmentQuoteInfo } from 'services/patient-proposal-api/types';

interface IEditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: ITreatmentQuoteInfo;
  index: number;
  children: ReactNode;
}

/**
 * @see {@link https://ant.design/components/table/#components-table-demo-edit-row}
 */
export const EditableCell: FC<IEditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  style,
  ...restProps
}) => {
  const inputNode =
    dataIndex === 'price' ? (
      <InputNumber
        type="number"
        onKeyDown={(e) => {
          if (e.key === 'e' || e.key === 'E' || e.key === '-') {
            e.preventDefault();
          }
        }}
        className="w-full"
        style={{ border: '1px solid #E8E8E8' }}
        controls={false}
        prefix={`${record?.currencyCode} ` ?? '£ '}
      />
    ) : (
      <Input style={{ border: '1px solid #E8E8E8' }} />
    );

  return (
    <td {...restProps} style={{ wordBreak: 'break-word', ...style }}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
