/* eslint-disable class-methods-use-this */
import { TNotificationItem } from 'services/notification/types';
import socketService from './index';

let timeout: any = null;
class NotificationsSocketService {
  public async onNotificationCome(listiner: (notis: TNotificationItem) => void) {
    if (socketService.socket) {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        console.info('notification on called');
        socketService?.socket?.on('notification', (notis) => listiner(notis));
      });
    }
  }

  public async offNotificationCome() {
    console.info('notification off called');
    if (socketService.socket) {
      socketService?.socket?.off('notification');
    }
  }
}

export default new NotificationsSocketService();
