export const getReadableFileSizeString = (bytes: number | undefined): string => {
  if (bytes === undefined) {
    return '';
  }

  let fileSizeInBytes = bytes;
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i += 1;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};
