/* eslint-disable react/no-unstable-nested-components */
import React, { Suspense } from 'react';
import { USER_ROLES } from '../../config/constants';
import { IRoute } from 'interface/IRoute';
import { PageLoader } from 'components/page-loader/page-loader.component';

const LazyDashboardComponent = React.lazy(() => import('./dashboard/dashboard.component'));
const LazyPatientsComponent = React.lazy(() => import('./patients/patients'));
const LazyEdu = React.lazy(() => import('./edu/edu'));
const LazyPricing = React.lazy(() => import('./pricing/pricing'));
const LazyCaseSubmissionComponent = React.lazy(() => import('./case-submission/case-submission'));
const LazyOrderReplacementComponent = React.lazy(() => import('./case-submission/order-replacement/order-replacement'));
const LazyOrderRetainerComponent = React.lazy(() => import('./case-submission/order-retainer/order-retainer'));
const LazyProfileComponent = React.lazy(() => import('../../components/profile/profile'));
const LazyCalculator = React.lazy(() => import('./calculator/calculator'));
const LazyCaseStudies = React.lazy(() => import('./case-studies/case-studies'));
const LazyGrowthHub = React.lazy(() => import('./growth-hub/growth-hub'));
const LazyCaseStudyDetails = React.lazy(() => import('./case-studies/case-study-details/case-study-details'));
const LazyChristmasGetCosy = React.lazy(
  () => import('components/layout/dashboard-layout/christmas-theme/christmas-fireplace'),
);

export const gdpRoutes: IRoute[] = [
  {
    path: '/dentist',
    exact: true,
    name: 'Dashboard',
    key: 'dashboard_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyDashboardComponent />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/profile',
    exact: true,
    name: 'Profile',
    key: 'profile_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyProfileComponent userType={USER_ROLES.DENTIST} />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/patients',
    exact: false,
    name: 'Patients',
    key: 'patients_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyPatientsComponent />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/patients/:submissionId/order-replacement',
    exact: true,
    name: 'Submit Order Replacement',
    key: 'submit_order_replacement',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyOrderReplacementComponent />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/patients/:submissionId/order-retainer',
    exact: false,
    name: 'Submit Order Retainer',
    key: 'submit_order_retainer',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyOrderRetainerComponent />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/patients/submit/:type/:planType?',
    exact: true,
    name: 'Submit Case Page',
    key: 'submit_case_page',
    // TODO: FE team, I remove this check, why we need check lowercase [duo, solo]?
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyCaseSubmissionComponent />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/education',
    exact: false,
    name: 'Education',
    key: 'edu_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyEdu />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/pricing',
    exact: false,
    name: 'Pricing',
    key: 'pricing_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyPricing />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/calculator',
    exact: false,
    name: 'Calculator',
    key: 'calculator_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyCalculator />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/growth-hub',
    exact: true,
    name: 'Growth Hub',
    key: 'growth_hub',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyGrowthHub />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/case-studies',
    exact: true,
    name: 'Case Studies',
    key: 'case_studies_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyCaseStudies />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/case-studies/:caseStudyId',
    exact: true,
    name: 'Case Details',
    key: 'case_study_details_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyCaseStudyDetails />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/get-cosy',
    exact: true,
    name: 'Get cosy',
    key: 'get_cosy_page',
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <LazyChristmasGetCosy />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
];

export const GDP_IP_PATH = '/dentist/patients/interested-patient';

const nestedRoutes = [
  `${GDP_IP_PATH}/submit`,
  `${GDP_IP_PATH}/:interestedPatientId`,
  `${GDP_IP_PATH}/:interestedPatientId/smile-questionnaire/:sqId?`,
  `${GDP_IP_PATH}/:interestedPatientId/pis/:pisId?`,
  '/dentist/patients/:submissionId/completed-treatment/:patientId/how-it-went',
  '/dentist/patients/:submissionId/completed-treatment/:patientId/recap',
  '/dentist/patients/:submissionId/completed-treatment/:patientId/add-case-study',
  '/dentist/patients/:submissionId',
  '/dentist/patients/:submissionId/design/:designId',
  '/dentist/patients/:submissionId/design/:designId/patient-proposal/create',
  '/dentist/patients/:submissionId/design/:designId/patient-proposal/:proposalId/edit',
  '/dentist/education/:contentId',
  '/dentist/education/assign/:contentId',
  'dentist/case-studies/:caseStudyId',
];

export const gdpRoutesToCheck = gdpRoutes.map((route) => route.path).concat(...nestedRoutes);
