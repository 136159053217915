import { FC, useState } from 'react';
import { Button, Skeleton } from 'antd';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { NiceCollapse } from 'components/commons/nice-collapse/nice-collapse';
import { ReactComponent as DownArrow } from 'assets/images/down-arrow.svg';
import { useGetSkusQuery } from 'services/sku-api/endpoints';
import { useCurrency } from 'hooks/useCurrency';

type TProps = {
  submission: string;
  treatmentDesign: string;
  scrollToClearAlignersSection: () => void;
};

export const SkuRecommendation: FC<TProps> = ({ submission, treatmentDesign, scrollToClearAlignersSection }) => {
  const [toggleRecommendedPackagesSection, setToggleRecommendedPackagesSection] = useState(false);
  const { data: recommendationData, isLoading: isRecommendationDataLoading } = useGetSkusQuery({
    submission,
    treatmentDesign,
  });
  const { moneyFormat } = useCurrency();

  const onClickRecommendedPackages = (key: string[]) => {
    setToggleRecommendedPackagesSection(!isEmpty(key));
  };

  return !isEmpty(recommendationData?.refinementRecomendation) ? (
    <div
      className={classNames(
        'mt-3',
        toggleRecommendedPackagesSection ? 'nice-collapse-transparent' : 'nice-collapse-gradient',
      )}
    >
      <NiceCollapse
        title={
          toggleRecommendedPackagesSection
            ? recommendationData?.refinementRecomendation?.recommendationNumberOfRefinements
            : "NEW - Click here to see 32Co's Package Recommendations"
        }
        open={false}
        onChange={onClickRecommendedPackages}
      >
        <Skeleton loading={isRecommendationDataLoading}>
          <div className="rounded-standard p-6 pt-2 pr-4 text-white">
            <div className="ml-7 mt-2 grid grid-cols-12 items-center gap-4">
              <div className="col-span-12 md:col-span-7">
                <div className="flex flex-col">
                  <div className="text-sm">
                    {recommendationData?.refinementRecomendation?.justificationOfRefinement}. <br />
                    {recommendationData?.refinementRecomendation?.averagePriceOfRefinements}
                  </div>
                </div>
              </div>
              <div className="col-span-12 flex flex-row items-center justify-between md:col-span-5">
                <div>
                  <div>{recommendationData?.refinementRecomendation?.packageAvailableFrom}</div>
                  <div className="text-center text-base font-bold">
                    From {moneyFormat(recommendationData?.refinementRecomendation?.price || 0)}
                  </div>
                </div>
                <Button
                  className="flex flex-row items-center justify-between !border-white !bg-white !p-4 font-semibold !text-[#D83D64]"
                  size="small"
                  shape="round"
                  onClick={scrollToClearAlignersSection}
                >
                  Order <DownArrow fill="#D83D64" className="ml-2 mt-1" />
                </Button>
              </div>
            </div>
          </div>
        </Skeleton>
      </NiceCollapse>
    </div>
  ) : null;
};
