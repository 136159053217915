import './signup.styles.less';
import { Button, Form, FormInstance, Progress } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IDentistRegisterPayload, useRegisterMutation } from '../services/authApi';
import { pushToDataLayer } from 'utils/gtm';
import { AuthLayout } from '../components/auth-layout';
import { Greeting } from './steps/greeting';
import { PersonalDetails } from './steps/personal-details';
import { Experience } from './steps/experience';
import NiceModal from '@ebay/nice-modal-react';
import { ReminderModal } from './reminder-modal/reminder-modal';
import { setCredentials } from '../authSlice';
import { useDispatch } from 'react-redux';
import { useQuery } from 'hooks/useQuery';
import { notificationApiError } from 'utils/ui';
import { Practice } from './steps/practice/practice';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import { isEmpty, merge } from 'lodash';
import { META_KEYS, useMetaOptionsSubmission } from 'hooks/useMetaOptionsSubmission';
import * as Sentry from '@sentry/react';
import { identifyUser, trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';
import { RouteLeavingGuard } from 'components/route-leave-guard/route-leave-guard';
// import { FormItemControl } from 'components/form/form-item-control/form-item-control';

const defaultFormData = {
  clinics: [
    {
      name: null,
      address: null,
      isScannerAvailable: null,
    },
  ],
};

const FRIEND_REF_CODE_OPTION = 'A friend or colleague recommended to me';

enum SIGN_UP_STEPS {
  greeting = -1,
  personalDetail = 0, // personal_details
  experience = 1, // learn_more
  practice = 2, // experience
}

export const DentistSignup: FC = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [step, setStep] = useState<SIGN_UP_STEPS>(SIGN_UP_STEPS.greeting);
  const [register, { isLoading: formSubmitting }] = useRegisterMutation();
  const allowShowReminder = query.get('show_reminder') !== 'false';
  const [isReminded, setIsReminded] = useState(!allowShowReminder);
  const formStepInstances = useRef<FormInstance[]>([Form.useForm()[0], Form.useForm()[0], Form.useForm()[0]]);
  const history = useHistory();

  const { getOptions } = useMetaOptionsSubmission(META_KEYS.dentistRegistration);
  const firstName = Form.useWatch('firstName', formStepInstances.current?.[SIGN_UP_STEPS.personalDetail]);
  const bestDescribes = Form.useWatch('bestDescribes', formStepInstances.current?.[SIGN_UP_STEPS.personalDetail]);

  const isConfident = useMemo(() => {
    const bestDescribesOptions = getOptions('bestDescribes');
    if (!bestDescribes || !bestDescribesOptions) return false;

    const expectedValue = 'I have a good foundation providing Clear Aligners';
    let isConfidentOption = bestDescribesOptions?.find((option) => option.value === expectedValue);

    if (!isConfidentOption) {
      Sentry.captureException(
        new Error('Dentist sign up form: isConfidentOption not found in meta api. Please check!'),
      );
      isConfidentOption = bestDescribesOptions?.[2]; // fallback if meta-option api change the value
    }

    return bestDescribes === isConfidentOption.value;
  }, [bestDescribes]);

  const handleNextButton = async () => {
    try {
      await formStepInstances.current[step]?.validateFields();
      switch (step) {
        case SIGN_UP_STEPS.greeting:
          pushToDataLayer({ event: 'sign_up_form.signupclick' });
          break;
        case SIGN_UP_STEPS.personalDetail:
          pushToDataLayer({ event: 'sign_up_form.personal_details.next' });
          break;
        case SIGN_UP_STEPS.experience:
          pushToDataLayer({ event: 'sign_up_form.learn_more.next' });
          break;
        default:
      }
      setStep(step + 1);
    } catch (e: any) {
      const { errorFields } = e || {};
      formStepInstances.current[step]?.scrollToField(errorFields?.[0]?.name, { block: 'center', behavior: 'smooth' });
    }
  };

  const handleBackButton = () => {
    switch (step) {
      case SIGN_UP_STEPS.experience:
        pushToDataLayer({ event: 'sign_up_form.learn_more.previous' });
        break;
      case SIGN_UP_STEPS.practice:
        pushToDataLayer({ event: 'sign_up_form.experience.previous' });
        break;
      default:
    }

    setStep(step - 1);
  };

  const getUTMQuery = () => ({
    'Registration UTM Source': query.get('utm_source'),
    'Registration UTM Medium': query.get('utm_medium'),
    'Registration UTM Name': query.get('utm_name'),
    'Registration UTM Term': query.get('utm_term'),
    'Registration UTM Content': query.get('utm_content'),
  });

  const trackRegisterCompleted = (user: any, payload: IDentistRegisterPayload) => {
    trackEvent(EEventNames.SIGN_UP_COMPLETED, {
      user_id: user.id,
      'Registration Date': new Date().toISOString(),
      'Registration Method': 'Email',
      Referred: !!payload.referredCode,
      referralCode: payload.referredCode,
      ...getUTMQuery(),
    });
    identifyUser(user.id, {
      referralByCode: payload.referredCode,
    });
  };

  const handleSubmit = async () => {
    try {
      const stepValueArray = await Promise.all(
        formStepInstances.current.map((_, stepFormInstanceIndex) =>
          formStepInstances.current[stepFormInstanceIndex].validateFields(),
        ),
      );
      pushToDataLayer({ event: 'sign_up_form.submit' });
      const formData = stepValueArray.reduce((finalValues, stepValue) => merge(finalValues, stepValue), {});
      const { confirmPassword, ...details } = formData;

      // prepare utm tags
      const queryObject = Object.fromEntries(query.entries());
      const utmTags: any = {};
      Object.entries(queryObject).forEach(([key, value]) => {
        if (/^utm_.*$/.test(key)) {
          utmTags[key] = value;
        }
      });

      const payload: IDentistRegisterPayload = {
        type: 'dentist',
        utm: !isEmpty(utmTags) ? utmTags : undefined,
        ...details,
      };

      try {
        const response = await register(payload).unwrap();
        setIsReminded(true);
        trackRegisterCompleted(response, payload);
        // logged in
        const userObject = { ...response, type: response.role?.toLowerCase() };
        localStorage.setItem('userType', userObject.type);
        await dispatch(setCredentials({ user: userObject, justSignUp: true }));
        history.push('/dentist');
      } catch (error) {
        notificationApiError(error);
      }
    } catch (e: any) {
      const { errorFields } = e || {};
      formStepInstances.current[step]?.scrollToField(errorFields?.[0]?.name, {
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  const [isStayOver10s, setIsStayOver10s] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setIsStayOver10s(true), 8000);
    const refCode = query.get('ref_code');
    const initFormData = {
      ...defaultFormData,
      ...(refCode
        ? {
            hearAbout: FRIEND_REF_CODE_OPTION,
            referredCode: refCode,
          }
        : {}),
    };
    formStepInstances.current?.map((formInstance) => formInstance.setFieldsValue(initFormData));
  }, []);

  const handleMouseLeave = () => {
    if (!isReminded && !formSubmitting && (isStayOver10s || step > -1)) {
      const formData = formStepInstances.current?.[SIGN_UP_STEPS.personalDetail]?.getFieldsValue();
      NiceModal.show(ReminderModal, { dentisEmailCast: formData.email });
      setIsReminded(true);
    }
  };

  useEffect(() => {
    trackEvent(EEventNames.SIGN_UP_STARTED, getUTMQuery());
  }, []);

  const handleLeaveForm = () => {
    const { password, confirmPassword, ...restValues } = formStepInstances.current.reduce<any>(
      (out, form) => ({ ...out, ...form.getFieldsValue() }),
      {},
    );

    trackEvent(EEventNames.SIGN_UP_ENDED, { ...restValues, ...getUTMQuery() });
  };

  return (
    <AuthLayout onMouseLeave={handleMouseLeave}>
      <div className="signup-page">
        {step === SIGN_UP_STEPS.greeting ? (
          // Greeting screen
          <Greeting onNext={handleNextButton} />
        ) : (
          // Form steps screens
          <>
            <div className="flex justify-between">
              <div className="title font-bold text-darkBlueColor">
                {step === SIGN_UP_STEPS.personalDetail
                  ? 'First, a few details so we can create your account'
                  : step === SIGN_UP_STEPS.experience
                  ? `Thanks ${firstName}, Please tell us a bit more about you & how we can help`
                  : 'Final set of questions, then we’ll log you in automatically'}
              </div>
              <Progress
                type="circle"
                percent={(99.99 * (step + 1)) / 3}
                format={(percent) => `${Math.round((percent || 100 / 3) / (100 / 3))}/3`}
                width={54}
              />
            </div>

            <div className="steps-content">
              <PersonalDetails
                isActive={step === SIGN_UP_STEPS.personalDetail}
                formInstance={formStepInstances.current?.[SIGN_UP_STEPS.personalDetail]}
              />
              <Experience
                isActive={step === SIGN_UP_STEPS.experience}
                formInstance={formStepInstances.current?.[SIGN_UP_STEPS.experience]}
                isConfident={isConfident}
              />
              <Practice
                isActive={step === SIGN_UP_STEPS.practice}
                formInstance={formStepInstances.current?.[SIGN_UP_STEPS.practice]}
              />
              <div
                className={`steps-action mt-4 gap-4 ${
                  step === SIGN_UP_STEPS.practice ? 'lg:float-right lg:-mt-[60px] lg:block lg:!w-auto' : ''
                }`}
              >
                {step > SIGN_UP_STEPS.personalDetail && (
                  <NiceButton
                    size="large"
                    className="min-w-[160px] text-white"
                    niceType="default"
                    onClick={handleBackButton}
                  >
                    Previous
                  </NiceButton>
                )}
                {step < SIGN_UP_STEPS.practice && (
                  <NiceButton
                    size="large"
                    className="min-w-[160px] text-white"
                    niceType="orange"
                    onClick={handleNextButton}
                  >
                    Next
                  </NiceButton>
                )}
                {step === SIGN_UP_STEPS.practice && (
                  <NiceButton
                    size="large"
                    className="min-w-[160px] text-white"
                    loading={formSubmitting}
                    onClick={handleSubmit}
                    niceType="orange"
                    id="submit-dentist-signup"
                  >
                    Submit
                  </NiceButton>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <RouteLeavingGuard
        when
        navigate={(link) => history.push(link)}
        shouldBlockNavigation={(nextLocation) => nextLocation.pathname !== history.location.pathname}
        title="You are now leaving the registration form"
        renderFooter={({ handleLeave, closeModal }) => {
          const handleLeaveClick = () => {
            handleLeaveForm();
            handleLeave();
          };
          return (
            <div className="flex w-full justify-between">
              <Button className="btn-default-redesigned w-44" size="large" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                type="primary"
                className="btn-primary-redesigned m-auto mr-0 w-44"
                size="large"
                onClick={handleLeaveClick}
              >
                Leave
              </Button>
            </div>
          );
        }}
        preventReload
        onUnload={() => handleLeaveForm()}
      />
    </AuthLayout>
  );
};
