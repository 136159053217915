import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button } from 'antd';
import { useState } from 'react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { showModal } from 'utils';

const FINISH_TYPE_MAP = [
  {
    value: 'Scalloped',
    title: 'Scalloped Edges',
    description: `Scalloped around the margins of the gums for increased comfort\nSignificantly lower retentive forces compared to straight cut aligners`,
    disabledDescription:
      'Unfortunately, this material is not available with scalloped trimlines. If you are happy to try straight trimlines these have slightly higher retentive forces but may be less comfortable patients with sensitive/bulbous gums. Alternatively, feel free to go back and use the filter to find an option with scalloped trimlines.',
  },
  {
    value: 'Straight',
    title: 'Straight Edges',
    description: `Straight edges sit about 2mm above the gumline to exert maximal retentive forces\nLess suitable for patients with thin or sensitive gums`,
    disabledDescription:
      'Unfortunately, this material is not available with straight trimlines. If you are happy to try scalloped trimlines these have slightly lower retentive forces but are less visible to patients, and they will still provide a great outcome! Alternatively, feel free to go back and use the filter to find an option with straight edges.',
  },
];

type TProps = {
  availableFinishTypeOptions: string[];
  initialFinishType?: string;
};

const FinishTypeModal = NiceModal.create<TModalProps<TProps>>(
  ({ availableFinishTypeOptions, initialFinishType, onCancel }) => {
    const modal = useModal();
    const [selectedFinishType, setSelectedFinishType] = useState<string | undefined>(
      availableFinishTypeOptions.length === 1 ? availableFinishTypeOptions[0] : initialFinishType,
    );

    return (
      <NiceAntModal
        modal={modal}
        width={550}
        footer={
          <div className="flex w-full items-center gap-2 md:flex-row">
            <Button
              className="btn-secondary-redesigned w-1/2"
              size="large"
              type="default"
              shape="round"
              onClick={() => {
                modal.resolve(undefined);
                modal.hide();
              }}
            >
              Go back
            </Button>
            <Button
              className="btn-primary-redesigned w-1/2"
              size="large"
              type="primary"
              style={{ minWidth: 200 }}
              disabled={!selectedFinishType}
              onClick={() => {
                modal.resolve(selectedFinishType!);
                modal.hide();
              }}
            >
              Go To Order Summary
            </Button>
          </div>
        }
        onCancel={onCancel}
        maskClosable={false}
      >
        <div className="mb-8 mt-2">
          <div className="text-lg font-bold uppercase text-black">Select your finish</div>
          {FINISH_TYPE_MAP.map((fnt) => (
            <div key={fnt.value} className="mt-6">
              <div className="font-bold uppercase text-black">{fnt.title}</div>
              <div className="mt-3 flex">
                <div className="mr-3 flex-1 font-medium text-darkBlueColor">
                  {!availableFinishTypeOptions.includes(fnt.value) ? fnt.disabledDescription : fnt.description}
                </div>
                <Button
                  disabled={!availableFinishTypeOptions.includes(fnt.value)}
                  onClick={() => setSelectedFinishType(fnt.value)}
                  type={fnt.value === selectedFinishType ? 'primary' : 'default'}
                  style={{ width: '130px' }}
                >
                  {fnt.value === selectedFinishType ? 'Selected' : 'Select'}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </NiceAntModal>
    );
  },
);

export const showFinishTypeModal = showModal<string, TProps>(FinishTypeModal);
