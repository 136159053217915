import './specialist-advice-detail-collapse.styles.less';
import { Typography, Skeleton, Divider, Popover, Empty } from 'antd';
import Chip from 'components/chip/chip.component';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { lookoutForPatientRadiographDefaultValue } from 'modules/specialist/submit-advice/consts';
import { USER_ROLES } from 'config/constants';
import { IAdvice } from 'services/specialist-api/types';
import { ViewItem } from 'components/view-item/view-item';
import { useAuth } from 'hooks/useAuth';
import classNames from 'classnames';
import { NiceCollapse } from 'components/commons/nice-collapse/nice-collapse';
import { NiceAvatar } from 'components/commons/nice-avatar/nice-avatar';
import { useWindowSize } from 'hooks/useWindowSize';
import { IUser } from 'modules/auth/services/type';

interface ICallOutsProps {
  title: string;
  content: string;
  mode: 'red' | 'yellow' | 'green';
}

const CallOuts = ({ title, content, mode }: ICallOutsProps) => (
  <div className={classNames('callOutsComponent', mode)}>
    <div className="titleWrapper">{title}</div>
    <div className="contentWrapper">{content}</div>
  </div>
);

interface IProps {
  specialistAdviceData?: IAdvice;
  specialistInfo?: Partial<IUser>;
}

export const SpecialistAdviceDetailCollapse = ({ specialistAdviceData, specialistInfo }: IProps) => {
  const { user } = useAuth();
  const { isMd } = useWindowSize();

  return (
    <NiceCollapse title="Specialist Advice" open>
      <div className="specialistAdviceContainer border-0 border-t border-solid border-gray-100 px-2 pt-4">
        <Skeleton loading={false}>
          {specialistAdviceData && (
            <div className="mb-1">
              {user?.type === USER_ROLES.DENTIST && (
                <div>
                  <Typography.Text className="head">
                    <span className="text-sm">Your specialist for this case is:</span> {specialistInfo?.prefix}{' '}
                    {specialistInfo?.fullName}
                  </Typography.Text>
                  <div className="mt-3 flex items-center">
                    <div className="mr-4">
                      <NiceAvatar
                        url={specialistInfo?.profilePic}
                        name={specialistInfo?.fullName}
                        size={isMd ? 100 : 80}
                      />
                    </div>
                    <div>
                      <p className="font-medium">A bit about Dr. {specialistInfo?.fullName}</p>
                      <span>{specialistInfo?.preferences?.bio || '-'}</span>
                    </div>
                  </div>
                </div>
              )}
              <span id="wizard-treatment-overview" className="grid grid-cols-12 gap-x-4 gap-y-2">
                <div className="col-span-12 mt-4">
                  <Typography.Text className="head">Treatment Overview</Typography.Text>
                  <Divider className="mb-0 mt-3" />
                </div>
                {user?.type !== USER_ROLES.DENTIST && (
                  <div className="col-span-12">
                    <ViewItem title="Personal note to dentist">{specialistAdviceData?.personalNote}</ViewItem>
                  </div>
                )}
                <div className="col-span-12 py-0">
                  <ViewItem title="Complexity">
                    {specialistAdviceData?.complexity ? (
                      <Chip
                        className="modify-chip col-span-6"
                        text={specialistAdviceData?.complexity}
                        key={specialistAdviceData?.complexity}
                      />
                    ) : (
                      '-'
                    )}
                  </ViewItem>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <ViewItem title="Diagnosis and problems list">
                    {specialistAdviceData?.diagnosisAndProblemList}
                  </ViewItem>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <ViewItem title="Aims of treatment">{specialistAdviceData?.aimsOfTreatmentAndSummary}</ViewItem>
                </div>
                <div className="col-span-12 py-0">
                  <ViewItem title="What is not being corrected? Are there Alternative Treatment options to solve these?">
                    {specialistAdviceData?.alternativeTreatmentPlan}
                  </ViewItem>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <ViewItem title="Estimated Treatment Length">
                    {specialistAdviceData?.estimatedTreatmentRange}
                  </ViewItem>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <ViewItem title="Recommended wear schedule">{specialistAdviceData?.suggestedWearSchedule}</ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem title="Refinement advice">{specialistAdviceData?.refinementAdvice}</ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem title="Retention advice">{specialistAdviceData?.retentionAdvice}</ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem title="How would you consent this patient?">{specialistAdviceData?.consent}</ViewItem>
                </div>
              </span>

              <span id="wizard-case-callouts" className="grid grid-cols-12 gap-x-4 gap-y-2">
                <div className="col-span-12 mt-4 py-0">
                  <Typography.Text className="head">Case Call outs</Typography.Text>
                  <Divider className="mb-3 mt-3" />
                </div>
                {specialistAdviceData?.submissionCallOut?.positive && (
                  <div className="col-span-12 md:col-span-6">
                    <CallOuts
                      mode="green"
                      title="Benefits of Treatment"
                      content={specialistAdviceData?.submissionCallOut?.positive}
                    />
                  </div>
                )}
                {specialistAdviceData?.submissionCallOut?.problematic && (
                  <div className="col-span-12 md:col-span-6">
                    <CallOuts
                      mode="yellow"
                      title="Possible Problems or Challenges"
                      content={specialistAdviceData?.submissionCallOut?.problematic}
                    />
                  </div>
                )}
                <div className="col-span-12 mt-4">
                  <Typography.Text className="title">
                    What Treatment Techniques have been included and why?
                  </Typography.Text>
                </div>
                <div className="col-span-12">
                  <ViewItem title="IPR">{specialistAdviceData?.iprPlan}</ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem title="Attachments">{specialistAdviceData?.attachmentPlan}</ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem title="Auxiliaries">{specialistAdviceData?.otherAuxillaries}</ViewItem>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <ViewItem
                    title={
                      <div>
                        Elastics Type
                        {user?.type === USER_ROLES.DENTIST && (
                          <Popover placement="top" content="32Co will include any elastics in your shipment">
                            <ExclamationCircleOutlined className="ml-2" />
                          </Popover>
                        )}
                      </div>
                    }
                  >
                    {specialistAdviceData?.elasticType}
                  </ViewItem>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <ViewItem title="Elastics Wear Schedule">{specialistAdviceData?.elasticWearSchedule}</ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem
                    title={
                      <div>
                        Recommended Buttons
                        {user?.type === USER_ROLES.DENTIST && (
                          <Popover placement="top" content="32Co will include any buttons in your shipment">
                            <ExclamationCircleOutlined className="ml-2" />
                          </Popover>
                        )}
                      </div>
                    }
                  >
                    {specialistAdviceData?.recommendedButtons}
                  </ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem title="Extractions">{specialistAdviceData?.extractions}</ViewItem>
                </div>

                <div className="col-span-12">
                  <Typography.Text className="title">
                    Feedback on Radiographs & Optional Orthodontic Assessment
                  </Typography.Text>
                </div>
                <div className="col-span-12">{lookoutForPatientRadiographDefaultValue}</div>
                <div className="col-span-12 py-0">
                  <ViewItem title="Any additional advice specific to this patient">
                    {specialistAdviceData?.specificToPatientRadiograph}
                  </ViewItem>
                </div>
                <div className="col-span-12">
                  <ViewItem title="Feedback on Orthodontic Assessment">
                    {specialistAdviceData?.orthodonticsFeedback}
                  </ViewItem>
                </div>
              </span>
            </div>
          )}
          {!specialistAdviceData && <Empty description="No data to show!" />}
        </Skeleton>
      </div>
    </NiceCollapse>
  );
};
