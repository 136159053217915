import { SUBMISSION_STATUS_TYPE } from 'config/constants';
import { ALL_MEAN_SKIP_FILTER } from 'hooks/useCommonQueryParams/table';
import { DENTIST_SUBMISSION_STATUS } from 'services/patients-api/types';
import { ISkuType } from 'services/sku-api/types';

export const ALL_CASES_STATUS = ALL_MEAN_SKIP_FILTER;

export const CLEAR_ALIGNER_PACKAGES_HELP_CONTENT =
  'https://www.notion.so/32co/c6aaf23434654b0e99c70f4766a42f22?v=3228ddaf3661460ab2900c3426e5c2eb&p=8b66a90d1e104735af0767e70294eb7c&pm=s';

export const REWARDS_AVAILABLE_INITIAL_SUBMISSION =
  'Sorry there are no rewards available. Refer your colleagues to earn more.';

export const REWARDS_AVAILABLE_REFINEMENT_SUBMISSION =
  "Rewards are not available for refinements if you've already paid for them.";

export const INTERESTED_PATIENT_TAB_KEY = 'INTERESTED_PATIENT';

export const DENTIST_SUBMISSION_TAB_MAPPING: Record<string, { bgColor: string; text: string }> = {
  [DENTIST_SUBMISSION_STATUS.DRAFT]: {
    bgColor: '#9e9e9e',
    text: 'Draft Submission',
  },
  [INTERESTED_PATIENT_TAB_KEY]: {
    bgColor: '#004bff',
    text: 'Potential Patients',
  },
  [DENTIST_SUBMISSION_STATUS.SUBMITTED]: {
    bgColor: '#004bff',
    text: 'In Design',
  },
  [`${DENTIST_SUBMISSION_STATUS.SUBMITTED}-${SUBMISSION_STATUS_TYPE.ON_HOLD}`]: {
    bgColor: '#DA3A6D',
    text: 'In Design - On Hold',
  },
  [DENTIST_SUBMISSION_STATUS.REVIEW_DESIGN]: {
    bgColor: '#3F69FF',
    text: 'Awaiting Approval',
  },
  [DENTIST_SUBMISSION_STATUS.APPROVED]: {
    bgColor: '#49DCB2',
    text: 'Approved',
  },
  [DENTIST_SUBMISSION_STATUS.IN_TREATMENT]: {
    bgColor: '#06b73c',
    text: 'In Treatment',
  },
  [DENTIST_SUBMISSION_STATUS.COMPLETED]: {
    bgColor: 'black',
    text: 'Completed Treatment',
  },
  [DENTIST_SUBMISSION_STATUS.EXPIRED]: {
    bgColor: '#8465FC',
    text: 'Expired',
  },
  [DENTIST_SUBMISSION_STATUS.NOT_SUITABLE]: {
    bgColor: '#00B1FF',
    text: 'Not Suitable',
  },
  [ALL_CASES_STATUS]: {
    bgColor: '',
    text: 'All',
  },
  [DENTIST_SUBMISSION_STATUS.ARCHIVED]: {
    bgColor: '#f4a125',
    text: 'Archived',
  },
};

export const CASES_PAGE_TABS: Array<{ key: string; label: string }> = [
  ALL_CASES_STATUS,
  INTERESTED_PATIENT_TAB_KEY,
  ...Object.values(DENTIST_SUBMISSION_STATUS),
].map((status: string) => ({
  key: status,
  label: DENTIST_SUBMISSION_TAB_MAPPING[status].text,
}));

export const INITIAL_SKU: ISkuType = {
  createdAt: '',
  description: '',
  discounts: [],
  id: '',
  name: '',
  skuId: '',
  status: '',
  updatedAt: '',
  price: 0,
};

export const ONBOARDING_SKU_DETAILS: ISkuType[] = [
  {
    id: '',
    createdAt: '2022-02-23T15:08:31.039Z',
    updatedAt: '2022-02-23T15:08:34.597Z',
    status: 'live',
    skuId: 'SKU-UZYFYWV',
    default: true,
    name: 'Zendura FLX by Straumann',
    refinementsIncluded: false,
    numberOfRefinementsIncluded: 0,
    description: '1 refinement included/Used by Straumann Aligner brands/Choose scalloped or straight trimlines',
    materialType: 'zendura',
    image: {
      url: '',
      type: 'image/jpg',
    },
    logo: {
      url: 'https://32s-email-resource.s3.eu-west-2.amazonaws.com/straumann.png',
      type: 'image/jpg',
    },
    price: 884,
    discounts: [
      {
        id: '123456',
        discountType: 'PERCENT',
        discountValue: 20,
        externalDiscountCode: 'DIUSNA50',
        externalDiscountDescription: '20% off any case with 32Co expires in 27 days',
      },
      {
        id: '',
        discountType: 'CASH',
        discountValue: 500,
        externalDiscountCode: '500POINTS',
        externalDiscountDescription: '£ 500 off a DUO case with 32Co',
      },
      {
        id: '',
        discountType: 'CASH',
        discountValue: 25,
        externalDiscountCode: 'REFSIGNUP',
        externalDiscountDescription: '£ 25 off a DUO case, expires in 5 days',
      },
      {
        id: '',
        discountType: 'CASH',
        discountValue: 200,
        externalDiscountCode: 'NEWFRIEND',
        externalDiscountDescription: '£ 200 off any case with 32Co, expires in 5 days',
      },
    ],
  },
  {
    id: '',
    createdAt: '2022-02-23T15:08:31.039Z',
    updatedAt: '2022-02-23T15:08:34.597Z',
    status: 'live',
    skuId: 'SKU-UZYFYWV',
    default: true,
    name: 'Zendura FLX by Straumann',
    refinementsIncluded: false,
    numberOfRefinementsIncluded: 0,
    description: 'No refinements included/Used by Straumann Aligner brands/Choose scalloped or straight trimlines',
    materialType: 'zendura',
    image: {
      url: '',
      type: 'image/jpg',
    },
    logo: {
      url: 'https://32s-email-resource.s3.eu-west-2.amazonaws.com/straumann.png',
      type: 'image/jpg',
    },
    price: 668,
    discounts: [
      {
        id: '123456',
        discountType: 'PERCENT',
        discountValue: 20,
        externalDiscountCode: 'DIUSNA50',
        externalDiscountDescription: '20% off any case with 32Co expires in 27 days',
      },
      {
        id: '',
        discountType: 'CASH',
        discountValue: 500,
        externalDiscountCode: '500POINTS',
        externalDiscountDescription: '£ 500 off a DUO case with 32Co',
      },
      {
        id: '',
        discountType: 'CASH',
        discountValue: 25,
        externalDiscountCode: 'REFSIGNUP',
        externalDiscountDescription: '£ 25 off a DUO case, expires in 5 days',
      },
      {
        id: '',
        discountType: 'CASH',
        discountValue: 200,
        externalDiscountCode: 'NEWFRIEND',
        externalDiscountDescription: '£ 200 off any case with 32Co, expires in 5 days',
      },
    ],
  },
  {
    id: '',
    createdAt: '2022-02-23T15:08:31.039Z',
    updatedAt: '2022-02-23T15:08:34.597Z',
    status: 'live',
    skuId: 'SKU-UZYFYWV',
    default: true,
    name: 'Essix ACE by Dentsply',
    refinementsIncluded: false,
    numberOfRefinementsIncluded: 0,
    description: '3 set of refinements included/Used by SureSmile/Choose scalloped or straight trimlines',
    materialType: 'zendura',
    image: {
      url: '',
      type: 'image/jpg',
    },
    logo: {
      url: 'https://32s-email-resource.s3.eu-west-2.amazonaws.com/densply.png',
      type: 'image/jpg',
    },
    price: 1360,
    discounts: [],
  },
  {
    id: '',
    createdAt: '2022-02-23T15:08:31.039Z',
    updatedAt: '2022-02-23T15:08:34.597Z',
    status: 'live',
    skuId: 'SKU-UZYFYWV',
    default: true,
    name: 'All in (3703)',
    refinementsIncluded: false,
    numberOfRefinementsIncluded: 0,
    description: '2 set of refinements included/Used by Henry Schein brands/Choose scalloped or straight trimlines',
    materialType: 'zendura',
    image: {
      url: '',
      type: 'image/jpg',
    },
    logo: {
      url: 'https://32s-email-resource.s3.eu-west-2.amazonaws.com/scheu.png',
      type: 'image/jpg',
    },
    price: 1098,
    discounts: [],
  },
];

export enum ESkuFilterOption {
  PAY_AS_YOU_GO = '0 Refinements (PAYG)',
  ONE_REFINEMENT = '1 Refinement',
  TWO_REFINEMENTS = '2 Refinements',
  STRAIGHT_EDGE = 'Straight-edge',
  SCALLOPED = 'Scalloped',
}

export const VALID_CURRENCY = ['GBP', 'EUR'];
