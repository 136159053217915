import { Location, Action } from 'history';
import { ReactNode, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import NiceAntModal from '../commons/nice-ant-modal/nice-ant-modal.component';
import { Button, Typography } from 'antd';

export interface IRouteLeavingGuardProps {
  when?: boolean | undefined;
  navigate?: (path: string, lastAction?: Action) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  title?: string;
  body?: ReactNode;
  renderFooter?: ({ handleLeave, closeModal }: { handleLeave: () => void; closeModal: () => void }) => ReactNode;
  preventReload?: boolean;
  onUnload?: () => void;
}

export const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  title = 'Are you sure you want to leave?',
  body = (
    <>
      <Typography.Paragraph>It looks like you have been editing something.</Typography.Paragraph>
      <Typography.Paragraph>If you leave before saving, your changes will be lost.</Typography.Paragraph>
    </>
  ),
  renderFooter = ({ handleLeave: handleConfirmNavigationClick, closeModal }) => (
    <div className="flex w-full justify-between">
      <Button className="btn-default-redesigned" size="large" style={{ width: 180 }} onClick={closeModal}>
        Stay
      </Button>
      <Button
        type="primary"
        className="btn-primary-redesigned"
        size="large"
        style={{ width: 180 }}
        onClick={handleConfirmNavigationClick}
      >
        Leave
      </Button>
    </div>
  ),
  preventReload,
  onUnload,
}: IRouteLeavingGuardProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [lastAction, setLastAction] = useState<Action>();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (preventReload) {
      /* This seems to be the only way to prevent page refresh */
      window.onbeforeunload = () => {
        onUnload?.();
        return true;
      };

      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location, action: Action): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      setLastAction(action);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate?.(`${lastLocation.pathname}${lastLocation.search}`, lastAction);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <NiceAntModal
        visible={modalVisible}
        width={450}
        title={title}
        onCancel={closeModal}
        onOk={handleConfirmNavigationClick}
        footer={renderFooter({ handleLeave: handleConfirmNavigationClick, closeModal })}
        hideDivider
      >
        {body}
      </NiceAntModal>
    </>
  );
};
