import { SUBMISSION_STATUS_TYPE } from 'config/constants';
import moment from 'moment';
import { ReactNode } from 'react';
import { IPatient } from 'services/patients-api/types';

export const DENTIST_PERSONAL_INFO_SHEET_STATUS_MAPPING: Record<string, { bgColor: string; text: string }> = {
  SENT: {
    bgColor: 'rgb(73, 220, 178)',
    text: 'sent',
  },
  DRAFT: {
    bgColor: '#9e9e9e',
    text: 'Draft',
  },
};

export const interestPatientReasonHeading = (interestPatient: IPatient): ReactNode => {
  const isWillUnArchive = interestPatient.isArchived;

  const UNARCHIVE_HEADING = (
    <>
      You archived this potential patient on{' '}
      <span className="font-medium">{moment(interestPatient.updatedAt).format('DD.MM.YY')}</span> because:
    </>
  );

  const ARCHIVE_HEADING = (
    <span className="mb-2 font-medium">
      Please capture why you're archiving this potential patient. You can un-archive this potential patient if you
      change your mind.
    </span>
  );

  const DRAFT_REASON_HEADING = (
    <>
      <p className="mb-2 font-semibold">This will archive the draft case as well.</p>
      {ARCHIVE_HEADING}
    </>
  );

  const WAITING_APPROVAL_REASON_HEADING = (
    <>
      <p className="mb-3 font-semibold">
        Warning⚠️: This will archive the Awaiting Approval case as well.
        <br />
        Are you sure? You've put a lot of work in to get this far 🤩
      </p>
      {ARCHIVE_HEADING}
    </>
  );

  return isWillUnArchive
    ? UNARCHIVE_HEADING
    : !interestPatient.submission
    ? ARCHIVE_HEADING
    : interestPatient.submission?.status === SUBMISSION_STATUS_TYPE.DRAFT
    ? DRAFT_REASON_HEADING
    : WAITING_APPROVAL_REASON_HEADING;
};

export const interestPatientReasonFooterText = (interestPatient: IPatient): string => {
  const isWillUnArchive = interestPatient.isArchived;

  return isWillUnArchive
    ? `Are you sure you want to unarchive this potential patient?`
    : `p.s. if you need any help on this please let us know, we're happy to help!`;
};

export const SMILE_QUESTIONNAIRE_NEW_DENTIST_LABELING = {
  rating: 'How does the patient feel when they see their smile in a mirror, or look at photos of themselves?',
  consideredBefore: 'Have they considered Improving their Smile before?',
  newChange: 'What does the Patient want to change about their Smile? (Choose as many as you want)',
  annoyIssues: 'Do any of the following issues annoy them?',
  newEventNote: 'What has motivated the Patient to start their new smile journey today?',
  newConcern: 'What are the patients biggest concerns?',
  note: 'Anything else to add?',
};

export const SMILE_QUESTIONNAIRE_DENTIST_LABELING = {
  rating: 'How does the patient feel when they see their smile in a mirror, or look at photos of themselves?',
  note: 'Is there anything the patient wants to change about their smile?',
  change: 'What would the patient like to change about their smile?',
  eventNote:
    'Does the patient have any special events coming up that you would like these changes for? If so, when is it?',
  concern: 'Does the patient have any initial concerns that they want to discuss in the consultation?',
};

export const SMILE_QUESTIONNAIRE_NEW_PATIENT_LABELING = {
  rating: 'How do you feel when you see your smile in a mirror, or look at photos of yourself?',
  consideredBefore: 'Have you considered Improving your Smile before?',
  newChange: 'What do you want to change about your Smile? (Choose as many as you want)',
  annoyIssues: 'Do any of the following issues annoy you?',
  newEventNote: 'What has motivated you to start your new smile journey today?',
  newConcern: 'What is your biggest question or concern about treatment?',
  note: 'Anything else you want to ask or want me to know?',
};

export const SMILE_QUESTIONNAIRE_PATIENT_LABELING = {
  rating: 'How do you feel when you see your smile in a mirror, or look at photos of yourself?',
  note: 'Is there anything you’d like to change about your smile?',
  change: 'Share more detail about changes you would make?',
  eventNote: 'Do you have any special events coming up that you would like these changes for? If so, when is it?',
  concern: 'Do you have any concerns you would like to discuss with me?',
};

interface IRadioOptionSQ {
  label: string;
  value: string | number | boolean;
}

export const ratingOptionsSQ: IRadioOptionSQ[] = [
  { label: '😩', value: 1 },
  { label: '🙁', value: 2 },
  { label: '😐', value: 3 },
  { label: '🙂', value: 4 },
  { label: '😁', value: 5 },
];

export const oldSQVersionBadge = (
  <div className="text-white">
    We’re always improving your 32Co platform and we updated the Smile Questionnaire with new questions and answers to
    make it easier for the patient to provide better answers. This patient was added before this update so you’re seeing
    a the previous Smile Questionnaire.
    <br />
    <p className="!mb-0 mt-1 font-bold">Add new patients to see our evolved version.</p>
  </div>
);

export const draftSQVersionBadge = (
  <div className="text-white">
    We updated the Smile Questionnaire to make it easier for the patient to provide better answers. This patient’s
    questionnaire was in draft and has been updated to the latest version. All your previous answers have been
    transferred.
  </div>
);
