import { useDispatch } from 'react-redux';
import React, { FC, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './App.less';
import NotFound from './components/404/404.component';
import NotAuthorized from './components/not-authorized/not-authorized.component';
import { useAuth } from './hooks/useAuth';
import { authRoutesToCheck } from 'modules/auth';
import { SignupSuccess } from 'modules/auth/signup-success/signup-success.component';
import DesignerModule from 'modules/designer';
import { designerRoutesToCheck } from 'modules/designer/designer-routes';
import GDPModule from 'modules/gdp';
import { gdpRoutesToCheck } from 'modules/gdp/gdp-routes';
import ManufacturerModule from 'modules/manufacturer';
import { manufacturerRoutesToCheck } from 'modules/manufacturer/manufacturer-routes';
import PatientTreatmentCheckIn from 'modules/patients/check-ins/check-ins';
import { CheckinSuccess } from 'modules/patients/check-ins/success/success.component';
import SpecialistModule from 'modules/specialist';
import { specialistRoutesToCheck } from 'modules/specialist/specialist-routes';
import { ViewProposal } from 'modules/patients/view-proposal/view-proposal.component';
import { setGooglePlacesLoaded } from './redux/appSlice';
import { RedirectToSignInWithBackUrl } from './components/PrivateRoute';
import { EmailSuccessfullyVerifiedPage } from 'modules/auth/email-successfully-verified/email-successfully-verified.component';
import { IUser } from 'modules/auth/services/type';
import * as Sentry from '@sentry/react';
import TechnicianModule from 'modules/technician';
import { technicianRoutesToCheck } from 'modules/technician/technician-routes';
import { identifyUser, trackPageView } from 'utils/mixpanel/mixpanel';
import { ViewInterestedPatientSQ } from 'modules/patients/interested-patient/interested-patient-sq';
import { PatientsPisPreview } from 'modules/patients/interested-patient-pis/interested-patient-pis';
import { CompletedTreatmentRecap } from 'modules/patients/completed-treatment/completed-treatment-recap/completed-treatment-recap';

const AuthModule = React.lazy(() => import('./modules/auth'));

const Loading = () => <div>Loading...</div>;

const SafeRedirect: FC<{ user: IUser | null }> = ({ user }) => {
  if (user) {
    return <Redirect to={`/${user.type}`} />;
  }
  return <RedirectToSignInWithBackUrl />;
};

const App = () => {
  const dispatch = useDispatch();
  (window as any).initMap = () => {
    dispatch(setGooglePlacesLoaded({ loading: true }));
  };
  const history = useHistory();

  const { user } = useAuth();

  useEffect(() => {
    if (user?.id) {
      Sentry.setContext('User Info', user);
      identifyUser(user.id, {
        $email: user.email,
        $name: user.fullName,
        $avatar: user.profilePic,
        role: user.role,
        status: user.status,
        referralCode: user.referralCode,
        practices: user.practices?.map((practice) => ({ 'Practice Name': practice.name, 'Practice ID': practice.id })),
        corporates: user.associatedWithDentalCorporates?.map((corporate) => ({
          'Corporate Name': corporate.name,
          'Corporate ID': corporate.id,
        })),
        'Last Logged At': user.lastLoggedInAt,
      });
    }
  }, [user?.id]);

  useEffect(() => {
    // Track page view on initial render
    trackPageView(window.location.pathname);

    // Track page view on route change
    const unlisten = history.listen((location) => {
      trackPageView(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, []);

  // TODO: hanlde mutiple device connection

  return (
    <Switch>
      <Route exact path="/" component={SafeRedirect} />

      <Route exact path="/signup-success">
        <Suspense fallback={Loading}>
          <SignupSuccess />
        </Suspense>
      </Route>

      <Route exact path="/verify">
        <Suspense fallback={Loading}>
          <EmailSuccessfullyVerifiedPage />
        </Suspense>
      </Route>

      <Route exact path={authRoutesToCheck}>
        <Suspense fallback={Loading}>
          <AuthModule />
        </Suspense>
      </Route>

      <Route exact path={gdpRoutesToCheck}>
        <GDPModule />
      </Route>

      <Route exact path="/patients/proposal/:proposalId">
        <ViewProposal />
      </Route>

      <Route exact path={designerRoutesToCheck}>
        <DesignerModule />
      </Route>

      <Route exact path={manufacturerRoutesToCheck}>
        <ManufacturerModule />
      </Route>

      <Route exact path={specialistRoutesToCheck}>
        <SpecialistModule />
      </Route>

      <Route exact path={technicianRoutesToCheck}>
        <TechnicianModule />
      </Route>

      <Route exact path="/patients/check-in">
        <PatientTreatmentCheckIn />
      </Route>

      <Route exact path="/patients/check-in/success">
        <CheckinSuccess />
      </Route>

      <Route exact path="/patients/interested-patient/:patientId/sq">
        <ViewInterestedPatientSQ />
      </Route>

      <Route exact path="/:dentist/:id/sq">
        <ViewInterestedPatientSQ />
      </Route>

      <Route exact path="/patients/interested-patient/:patientId/pis">
        <div className="bg-[#F0F1F5]">
          <PatientsPisPreview />
        </div>
      </Route>

      {/* TODO: apply slice code by using Suspense */}
      <Route exact path="/patients/completed-treatment/:patientId/recap">
        <CompletedTreatmentRecap />
      </Route>

      <Route path="/not-authorized" component={NotAuthorized} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default App;
