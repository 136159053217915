/* eslint-disable react/no-unstable-nested-components */
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Divider, SelectProps, Spin, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { NiceSelect } from 'components/commons/nice-select/nice-select';
import { useDebounceState } from 'hooks/useDebounce';
import { useMemo, useState } from 'react';
import { useCreateCorporateMutation, useGetCorporatesQuery } from 'services/api';
import { notificationApiError } from 'utils/ui';

export interface ICorporate {
  name: string;
  id: string;
  isVerified?: boolean;
}

const NO_NOT_APPLICABLE = 'No / Not Applicable';
export const NO_ASSOCIATED_WITH_DENTAL_CORPORATE = null;

export const CorporatesSelect = (props: SelectProps<string[] | typeof NO_ASSOCIATED_WITH_DENTAL_CORPORATE>) => {
  const { value, onChange, ...restProps } = props;
  const [searchValue, setSearchValue] = useState('');
  const corporateSearchValue = useDebounceState(searchValue, 300);
  const [selectedOptions, setSelectedOptions] = useState<DefaultOptionType[]>([]);

  const { data, isFetching, isLoading: isListLoading } = useGetCorporatesQuery({ name: corporateSearchValue });

  const [createOption, { isLoading: isCreateLoading }] = useCreateCorporateMutation();

  const handleChange = (newValue: string[], option?: DefaultOptionType | DefaultOptionType[]) => {
    if (Array.isArray(newValue) && newValue.at(-1) === NO_NOT_APPLICABLE) {
      onChange?.(NO_ASSOCIATED_WITH_DENTAL_CORPORATE, option!);
      setSelectedOptions([]);
    } else {
      onChange?.(
        newValue.filter((item) => item !== NO_NOT_APPLICABLE),
        option!,
      );
      setSelectedOptions(
        (option?.filter((item: DefaultOptionType) => item.value !== NO_NOT_APPLICABLE) as DefaultOptionType[]) || [],
      );
    }
  };

  const addNewOption = async (_e: React.MouseEvent<HTMLAnchorElement>) => {
    try {
      const response = await createOption({
        name: corporateSearchValue,
      }).unwrap();
      handleChange([...(value || []), response.id]);
      setSearchValue('');
      setSelectedOptions([...selectedOptions, { value: response.id, label: corporateSearchValue }]);
    } catch (e) {
      notificationApiError(e);
    }
  };

  const presentValue = useMemo(
    () => (value === NO_ASSOCIATED_WITH_DENTAL_CORPORATE ? [NO_NOT_APPLICABLE] : value),
    [value],
  );

  const presentOptions = useMemo(() => {
    const notApplicable = { label: NO_NOT_APPLICABLE, value: NO_NOT_APPLICABLE };
    if (data) {
      return [notApplicable, ...data.map((item) => ({ label: item.name, value: item.id }))];
    }
    return [notApplicable, ...selectedOptions];
  }, [data, selectedOptions]);

  return (
    <NiceSelect
      allowClear
      showArrow
      suffixIcon={<SearchOutlined />}
      loading={isFetching || isListLoading}
      value={presentValue}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <>
          <Spin spinning={isFetching || isListLoading} size="small">
            {menu}
          </Spin>
          {corporateSearchValue?.length > 2 && !(isFetching || isListLoading) && (
            <>
              <Divider className="my-1" />
              <div className="flex w-full items-center justify-center px-2 py-1">
                <Typography.Link
                  onClick={addNewOption}
                  onMouseDown={(e) => e.preventDefault()}
                  style={{ whiteSpace: 'nowrap' }}
                  disabled={isCreateLoading}
                  className="p-1"
                >
                  {isCreateLoading ? <Spin /> : <PlusOutlined />} Add new "<b>{corporateSearchValue}</b>"
                </Typography.Link>
              </div>
            </>
          )}
        </>
      )}
      filterOption={false}
      searchValue={searchValue}
      onSearch={setSearchValue}
      onSelect={() => setSearchValue('')}
      options={presentOptions}
      notFoundContent={null}
      {...restProps}
    />
  );
};
