import { Typography, FormInstance, Input, Checkbox, Radio, Form } from 'antd';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { META_KEYS, useMetaOptionsSubmission } from 'hooks/useMetaOptionsSubmission';
import { FC, useEffect } from 'react';
import { ImageDraggerNew } from 'modules/gdp/case-submission/components/file-upload-form/panel-items/image-dragger/image-dragger-new';
import { useWindowSize } from 'hooks/useWindowSize';
import { ReactComponent as CloudUploadSVG } from 'assets/images/interested-patients/cloud-upload.svg';
import smileImageOne from 'assets/images/help-content/extraoral-help-4.jpg';
import smileImageTwo from 'assets/images/help-content/smile.jpg';
import { NiceRadioGroup } from 'components/commons/nice-radio-group/nice-radio-group';
import {
  SMILE_QUESTIONNAIRE_NEW_PATIENT_LABELING,
  SMILE_QUESTIONNAIRE_NEW_DENTIST_LABELING,
  ratingOptionsSQ,
} from 'modules/gdp/interested-patients/utils';
import { InfoTag } from './info-tag';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';

const { TextArea } = Input;

interface IProps {
  isFormDisabled?: boolean;
  form?: FormInstance;
  showContentForPatient?: boolean;
  onForceAutoSave: (values: any) => void;
}

export const SmileQuestionnaireFormItems: FC<IProps> = ({
  form,
  onForceAutoSave,
  isFormDisabled = false,
  showContentForPatient = false,
}) => {
  const { isMd } = useWindowSize();
  const { getOptions } = useMetaOptionsSubmission(META_KEYS.smileQuestionnaire);
  const consideredBeforeOptions = getOptions('consideredBefore') || [];
  const newChangeOptions = getOptions('newChange') || [];
  const annoyIssuesOptions = getOptions('annoyIssues') || [];
  const newEventNoteOptions = getOptions('newEventNote') || [];
  const newConcernOptions = getOptions('newConcern') || [];

  const labeling = showContentForPatient
    ? SMILE_QUESTIONNAIRE_NEW_PATIENT_LABELING
    : SMILE_QUESTIONNAIRE_NEW_DENTIST_LABELING;

  const showRatingTag = Form.useWatch('rating', form);
  const showNewChangeTag = Form.useWatch('newChange', form);
  const showNewConcernTag = Form.useWatch('newConcern', form);

  useEffect(() => {
    if (showRatingTag) {
      trackEvent(EEventNames.SQ_SMILE_RATING_INFO_CLOSE_ICON_CLICKED);
    }
    if (showNewChangeTag) {
      trackEvent(EEventNames.SQ_QUESTION_NEW_CHANGE_INFO_CLOSE_ICON_CLICKED);
    }
    if (showNewConcernTag) {
      trackEvent(EEventNames.SQ_QUESTION_NEW_CONCERN_INFO_CLOSE_ICON_CLICKED);
    }
  }, [showRatingTag, showNewChangeTag, showNewConcernTag]);

  const imageDraggerPlaceholderMd = (
    <div className="w-full text-center">
      <b className="text-base">Browse files</b>
      <br />
      <span className="text-sm">or Drag and drop your images here </span>
    </div>
  );

  const imageDraggerPlaceholderSm = (
    <div className="text-center">
      <span className="underline">Upload your photos</span>
      <br />
      or
      <br />
    </div>
  );

  const photosInfoSection = (className: string) => (
    <div className={className}>
      <div className="flex flex-col flex-wrap items-center text-center">
        <img src={smileImageOne} height="130px" alt="" />
        <Typography.Text>
          Selfie with a big <br /> Smile
        </Typography.Text>
      </div>
      <div className="flex flex-col flex-wrap items-center text-center">
        <img src={smileImageTwo} height="130px" alt="" />
        <Typography.Text>
          Close up of your smile, <br /> teeth closed
        </Typography.Text>
      </div>
    </div>
  );

  return (
    <div className="grid w-full grid-cols-10 gap-2 pb-1">
      <div className="custom-slider-styling col-span-12 md:col-span-7 [&_.anticon-close]:text-base [&_.anticon-close]:!text-black">
        <FormItemControl
          name="rating"
          label={<Typography.Text className="mt-2 text-base font-semibold">{labeling.rating}</Typography.Text>}
          rules={[
            {
              required: true,
              message: 'Please tell me how you feel about your smile',
            },
          ]}
          isRequired
        >
          <NiceRadioGroup options={ratingOptionsSQ} disabled={isFormDisabled} />
        </FormItemControl>
        <div className="mb-4 mr-4 mt-1 flex items-center justify-between">
          <div className="font-semibold">Not Happy</div>
          <div />
          <div className="mr-3 font-semibold">Not Sure</div>
          <div />
          <div className="font-semibold">Love it</div>
        </div>
        {showRatingTag && showContentForPatient && (
          <InfoTag className="flex w-full animate-fadeIn items-baseline justify-between rounded-lg border-none bg-[#E6E3FF] px-4 py-2 text-black duration-700">
            <p className="mt-2 text-sm font-semibold">😲 Did you know that most people could improve their smile </p>
            <p className="mb-2 font-medium">
              70% of the adult population suffer from Crooked or misaligned teeth suitable for treatment.
            </p>
          </InfoTag>
        )}
      </div>
      <div className="col-span-12 md:col-span-12">
        <FormItemControl
          name="consideredBefore"
          label={
            <Typography.Text className="mt-2 text-base font-semibold">{labeling.consideredBefore}</Typography.Text>
          }
          isRequired
          rules={[
            {
              required: true,
              message: 'Please select one options',
            },
          ]}
          className="smile-radio-group"
        >
          <Radio.Group disabled={isFormDisabled} options={consideredBeforeOptions} />
        </FormItemControl>
      </div>
      <div className="col-span-12 [&_.anticon-close]:text-base [&_.anticon-close]:!text-black">
        <FormItemControl
          name={['newChange']}
          isRequired
          rules={[
            {
              required: true,
              message: 'Please select one option',
            },
          ]}
          label={<Typography.Text className="mt-2 text-base font-semibold">{labeling.newChange}</Typography.Text>}
          className="smile-checkbox-group mb-4"
        >
          <Checkbox.Group disabled={isFormDisabled} options={newChangeOptions} />
        </FormItemControl>
        {showNewChangeTag?.length > 0 && showContentForPatient && (
          <InfoTag className="flex w-full animate-fadeIn items-baseline justify-between rounded-lg border-none bg-[#E6E3FF] px-4 py-2 text-black duration-700">
            <p className="mt-2 text-sm font-semibold">
              {' '}
              😲 Did you know that cosmetic treatments can also improve your health?{' '}
            </p>
            <p className="mb-2 font-medium">
              Treatments improve the appearance of teeth and can ease pain, reduce wear, and make teeth easier to clean
              - keeping your teeth and gums healthy!
            </p>
          </InfoTag>
        )}
      </div>
      <div className="col-span-12">
        <FormItemControl
          name={['annoyIssues']}
          label={<Typography.Text className="mt-2 text-base font-semibold">{labeling.annoyIssues}</Typography.Text>}
          className="smile-checkbox-group"
        >
          <Checkbox.Group disabled={isFormDisabled} options={annoyIssuesOptions} />
        </FormItemControl>
      </div>
      <div className="col-span-12">
        <FormItemControl
          name={['newEventNote']}
          isRequired
          rules={[
            {
              required: true,
              message: 'Please select one option',
            },
          ]}
          label={<Typography.Text className="mt-2 text-base font-semibold">{labeling.newEventNote}</Typography.Text>}
          className="smile-checkbox-group"
        >
          <Checkbox.Group disabled={isFormDisabled} options={newEventNoteOptions} />
        </FormItemControl>
      </div>
      <div className="col-span-12 [&_.anticon-close]:text-base [&_.anticon-close]:!text-black">
        <FormItemControl
          name={['newConcern']}
          isRequired
          rules={[
            {
              required: true,
              message: 'Please select one option',
            },
          ]}
          label={<Typography.Text className="mt-2 text-base font-semibold">{labeling.newConcern}</Typography.Text>}
          className="smile-checkbox-group mb-4"
        >
          <Checkbox.Group disabled={isFormDisabled} options={newConcernOptions} />
        </FormItemControl>
        {showNewConcernTag?.length > 0 && showContentForPatient && (
          <InfoTag className="flex w-full animate-fadeIn items-baseline justify-between rounded-lg border-none bg-[#E6E3FF] px-4 py-2 text-black duration-700">
            <p className="mt-1 text-sm font-semibold"> 😲 Did you know that your smile helps others feel better? </p>
            <p className="mb-2 font-medium">
              3 in 5 people agree that a simple smile from someone makes them feel more confident
            </p>
          </InfoTag>
        )}
      </div>
      <div className="custom-textarea-styling col-span-12 md:col-span-8">
        <FormItemControl
          name="note"
          label={<Typography.Text className="mt-2 text-base font-semibold">{labeling.note}</Typography.Text>}
          rules={[
            {
              transform: (value) => value?.trim(),
            },
          ]}
        >
          <TextArea disabled={isFormDisabled} placeholder="" rows={3} />
        </FormItemControl>
      </div>
      <div className="col-span-12 mt-2">
        {showContentForPatient ? (
          <>
            <Typography.Title level={3} className="!mb-0">
              Let's take some photos!
            </Typography.Title>
            <Typography.Text className="text-semibold">
              Don't worry about taking the perfect photo. They're just for my reference to help me see where I can help.
            </Typography.Text>
            <div className="my-4 flex w-full flex-col rounded-xl p-4 md:flex-row">
              {photosInfoSection('my-4 flex flex-col items-center justify-center gap-4 md:flex-row md:gap-8')}
              {!isFormDisabled && (
                <div className="ml-4 mt-4">
                  <div className="ml-4 text-[#555770]">
                    Try to take <b>at least 1 photo</b> of your full face and 1 photo of your close up smile.
                  </div>
                  <ul>
                    <p className="mb-1 mt-2 font-semibold text-black">Some tips to help you:</p>
                    <li>Make sure your flash is on</li>
                    <li>Try to capture just your mouth and teeth if possible</li>
                    <li>Relax your lips – don’t make them tense</li>
                    <li>Keep your teeth together! Bite on your back teeth during the photo</li>
                  </ul>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <Typography.Title level={3} className="mt-2 text-base font-medium">
              Let's take some photos!
            </Typography.Title>
            <Typography.Text className="text-semibold">
              Take some nice photos of the patient to get good reference of what their current situation is.
            </Typography.Text>
            {photosInfoSection('my-4 flex flex-col items-start justify-start gap-4 md:flex-row md:gap-8')}
          </>
        )}

        <FormItemControl name="attachments">
          <ImageDraggerNew
            placeholder={isMd ? imageDraggerPlaceholderMd : imageDraggerPlaceholderSm}
            name="attachments"
            className="smile-questionnaire-dragger !ml-0 !w-full !max-w-full"
            uploadImageIcon={<CloudUploadSVG />}
            disabled={isFormDisabled}
            publicUpload={showContentForPatient}
            isWebcamEnable={!isMd}
            showUploadList
            isPatientSmileQuestionnaire
            onSave={() => onForceAutoSave(form?.getFieldsValue())}
          />
        </FormItemControl>
      </div>
    </div>
  );
};
