import { FC } from 'react';
import { Page, Text, View, Document, Image, Link as PDFLink } from '@react-pdf/renderer';
import moment from 'moment';
import { PdfKeyValue, PdfSectionHeader, PdfSubSectionHeader } from 'components/download-as-pdf-base/pdf-form-items';
import { getYesNoValue, pdfStyles } from 'components/download-as-pdf-base/utils';
import { IImage, ISubmission } from 'services/patients-api/types';
import logoBlackPng from 'assets/images/logo_black.png';
import { ITreatmentDesign } from 'services/treatment-designs-api/types';
import { lookoutForPatientRadiographDefaultValue } from 'modules/specialist/submit-advice/consts';
import { SUBMISSION_SERVICE_TYPE } from 'config/constants';
import { PDfImages } from 'components/download-as-pdf-base/pdf-form-image';
import { SUBMISSION_PLAN_TYPE } from 'modules/gdp/case-submission/utils';
import { IModification } from 'services/revision-requests-api/types';
import { formatDate } from 'utils/index';

interface IProps {
  treatmentDesign: ITreatmentDesign;
  submission: ISubmission;
  revisions?: IModification[];
}

// Create Document Component
export const TreatmentDesignPdfView: FC<IProps> = ({ treatmentDesign, submission, revisions }) => {
  const isDuo = submission.serviceType === SUBMISSION_SERVICE_TYPE.DUO;
  const isInitial = submission.planType === SUBMISSION_PLAN_TYPE.INITIAL;

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page} wrap>
        <View style={pdfStyles.header}>
          <Image src={logoBlackPng} style={{ width: 90, height: 33 }} />
          <Text
            style={{ ...pdfStyles.h1, marginLeft: 40, textTransform: 'capitalize' }}
          >{`Treatment Design - ${submission?.serviceType?.toLowerCase()} ${submission?.planType?.toLowerCase()}`}</Text>
        </View>
        <View style={pdfStyles.author}>
          <PdfKeyValue label="Created by" value={submission?.createdBy?.fullName} />
          <PdfKeyValue label="Date Created" value={moment(submission.createdAt).format('DD/MM/YYYY')} />
        </View>
        {(isDuo || isInitial) && (
          <View style={pdfStyles.section}>
            <PdfSectionHeader value="Summary" />
            <PdfKeyValue label="Personal note to dentist" value={treatmentDesign?.advice?.personalNote} />
            <View style={pdfStyles.row}>
              <PdfKeyValue width="50%" label="Complexity" value={treatmentDesign?.advice?.complexity || '-'} />
              <PdfKeyValue
                width="50%"
                label="Expected Length of Treatment"
                value={treatmentDesign?.advice?.estimatedTreatmentRange}
              />
            </View>
            <View style={pdfStyles.row}>
              <PdfKeyValue width="50%" label="Arches in treatment" value={treatmentDesign?.arches} />
              <PdfKeyValue width="50%" label="Total Stages" value={treatmentDesign?.totalSteps} />
            </View>
            <View style={pdfStyles.row}>
              <PdfKeyValue width="33%" label="IPR" value={getYesNoValue(treatmentDesign?.havingIpr)} />
              <PdfKeyValue width="33%" label="Attachments" value={getYesNoValue(treatmentDesign?.havingAttachment)} />
              <PdfKeyValue width="33%" label="Auxiliaries" value={getYesNoValue(treatmentDesign?.havingElastic)} />
            </View>
          </View>
        )}
        {isDuo && submission?.specialist && (
          <View style={pdfStyles.section}>
            <PdfSectionHeader value="Specialist Advice" />
            <Text style={pdfStyles.keyValue}>
              Your specialist for this case is: ${submission?.specialist?.prefix} {submission?.specialist?.fullName}
            </Text>
            <View style={{ ...pdfStyles.row, marginLeft: 20, marginTop: 10 }}>
              <View style={{ width: '40%' }}>
                <Image
                  src={{
                    uri: submission?.specialist?.profilePic || '',
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                    body: '',
                  }}
                  style={{ width: 100, height: 100, marginTop: 20, borderRadius: '50%', objectFit: 'cover' }}
                />
                <View style={pdfStyles.fallbackImage}>
                  <Text>{submission?.specialist?.firstName?.charAt(0)?.toUpperCase()}</Text>
                </View>
              </View>
              <PdfKeyValue
                width="60%"
                label={`A bit about ${submission?.specialist?.prefix} ${submission?.specialist?.fullName}`}
                value={submission?.specialist?.preferences?.bio}
                wrap
              />
            </View>
            <PdfSubSectionHeader value="Treatment Overview" />
            <PdfKeyValue label="Complexity" value={treatmentDesign?.advice?.complexity || '-'} />
            <View style={pdfStyles.row}>
              <PdfKeyValue
                width="50%"
                label="Diagnosis and problems list"
                value={treatmentDesign?.advice?.diagnosisAndProblemList}
              />
              <PdfKeyValue
                width="50%"
                label="Aims of treatment"
                value={treatmentDesign?.advice?.aimsOfTreatmentAndSummary}
              />
            </View>
            <PdfKeyValue
              label="What is not being corrected? Are there Alternative Treatment options to solve these?"
              value={treatmentDesign?.advice?.alternativeTreatmentPlan}
            />
            <View style={pdfStyles.row}>
              <PdfKeyValue
                width="50%"
                label="Estimated Treatment Length"
                value={treatmentDesign?.advice?.estimatedTreatmentRange}
              />
              <PdfKeyValue
                width="50%"
                label="Recommended wear schedule"
                value={treatmentDesign?.advice?.suggestedWearSchedule}
              />
            </View>
            <PdfKeyValue label="Refinement advice" value={treatmentDesign?.advice?.refinementAdvice} />
            <PdfKeyValue label="Retention advice" value={treatmentDesign?.advice?.retentionAdvice} />
            <PdfKeyValue label="How would you consent this patient?" value={treatmentDesign?.advice?.consent} />

            <PdfSubSectionHeader value="Case Call outs" />
            <View style={pdfStyles.row}>
              <PdfKeyValue
                width="50%"
                label="Benefits of Treatment"
                value={treatmentDesign?.advice?.submissionCallOut?.positive}
              />
              <PdfKeyValue
                width="50%"
                label="Possible Problems or Challenges"
                value={treatmentDesign?.advice?.submissionCallOut?.problematic}
              />
            </View>
            <Text style={pdfStyles.keyValue}>What Treatment Techniques have been included and why?</Text>
            <PdfKeyValue label="IPR" value={treatmentDesign?.advice?.iprPlan} />
            <PdfKeyValue label="Attachments" value={treatmentDesign?.advice?.attachmentPlan} />
            <PdfKeyValue label="Auxiliaries" value={treatmentDesign?.advice?.otherAuxillaries} />
            <View style={pdfStyles.row}>
              <PdfKeyValue width="50%" label="Elastics Type" value={treatmentDesign?.advice?.elasticType} />
              <PdfKeyValue
                width="50%"
                label="Elastics Wear Schedule"
                value={treatmentDesign?.advice?.elasticWearSchedule}
              />
            </View>
            <PdfKeyValue label="Recommended Buttons" value={treatmentDesign?.advice?.recommendedButtons} />
            <PdfKeyValue label="Extractions" value={treatmentDesign?.advice?.extractions} />
            <PdfKeyValue
              label="Feedback on Radiographs & Optional Orthodontic Assessment"
              value={lookoutForPatientRadiographDefaultValue}
              wrap
            />
            <PdfKeyValue
              label="Any additional advice specific to this patient"
              value={treatmentDesign?.advice?.specificToPatientRadiograph}
            />
            <PdfKeyValue
              label="Feedback on Orthodontic Assessment"
              value={treatmentDesign?.advice?.orthodonticsFeedback}
            />
          </View>
        )}
        {(!isDuo || isInitial) && (
          <View style={pdfStyles.section}>
            <PdfSectionHeader value="Treatment Design" />
            <View style={pdfStyles.row}>
              <PdfKeyValue width="50%" label="Arches in treatment" value={treatmentDesign?.arches} />
              <PdfKeyValue width="50%" label="Total steps" value={treatmentDesign?.totalSteps} />
            </View>
            <PdfKeyValue label="No. of Upper Aligners" value={treatmentDesign?.upperStageNumber} />
            <PdfKeyValue label="No. of Lower Aligners" value={treatmentDesign?.lowerStageNumber} />
            <View style={pdfStyles.row}>
              <PdfKeyValue width="50%" label="IPR" value={getYesNoValue(treatmentDesign?.havingIpr)} />
              <PdfKeyValue width="50%" label="IPR stages" value={treatmentDesign?.iprStages} />
            </View>
            <View style={pdfStyles.row}>
              <PdfKeyValue width="50%" label="Attachments" value={getYesNoValue(treatmentDesign?.havingAttachment)} />
              <PdfKeyValue width="50%" label="Attachments stages" value={treatmentDesign?.attachmentStages} />
            </View>
            <View style={pdfStyles.row}>
              <PdfKeyValue width="50%" label="Auxiliaries" value={getYesNoValue(treatmentDesign?.havingElastic)} />
              <PdfKeyValue width="50%" label="Auxiliaries type" value={treatmentDesign?.elastics} />
            </View>
            <PdfKeyValue label="Auxiliaries details" value={treatmentDesign?.auxiliaryDetail} />
            {submission.serviceType === SUBMISSION_SERVICE_TYPE.SOLO && (
              <PdfKeyValue label="Comments" value={treatmentDesign?.comment} />
            )}
          </View>
        )}
        {!isDuo && (
          <View style={pdfStyles.section} wrap={false}>
            <PdfSectionHeader value="Revision Requests" />
            {!revisions || revisions.length === 0 ? (
              <Text style={pdfStyles.keyValue}>No data to show!</Text>
            ) : (
              revisions.map((revision) => (
                <PdfKeyValue
                  key={revision.id}
                  label={`Design ${revision.dentistDesignNumber} - ${formatDate(
                    revision.createdAt,
                    'DD/MM/YYYY - hh:mm a',
                  )}`}
                  value={revision?.comment}
                />
              ))
            )}
          </View>
        )}
        <View style={pdfStyles.section} wrap={false}>
          <PdfSectionHeader value="Tooth Movement - Animation link" />
          {treatmentDesign?.toothAnimationUrl ? (
            <PDFLink src={treatmentDesign?.toothAnimationUrl} style={pdfStyles.keyValue}>
              View Animation link
            </PDFLink>
          ) : (
            <Text style={pdfStyles.keyValue}>No data to show!</Text>
          )}
        </View>
        <View style={pdfStyles.section} wrap={false}>
          <PdfSectionHeader value="IPR Schematics" />
          <PDfImages images={treatmentDesign?.iprImages as IImage[]} />
        </View>
        <View style={pdfStyles.section} wrap={false}>
          <PdfSectionHeader value="Tooth movement table" />
          <PDfImages
            images={
              treatmentDesign?.toothMovementTableImage?.url
                ? [{ url: treatmentDesign?.toothMovementTableImage?.url, type: '', name: '' }]
                : []
            }
          />
        </View>
        <View style={pdfStyles.section} wrap={false}>
          <PdfSectionHeader value="Additional Images" />
          <PDfImages images={treatmentDesign?.additionalAttachments as IImage[]} />
        </View>
      </Page>
    </Document>
  );
};
