import { Card, Divider, Skeleton, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useQuery } from 'hooks/useQuery';
import { CallNow } from 'modules/gdp/interested-patients/interested-patients-pis/interested-patients-pis-preview/call-now/call-now';
import { ClearAlignersInformation } from 'modules/gdp/interested-patients/interested-patients-pis/interested-patients-pis-preview/clear-aligners-information/clear-aligners-information';
import { SimilarCase } from 'modules/gdp/interested-patients/interested-patients-pis/interested-patients-pis-preview/similar-case/similar-case';
import { TreatmentAndGoal } from 'modules/gdp/interested-patients/interested-patients-pis/interested-patients-pis-preview/treatment-and-goal/treatment-and-goal';
import { TreatmentEstimation } from 'modules/gdp/interested-patients/interested-patients-pis/interested-patients-pis-preview/treatment-estimation/treatment-estimation';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetInterestedPatientByPasswordQuery } from 'services/interested-patients-api/endpoints';
import { EEventNames } from 'utils/mixpanel/events';
import { trackEvent } from 'utils/mixpanel/mixpanel';

export const PatientsPisPreview: FC = () => {
  const params = useParams<{ patientId: string }>();
  const query = useQuery();

  const { data: interestedPatient, isLoading: isInterestedPatientLoading } = useGetInterestedPatientByPasswordQuery({
    id: params.patientId,
    password: query.get('token') || '',
  });

  useEffect(() => {
    if (interestedPatient) {
      trackEvent(EEventNames.INTERESTED_PATIENT_PIS_VIEWED, {
        patientId: interestedPatient?.id,
        patientName: interestedPatient?.fullName,
      });
    }
  }, [interestedPatient]);

  const { personalInformationSheet } = interestedPatient || {};

  return (
    <div className="mx-auto max-w-7xl">
      <Skeleton loading={isInterestedPatientLoading} active>
        <Card bordered={false} className="rounded-lg !p-0 shadow-md" bodyStyle={{ padding: 0 }}>
          <div className="rounded-t-lg bg-branch-title p-3 md:p-6">
            <Typography.Title level={3} className="!mb-0 text-center !text-xl !text-white md:!text-2xl">
              Hi {interestedPatient?.fullName}, here is your personal Smile Review
            </Typography.Title>
          </div>
          <Card bordered={false} bodyStyle={{ paddingLeft: 20, paddingRight: 20, paddingTop: 36 }}>
            <div className="flex gap-4">
              {interestedPatient?.personalInformationSheet?.includeDentistProfilePic && (
                <Avatar size={64} src={interestedPatient?.createdBy?.profilePic} className="min-w-[64px]" />
              )}
              <div>
                <div className="text-base">Dr. {interestedPatient?.createdBy?.fullName}</div>
                <div className="text-blue-500">{interestedPatient?.practice?.name}</div>
                <div>{interestedPatient?.practice?.address.address1}</div>
              </div>
            </div>
            <TreatmentAndGoal
              note={personalInformationSheet?.note}
              attachments={personalInformationSheet?.attachments}
            />
            <TreatmentEstimation
              estimatedPrice={personalInformationSheet?.estimatedPrice}
              estimatedDuration={personalInformationSheet?.estimatedDuration}
            />
            {!!personalInformationSheet?.caseStudies?.length && (
              <SimilarCase caseStudies={personalInformationSheet?.caseStudies} />
            )}
          </Card>
          <CallNow
            className="mt-8"
            phoneNumber={
              personalInformationSheet?.contactPreferencePractice?.includes('PHONE')
                ? personalInformationSheet?.practicePhone
                : undefined
            }
            email={
              personalInformationSheet?.contactPreferencePractice?.includes('EMAIL')
                ? personalInformationSheet?.practiceEmail
                : undefined
            }
            fullName={interestedPatient?.fullName}
          />
          <Divider />
          {/* <TeethStraighteningInformation className="mt-10" /> */}
          <ClearAlignersInformation />
          {/* <PatientJourneyNextStep className="mt-10" /> */}
          <CallNow
            className="mt-8"
            phoneNumber={
              personalInformationSheet?.contactPreferencePractice?.includes('PHONE')
                ? personalInformationSheet?.practicePhone
                : undefined
            }
            email={
              personalInformationSheet?.contactPreferencePractice?.includes('EMAIL')
                ? personalInformationSheet?.practiceEmail
                : undefined
            }
            fullName={interestedPatient?.fullName}
          />
        </Card>
      </Skeleton>
    </div>
  );
};
