import { FC } from 'react';
import { Button, Empty } from 'antd';
import { getClickableLink } from 'helpers';
import { NiceCollapse } from 'components/commons/nice-collapse/nice-collapse';
import { SmilePreviewComponent } from 'modules/gdp/patients/proposal/components/smile-preview/smile-preview.component';
import { ITreatmentDesign } from 'services/treatment-designs-api/types';

interface IProps {
  treatmentDesign?: ITreatmentDesign;
}

export const ToothMovementAnimationCollapse: FC<IProps> = ({ treatmentDesign }) => {
  const extraToothMovementAnimation = treatmentDesign?.toothAnimationUrl ? (
    <a
      href={getClickableLink(treatmentDesign.toothAnimationUrl)}
      onClick={(e: any) => e.stopPropagation()}
      target="_blank"
      rel="noreferrer"
    >
      <Button type="default" id="view-tooth-animation" size="middle" className="rounded-2xl bg-btnSecondaryColor px-4">
        Open Animation
      </Button>
    </a>
  ) : null;

  return (
    <NiceCollapse title="Tooth Movement Animation" open extra={extraToothMovementAnimation}>
      {treatmentDesign?.toothAnimationUrl ? (
        <SmilePreviewComponent url={treatmentDesign?.toothAnimationUrl} isFullSizePage={false} />
      ) : null}
      {!treatmentDesign?.toothAnimationUrl && <Empty description="No data to show!" />}
    </NiceCollapse>
  );
};
