/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNotificationItem } from 'services/notification/types';

export type TNotisType = 'unread' | 'all' | 'read';

type TNotificationsState = {
  notisList: Record<TNotisType, TNotificationItem[] | undefined>;
  unreadNotificationsCount?: number;
  showNotifications: boolean;
};

const INITIAL_NOTIS_LIST = { unread: undefined, all: undefined, read: undefined };
const INITIAL_STATE: TNotificationsState = {
  notisList: INITIAL_NOTIS_LIST,
  unreadNotificationsCount: undefined,
  showNotifications: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: INITIAL_STATE,
  reducers: {
    setNotisList: (
      state,
      { payload: { type, items } }: PayloadAction<{ type: TNotisType; items: TNotificationItem[] }>,
    ) => {
      state.notisList[type] = items;
    },
    addMoreNotisList: (
      state,
      { payload: { type, items } }: PayloadAction<{ type: TNotisType; items: TNotificationItem[] }>,
    ) => {
      state.notisList[type]?.push(...items);
    },
    socketNotisCome: (state, { payload }: PayloadAction<TNotificationItem>) => {
      state.notisList.unread?.unshift(payload);
      state.notisList.all?.unshift(payload);
      state.unreadNotificationsCount! += 1;
    },
    updateNotisItem: (
      state,
      {
        payload: { type, notisId, isViewedTo },
      }: PayloadAction<{ type: TNotisType; notisId: string; isViewedTo: boolean }>,
    ) => {
      const currentListWillBe =
        type === 'all'
          ? state.notisList.all?.map((n) => (n.id !== notisId ? n : { ...n, isViewed: isViewedTo }))
          : state.notisList[type]?.filter((n) => n.id !== notisId);

      state.notisList = {
        ...INITIAL_NOTIS_LIST,
        [type]: currentListWillBe,
      };
      state.unreadNotificationsCount! += isViewedTo ? -1 : 1;
    },
    setUnreadNotificationsCount: (state, { payload }: PayloadAction<number>) => {
      state.unreadNotificationsCount = payload;
    },
    reloadAllNotifications: (state) => {
      state.notisList = { unread: undefined, all: undefined, read: undefined };
    },
    showNotificationsPanel: (state, { payload }: PayloadAction<boolean>) => {
      state.showNotifications = payload;
    },
  },
});

export const {
  setNotisList,
  addMoreNotisList,
  socketNotisCome,
  updateNotisItem,
  setUnreadNotificationsCount,
  reloadAllNotifications,
  showNotificationsPanel,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
