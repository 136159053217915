import NiceModal from '@ebay/nice-modal-react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { Typography } from 'antd';
import { CompanyAwardsImages } from './company-awards-images';

export const CompanyAwardsModal = NiceModal.create(() => {
  const modal = NiceModal.useModal();
  return (
    <NiceAntModal modal={modal}>
      <div className="flex flex-col items-center justify-center">
        <Typography.Title level={4} className="text-center">
          Proud winners in 2023
        </Typography.Title>
        <CompanyAwardsImages />
      </div>
    </NiceAntModal>
  );
});
