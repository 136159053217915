import { Avatar, Button, Divider, Form, Input, Rate, Skeleton, Space, Typography, message, notification } from 'antd';
import { FC, useEffect } from 'react';
import confettiIconSvg from 'assets/images/confetti-icon.svg';
import { PhoneOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { ImageUploadList } from 'modules/gdp/case-submission/components/file-upload-form/panel-items/image-dragger/image-upload-list';
import { RecapCardLayout } from '../recap-card-layout/recap-card-layout';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import { IPatientCompletedTreatmentRecap } from 'services/completed-treatment-api/types';
import { MetaOptionsInput } from 'components/meta-options-input/meta-options-input';
import { META_KEYS } from 'hooks/useMetaOptionsSubmission';
import { EConsentToShareImages } from 'modules/gdp/patients/patient-details/completed-treatment/utils';
import { useSubmitPatientFeedbackCompletedTreatmentRecapMutation } from 'services/completed-treatment-api/endpoints';
import { notificationApiError } from 'utils/ui';
import { MailTo } from 'components/mail-to/mail-to';
import copy from 'copy-to-clipboard';
import { useQuery } from 'hooks/useQuery';
import { useWindowSize } from 'hooks/useWindowSize';
import { trackEvent } from 'utils/mixpanel/mixpanel';
import { EEventNames } from 'utils/mixpanel/events';

interface IProps {
  recap?: IPatientCompletedTreatmentRecap;
  password?: string;
  viewOnly?: boolean;
  patientId?: string;
}

export const PatientRecapSubmit: FC<IProps> = ({ recap, password, viewOnly, patientId }) => {
  const isPublic = !!useQuery().get('public');
  const [form] = Form.useForm();
  const patientFeedback = Form.useWatch('patientFeedback', form);
  const consentToShareImages = Form.useWatch('consentToShareImages', form);
  const [submitPatientFeedbackRecap, { isLoading: isSubmitLoading }] =
    useSubmitPatientFeedbackCompletedTreatmentRecapMutation();
  const { isMd } = useWindowSize();

  useEffect(() => {
    form.setFieldsValue(recap?.treatmentRecap);
  }, [recap]);

  const handleSubmit = async (values: any) => {
    try {
      await submitPatientFeedbackRecap({ id: patientId!, payload: { ...values, password } }).unwrap();
      trackEvent(EEventNames.PATIENT_TESTIMONIAL_SUBMITTED, {
        'Testimonial Submitted At': new Date().toISOString(),
        'Patient Feedback Scode': values.patientFeedback,
        'Patient Feedback Summary': values.patientFeedbackSummary,
        'Patient Consent': values.consentToShareImages,
        'Patient ID': recap?.id,
        'Dentist ID': recap?.createdBy?.id,
      });
      notification.success({ message: 'Successfully submitted' });
    } catch (e) {
      notificationApiError(e);
    }
  };

  const isSubmitted = !!recap?.treatmentRecap?.patientFeedback;
  const shareUrl = isPublic
    ? window.location.href
    : `${window.location.href}?public=true&token=${recap?.treatmentRecap?.token}`;
  const shouldDisabledForm = viewOnly || isSubmitted || isPublic;

  return (
    <RecapCardLayout title="Smile Treatment Complete">
      <Skeleton loading={!recap} active>
        <Form
          form={form}
          layout="vertical"
          className="m-auto px-4 py-12 md:px-20"
          onFinish={handleSubmit}
          size="large"
          initialValues={{ consentToShareImages: recap?.consentToShareImages }}
        >
          <Typography.Title level={3}>
            <img src={confettiIconSvg} height={44} alt="" className="mr-4" />
            Congratulations {recap?.firstName}, you're done with your Clear Aligners! 🥳
          </Typography.Title>

          <div className="mt-4 rounded-standard bg-[#F1F7FF] p-4">
            <Typography.Text className="font-medium">Hello {recap?.firstName},</Typography.Text>
            <p className="mt-2">
              Completing your Clear Aligner Treatment is a big deal. Here's a recap of just how far you and your teeth
              have come!
            </p>
            <ul className="pl-4 leading-6">
              <li>Check-out your before and after photos</li>
              <li>Leave some feedback to help other patients decide if aligners are for them</li>
              <li>Share your results with your friends and family</li>
            </ul>
            <div className="flex">
              {recap?.treatmentRecap?.includeDentistProfilePic && (
                <div className="flex items-center">
                  <Avatar size={64} src={recap?.createdBy?.profilePic} className="mr-4 min-w-[64px]" />
                </div>
              )}
              <div>
                <div className="font-bold">Dr. {recap?.createdBy?.fullName}</div>
                <div className="text-blue-500">{recap?.practice?.name}</div>
                {recap?.treatmentRecap?.includeDentistContactInformation && (
                  <>
                    {recap?.createdBy?.phone && (
                      <div>
                        <PhoneOutlined /> &nbsp;{' '}
                        {isMd ? (
                          `${recap?.createdBy?.phone?.ext} ${recap?.createdBy?.phone?.number}`
                        ) : (
                          <a href={`tel:${recap?.createdBy?.phone?.ext}${recap?.createdBy?.phone?.number}`}>
                            {recap?.createdBy?.phone?.ext} {recap?.createdBy?.phone?.number}
                          </a>
                        )}
                      </div>
                    )}
                    {recap?.createdBy?.email && (
                      <div>
                        <MailOutlined /> &nbsp;{' '}
                        <MailTo email={recap?.createdBy?.email}>{recap?.createdBy?.email}</MailTo>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <Typography.Title level={4} className="mt-8">
            Your Journey to a New Smile
          </Typography.Title>
          <Typography.Text>{recap?.treatmentRecap?.patientJourney}</Typography.Text>
          <Divider />
          {!!recap?.treatmentRecap?.beforeTreatmentImages?.length && (
            <>
              <Typography.Title level={4} className="mt-8">
                Before your first Aligner
              </Typography.Title>
              <ImageUploadList
                name="beforeTreatmentImages"
                images={recap?.treatmentRecap?.beforeTreatmentImages || []}
              />
            </>
          )}
          {!!recap?.treatmentRecap?.afterTreatmentImages?.length && (
            <>
              <Typography.Title level={4} className="mt-6">
                Your New Smile
              </Typography.Title>
              <ImageUploadList name="afterTreatmentImages" images={recap?.treatmentRecap?.afterTreatmentImages || []} />
            </>
          )}
          {recap?.treatmentRecap?.includePatientFeedback && (
            <>
              <Typography.Title level={4} className="mt-12">
                Feedback and Testimonials
              </Typography.Title>
              <div className="mt-6 rounded-standard bg-[#F1F7FF] px-4 pb-8 pt-4">
                <FormItemControl
                  name="patientFeedback"
                  label={
                    <div className="-mb-4 text-base font-semibold text-darkBlueColor">
                      How did you find your Clear Aligner Experience?<span className="text-red">*</span>
                    </div>
                  }
                  rules={[{ required: true, type: 'number', min: 1, message: 'Please rate your experience' }]}
                >
                  <Rate className="-mt-12" disabled={shouldDisabledForm} />
                </FormItemControl>
                <FormItemControl
                  name="patientFeedbackSummary"
                  label={
                    <span className="text-base font-semibold text-darkBlueColor">
                      Can you tell me why? Please include anything you think other patients should know about me or
                      Clear Aligners<span className="text-red">*</span>
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: patientFeedback
                        ? 'Please tell me about your experience so that other patients can know about Clear Aligners'
                        : 'Please provide some details to help other patients or help me improve my service',
                    },
                  ]}
                  className="mt-6"
                >
                  <Input.TextArea
                    placeholder="Please describe your experience"
                    disabled={shouldDisabledForm}
                    rows={4}
                  />
                </FormItemControl>

                {!isPublic && (
                  <>
                    <FormItemControl
                      name="consentToShareImages"
                      label={
                        <span className="text-base font-semibold text-darkBlueColor">
                          Can I share your image and testimonial to educate other patients about Dental treatments. Your
                          name and personal details will not be used<span className="text-red">*</span>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Please let me know if I have consent to use your images and testimonial',
                        },
                      ]}
                      className="mt-6"
                    >
                      <MetaOptionsInput
                        metaKey={META_KEYS.completeTreatment}
                        optionKey="consentToShareImagesPatient"
                        disabled={shouldDisabledForm}
                        uiType="niceRadio"
                      />
                    </FormItemControl>
                    {[EConsentToShareImages.YesFullFace, EConsentToShareImages.YesMyTeeth].includes(
                      consentToShareImages,
                    ) && (
                      <div className="mt-4">
                        🥳 Thank you! Your approval will educate others on changing their lives with an improved smile!{' '}
                      </div>
                    )}
                    <div>
                      {isSubmitted ? (
                        <div className="mt-4 font-bold text-darkBlueColor">Feedback has been sent!</div>
                      ) : (
                        <NiceButton
                          niceType="orange"
                          size="small"
                          className="mr-4 mt-4 w-36"
                          htmlType="submit"
                          loading={isSubmitLoading}
                          disabled={shouldDisabledForm}
                        >
                          Send Feedback
                        </NiceButton>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <Divider />
          {!isPublic && (
            <>
              <Typography.Title level={4} className="mt-6">
                Want to share with Friends and Family?
              </Typography.Title>
              <Typography.Text className="mt-4 text-base">
                Click the share buttons to generate personal links to your Story for Friends and Family to see your
                journey.
              </Typography.Text>
            </>
          )}
          <br />
          {!isPublic && (
            <Space className="mt-4" wrap>
              <MailTo email="" subject="Check out my Journey to a New Smile" body={encodeURIComponent(shareUrl)}>
                <Button
                  icon={<MailOutlined />}
                  onClick={() => {
                    trackEvent(EEventNames.PATIENT_SMILE_STORY_SHARED_VIA_EMAIL, {
                      'Patient ID': recap?.id,
                      'Patient Full Name': recap?.fullName,
                      Link: shareUrl,
                    });
                  }}
                >
                  Email
                </Button>
              </MailTo>
              {!isMd && (
                <a href={`https://wa.me/?text=${encodeURIComponent(shareUrl)}`}>
                  <Button
                    icon={
                      <WhatsAppOutlined
                        onClick={() => {
                          trackEvent(EEventNames.PATIENT_SMILE_STORY_SHARED_VIA_WHATSAPP, {
                            'Patient ID': recap?.id,
                            'Patient Full Name': recap?.fullName,
                            Link: shareUrl,
                          });
                        }}
                      />
                    }
                  >
                    Whats app
                  </Button>
                </a>
              )}
              <Button
                onClick={() => {
                  copy(shareUrl);
                  trackEvent(EEventNames.PATIENT_SMILE_STORY_LINK_COPIED, {
                    'Patient ID': recap?.id,
                    'Patient Full Name': recap?.fullName,
                    Link: shareUrl,
                  });
                  message.success('Copied!');
                }}
              >
                Copy Link
              </Button>
            </Space>
          )}
        </Form>
      </Skeleton>
    </RecapCardLayout>
  );
};
