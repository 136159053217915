import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { useCheckEmailMutation } from 'services/api';

const EXISTING_EMAIL_MESSAGE = 'An account already exists with this email.';

export const EmailField: React.FC = () => {
  const formInstance = Form.useFormInstance();
  const [checkEmail, { isLoading }] = useCheckEmailMutation();
  const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>();

  const handleBlur = async () => {
    let value;
    try {
      value = await formInstance.validateFields(['email']);
    } catch (e: any) {
      setIsEmailValid(undefined);
      value = e.values;
      if (e.errorFields[0]?.errors?.[0] !== EXISTING_EMAIL_MESSAGE) return;
    }
    try {
      await checkEmail({ email: value.email }).unwrap();
      setIsEmailValid(true);
    } catch (e) {
      setIsEmailValid(false);
    } finally {
      formInstance.validateFields(['email']);
    }
  };

  const handleExistingValidate = (_: unknown, email: string) => {
    if (email) {
      return isLoading
        ? Promise.reject(new Error('We are validating your email..'))
        : isEmailValid === false
        ? Promise.reject(new Error(EXISTING_EMAIL_MESSAGE))
        : Promise.resolve();
    }
    return Promise.resolve();
  };

  return (
    <FormItemControl
      name="email"
      label="We’ll need your Email Address please"
      isRequired
      rules={[
        {
          required: true,
          message: 'Please enter your email address',
        },
        { type: 'email', message: 'Please enter your full email address' },
        () => ({
          validator: handleExistingValidate,
        }),
      ]}
      normalize={(value) => value?.toLowerCase()}
      validateTrigger={['onBlur']}
    >
      <Input className="custom-input" placeholder="Phil.Ling@email.com" onBlur={handleBlur} />
    </FormItemControl>
  );
};
