import { FC } from 'react';
import midlLogoSvg from 'assets/images/auth/mid-logo.svg';
import logoSvg from 'assets/images/dashboard/logo.svg';
import { useHistory } from 'react-router-dom';
import { pushToDataLayer } from 'utils/gtm';
import styled from 'styled-components';
import { Button, Carousel, Popover, Typography } from 'antd';
import { BREAKPOINT } from '../../../utils/ui';
import { useWindowSize } from 'hooks/useWindowSize';
import { FistSlide } from './register-carousel/first-slide';
import { SecondSlide } from './register-carousel/second-slide';
import { ThirdSlide } from './register-carousel/third-slide';
import { FourthSlide } from './register-carousel/fourth-slide';
import companyAwardsPng from 'assets/images/company_awards.png';
import { CompanyAwardsImages } from 'components/layout/dashboard-layout/company-awards/company-awards-images';
import NiceModal from '@ebay/nice-modal-react';
import { CompanyAwardsModal } from 'components/layout/dashboard-layout/company-awards/company-awards-modal';

const DIV = styled.div`
  min-height: 100vh;

  .left {
    .ant-carousel .slick-dots li {
      width: 12px;
      button {
        height: 10px;
        border-radius: 32px;
      }
      &.slick-active {
        width: 24px;
      }
    }
  }

  .topPart {
    height: 70px;
    top: 0;
    z-index: 2;
    background: white;
    width: 100%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    justify-content: space-between;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    .topPart {
      box-shadow: none;
    }
  }
`;

interface IAuthLayoutProps {
  children: React.ReactNode;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export const AuthLayout: FC<IAuthLayoutProps> = ({ children, onMouseLeave }) => {
  const history = useHistory();
  const { isMd, isLg } = useWindowSize();
  const openAwardsModal = () => {
    NiceModal.show(CompanyAwardsModal);
  };
  return (
    <DIV onMouseLeave={onMouseLeave}>
      <div className="left fixed hidden h-[100vh] flex-col bg-[#050a46] md:flex md:w-[360px] lg:w-[516px] lg:pl-6 lg:pr-15">
        <div className="mt-8">
          <img src={logoSvg} alt="logo" height={63} width={169} />
        </div>
        <div className="mb-auto mt-auto flex flex-col items-center justify-center text-white">
          <Carousel
            autoplay
            autoplaySpeed={30000}
            pauseOnHover
            className="mb-10 mt-10 w-[340px] pl-6 pr-1 lg:w-[460px] lg:pl-8 lg:pr-6"
            dots={{ className: '!justify-start !ml-8 !bottom-0 py-2 bg-[#050a46]' }}
          >
            <FistSlide />
            <SecondSlide />
            <ThirdSlide />
            <FourthSlide />
          </Carousel>
        </div>
      </div>

      <div className="right md:ml-[360px] lg:ml-[516px]">
        <div className="topPart fixed flex items-center px-3 md:relative">
          {!isMd ? <img src={midlLogoSvg} alt="logo" height={40} width={107} /> : <div />}
          {isLg && (
            <div className="flex h-10 flex-grow cursor-pointer items-center gap-2" onClick={openAwardsModal}>
              <Popover
                content={
                  <div className="w-[500px]">
                    <CompanyAwardsImages />
                  </div>
                }
              >
                <img src={companyAwardsPng} alt="32co awards" className="h-full" />
              </Popover>
              <div className="flex flex-col  rounded bg-[#F5FBFF] px-2 py-1">
                <Typography.Text className="text-sm font-semibold">Proud winners in 2023</Typography.Text>
              </div>
            </div>
          )}
          <div className="flex items-center">
            <span className="mr-4 hidden font-medium md:block">Already have account?</span>
            <Button
              onClick={() => {
                const pathName = window.location.pathname.replace('/', '');
                pushToDataLayer({ event: `${pathName}.signin.click` });
                history.push('/signin');
              }}
              style={{
                width: '94px',
                borderColor: '#e83c41',
                height: '36px',
              }}
            >
              Sign in
            </Button>
          </div>
        </div>

        <div className="children px-4 lg:px-8">{children}</div>
      </div>
    </DIV>
  );
};
