/* eslint-disable no-param-reassign */
/* eslint-disable no-extend-native */
if (![].at) {
  Array.prototype.at = function (n) {
    // ToInteger() abstract op
    n = Math.trunc(n) || 0;
    // Allow negative indexing from the end
    if (n < 0) n += this.length;
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) return undefined;
    // Otherwise, this is just normal property access
    return this[n];
  };
}
