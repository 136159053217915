import { useCallback } from 'react';
import { ICountryOption, useGetCountryOptionsQuery } from 'services/api';

let countryCodeMap: Record<string, ICountryOption>;
let countryExtMap: Record<string, ICountryOption>;

export const useCountryOptions = () => {
  const { data, isLoading } = useGetCountryOptionsQuery();

  const getCountryInfoFromCode = useCallback(
    (countryCode: string) => {
      if (!countryCodeMap && data) {
        countryCodeMap = data.reduce(
          (newMap, item) => ({ ...newMap, [item.code]: item }),
          {} as Record<string, ICountryOption>,
        );
      }
      return countryCodeMap[countryCode];
    },
    [data],
  );

  const getCountryInfoFromExt = useCallback(
    (ext: string) => {
      if (!countryExtMap && data) {
        countryExtMap = data.reduce(
          (newMap, item) => ({ ...newMap, [item.ext]: item }),
          {} as Record<string, ICountryOption>,
        );
      }
      return countryExtMap[ext];
    },
    [data],
  );

  return {
    countries: data,
    isLoading,
    getCountryInfoFromCode: data ? getCountryInfoFromCode : undefined,
    getCountryInfoFromExt: data ? getCountryInfoFromExt : undefined,
  };
};
