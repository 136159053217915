import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const NotFound = () => {
  const { userLogout } = useAuth();
  const history = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <>
          <Button
            type="primary"
            className="btn-primary-redesigned"
            size="large"
            onClick={() => {
              history.push('/');
            }}
          >
            Back Home
          </Button>{' '}
          <Button size="large" className="btn-secondary-redesigned ml-2" onClick={userLogout}>
            Logout
          </Button>
        </>
      }
    />
  );
};

export default NotFound;
