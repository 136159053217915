export const specialNoteToDentistPlaceholder = `This box is the first thing the dentist will see. 


Please provide a summary of your advice below, review the dentist’s treatment objectives and answer any of their questions or concerns 
`;

export const diagnosisAndProblemsListPlaceholder = `What is the full diagnosis of the patient`;

export const estimatedTreatmentRangePlaceholder = `How long do you expect treatment to take`;

export const aimsOfTreatmentAndSummaryOfOrthoPlanPlaceholder = 'What are we trying to achieve? Why?';

export const alternativeTreatmentOptionsPlaceholder = `What issues are not being corrected in this plan and why?

Could they be treated with clear aligners and what would the trade-offs be?`;

export const positivePlaceholder = `How would you sell this case to a patient?

This patient will find cleaning much easier following ortho, make sure to mention that :)`;

export const problematicPlaceholder =
  'Is there anything that could present a challenge during treatment or is likely to require close observation?';

export const attachmentsPlaceholder =
  'If present please explain why attachments are being prescribed. If not present, leave blank.';

export const iprPlaceholder = 'If present please explain why IPR is being prescribed. If not present, leave blank.';

export const otherAuxillariesPlaceholder = `If present please explain why auxiliaries are being prescribed. If not present, leave blank.`;

export const consentPlaceholder = 'What are the main watch outs?';

export const orthodonticsFeedbackPlaceholder = `Review and Provide feedback on their assessment to improve their assessment skills. If the dentist did not fill it in then leave blank. 

p.s. Consider triggering educational content if relevant and helpful`;

export const lookoutForPatientRadiographDefaultValue =
  'A reminder: This case has been treatment planned assuming that this patient is fit for orthodontic treatment. This means that Periodontal & Dental health has been assessed and is stable. That radiographs will be reviewed before treatment commences by the dentist to rule out pathology or contraindications to orthodontic treatment (e.g. shortened roots, unerupted teeth, supernumararies and any other lesions).';

export const specificToPatientRadiographPlaceholder =
  'Answer any questions or concerns and point out any specific issues to watch out for with this case.';

export const recommendedButtonsPlaceholder =
  'What type & number of buttons to include for the patient (e.g. 2 Metal, 2 Ceramic)';

export const extractionsButtonsPlaceholder =
  'If this case requires extractions please explain in a way that helps the dentist explain this to the patient.';

export const ELASTICS_TYPE_OPTIONS = [
  {
    value: 'Medium, 4.5 Oz, 5/16” Size',
    label: 'Medium, 4.5 Oz, 5/16” Size',
  },
  {
    value: 'Medium, 4.5 Oz, 3/16” Size',
    label: 'Medium, 4.5 Oz, 3/16” Size',
  },
  {
    value: 'Medium, 4.5 Oz, 1 /4” Size',
    label: 'Medium, 4.5 Oz, 1 /4” Size',
  },
  {
    value: 'Other',
    label: 'Other',
  },
  {
    value: 'Multiple',
    label: 'Multiple',
  },
  {
    value: 'Not Applicable',
    label: 'Not Applicable',
  },
];
