import { FC, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { TreatmentDesignPdfView } from './treatment-design-pdf-view/treatment-design-pdf-view';
import { ITreatmentDesign } from 'services/treatment-designs-api/types';
import { ISubmission } from 'services/patients-api/types';
import { useGetRevisionRequestsQuery } from 'services/revision-requests-api/endpoints';
import { SUBMISSION_SERVICE_TYPE } from 'config/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import { saveAs } from 'file-saver';

interface IProps {
  treatmentDesign: ITreatmentDesign;
  submission: ISubmission;
}

export const DownloadTreatmentDesignAsPdf: FC<IProps> = ({ treatmentDesign, submission }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: revisions,
    isLoading: isRevisionLoading,
    isFetching: isRevisionFetching,
  } = useGetRevisionRequestsQuery(
    {
      page: 1,
      perPage: 20,
      submissionId: submission.id,
    },
    { skip: submission.serviceType !== SUBMISSION_SERVICE_TYPE.SOLO },
  );
  const { isMd } = useWindowSize();

  const handleClick = async () => {
    setIsLoading(true);
    const doc = (
      <TreatmentDesignPdfView treatmentDesign={treatmentDesign} submission={submission} revisions={revisions?.docs} />
    );
    const blob = await pdf(doc).toBlob();
    const fileName = `[Treatment Design - ${submission?.serviceType} ${submission?.planType}] - ${
      submission?.patient?.firstName || ''
    } ${submission?.patient?.lastName || ''}.pdf`;
    saveAs(blob, fileName);
    setIsLoading(false);
  };

  if (isRevisionLoading || isRevisionFetching) return null;

  return (
    <Tooltip
      title={<div className="text-black">Download as PDF</div>}
      placement="left"
      color="white"
      overlayStyle={{ maxWidth: 'none', zIndex: 1 }}
      trigger="hover"
    >
      {isMd ? (
        <Button
          size="large"
          type="text"
          shape="round"
          onClick={handleClick}
          icon={<DownloadOutlined />}
          className="mr-1"
          loading={isLoading}
        />
      ) : (
        <Button
          size="middle"
          type="default"
          onClick={handleClick}
          icon={<DownloadOutlined />}
          className="mr-2"
          loading={isLoading}
        >
          Download as PDF
        </Button>
      )}
    </Tooltip>
  );
};
