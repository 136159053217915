import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { THeathResponse, TUploadToDesignPortalPayload } from './types';

const ONYX_SERVER_URL = process.env.REACT_APP_ONYX_SERVER_URL || 'http://localhost:5000';

// initialize an empty api service that we'll inject endpoints into later as needed
export const onyxApi = createApi({
  reducerPath: 'onyxApi',
  baseQuery: fetchBaseQuery({ baseUrl: ONYX_SERVER_URL }),
  endpoints: (builder) => ({
    checkHealth: builder.query<THeathResponse, void>({
      query: () => '/health',
    }),
    submitFiles: builder.mutation<{ message: string }, TUploadToDesignPortalPayload>({
      query: (payload) => ({
        url: '/submit',
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
  }),
});

export const { useCheckHealthQuery, useSubmitFilesMutation } = onyxApi;
