import { IDentist } from '../patient-proposal-api/types';
import { IAddress } from 'components/address-field/address-field';
import type { IInstruction } from '../specialist-api/types';
import { ITreatmentDesign, TREATMENT_DESIGN_STATUS } from '../treatment-designs-api/types';
import { SUBMISSION_SERVICE_TYPE, SUBMISSION_STATUS_TYPE } from 'config/constants';
import { SUBMISSION_PLAN_TYPE } from 'modules/gdp/case-submission/utils';
import { IUser } from 'modules/auth/services/type';
import {
  StlIssueReportAdminStatuses,
  StlIssueReportCategories,
  StlIssueReportDentistStatuses,
} from 'services/stl-rejection-api/types';
import { IPersonalInfoSheet, ISmileQuestionnaire } from 'services/interested-patients-api/types';
import {
  ICompletedTreatmentCaseStudy,
  ICompletedTreatmentHowItWent,
  ICompletedTreatmentRecap,
} from 'services/completed-treatment-api/types';
import { Area } from 'react-easy-crop';
import { EConsentToShareImages } from 'modules/gdp/patients/patient-details/completed-treatment/utils';

export interface IClinic {
  id: string;
  name: string;
  address: IAddress;
  email: string;
  isScannerAvailable: boolean;
  scanners: string[];
  phone: TPhone;
  defaultPhone?: TPhone;
  defaultEmail?: string;
}

export interface IMobile {
  ext: string;
  number: string;
}

export interface IPatient {
  fullName: string;
  firstName: string;
  lastName: string;
  phone: TPhone;
  profilePic: string;
  email: string;
  dob: string;
  id: string;
  createdAt?: string;
  updatedAt?: string;
  patientId?: string; // readable
  practice: IClinic;
  submission?: ISubmission;
  dentist?: IDentist;
  // Interested patient
  smileQuestionnaire?: ISmileQuestionnaire;
  personalInformationSheet?: IPersonalInfoSheet;
  isArchived?: boolean;
  archiveReason?: string;
  lastPreferredPreferences?: string[];
  dentistProfilePic?: string;
  createdBy?: {
    profilePic?: string;
    fullName?: string;
  };
  isViewedByDentist?: boolean;
  isFromSQLink?: boolean;
  // Completed treatment
  refinementsAvailable?: number;
  sku?: string;
  anonymisedInfo?: boolean;
  completedTreatmentStatus?: string;
  treatmentJourney?: ICompletedTreatmentHowItWent;
  treatmentRecap?: ICompletedTreatmentRecap;
  caseStudy?: ICompletedTreatmentCaseStudy;
  isSkippedRecap?: boolean;
  isSkippedCaseStudy?: boolean;
  isCompletedTreatmentStarted?: boolean;
  isCompletedTreatmentEnded?: boolean;
  consentToShareImages?: EConsentToShareImages;
  isConsentProvidedByDentist?: boolean;
  completedTreatmentEndedAt?: string;
  totalTreatmentChecking?: number;
  version?: number;
}

export interface ICropConfig {
  cropArea?: Area;
  rotation: number;
  aspect: number;
  zoom: number;
  originalUrl: string;
}

export interface IImage {
  url: string;
  type: string;
  name: string;
  isArchived?: boolean;
  status?: string; // client key
  uid?: string; // client key
  createdAt?: string;
  isDentistDownloadable?: boolean;
  percent?: number;
  size?: number;
  tag?: string;
  isRemoved?: boolean;
  cropConfigs?: ICropConfig;
}

// TODO: apply emun name convention for all
export enum DENTIST_SUBMISSION_STATUS {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  APPROVED = 'APPROVED',
  IN_TREATMENT = 'IN_TREATMENT',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  ARCHIVED = 'ARCHIVED',
}

export enum DESIGNER_SUBMISSION_STATUS {
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  SUBMIT_DESIGN = 'SUBMIT_DESIGN',
  AWAITING_DESIGN_APPROVAL = 'AWAITING_DESIGN_APPROVAL',
  REVISION_REQUESTED = 'REVISION_REQUESTED',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED',
}

export enum TECHNICIAN_SUBMISSION_STATUS {
  VERIFY_STL_FILES = 'VERIFY_STL_FILES',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMIT_STL_FILES = 'SUBMIT_STL_FILES',
  COMPLETED = 'COMPLETED',
  ON_HOLD = 'ON_HOLD',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED',
  NOT_SUITABLE = 'NOT_SUITABLE',
}

export enum MANUFACTURER_SUBMISSION_STATUS {
  NEW_ORDER = 'NEW_ORDER',
  IN_PROGRESS = 'IN_PROGRESS',
  SHIPPED = 'SHIPPED',
}

export enum SPECIALIST_SUBMISSION_STATUS {
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  SUBMIT_INSTRUCTION = 'SUBMIT_INSTRUCTION',
  ON_HOLD = 'ON_HOLD',
  NOT_SUITABLE = 'NOT_SUITABLE',
  AWAITING_DESIGN = 'AWAITING_DESIGN',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
  NOT_SUITABLE_PENDING = 'NOT_SUITABLE_PENDING',
}

export type TRejectedReasons = {
  commentsToPatient?: string;
  suggestedNextSteps: string;
  complexity?: string;
  patientGoesAhead?: string;
  implicationsForPatient?: string;
  orthoSuggestions?: string;
  createdAt: string;
  id: string;
};

export type TRejectedReasonsExtended = TRejectedReasons & {
  adminRemarks?: TRejectedReasons;
};

interface IDiagnosis {
  arches?: string;
  upperDentalCenterLineOfFace?: string;
  phasingPreference?: string;
  phasingPreferenceDetails?: string;
}

export enum DelayTrackingStatuses {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export interface ISubmission extends Omit<IInstruction, 'status' | 'case'> {
  // initial keys
  id: string;
  submissionId: string;
  serviceType: SUBMISSION_SERVICE_TYPE;
  planType: SUBMISSION_PLAN_TYPE;
  createdAt: string;
  submittedAt: string;
  assignedAt: string;
  updatedAt: string;
  createdBy?: IDentist;
  specialist: Partial<IUser>;
  autoSavedAt?: string;

  // form keys
  patient: IPatient;
  practice: IClinic;
  txnObjective: string[];
  dentalAndMedicalFit: boolean;
  diagnosis: IDiagnosis;
  intraOralImages: IImage[];
  extraOralImages: IImage[];
  radioGraphImgs: IImage[];
  imprImages: IImage[];
  impressionType: string;
  impressionScannerType?: string;
  stlUploadType?: string;
  buttonCutOuts: string[];
  refinementInfo: IRefinementInfo;
  priorOrthodontics: boolean;
  priorOrthoDetails: string;
  relevantMedicalSocialAndDrugHistory: string;
  patientOccupation: string;
  toothSurfaceLoss: string;
  oralHealthPathology: string;
  gingivalBiotype: string;
  widerTreatmentPlan: string[];
  patientSuitability: boolean;
  statedTimings?: string;
  BPE: string;
  postageDate?: string;
  activeStep: number;
  crossBite: string[];
  patientAcceptableInterventions: {
    IPR: boolean;
    attachments: string;
    auxiliaries: boolean;
    extraction: boolean;
  };
  materialType?: string;
  finishOptionText?: string;
  anonymisedInfo?: boolean;
  animationUrl?: string;
  outboundInvoiceDate?: string;
  statedTimingsNotes?: string;
  TMJ?: boolean;
  paraFuctionalActivity?: boolean;
  skeletalPatternAntero?: string;
  skeletalPatternVertical?: string;
  skeletalPatternTransverse?: string;
  crowdingInUpperArch?: string;
  crowdingInLowerArch?: string;
  rotation?: string[];
  overjetIncisorRelationship?: string;
  overjetInMM?: { lower: number; upper: number };
  overjetOverbiteOrAnterior?: string;
  lowerDentalCenterLineOfFace?: string;
  leftMolarRelations?: string;
  rightMolarRelations?: string;
  notesToSelf?: string;
  radiographQuery?: boolean;
  radiographQueryDetails?: string;
  crownsOrVeneers?: string[];
  orthodonticAssessmentNow?: boolean;
  specialistPrioritiseDetail?: string;
  goodOutComes?: string;
  specialistDePrioritiseDetail?: string;
  submissionPercentage?: number;

  // timeline keys
  status: SUBMISSION_STATUS_TYPE;
  designerStatus: DESIGNER_SUBMISSION_STATUS;
  dentistStatus: DENTIST_SUBMISSION_STATUS;
  specialistStatus: SPECIALIST_SUBMISSION_STATUS;
  manufacturerStatus: MANUFACTURER_SUBMISSION_STATUS;
  technicianStatus: TECHNICIAN_SUBMISSION_STATUS;
  designsSubmitted: number;
  archiveReason?: string;
  expiredInDays?: number;
  onHold?: {
    autoUnHoldAt: string | null;
    onHoldAt: string;
    reason: string;
    resolutionTime?: string;
  };
  designerProposalStatus: TREATMENT_DESIGN_STATUS;
  dueAt?: string;
  isSubmissionApproved: boolean;
  refinementNumber: number;
  expectedDeliveryDate: string;
  treatmentDesign?: ITreatmentDesign;
  order: any;
  delay?: {
    status: DelayTrackingStatuses;
    dueAt: string;
  };
  isTreatmentCheckinActive: boolean;
  proposals?: any;
  manufacturerShippingLink: string;
  instruction: IInstruction;
  rejectedReason: TRejectedReasonsExtended;
  hasStlIssues?: boolean;
  stlIssuesReport: {
    id: string;
    category: StlIssueReportCategories;
    dentistStatus: StlIssueReportDentistStatuses;
    adminStatus: StlIssueReportAdminStatuses;
  };
  estimatedDesignDate?: string;
  eiddLessThenSeven?: boolean; // for manufacture
  shippingLink?: string;
  hasStlUploaded?: boolean; // for technician check if stl uploaded
  haveDraftReplacement?: boolean;
  approveTreatmentDesignId?: string; // for technician only, in retainer.upperArchSubmission response
  patientStatus?: DENTIST_SUBMISSION_STATUS; // the latest status of submissions from a patient
}

export interface IRefinementInfo {
  arches: string;
  stepReached: string;
  stepReachedComments: string;
  treatmentBackground: string;
  treatmentPlanAdditionalComments: string;
}

export interface IPatientsResponse {
  docs: ISubmission[];
  total: number;
}
export interface IPatientsRequest {
  page: number;
  perPage: number;
  status?: string;
  q?: string;
  type?: string;
  order?: 'asc' | 'desc';
  sort?: string;
  dentist?: string;
  planType?: string;
}

export interface IPatientJourneyResponse {
  DRAFT: ISubmission[];
  SUBMITTED: ISubmission[];
  REVIEW_DESIGN: ISubmission[];
  APPROVED: ISubmission[];
  IN_TREATMENT: ISubmission[];
  COMPLETED: IPatient[];
  INTERESTED_PATIENT: IPatient[];
}

export type TVerifyPatientTokenResponse = {
  id: string;
  patient: {
    id: string;
    fullName: string;
  };
};

export interface IArchivePatientRequest {
  reason: string;
  id: string;
}

interface IPatientCheckings {
  startDate: string;
  nextRunAt: string;
  lastCheckinDate: string;
  cancelledAt: string;
  currentCheckinNumber: number;
  Interval: number;
  checkInCount: number;
  remainingCheckInCount: number;
  patient: IPatient;
  order: unknown;
  submission: ISubmission;
  paymentStatus: string;
  email: string;
  dentist: IDentist;
  notificationType: string;
  status: 'ACTIVE' | 'CANCELED' | 'COMPLETED';
}
export interface IGetPatientCheckingsResponse {
  docs: IPatientCheckings[];
  total: number;
}

export interface ITreatmentDesignCountResponse {
  count: number;
}

export interface IDentistPatientSubmissionForm {
  checkInCount: number;
  confirmConsent: boolean;
  email: string;
  interval: number;
  notificationType: string;
  phone: IMobile;
  startDate: string;
  submission: string;
  notificationTypeForDentist: {
    patientCheckInMissed: string[];
    patientCheckInSubmitted: string[];
    patientCheckInConcernRaised: string[];
  };
  additionalEmail?: string;
}

export interface IPatientSubmission {
  id: string;
  checkInNumber: string;
  alignerStage: number;
  havingConcerns: boolean;
  concerns: string;
  createdAt: string;
  // checkingHistory: string;
  wearingDuration: string;
  images: IImage[];
}

export enum CheckingHistoryStatuses {
  EXPIRED = 'EXPIRED',
  NOT_VIEWED = 'NOT_VIEWED',
  PENDING = 'PENDING',
  VIEWED = 'VIEWED',
}

export interface ICheckingHistory {
  checkInNumber: number;
  id: string;
  createdAt: string;
  patientChecking: string;
  patientSubmission: IPatientSubmission;
  status: CheckingHistoryStatuses;
  statusText: string;
  updatedAt: string;
}

export interface IDentistPatientSubmission {
  id: string;
  status: 'ACTIVE' | 'CANCELED' | 'COMPLETED';
  checkingHistories: ICheckingHistory[];
}

export enum OrderTypes {
  DEFAULT = 'DEFAULT',
  EXPIRED = 'EXPIRED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  REPLACEMENT = 'REPLACEMENT',
  REPORT = 'REPORT',
  RETAINER = 'RETAINER',
}

export enum EPatientProposalStatuses {
  DRAFT = 'DRAFT',
  BOOKED = 'BOOKED',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface IDentistRelationResponse {
  orderDetail: {
    orderedClearAlignerDetail: {
      havingAttachment: boolean;
      havingElastic: boolean;
      havingIpr: boolean;
    };
  };
  togetherSubmissions: number;
  isFirstSubmission: boolean;
  firstSubmissionId: string;
}

export interface IDentistRelationPatientListPayload {
  submission: string;
  page: number;
  perPage: number;
  q?: string;
  order?: string;
}

export interface IDentistRelationPatientListResponse {
  total: number;
  docs: ISubmission[];
}

export interface IUpdateSubmissionResponse {
  message: string;
  submission: Omit<ISubmission, 'patient'> & { patient: string };
  popupMessages: [string, string];
}

export type TSubmissionByPatientResponse = Pick<
  ISubmission,
  'id' | 'planType' | 'serviceType' | 'status' | 'refinementNumber'
>;

export type TPreviousIprImagesResponse = {
  submission: string;
  planType: SUBMISSION_PLAN_TYPE;
  refinementNumber: string;
  treatmentDesigns: {
    iprImages: IImage[];
  }[];
}[];

export type TSharedLinkDentistResponse = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  profilePic: string;
  phone: TPhone;
};

export type TUpdateSharedLinkPatientSQ = {
  rating: number;
  annoyIssues: string[];
  consideredBefore: string;
  newChange: string[];
  newConcern: string[];
  newEventNote: string[];
  attachments?: IImage[];
  contactPreference: string;

  fistName: string;
  lastName: string;
  email: string;
  phone: TPhone;
  sqLinkId: string;
};
