import { FC, useRef } from 'react';
import { Empty, Spin } from 'antd';
import { NotificationItem } from './notification-item/notification-item';
import { useNotificationsContainer } from './hooks/use-notifications-container';
import _ from 'lodash';
import { TNotisType } from 'redux/notificationsSlice';
import { useMarkNotSuitableSubmissionAsRead } from './hooks/use-mark-not-suitable-submission-as-read';

export const UNREAD_NOTIS_CONTAINER_ID = 'UNREAD_NOTIS_CONTAINER_ID';

type TProps = {
  type: TNotisType;
};

export const NotificationsContainer: FC<TProps> = ({ type }) => {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const { isLoading, notifications, loadMoreNotification, markNotificationsAs } = useNotificationsContainer({ type });

  useMarkNotSuitableSubmissionAsRead({
    unreadNotifications: type === 'unread' ? notifications : undefined,
    markNotificationsAs,
  });

  const handleScroll = _.throttle((_e) => {
    if (!listInnerRef?.current) return;

    const { scrollHeight, scrollTop, clientHeight } = listInnerRef.current;
    const scrollBottom = scrollHeight - scrollTop - clientHeight;
    if (scrollBottom < 250) {
      loadMoreNotification();
    }
  }, 500);

  return (
    <div
      id={type === 'unread' ? UNREAD_NOTIS_CONTAINER_ID : ''}
      onWheel={handleScroll}
      ref={listInnerRef}
      style={{
        height: 'calc(100vh - 183px)',
        overflowY: 'scroll',
        marginTop: '112px',
      }}
    >
      {notifications && notifications.length === 0 ? (
        <Empty description="No notifications to show" />
      ) : (
        <>
          {(notifications || []).map((n: any) => (
            <NotificationItem key={n.id} notification={n} markNotificationsAs={markNotificationsAs} />
          ))}
          {isLoading && (
            <div className="flex items-center justify-center" style={{ height: '100px' }}>
              <Spin tip="Loading notifications..." />
            </div>
          )}
        </>
      )}
    </div>
  );
};
