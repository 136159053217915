import { Spin } from 'antd';
import { ZINDEX } from 'utils/ui';

const PageOverlayLoader = ({ loading }: { loading: boolean }) => {
  if (!loading) return null;

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 h-screen w-screen"
      style={{ zIndex: ZINDEX.PAGE_OVERLAY_LOADER }}
    >
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="overlay absolute h-screen w-screen" style={{ background: '#000', opacity: 0.3 }} />
        <Spin size="large" className="z-20" />
      </div>
    </div>
  );
};

export default PageOverlayLoader;
