import './preview-image-group.less';
import { Image, Col, Progress, Tooltip, Button } from 'antd';
import { EyeOutlined, CloseOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { IImage } from 'services/patients-api/types';
import placeholderPng from 'assets/images/placeholder.png';
import { PopoverDelete } from 'components/popovers/popover-delete';

interface IProps {
  images: IImage[];
  deleteImage?: Function;
  name: string;
  perRow?: number;
}

export const PreviewImageGroup: FC<IProps> = ({ images, deleteImage, name, perRow = 2 }) => {
  const [currentPreview, setCurrentPreviewImage]: any = useState(0);
  const [visible, setShowPreviewImage] = useState(false);

  const previewImage = (index: number) => {
    setCurrentPreviewImage(index);
    setShowPreviewImage(true);
  };

  return (
    <>
      {images?.map(({ url, status, uid }, index: number) => (
        <Col className="gutter-row" span={24 / perRow} key={url || uid}>
          <div className="shadow-md">
            {!!deleteImage && (
              <PopoverDelete onDelete={() => deleteImage?.(url, name)}>
                <Tooltip title="Delete">
                  <Button
                    shape="circle"
                    icon={<CloseOutlined className=" text-grey" />}
                    size="small"
                    className="absolute -right-[3px] -top-3 z-10 opacity-70"
                  />
                </Tooltip>
              </PopoverDelete>
            )}
            {status && status !== 'done' ? (
              <div
                style={{
                  backgroundImage: `url(${placeholderPng})`,
                }}
                className="mb-2 min-h-[100px] rounded bg-cover bg-center"
              >
                {/* TODO: <Progress percent={Math.floor(percent || 0)} size="small" className="custom-progress mt-10 p-1" /> */}
                <Progress percent={Math.floor(0)} size="small" className="custom-progress mt-10 p-1" />
              </div>
            ) : (
              <div className="small-image-container mb-2 min-h-[100px] w-full cursor-pointer">
                <img id={url} src={url} className="h-full" alt="" crossOrigin="anonymous" />
                <div className="overlay h-full w-full">
                  <div className="image-backdrop" />
                  <div className="image-preview-btn flex h-full w-full items-center justify-center">
                    <Tooltip title="Preview">
                      <EyeOutlined className="text-lg text-lightGrey" onClick={() => previewImage(index)} />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      ))}
      <div style={{ display: 'none' }}>
        {visible && (
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setShowPreviewImage(vis), current: currentPreview }}
          >
            {images.map(({ url }: any) => (
              <Image key={url} src={url} />
            ))}
          </Image.PreviewGroup>
        )}
      </div>
    </>
  );
};
