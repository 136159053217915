import { useEffect } from 'react';
import { DashboardLayout } from 'components/layout/dashboard-layout/dashboard-layout';
import { useLastActive } from 'hooks/useLastActive';
import { gdpRoutes } from './gdp-routes';
import { useFetchProfile } from 'hooks/useFetchProfile';

const GDPModule = () => {
  const { fetchProfile } = useFetchProfile();
  useLastActive();

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return <DashboardLayout routes={gdpRoutes} />;
};

export default GDPModule;
