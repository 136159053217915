import { api, VERSION } from '../api';
import { IRelease } from './types';

export const releaseNotesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLatestReleaseNotes: build.query<IRelease, void>({
      query: () => `/${VERSION}/user/releases/latest`,
    }),
  }),
});

export const { useGetLatestReleaseNotesQuery } = releaseNotesApi;
