import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Checkbox, Form, Spin, Typography } from 'antd';
import { useState } from 'react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { IDiscount, ISkuType } from 'services/sku-api/types';
import logo from 'assets/images/clear_aligner_logo.png';
import { TGetOrderSummaryResponse } from 'services/order-api/types';
import { useGetOrderSummaryMutation } from 'services/order-api/endpoints';
import { SUBMISSION_PLAN_TYPE } from 'modules/gdp/case-submission/utils';
import { notificationApiError } from 'utils/ui';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { REWARDS_AVAILABLE_INITIAL_SUBMISSION } from 'modules/gdp/constants';
import moment from 'moment';
import { showModal } from 'utils';
import { useCurrency } from 'hooks/useCurrency';

type TProps = {
  selectedSku: ISkuType;
  initOrderSummary: TGetOrderSummaryResponse | undefined;
  planType?: SUBMISSION_PLAN_TYPE;
  submissionId?: string;
  treatmentDesignId?: string;
  initialAgreeTC?: boolean;
};

const AddToBasketModal = NiceModal.create<TModalProps<TProps>>(
  ({ onCancel, selectedSku, initOrderSummary, submissionId, treatmentDesignId, initialAgreeTC }) => {
    const modal = useModal();
    const [getOrderSummary, { isLoading: isOrderSummaryLoading }] = useGetOrderSummaryMutation();
    const [basketForm] = Form.useForm();
    const [orderSummaryDetails, setOrderSummaryDetails] = useState<TGetOrderSummaryResponse | undefined>(
      initOrderSummary,
    );
    const { moneyFormat, getDiscountDisplay } = useCurrency();

    const applyOffer = async (offersData: IDiscount) => {
      const reqData = {
        submission: submissionId,
        discount: offersData.id,
        sku: selectedSku?.id,
        treatmentDesign: treatmentDesignId,
      };

      try {
        const getOfferDetails = await getOrderSummary(reqData).unwrap();
        setOrderSummaryDetails(getOfferDetails);
      } catch (error) {
        notificationApiError(error);
      }
    };

    const handleAddToBasket = () => {
      modal.resolve(orderSummaryDetails);
      modal.hide();
    };

    const handleCancel = (e: any) => {
      onCancel?.(e);
      modal.reject();
    };

    const renderPrice = () => {
      let price = selectedSku?.price?.toFixed(2);
      if (orderSummaryDetails) {
        price = Math.max(orderSummaryDetails!.total - (orderSummaryDetails?.discountAmount || 0), 0).toFixed(2);
      }
      return (
        <div>
          {/* check min?? */}
          {orderSummaryDetails && (
            <Typography.Title level={5} className="m-0 text-sm font-medium text-gray-500 line-through">
              {moneyFormat(orderSummaryDetails.total, { fractionDigits: 2 })}
            </Typography.Title>
          )}
          <Typography.Title level={5} className="!m-0">
            {moneyFormat(price)}
          </Typography.Title>
        </div>
      );
    };

    return (
      <NiceAntModal modal={modal} width={800} onCancel={handleCancel}>
        {isOrderSummaryLoading && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center rounded-[30px] bg-white bg-opacity-50">
            <Spin className="text-grey" size="large" tip="Loading..." spinning={isOrderSummaryLoading} />
          </div>
        )}
        <div>
          <div className="mb-2 flex pb-2">
            <div className="radio-btn">
              <div className="radio-btn-dot" />
            </div>
            <div className="text-sm">
              <strong>{selectedSku?.name}</strong>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 border-0 border-t border-solid border-gray-100 pt-1">
            <div
              className="col-span-12 flex flex-col justify-between border-0 border-r border-solid pt-3 md:col-span-6"
              style={{ borderRightColor: '#d9d9d9' }}
            >
              <div className="mx-5">
                <Typography.Text className="font-xs">Brand</Typography.Text>
                <div className="mt-1">
                  <img src={selectedSku?.logo?.url || logo} alt="32Co logo" style={{ maxHeight: 28 }} />
                </div>
                <div className="clear-aligner-modal-bg" />
                <div className="flex flex-col">
                  <span className="text-sm font-medium">Price</span>
                  <div className="text-2xl font-bold">{moneyFormat(selectedSku?.price, { fractionDigits: 2 })}</div>
                </div>
              </div>
              <div className="specifications-list mr-6 mt-2 pb-2 pl-6 pt-3">
                <div className="sticky">
                  <Typography.Title level={5}>Specifications</Typography.Title>
                </div>
                <div className="specifications-description">
                  <ul className="p-0 pt-1">
                    {selectedSku?.description &&
                      selectedSku?.description.split('/').map((element: any) => (
                        <li key={element} className="mb-1 text-sm font-medium">
                          {element}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-12 mx-2 flex flex-col pt-3 md:col-span-6">
              <div className="flex-1">
                <div className="sticky">
                  <Typography.Title level={5}>Rewards Available</Typography.Title>
                </div>
                <div className="h-auto overflow-auto md:h-[340px]">
                  {selectedSku?.discounts?.length ? (
                    selectedSku?.discounts?.map((offers) => (
                      <div key={offers.id}>
                        <div className="flex flex-row">
                          <div className="flex cursor-pointer">
                            <input
                              className="mt-4 cursor-pointer"
                              type="radio"
                              name="skus"
                              checked={offers.id === orderSummaryDetails?.discount?.id}
                              onChange={() => applyOffer(offers)}
                              style={{
                                height: 20,
                                width: 20,
                                verticalAlign: 'middle',
                                accentColor: '#000',
                              }}
                            />
                          </div>
                          <div className="my-2 flex cursor-pointer">
                            <div className="flex flex-col">
                              <div className="flex flex-row">
                                <div className="offer-badge ml-2 w-56 rounded-standard px-4 py-2 text-center !text-base font-bold">
                                  {offers.externalDiscountCode}
                                </div>
                                <div className="ml-4 flex items-center text-base font-bold">
                                  {getDiscountDisplay(offers?.discountType, offers?.discountValue)} off
                                </div>
                              </div>
                              <div className="ml-2 mt-2 text-xs font-medium">{offers?.externalDiscountDescription}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="m-auto flex h-full w-2/3 items-center py-4 text-center">
                      {REWARDS_AVAILABLE_INITIAL_SUBMISSION}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <div className="flex items-end justify-between bg-btnSecondaryColor p-3">
                  <Typography.Title level={5} className="!m-0">
                    Total Price:
                  </Typography.Title>
                  {renderPrice()}
                </div>
                {!!selectedSku.estDelivery && (
                  <div className="flex items-end justify-between  p-3">
                    <Typography.Text className="!m-0">Estimated Delivery date:</Typography.Text>
                    <Typography.Text className="!m-0">
                      {moment(selectedSku.estDelivery).format('Do MMM')}
                    </Typography.Text>
                  </div>
                )}
                <div className="mt-3">
                  <Form
                    form={basketForm}
                    onFinish={handleAddToBasket}
                    initialValues={{ isAgreeTermCondition: initialAgreeTC }}
                  >
                    <Button
                      key="back"
                      type="primary"
                      shape="round"
                      block
                      size="large"
                      className="btn-primary-redesigned text-white"
                      style={{
                        pointerEvents: 'initial',
                      }}
                      htmlType="submit"
                      id="add-to-basket"
                    >
                      Add to basket
                    </Button>
                    <FormItemControl
                      name="isAgreeTermCondition"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Please confirm the T&Cs')),
                        },
                      ]}
                    >
                      <Checkbox className="mt-3">
                        <a
                          style={{
                            color: '#808080',
                            textDecoration: 'underline',
                          }}
                          href="https://www.32co.com/dentist/legal/terms-and-conditions"
                          target="_blank"
                          rel="noreferrer"
                        >
                          I have read and accept the terms and conditions*
                        </a>
                      </Checkbox>
                    </FormItemControl>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NiceAntModal>
    );
  },
);

export const showAddToBasketModal = showModal<TGetOrderSummaryResponse | undefined, TProps>(AddToBasketModal);
