import { SorterResult } from 'antd/lib/table/interface';
import { isArray } from 'lodash';

export const ALL_MEAN_SKIP_FILTER = 'ALL_MEAN_SKIP_FILTER';

export const DEFAULT_PAGINATION_CONFIG = {
  pageSizeOptions: ['10', '20'],
  position: ['bottomRight'] as any,
  showSizeChanger: true,
  showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
};

export type TSortOrderParam = 'asc' | 'desc' | '';
export type TSorterParams = { sort: string; order: TSortOrderParam };

export const getSorterParams = (sorterAntd: SorterResult<any>): TSorterParams => {
  const sortField: string = isArray(sorterAntd.field) ? sorterAntd.field.join(',') : (sorterAntd.field as string);
  return {
    sort: sorterAntd.order && sortField ? sortField : '',
    order: sorterAntd.order === 'descend' ? 'desc' : sorterAntd.order === 'ascend' ? 'asc' : '',
  };
};

// TODO: will use . instead of , here:
export const getFiltersParam = (filters: any) =>
  Object.entries<string[] | undefined>(filters).reduce<Record<string, string>>((acc, [key, value]) => {
    const convertedKey = key.split('.').join(','); // for ex: from patient.fullName to patient,fullName
    delete acc[key];
    acc[convertedKey] = value?.join(',') || '';

    return acc;
  }, {} as Record<string, string>);
