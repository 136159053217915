import './thank-you-specialist-modal.less';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Typography, Rate, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory } from 'react-router-dom';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { useAuth } from 'hooks/useAuth';
import { useSendFeedbackMutation } from 'services/order-api/endpoints';
import { notificationApiError } from 'utils/ui';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import confettiIcon from 'assets/images/confetti-icon.svg';
import ReactConfetti from 'react-confetti';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppSelector } from 'hooks/store';
import { selectChristmasEnabled } from 'redux/themeSlice';
import { NiceParticles } from 'components/nice-particles/nice-particles';
import { EParticlesType } from 'components/nice-particles/particles-options';

const QUESTIONS = [
  { key: 'overallExperience', question: 'How was your overall experience?' },
  { key: 'specialistExperience', question: 'How was working with your specialist?' },
  { key: 'speedOfService', question: 'How was the speed of our service so far?' },
];

const MODAL_WIDTH = 600;

interface IFeedbackForm {
  thankYouMessage?: string;
  message?: string;
  overallExperience?: number;
  speedOfService?: number;
  specialistExperience?: number;
}

interface IProps {
  submissionId: string;
  estDelivery?: string;
}

export const ThankYouSpecialistModal = NiceModal.create((routerParams: IProps) => {
  const history = useHistory();
  const { user } = useAuth();

  const [form] = Form.useForm<IFeedbackForm>();
  const [sendFeedback, { isLoading: isSendingFeedback }] = useSendFeedbackMutation();
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [isShowingThankYouForFeedback, setIsShowingThankYouForFeedback] = useState(false);
  const modal = useModal();
  const isChristmasEnabled = useAppSelector(selectChristmasEnabled);

  const onFeedback = async (feedback: IFeedbackForm) => {
    try {
      const reqData = {
        submission: routerParams.submissionId,
        ...feedback,
      };
      await sendFeedback(reqData).unwrap();
    } catch (error: any) {
      notificationApiError(error);
    }
  };

  const navigateToOrderSummary = () => {
    history.push(`/${user?.type}/patients/${routerParams.submissionId}?activeTab=order-summary`);
    modal.hide();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isShowingThankYouForFeedback) {
      timer = setTimeout(() => navigateToOrderSummary(), 2000);
    }
    return () => clearTimeout(timer);
  }, [isShowingThankYouForFeedback]);

  const onSubmit = async () => {
    const values = form.getFieldsValue();

    if (
      values.message ||
      values.thankYouMessage ||
      values.overallExperience !== undefined ||
      values.speedOfService !== undefined ||
      values.specialistExperience !== undefined
    ) {
      onFeedback(values);
    }
    // Show thank you for feedback 2 seconds
    setIsShowingThankYouForFeedback(true);
  };

  const onValuesChange = (changedValues: any) => {
    if (Object.values(changedValues).some((value) => value)) {
      setIsDisabledSubmit(false);
    } else {
      setIsDisabledSubmit(true);
    }
  };

  return (
    <NiceAntModal modal={modal} width={MODAL_WIDTH} onCancel={navigateToOrderSummary}>
      {isShowingThankYouForFeedback ? (
        <div className="flex flex-col items-center">
          <img src={confettiIcon} height={80} alt="" className="mb-5" />
          <div className="text-2xl font-bold text-darkBlueColor">Thank you for your feedback!</div>
        </div>
      ) : (
        <Form form={form} onValuesChange={onValuesChange}>
          {isChristmasEnabled ? (
            <div className="absolute top-0 h-full">
              <NiceParticles id="tsparticles-confetti" optionType={EParticlesType.christmasConfetti} />
            </div>
          ) : (
            <ReactConfetti run width={MODAL_WIDTH} tweenDuration={1000} recycle={false} />
          )}

          <div className="flex flex-col">
            <div className="flex flex-col items-center self-center">
              <img src={confettiIcon} height={80} alt="" className="mb-5" />
              <div className="text-3xl font-bold text-darkBlueColor">Your case has been ordered!</div>
              <div className="mt-4 text-sm font-bold text-darkGrey">{`It should be with you ${
                routerParams.estDelivery ? `by ${moment(routerParams.estDelivery).format('Do MMM')}` : 'in 10-12 days'
              }`}</div>
              <div className="mt-6 font-bold text-darkGrey">
                Would you like to send a personal thank you to your specialist?
              </div>
            </div>
            <Form.Item name="thankYouMessage" className="mt-4">
              <TextArea
                className="shadow-custom2"
                placeholder="Write your message here. The specialist will see that it has come from you."
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
            <div className="mt-4 font-bold text-darkGrey">
              How was your experience? Share your anonymous feedback and help us improve
            </div>
            <div className="rating-question mt-6">
              {QUESTIONS.map((question) => (
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  key={question.key}
                  label={question.question}
                  name={question.key}
                  className="text-left md:text-right"
                >
                  <Rate />
                </Form.Item>
              ))}
            </div>
            <Typography.Text>What could we do to make your next experience better?</Typography.Text>
            <Form.Item name="message" className="mt-4">
              <TextArea
                className="shadow-custom2"
                autoSize={{ minRows: 2, maxRows: 3 }}
                placeholder="Write your feedback here. It can be anything you fancy - we’d love to know!"
              />
            </Form.Item>
            <Form.Item shouldUpdate className="submit" noStyle>
              {() => (
                <NiceButton
                  niceType="orange"
                  loading={isSendingFeedback}
                  disabled={isDisabledSubmit || isSendingFeedback}
                  className="btn-primary-redesigned w-32 self-end"
                  shape="round"
                  onClick={onSubmit}
                  size="small"
                >
                  Send
                </NiceButton>
              )}
            </Form.Item>
          </div>
        </Form>
      )}
    </NiceAntModal>
  );
});
