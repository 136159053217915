import { IRoute } from 'interface/IRoute';
import { DentistSignup } from './dentist-signup/dentist-signup';
import DesignerSignupPage from './designer-signup/designer.component';
import ManufacturerSignupPage from './manufacturer-signup/manufacturer.component';
import ResetPassword from './reset-password/reset-password.component';
import Signin from './signin/signin';
import SpecialistSignupPage from './specialist-signup/specialist.component';
import ForgotPassword from './forgot-password/forgot-password.component';
import PasswordRecovered from './password-recovered/password-recovered.component';
import { Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

const authRoutes: IRoute[] = [
  {
    path: '/signin',
    exact: true,
    name: 'Signin Page',
    key: 'signin_page',
    component: () => <Signin />,
  },
  {
    path: '/signup/:userType?',
    exact: true,
    name: 'Signun Page',
    key: 'signup_page',
    component: (params: RouteComponentProps<{ userType: string }>) => {
      const { userType } = params.match.params;
      if (userType) {
        return <Redirect to={`/joinus/${params.match.params.userType}`} />;
      }
      return <Redirect to="/joinus" />;
    },
  },
  {
    path: '/joinus',
    exact: true,
    name: 'Signup Page',
    key: 'joinus_page',
    component: () => <DentistSignup />,
  },
  {
    path: '/joinus/designer',
    exact: true,
    name: 'Designer Signup Page',
    key: 'designer_joinus_page',
    component: () => <DesignerSignupPage />,
  },
  {
    path: '/joinus/specialist',
    exact: true,
    name: 'Specialist Signup Page',
    key: 'specialist_joinus_page',
    component: () => <SpecialistSignupPage />,
  },
  {
    path: '/joinus/manufacturer',
    exact: true,
    name: 'Manufacturer Signup Page',
    key: 'manufacturer_joinus_page',
    component: () => <ManufacturerSignupPage />,
  },
  {
    path: '/forgot-password',
    exact: true,
    name: 'Forgot Page',
    key: 'forgot_page',
    component: () => <ForgotPassword />,
  },
  {
    path: '/password-recovered',
    exact: true,
    name: 'Password Recovered',
    key: 'password_recovered',
    component: () => <PasswordRecovered />,
  },
  {
    path: '/reset-password',
    exact: true,
    name: 'Reset Page',
    key: 'reset_page',
    component: () => <ResetPassword />,
  },
];

// import IRoute from "../interfaces/route"
// import {SigninPage, SignupPage, ForgotPasswordPage, ResetPasswordPage, DesignLabSignupPage, ManufacturerSignupPage, SpecialistSignupPage } from "../pages/auth";

export default authRoutes;
