import { useEffect, useState } from 'react';

const COLLAPSE_SIDEBAR_SIZE = '100px';
const EXPAND_SIDEBAR_SIZE = '200px';

let isCollapsed = !!localStorage.getItem('collapseMenu');
let observers: React.Dispatch<React.SetStateAction<boolean>>[] = [];

// set isCollapsed and notify observers
export const setIsCollapsed = (status: boolean) => {
  isCollapsed = status;
  observers.forEach((update) => update(isCollapsed));
};

export const useSideBar = () => {
  const [isCollapsedState, setCollapsedState] = useState<boolean>(isCollapsed);

  useEffect(() => {
    // add observer
    observers.push(setCollapsedState);

    setCollapsedState(isCollapsed);
    return () => {
      // remove observer when unmount
      observers = observers.filter((update) => update !== setCollapsedState);
    };
  }, []);

  const toggleSideBar = () => {
    localStorage.setItem('collapseMenu', !isCollapsedState ? 'true' : '');
    setIsCollapsed(!isCollapsedState);
  };

  const sideBarSize = isCollapsedState ? COLLAPSE_SIDEBAR_SIZE : EXPAND_SIDEBAR_SIZE;

  return {
    isCollapsedMenu: isCollapsedState,
    toggleSideBar,
    sideBarSize,
  };
};
