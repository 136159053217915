import React, { FC, useState, useMemo } from 'react';
import produce from 'immer';
import { Button, Form, Select, Space, Table, Typography } from 'antd';
import { columns } from './columns';
import './treatment-quote-table.styles.less';
import { EditableCell } from './components/editable-cell.component';
import { TTreatmentQuoteTableColumn } from './types/TreatmentQuoteTableColumnType';
import { CloseCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { AddToQuoteModal } from '../add-to-quote-modal/add-to-quote-modal.component';
import { ITreatmentOption, ITreatmentQuoteInfo } from 'services/patient-proposal-api/types';
import { PopoverDelete } from 'components/popovers/popover-delete';
import { isNumber } from 'lodash';
import { VALID_CURRENCY } from 'modules/gdp/constants';
import { NiceSelect } from 'components/commons/nice-select/nice-select';
import { useCurrency } from 'hooks/useCurrency';

interface IItemProps {
  record: ITreatmentQuoteInfo;
  editingKey: string;
  onCancelItem: () => void;
  onSaveItem: (title: string) => void;
  onRemoveItem: (title: string) => void;
  onEditItem: (record: ITreatmentQuoteInfo) => void;
}
const Item: FC<IItemProps> = ({ record, editingKey, onCancelItem, onSaveItem, onRemoveItem, onEditItem }) => {
  const editable = record.title === editingKey;

  return editable ? (
    <Space wrap>
      <Button danger type="text" icon={<CloseCircleOutlined />} onClick={onCancelItem}>
        Cancel
      </Button>
      <Button type="text" icon={<SaveOutlined />} onClick={() => onSaveItem(record.title)}>
        Save
      </Button>
    </Space>
  ) : (
    <Space wrap>
      <PopoverDelete placement="top" onDelete={() => onRemoveItem(record.title)}>
        <Button danger type="text" icon={<CloseCircleOutlined />}>
          Delete
        </Button>
      </PopoverDelete>
      <Button type="text" icon={<EditOutlined />} disabled={editingKey !== ''} onClick={() => onEditItem(record)}>
        Edit
      </Button>
    </Space>
  );
};

interface ITreatmentQuoteTableComponentProps {
  data: ITreatmentQuoteInfo[];
  setData?: (data: ITreatmentQuoteInfo[]) => void;
  onRemove?: () => void;
  editable?: boolean;
  treatmentOptions?: ITreatmentOption[];
  currencyCode?: string | undefined;
  setCurrencyCodeData?: (code: string) => void;
  isPreviewProposal?: boolean;
}

const tableComponents = {
  body: {
    cell: EditableCell,
  },
};

export const TreatmentQuoteTableComponent: FC<ITreatmentQuoteTableComponentProps> = ({
  data,
  setData = () => {},
  onRemove = () => {},
  editable,
  treatmentOptions,
  currencyCode,
  setCurrencyCodeData,
  isPreviewProposal,
}) => {
  const [form] = Form.useForm<ITreatmentQuoteInfo>();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: ITreatmentQuoteInfo) => record.title === editingKey;
  const { getCurrencySymbolByCode, currencyList, isCurrencyListLoading } = useCurrency();

  const filteredCurrencies = useMemo(
    () => (currencyList || []).filter((item) => VALID_CURRENCY.includes(item.currencyCode)),
    [currencyList, VALID_CURRENCY],
  );

  const currencySymbol = getCurrencySymbolByCode(currencyCode!);

  const onAddClick = () => {
    NiceModal.show(AddToQuoteModal, {
      data,
      treatmentOptions,
      onConfirm: (values: ITreatmentQuoteInfo[]) => {
        setData(values);
      },
    });
  };

  const edit = (record: Partial<ITreatmentQuoteInfo> & { title: React.Key }) => {
    form.setFieldsValue({ price: 0, details: '', ...record });
    setEditingKey(record.title);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const remove = (title: string) => {
    setData(data.filter((d) => d.title !== title));
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as ITreatmentQuoteInfo;

      const index = data.findIndex((item) => key === item.title);

      if (index > -1) {
        setData(
          produce(data, (draft) => {
            const item = draft[index];
            // eslint-disable-next-line no-param-reassign
            draft[index] = {
              ...item,
              ...row,
            };
          }),
        );
        setEditingKey('');
      } else {
        setData([...data, row]);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columnsToUse: TTreatmentQuoteTableColumn[] = [
    ...columns,
    ...(editable
      ? [
          {
            title: 'Actions',
            dataIndex: undefined,
            key: 'Actions',
            editable: false,
            fixed: 'right' as any,
            width: 100,
            render: (_: unknown, record: ITreatmentQuoteInfo) => (
              <Item
                record={record}
                editingKey={editingKey}
                onCancelItem={cancel}
                onSaveItem={save}
                onRemoveItem={remove}
                onEditItem={edit}
              />
            ),
          },
        ]
      : []),
  ].map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'price' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    } as TTreatmentQuoteTableColumn;
  });

  const tableData = data.map((item) => ({
    ...item,
    currencyCode: currencySymbol,
  }));

  return (
    <div className="treatment-quote-table">
      <Form form={form} component={false}>
        <Table
          pagination={false}
          dataSource={tableData}
          columns={columnsToUse}
          components={tableComponents}
          scroll={{ x: 'max-content' }}
          rowKey="id"
          // eslint-disable-next-line react/no-unstable-nested-components
          footer={() => (
            <table>
              <tbody>
                <tr>
                  <td className={editable ? 'md:w-9/12 lg:w-[24.6%]' : 'w-1/3'}>
                    <div className="flex items-center justify-around">
                      <Typography.Text>
                        <b>Total </b>
                      </Typography.Text>
                      {!isPreviewProposal && (
                        <Typography.Text>
                          <b>
                            <NiceSelect
                              className="w-24 "
                              showSearch
                              autoClearSearchValue
                              value={currencyCode}
                              optionFilterProp="label"
                              loading={isCurrencyListLoading}
                              filterOption={(input, option) => {
                                if (typeof option?.label === 'string' && typeof input === 'string') {
                                  return option.label.toLowerCase().includes(input.toLowerCase());
                                }
                                return false;
                              }}
                              onChange={setCurrencyCodeData}
                            >
                              {filteredCurrencies?.map((option) => (
                                <Select.Option
                                  key={option.currencyCode}
                                  value={option.currencyCode}
                                  label={option.currencyCode}
                                >
                                  <div className="flex items-center">
                                    <div className="ml-2">{option.currencyCode}</div>
                                    <div className="ml-2">{option.currencySymbol}</div>
                                  </div>
                                </Select.Option>
                              ))}
                            </NiceSelect>
                          </b>
                        </Typography.Text>
                      )}
                    </div>
                  </td>

                  <td className={editable ? 'w-1/4' : 'w-1/3'}>
                    {(data?.length || currencySymbol) && (
                      <div className="font-bold">
                        {currencySymbol} &nbsp;&nbsp;
                        {data.reduce((sum, curr) => sum + (isNumber(curr.price) ? curr.price : 0), 0)}
                      </div>
                    )}
                  </td>
                  <td aria-label="empty" />
                </tr>
              </tbody>
            </table>
          )}
        />
      </Form>
      {editable && (
        <Space className="mt-2 flex justify-between pr-4">
          <Button shape="round" onClick={onAddClick}>
            + Add
          </Button>
          <PopoverDelete placement="topLeft" onDelete={onRemove}>
            <Button danger>Delete quote</Button>
          </PopoverDelete>
        </Space>
      )}
    </div>
  );
};
