import { FC } from 'react';
import { Col } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { ITreatmentDesignImage } from 'services/treatment-designs-api/types';
import Tooltip from 'antd/es/tooltip';
import { LinkDownload } from 'components/commons/link-download/link-download';

interface IProps {
  image: ITreatmentDesignImage;
  onPreview: () => void;
}

export const IprSchematicsItem: FC<IProps> = ({ image, onPreview }) => (
  <Col span={24} className="mb-3" key={image?.url}>
    <div className="small-image-container mb-2 w-full cursor-pointer" style={{ minHeight: 240 }}>
      <img src={image?.url} className="h-full" alt="" />
      <div className="overlay h-full w-full">
        <div className="image-backdrop" />
        <div className="image-preview-btn flex h-full w-full flex-row items-center justify-center space-x-1">
          <Tooltip title="Preview">
            <EyeOutlined style={{ color: 'lightgrey', fontSize: 26 }} onClick={onPreview} />
          </Tooltip>
          <LinkDownload url={image.url} name={image.name} isIcon isLarge className="-mb-1 text-lightGrey" />
        </div>
      </div>
    </div>
  </Col>
);
