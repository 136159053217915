import './preview-image-group.less';
import { Image, Progress, Tooltip } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { IImage } from 'services/patients-api/types';
import placeholderPng from 'assets/images/placeholder.png';
import { PopoverDelete } from 'components/popovers/popover-delete';

interface IProps {
  images: IImage[];
  deleteImage?: Function;
  name: string;
}

export const PreviewImageGroupNew: FC<IProps> = ({ images, deleteImage, name }) => {
  const [currentPreview, setCurrentPreviewImage]: any = useState(0);
  const [visible, setShowPreviewImage] = useState(false);

  const previewImage = (index: number) => {
    setCurrentPreviewImage(index);
    setShowPreviewImage(true);
  };

  return (
    <>
      {images?.map(({ url, status, uid, percent, name: imageName }, index: number) => (
        <div className="my-4 flex items-center justify-between bg-[#F9F9FA]" key={url || uid}>
          {status && status !== 'done' ? (
            <div
              style={{
                backgroundImage: `url(${placeholderPng})`,
              }}
              className="min-h-[100px] w-full max-w-[170px] rounded bg-cover bg-center"
            >
              <Progress percent={Math.floor(percent || 0)} size="small" className="custom-progress mt-10 p-1" />
            </div>
          ) : (
            <div className="flex w-full items-center gap-4">
              <div className="small-image-container mb-2 min-h-[100px] w-full max-w-[170px] cursor-pointer">
                <div>
                  <img id={url} src={url} className="h-full" alt="" />
                  <div className="overlay h-full w-full">
                    <div className="image-backdrop" />
                    <div className="image-preview-btn flex h-full w-full items-center justify-center">
                      <Tooltip title="Preview">
                        <EyeOutlined className="text-lg text-lightGrey" onClick={() => previewImage(index)} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <p className="font-semibold">{imageName}</p>
            </div>
          )}
          {url && !!deleteImage && (
            <PopoverDelete onDelete={() => deleteImage?.(url, name)} placement="left">
              <Tooltip title="Delete">
                <DeleteOutlined className="mr-4 text-2xl text-[#050D43]" />
              </Tooltip>
            </PopoverDelete>
          )}
        </div>
      ))}
      <div style={{ display: 'none' }}>
        {visible && (
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setShowPreviewImage(vis), current: currentPreview }}
          >
            {images.map(({ url }: any) => (
              <Image key={url} src={url} />
            ))}
          </Image.PreviewGroup>
        )}
      </div>
    </>
  );
};
